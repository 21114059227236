import React, { useEffect, useState } from "react";
import { cloneDeep } from "lodash";
import cn from "classnames";
import { Dots } from "react-activity";
import PropTypes from "prop-types";
import "react-activity/dist/Dots.css";
import { Switch, Select } from "antd";
import Input from "../../../components/Input/index";
import clientServices from "../../../api/clientServices";
import showAlert from "../../../utils/showAlert";
import ProductsTable from "../ProductsTable";

function ClientManagementPopup({
  setLoader,
  popUpData,
  getClientListDetails,
  closePopup,
}) {
  const [formDetails, setFormDetails] = useState({
    sender_name: "",
    email: "",
    note: "",
    companyName: null,
    status: true,
  });
  const [passwordError, updatePasswordError] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [orgListLoading, setOrgListLoading] = useState(false);
  const [orgName, setOrgName] = useState("");
  const [orgOptions, setOrgOption] = useState([]);
  const [activeTab, setActiveTab] = useState("update");

  useEffect(() => {
    if (popUpData.isEditMode) {
      const formDetailsCopy = cloneDeep(formDetails);
      formDetailsCopy.email = popUpData.data.email;
      formDetailsCopy.note = popUpData.data.note;
      formDetailsCopy.status = popUpData.data.status;
      formDetailsCopy.companyName = popUpData.data.organization;
      formDetailsCopy.sender_name = popUpData.data.sender_name;

      setFormDetails(formDetailsCopy);
    }
  }, []);

  const loadOrganizationList = async () => {
    try {
      setOrgListLoading(true);
      const res = await clientServices.getSenderOrganization(orgName);
      const array = res.map((e) => ({
        lable: e.organization,
        value: e.organization,
      }));
      setOrgOption(array);
      setOrgListLoading(false);
    } catch (e) {
      setOrgListLoading(false);
    }
  };

  useEffect(() => {
    let autoSaveTimer;
    autoSaveTimer = setTimeout(() => loadOrganizationList(), 1000);
    return () => {
      // cancel the request when component un mounts
      clearInterval(autoSaveTimer);
      autoSaveTimer = null;
    };
  }, [orgName]);

  const onChangeHandler = (key) => (value1, value2) => {
    const formDetailsCopy = cloneDeep(formDetails);

    if (key === "sender_name") {
      const regex = /^[a-zA-Z0-9\s]+$/;
      if (regex.test(value1)) {
        formDetailsCopy.sender_name = value1;
      }
    } else {
      formDetailsCopy[key] = value1;
      if (key === "photo") formDetailsCopy.image_name = value2;
      if (key === "password" || key === "confirmPassword") {
        updatePasswordError("");
      }
    }
    setFormDetails(formDetailsCopy);
  };

  const selectClear = () => {
    const formDetailsCopy = cloneDeep(formDetails);
    formDetailsCopy.companyName = "";
    setFormDetails(formDetailsCopy);
  };

  const onSelectChange = (value) => {
    const formDetailsCopy = cloneDeep(formDetails);
    formDetailsCopy.companyName = value.slice(-1).pop();
    setFormDetails(formDetailsCopy);
  };

  const submitData = async () => {
    let payload = {
      sender_name: formDetails.sender_name,
      email: formDetails.email,
      note: formDetails.note,
      status: formDetails.status,
      organization: formDetails.companyName,
    };

    try {
      setLoading(true);
      if (popUpData.isEditMode) {
        payload = {
          ...payload,
          id: popUpData.data.id,
        };
        if (formDetails.password) {
          payload.password = formDetails.password;
        }
        await clientServices.updateSenderData(payload);
      } else {
        Object.keys(payload).forEach((key) => {
          if (payload[key] === "" || payload[key] === null) {
            delete payload[key];
          }
        });
        await clientServices.addNewSender(payload);
      }
      setLoader(true);
      setLoading(false);
      closePopup();
      showAlert("Sender updated successfully!", "success");
      getClientListDetails();
    } catch (error) {
      let message = error.response?.data?.message || error.response?.data?.[0];
      if (error.response.status === 400 && error?.response?.data) {
        [[message]] = Object.values(error?.response?.data);
      }
      if (message) showAlert(message, "error");
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  };

  const submitHandler = (event) => {
    event.preventDefault();
    if (formDetails.password !== formDetails.confirmPassword) {
      updatePasswordError("Password and confirm password should be same");
    } else {
      submitData();
    }
  };

  const tabHandler = (value) => {
    setActiveTab(value);
  };

  return (
    <div className="popup" data-testid="client-management-popup">
      <div className="popup-overlay" />
      <div className="popup-wrapper">
        {popUpData.isEditMode && (
          <div className="popup-tabs">
            <div
              role="button"
              tabIndex={0}
              onClick={() => tabHandler("update")}
              onKeyPress={() => tabHandler("update")}
              className={cn({ tab: true, active: activeTab === "update" })}
            >
              {popUpData.isEditMode ? "Edit Sender" : "New Sender"}
            </div>
            <div
              role="button"
              tabIndex={0}
              onClick={() => tabHandler("product")}
              onKeyPress={() => tabHandler("product")}
              className={cn({ tab: true, active: activeTab === "product" })}
            >
              Products
            </div>
          </div>
        )}
        {activeTab === "update" ? (
          <>
            <div className="popup-header">
              <h1>{popUpData.isEditMode ? "Edit Sender" : "New Sender"}</h1>
              {/* <p>
            {popUpData.isEditMode
              ? "Use this page to update your contact information and change your password."
              : "Use this page to add a new User"}
          </p> */}
            </div>
            <form onSubmit={submitHandler}>
              <div className="popup-body">
                <div className="popup-row">
                  <Input
                    maxLength={90}
                    required="required"
                    type="email"
                    placeholder="Email address"
                    onChange={onChangeHandler("email")}
                    value={formDetails.email}
                  />
                  <div>
                    <div
                      style={{
                        color: "#b0bac9",
                        fontSize: 11,
                        marginBottom: 8,
                        letterSpacing: 1.13,
                      }}
                    >
                      COMPANY NAME
                    </div>
                    <Select
                      placeholder="Please select"
                      mode="tags"
                      onDeselect={selectClear}
                      maxTagCount={1}
                      maxTagTextLength={10}
                      maxLength={5}
                      loading={orgListLoading}
                      size="large"
                      options={orgOptions}
                      onSelect={onChangeHandler("companyName")}
                      onChange={onSelectChange}
                      onSearch={(e) => setOrgName(e)}
                      value={
                        formDetails.companyName &&
                        formDetails.companyName !== ""
                          ? formDetails.companyName
                          : undefined
                      }
                      bordered={false}
                      style={{
                        width: "100%",
                        maxWidth: 340,
                        height: 42,
                        paddingRight: 10,
                        background: "rgba(224, 231, 255, 0.2)",
                        border: "1px solid #e0e7ff",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div />
                  {/* <FileInput
                onChange={onChangeHandler("photo")}
                imageUrl={formDetails.photo}
              /> */}
                </div>
                <div className="popup-row">
                  <Input
                    // required="required"
                    maxLength={90}
                    type="text"
                    placeholder="Name"
                    onChange={onChangeHandler("sender_name")}
                    value={formDetails.sender_name}
                  />
                  <Input
                    // required="required"
                    maxLength={90}
                    placeholder="Note"
                    onChange={onChangeHandler("note")}
                    value={formDetails.note}
                  />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <div style={{ color: "#b0bac9", marginRight: 10 }}>
                      Status
                    </div>
                    <Switch
                      onChange={onChangeHandler("status")}
                      checked={formDetails.status}
                    />
                  </div>
                </div>
              </div>
              <div className="invalidCredentials">{passwordError}</div>
              <div className="popup-footer">
                <button
                  type="button"
                  className="cancel-btn"
                  onClick={closePopup}
                >
                  Cancel
                </button>
                <button type="submit" className="save-btn" disabled={isLoading}>
                  {isLoading ? (
                    <div style={{ textAlign: "center" }}>
                      <Dots />
                    </div>
                  ) : (
                    (popUpData.isEditMode && "Save Changes") ||
                    "Add New Account"
                  )}
                </button>
              </div>
            </form>
          </>
        ) : (
          <div className="sender-product-table">
            <ProductsTable popUpData={popUpData} />
            <div className="popup-footer">
              <button type="button" className="cancel-btn" onClick={closePopup}>
                Cancel
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ClientManagementPopup;

ClientManagementPopup.propTypes = {
  setLoader: PropTypes.func.isRequired,
  popUpData: PropTypes.instanceOf(Object).isRequired,
  getClientListDetails: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
};
