import React, { useEffect, useState, useRef } from "react";
import AttachMoneyTwoToneIcon from "@material-ui/icons/AttachMoneyTwoTone";
import CancelButton from "@material-ui/icons/CancelRounded";
import moment from "moment";
import cn from "classnames";
import { Switch } from "antd";
import PropTypes from "prop-types";
import { startCase } from "lodash";
import TextWrap from "../TextWrap";
import alert from "../../../../../components/ConfirmAlert";
import showAlert from "../../../../../utils/showAlert";
import styles from "../../../resources/index.module.scss";
import userServices from "../../../../../api/userServices";
import ResolveMailContainer from "../ResolveMailContainer";
import helper from "../../../../../utils/helper";

const statusFilters = {
  all: "all",
  published: "pub",
  processing: "pro",
  error: "err",
  resolved: "res",
};

function Preview({
  listViewOption,
  selectedRecord,
  isInsideModal,
  openFundTagModal,
  userAddedFundTag = [],
  openMailModal,
  threadCount = 0,
  onStartedWorking,
  onStoppedWorking,
  onResolved,
  userInfo,
  expandHandler,
  tableRef,
  hotQueueRulesData,
  mailLabels,
  labelHandler,
}) {
  // isInsideModal is not being used currently
  const [started, setStarted] = useState(false);
  const [activeTab, setActiveTab] = useState("totalbreakdown");
  const [, setOverriding] = useState(false);
  const [hotQueueInstructionOpen, setHotQueueInstructionOpen] = useState(true);
  const { userId } = userInfo;
  const { getName, replaceURLs } = helper;

  const previewRef = useRef();

  const [rightBoxSize, setRightBoxSize] = useState(
    previewRef.current ? previewRef.current.clientWidth : 0
  );
  const [leftBoxSize, setLeftBoxSize] = useState(
    tableRef.current ? tableRef.current.clientWidth : 0
  );

  const observer = useRef(null);
  const observer2 = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (previewRef.current) {
        observer.current = new ResizeObserver(() => {
          setRightBoxSize(
            previewRef.current ? previewRef.current.clientWidth : 0
          );
        });

        observer.current.observe(previewRef.current);

        return () =>
          previewRef.current
            ? observer.current.unobserve(previewRef.current)
            : null;
      }
    }, 1000);
  }, []);
  useEffect(() => {
    setTimeout(() => {
      if (tableRef.current) {
        observer2.current = new ResizeObserver(() => {
          setLeftBoxSize(tableRef.current ? tableRef.current.clientWidth : 0);
        });

        observer2.current.observe(tableRef.current);

        return () =>
          tableRef.current
            ? observer2.current.unobserve(tableRef.current)
            : null;
      }
    }, 1000);
  }, []);

  const checkCanStartWorking = (mail) => {
    const count = mail?.remaining_status_to_resolve || 0;
    const othersWorkingCount = mail?.working_count?.others_working_count || 0;
    const myWorkingCount =
      selectedRecord?.working_count?.same_user_working_count || 0;
    return count > 0 || othersWorkingCount > 0 || myWorkingCount > 0;
  };

  const getWorkerNames = (workers, showYou) => {
    const workerCount = workers.length;
    let name = "Somebody";
    if (workerCount === 2) {
      name = `${workers[0].id === userId ? "You" : workers[0].username} & ${
        workers[1].id === userId ? "you" : workers[1].username
      }`;
    } else if (workerCount > 1) {
      if (workers?.some((item) => item.id === userId)) {
        name = `${showYou && "You & "}${workerCount - 1} others`;
      } else {
        name = `${workers[0].username} & ${workerCount - 1} others`;
      }
    } else if (workerCount === 1) {
      name = `${workers[0].id === userId ? "You" : workers[0].username}`;
    }
    return name;
  };

  const isUserWorking = (workers, newUserId) =>
    workers?.some((item) => item.id === newUserId);

  function isStarted() {
    const othersWorkingCount =
      selectedRecord?.working_count?.others_working_count || 0;
    const myWorkingCount =
      selectedRecord?.working_count?.same_user_working_count || 0;
    const resolvingAttachments =
      selectedRecord?.working_count?.resolving_attachments_count || 0;
    const newIsStarted =
      resolvingAttachments === othersWorkingCount + myWorkingCount;
    return (
      isUserWorking(selectedRecord?.start_resolving_by, userId) && newIsStarted
    );
  }

  useEffect(() => {
    setStarted(isStarted());
  }, [selectedRecord]);

  function startResolvingAll(assignAll) {
    const data = { resolve_all: assignAll };
    if (listViewOption !== statusFilters.all) {
      data.ref_ids = selectedRecord.ref_ids;
    }
    setOverriding(true);
    userServices
      .getStartResolvingAll(selectedRecord.id, data)
      .then((response) => {
        setOverriding(false);
        showAlert(
          response?.message || "Started working successfully!",
          "success"
        );
        if (onStartedWorking) onStartedWorking();
      })
      .catch((error) => {
        setStarted(false);
        setOverriding(false);
        showAlert(
          error?.response?.data?.message || "Something went wrong!",
          "error"
        );
      });
  }

  function confirmOverride(response) {
    const issuesRemaining =
      response.resolving_attachments_count - response.others_working_count;
    let message;
    let buttons;
    if (issuesRemaining === 0) {
      message = `${getWorkerNames(selectedRecord?.start_resolving_by, false)} ${
        selectedRecord?.start_resolving_by.length > 1 ? "are" : "is"
      } already working on all the issues. Do you want to override?`;
      buttons = [
        {
          label: `Override`,
          onClick: () => {
            startResolvingAll(true);
          },
          className: "btn-action",
        },
        {
          label: `Cancel`,
          onClick: () => {
            setStarted(false);
          },
          className: "btn-action",
        },
      ];
    } else {
      message = `${getWorkerNames(selectedRecord?.start_resolving_by, false)} ${
        selectedRecord?.start_resolving_by.length > 1 ? "are" : "is"
      } already working on ${
        response.others_working_count
      } issues among a total of 
      ${response.resolving_attachments_count} issues. 
      You can either Override all issues, or Start working on the remaining ${issuesRemaining} issues. 
      What do you want?`;
      buttons = [
        {
          label: `Override all`,
          onClick: () => {
            startResolvingAll(true);
          },
          className: "btn-action",
        },
        {
          label: `Start remaining`,
          onClick: () => {
            startResolvingAll(false);
          },
          className: "btn-action",
        },
        {
          label: `Cancel`,
          onClick: () => {
            setStarted(false);
          },
          className: "btn-action",
        },
      ];
    }
    alert({
      title: "Confirm",
      message,
      buttons,
    });
  }

  function checkAnyoneIsWorking() {
    setOverriding(true);
    userServices
      .getCheckAnyoneIsWorking(selectedRecord.id, listViewOption)
      .then((response) => {
        setOverriding(false);
        if (response?.working) {
          confirmOverride(response);
        } else {
          startResolvingAll(true);
        }
      })
      .catch((error) => {
        setOverriding(false);
        setStarted(false);
        showAlert(
          error?.response?.data?.message || "Something went wrong!",
          "error"
        );
      });
  }

  function endResolvingAll() {
    userServices
      .getEndResolvingAll(selectedRecord, listViewOption)
      .then((response) => {
        setStarted(false);
        showAlert(response?.message || "Stopped working!", "success");
        if (onStoppedWorking) onStoppedWorking();
      })
      .catch((error) => {
        setStarted(false);
        showAlert(
          error?.response?.data?.message || "Something went wrong!",
          "error"
        );
      });
  }

  const onSwitchChange = (checked) => {
    setStarted(checked);
    if (checked) {
      checkAnyoneIsWorking();
    } else {
      endResolvingAll();
    }
  };

  const tabData = () => {
    if (activeTab === "totalbreakdown") {
      return (
        <div className={styles.countCard}>
          <div className={styles.red}>
            Error <span>{selectedRecord?.error_count}</span>
          </div>
          <div className={styles.yellow}>
            Processing <span>{selectedRecord?.processing_count}</span>
          </div>
          <div className={styles.blue}>
            Resolved <span>{selectedRecord?.resolved_count}</span>
          </div>
          <div className={styles.green}>
            Published <span>{selectedRecord?.published_count}</span>
          </div>
          <div className={styles.orange}>
            Hot Queue <span>{selectedRecord?.hot_queue_count}</span>
          </div>
          <div className={styles.violet}>
            Historical Price
            <span>{selectedRecord?.historical_price_error_count}</span>
          </div>
          <div className={styles.red}>
            Inactive <span>{selectedRecord?.inactive_error_count}</span>
          </div>
          <div className={styles.red}>
            Not In DB <span>{selectedRecord?.invalid_error_count}</span>
          </div>
          <div className={styles.red}>
            Not A Product
            <span>{selectedRecord?.not_a_product_error_count}</span>
          </div>
          <div className={styles.red}>
            Zip File
            <span>{selectedRecord?.zip_file_error_count}</span>
          </div>
          <div className={styles.lightBlue}>
            EWKA
            <span>{selectedRecord?.known_attachment_count}</span>
          </div>
          <div className={styles.lightBlue}>
            EWOA
            <span>{selectedRecord?.no_attachment_count}</span>
          </div>
          <div className={styles.lightYellow}>
            Quarantine
            <span>{selectedRecord?.quarantined_count}</span>
          </div>
          <div className={styles.yellow}>
            IA Processing
            <span>{selectedRecord?.ia_processing_count}</span>
          </div>
        </div>
      );
    }
    return (
      <table>
        <tbody>
          {selectedRecord.date ? (
            <tr>
              <td>
                <div>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.25 3H12.75V2.25C12.75 2.05109 12.671 1.86032 12.5303 1.71967C12.3896 1.57902 12.1989 1.5 12 1.5C11.8011 1.5 11.6103 1.57902 11.4696 1.71967C11.329 1.86032 11.25 2.05109 11.25 2.25V3H6.74997V2.25C6.74997 2.05109 6.67095 1.86032 6.5303 1.71967C6.38965 1.57902 6.19888 1.5 5.99997 1.5C5.80106 1.5 5.61029 1.57902 5.46964 1.71967C5.32899 1.86032 5.24997 2.05109 5.24997 2.25V3H3.74997C3.15323 3 2.58094 3.23705 2.15898 3.65901C1.73702 4.08097 1.49997 4.65326 1.49997 5.25V14.25C1.49997 14.8467 1.73702 15.419 2.15898 15.841C2.58094 16.2629 3.15323 16.5 3.74997 16.5H14.25C14.8467 16.5 15.419 16.2629 15.841 15.841C16.2629 15.419 16.5 14.8467 16.5 14.25V5.25C16.5 4.65326 16.2629 4.08097 15.841 3.65901C15.419 3.23705 14.8467 3 14.25 3ZM15 14.25C15 14.4489 14.921 14.6397 14.7803 14.7803C14.6396 14.921 14.4489 15 14.25 15H3.74997C3.55106 15 3.36029 14.921 3.21964 14.7803C3.07899 14.6397 2.99997 14.4489 2.99997 14.25V9H15V14.25ZM15 7.5H2.99997V5.25C2.99997 5.05109 3.07899 4.86032 3.21964 4.71967C3.36029 4.57902 3.55106 4.5 3.74997 4.5H5.24997V5.25C5.24997 5.44891 5.32899 5.63968 5.46964 5.78033C5.61029 5.92098 5.80106 6 5.99997 6C6.19888 6 6.38965 5.92098 6.5303 5.78033C6.67095 5.63968 6.74997 5.44891 6.74997 5.25V4.5H11.25V5.25C11.25 5.44891 11.329 5.63968 11.4696 5.78033C11.6103 5.92098 11.8011 6 12 6C12.1989 6 12.3896 5.92098 12.5303 5.78033C12.671 5.63968 12.75 5.44891 12.75 5.25V4.5H14.25C14.4489 4.5 14.6396 4.57902 14.7803 4.71967C14.921 4.86032 15 5.05109 15 5.25V7.5Z"
                      fill="#79828B"
                    />
                  </svg>
                </div>
              </td>
              <td>Date and Time</td>
              <td>
                {moment.unix(selectedRecord.date).format("MMM DD, HH:mm")}
              </td>
            </tr>
          ) : null}

          {selectedRecord?.tag_type ? (
            <tr>
              <td>
                <div>
                  <AttachMoneyTwoToneIcon
                    className={styles.moneyIcon}
                    fontSize="small"
                  />
                </div>
              </td>
              <td>Fund Tag</td>
              <td
                title="Predicted by IA, not entirely accurate."
                className={styles.infoIcon}
              >
                {selectedRecord?.tag_type}
              </td>
            </tr>
          ) : null}

          <tr>
            <td>
              <div>
                <AttachMoneyTwoToneIcon
                  className={styles.moneyIcon}
                  fontSize="small"
                />
              </div>
            </td>
            <td
              role="presentation"
              onClick={openFundTagModal}
              className={styles.editIcon}
            >
              Edit Fund Tag
            </td>
            <td>
              {userAddedFundTag
                ? userAddedFundTag.map((el, index) => {
                    if (index === 0) return `${el}`;
                    return `, ${el}`;
                  })
                : null}
            </td>
          </tr>

          {selectedRecord?.ia_generated_count !== 0 && (
            <tr>
              <td>
                <div className={styles.redBall} />
              </td>
              <td>Total IA Generated</td>
              <td>{selectedRecord?.ia_generated_count}</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  return (
    <div
      className={styles.preview}
      data-testid="preview"
      id="preview"
      ref={previewRef}
    >
      {/* inner expansion line and button */}
      <div
        role="button"
        tabIndex={0}
        onMouseDown={expandHandler}
        className={styles.expandContainer}
      >
        <div className={styles.expandBtnContainer}>
          <div className={styles.expandBtn}>
            <img
              src={
                leftBoxSize && leftBoxSize <= 800
                  ? "/assets/white-arrow-right.svg"
                  : "/assets/white-arrow.svg"
              }
              alt="arrow"
            />
          </div>
        </div>
      </div>
      {selectedRecord && (
        <>
          {selectedRecord.status === statusFilters.error && isInsideModal && (
            <>
              <TextWrap text={selectedRecord.api_response} />
              <br />
            </>
          )}
          <>
            <div className={styles.previewHeader}>
              <div
                style={{
                  flexBasis: "60%",
                  display: "flex",
                  overflow: "hidden",
                }}
              >
                <div className={styles.profilePic}>
                  {selectedRecord?.manual_upload
                    ? startCase(selectedRecord?.sender_details?.sender_name)
                        .split(" ")
                        .splice(0, 2)
                        .map((name) => name[0])
                        .join("")
                        .toUpperCase()
                    : startCase(getName(selectedRecord.mail_from ?? ""))
                        .split(" ")
                        .splice(0, 2)
                        .map((name) => name[0])
                        .join("")
                        .toUpperCase()}
                </div>

                <div
                  className={styles.previewSubject}
                  title={selectedRecord.subject}
                >
                  {selectedRecord.subject}
                  <div className={styles.emailDateTime}>
                    {moment.unix(selectedRecord.date).format("MMM DD, HH:mm")}
                  </div>
                </div>
              </div>
              {!selectedRecord?.manual_upload && (
                <div className={styles.previewReply}>
                  <div
                    data-testid="preview-reply-btn"
                    role="button"
                    tabIndex="0"
                    className={cn({
                      [styles.hide]: threadCount >= 2,
                    })}
                    onKeyDown={() => openMailModal(true)}
                    onClick={() => openMailModal(true)}
                  >
                    <img src="/assets/reply.svg" alt="Reply" />
                    Reply
                  </div>
                  <div
                    data-testid="preview-thread-btn"
                    role="button"
                    tabIndex="0"
                    onKeyDown={() => openMailModal(false)}
                    onClick={() => openMailModal(false)}
                  >
                    <img src="/assets/chat-teardrop.svg" alt="view thread" />
                    Thread ({threadCount})
                  </div>
                </div>
              )}
              {/* <div className={styles.previewDate}>
                <svg
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.58334 0C3.67684 0 2.7907 0.268808 2.03697 0.772431C1.28325 1.27605 0.695789 1.99187 0.348888 2.82937C0.00198589 3.66686 -0.0887794 4.58842 0.0880695 5.4775C0.264918 6.36658 0.701439 7.18325 1.34243 7.82424C1.98342 8.46523 2.80009 8.90175 3.68917 9.0786C4.57825 9.25545 5.49981 9.16468 6.3373 8.81778C7.1748 8.47088 7.89061 7.88342 8.39424 7.1297C8.89786 6.37597 9.16667 5.48983 9.16667 4.58333C9.16667 3.98144 9.04812 3.38544 8.81778 2.82937C8.58745 2.27329 8.24984 1.76803 7.82424 1.34243C7.39864 0.916825 6.89338 0.57922 6.3373 0.348885C5.78123 0.118551 5.18523 0 4.58334 0ZM4.58334 8.25C3.85814 8.25 3.14922 8.03495 2.54624 7.63205C1.94326 7.22916 1.4733 6.6565 1.19578 5.98651C0.918256 5.31651 0.845644 4.57927 0.987123 3.868C1.1286 3.15674 1.47782 2.5034 1.99061 1.99061C2.5034 1.47782 3.15674 1.1286 3.868 0.987121C4.57927 0.845642 5.31651 0.918254 5.98651 1.19578C6.6565 1.4733 7.22916 1.94326 7.63206 2.54624C8.03496 3.14922 8.25 3.85813 8.25 4.58333C8.25 5.55579 7.86369 6.48842 7.17606 7.17606C6.48843 7.86369 5.5558 8.25 4.58334 8.25Z"
                    fill="#79828B"
                  />
                  <path
                    d="M6.41667 4.12508H5.04167V2.75008C5.04167 2.62852 4.99338 2.51195 4.90742 2.42599C4.82147 2.34004 4.70489 2.29175 4.58333 2.29175C4.46178 2.29175 4.3452 2.34004 4.25924 2.42599C4.17329 2.51195 4.125 2.62852 4.125 2.75008V4.58342C4.125 4.70497 4.17329 4.82155 4.25924 4.90751C4.3452 4.99346 4.46178 5.04175 4.58333 5.04175H6.41667C6.53822 5.04175 6.6548 4.99346 6.74076 4.90751C6.82671 4.82155 6.875 4.70497 6.875 4.58342C6.875 4.46186 6.82671 4.34528 6.74076 4.25932C6.6548 4.17337 6.53822 4.12508 6.41667 4.12508Z"
                    fill="#79828B"
                  />
                </svg>
                {moment.unix(selectedRecord.date).format("MMM DD")}
              </div> */}
            </div>
            {mailLabels.length > 0 && (
              <div className={styles.labelsTextContainer}>
                {mailLabels.map((e) => (
                  <div
                    key={e}
                    style={{
                      background: e?.color?.backgroundColor ?? "#E3E9EC",
                      color: e?.color?.textColor ?? "#000000",
                    }}
                    className={styles.labelBox}
                  >
                    {e.label_name}
                    {e.type === "user" && (
                      <CancelButton
                        className={styles.closeIcon}
                        onClick={() => labelHandler(e.id, "remove")}
                      />
                    )}
                  </div>
                ))}
              </div>
            )}
            <div className={styles.previewBody}>
              <div style={{ flexBasis: "80%" }}>
                {selectedRecord?.manual_upload ? (
                  <TextWrap
                    text={`Manual Upload by ${selectedRecord?.mail_from}`}
                  />
                ) : (
                  <TextWrap text={selectedRecord.body} />
                )}
              </div>
              {!selectedRecord.manual_upload && (
                <div className={styles.openLink}>
                  <a
                    rel="noreferrer"
                    href={
                      userInfo?.gmailURL
                        ? `${userInfo?.gmailURL}/#all/${selectedRecord.message_id}`
                        : null
                    }
                    target="_blank"
                    onClick={() => {
                      if (!userInfo?.gmailURL)
                        showAlert(
                          "You must set Gmail URL in settings page",
                          "warning"
                        );
                    }}
                  >
                    <img src="/assets/open-new-tab.svg" alt="full body link" />
                    Open
                  </a>
                </div>
              )}
            </div>
          </>

          {(checkCanStartWorking(selectedRecord) || isStarted()) && (
            <div
              className={cn({
                [styles.workStatus]: true,
              })}
            >
              {selectedRecord?.start_resolving_by?.length > 0 &&
                selectedRecord?.working_count?.others_working_count > 0 && (
                  <div>
                    <div className={styles.rowShade}>
                      <div style={{ display: "flex" }}>
                        <img
                          style={{ marginRight: 5 }}
                          src="/assets/loader-circle.svg"
                          alt="loader-circle"
                        />
                        <div>Currently Working</div>
                      </div>

                      <div>
                        {getWorkerNames(
                          selectedRecord?.start_resolving_by,
                          true
                        )}
                      </div>
                    </div>
                  </div>
                )}
              <div className={styles.rowShade}>
                <div style={{ display: "flex" }}>
                  <img
                    style={{ marginRight: 5 }}
                    src="/assets/loader-circle.svg"
                    alt="loader-circle"
                  />
                  <div>Start Working</div>
                </div>

                <div data-testid="preview-start-working-switch-btn">
                  <Switch
                    // data-testid="preview-start-working-switch-btn"
                    size="small"
                    onChange={onSwitchChange}
                    checked={started}
                  />
                </div>
              </div>
            </div>
          )}

          <div
            className={cn({
              [styles.tabContainer]: true,
              [styles.inactive]: rightBoxSize && rightBoxSize >= 520, // condition when expanding
            })}
          >
            <div className={styles.tabHeader}>
              <div
                role="button"
                tabIndex={0}
                className={cn({
                  [styles.tab]: true,
                  [styles.active]: activeTab === "totalbreakdown",
                })}
                onClick={() => setActiveTab("totalbreakdown")}
                onKeyDown={() => setActiveTab("totalbreakdown")}
              >
                Total Breakdown
              </div>
              <div
                role="button"
                tabIndex={0}
                className={cn({
                  [styles.tab]: true,
                  [styles.active]: activeTab === "filedetails",
                })}
                onClick={() => setActiveTab("filedetails")}
                onKeyDown={() => setActiveTab("filedetails")}
              >
                File Details
              </div>
            </div>

            {tabData()}
          </div>
          <div
            className={cn({
              [styles.largeScreenContainer]:
                rightBoxSize && rightBoxSize >= 520, // condition when expanding
              [styles.inactive]: rightBoxSize === 0 || rightBoxSize < 520, // condition when expanding
            })}
          >
            <div>
              <div
                className={cn({
                  [styles.totalAttachmentsCount]: true,
                  // [styles.hide]: selectedRecord?.total_attachment_count < 2,
                })}
              >
                <div className={styles.totalDisplay}>
                  <span>Total Breakdowns in the Email</span>
                  <span className={styles.countValue}>
                    {selectedRecord?.total_attachment_count}
                  </span>
                </div>
                {/* <div className={styles.totalDisplay} style={{ marginTop: 10 }}>
                  <div />
                </div> */}
              </div>
              <div
                className={cn({
                  [styles.countCard]: true,
                  [styles.countCardLarge]: rightBoxSize > 730, // condition when expanding
                  [styles.countCardBorder]: true,
                })}
              >
                <div className={styles.red}>
                  Error <span>{selectedRecord?.error_count}</span>
                </div>
                <div className={styles.yellow}>
                  Processing <span>{selectedRecord?.processing_count}</span>
                </div>
                <div className={styles.blue}>
                  Resolved <span>{selectedRecord?.resolved_count}</span>
                </div>
                <div className={styles.green}>
                  Published <span>{selectedRecord?.published_count}</span>
                </div>
                <div className={styles.orange}>
                  Hot Queue <span>{selectedRecord?.hot_queue_count}</span>
                </div>
                <div className={styles.violet}>
                  Historical Price
                  <span>{selectedRecord?.historical_price_error_count}</span>
                </div>
                <div className={styles.red}>
                  Inactive <span>{selectedRecord?.inactive_error_count}</span>
                </div>
                <div className={styles.red}>
                  Not In DB <span>{selectedRecord?.invalid_error_count}</span>
                </div>
                <div className={styles.red}>
                  Not A Product
                  <span>{selectedRecord?.not_a_product_error_count}</span>
                </div>
                <div className={styles.red}>
                  Zip File
                  <span>{selectedRecord?.zip_file_error_count}</span>
                </div>
                <div className={styles.lightBlue}>
                  EWKA
                  <span>{selectedRecord?.known_attachment_count}</span>
                </div>
                <div className={styles.lightBlue}>
                  EWOA
                  <span>{selectedRecord?.no_attachment_count}</span>
                </div>
                <div className={styles.lightYellow}>
                  Quarantine
                  <span>{selectedRecord?.quarantined_count}</span>
                </div>
                <div className={styles.yellow}>
                  IA Processing
                  <span>{selectedRecord?.ia_processing_count}</span>
                </div>
              </div>
            </div>
            {/* <hr className={styles.previewHeaderSeparator} /> */}
            <div>
              <div
                className={cn({
                  [styles.totalAttachmentsCount]: true,
                })}
              >
                <div className={styles.totalDisplay}>
                  <span>File Details</span>
                </div>
                <div className={styles.totalDisplay} style={{ marginTop: 10 }}>
                  <div />
                </div>
              </div>
              <div className={styles.countCardBorder}>
                <table>
                  <tbody>
                    {selectedRecord.date ? (
                      <tr>
                        <td>
                          <div>
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M14.25 3H12.75V2.25C12.75 2.05109 12.671 1.86032 12.5303 1.71967C12.3896 1.57902 12.1989 1.5 12 1.5C11.8011 1.5 11.6103 1.57902 11.4696 1.71967C11.329 1.86032 11.25 2.05109 11.25 2.25V3H6.74997V2.25C6.74997 2.05109 6.67095 1.86032 6.5303 1.71967C6.38965 1.57902 6.19888 1.5 5.99997 1.5C5.80106 1.5 5.61029 1.57902 5.46964 1.71967C5.32899 1.86032 5.24997 2.05109 5.24997 2.25V3H3.74997C3.15323 3 2.58094 3.23705 2.15898 3.65901C1.73702 4.08097 1.49997 4.65326 1.49997 5.25V14.25C1.49997 14.8467 1.73702 15.419 2.15898 15.841C2.58094 16.2629 3.15323 16.5 3.74997 16.5H14.25C14.8467 16.5 15.419 16.2629 15.841 15.841C16.2629 15.419 16.5 14.8467 16.5 14.25V5.25C16.5 4.65326 16.2629 4.08097 15.841 3.65901C15.419 3.23705 14.8467 3 14.25 3ZM15 14.25C15 14.4489 14.921 14.6397 14.7803 14.7803C14.6396 14.921 14.4489 15 14.25 15H3.74997C3.55106 15 3.36029 14.921 3.21964 14.7803C3.07899 14.6397 2.99997 14.4489 2.99997 14.25V9H15V14.25ZM15 7.5H2.99997V5.25C2.99997 5.05109 3.07899 4.86032 3.21964 4.71967C3.36029 4.57902 3.55106 4.5 3.74997 4.5H5.24997V5.25C5.24997 5.44891 5.32899 5.63968 5.46964 5.78033C5.61029 5.92098 5.80106 6 5.99997 6C6.19888 6 6.38965 5.92098 6.5303 5.78033C6.67095 5.63968 6.74997 5.44891 6.74997 5.25V4.5H11.25V5.25C11.25 5.44891 11.329 5.63968 11.4696 5.78033C11.6103 5.92098 11.8011 6 12 6C12.1989 6 12.3896 5.92098 12.5303 5.78033C12.671 5.63968 12.75 5.44891 12.75 5.25V4.5H14.25C14.4489 4.5 14.6396 4.57902 14.7803 4.71967C14.921 4.86032 15 5.05109 15 5.25V7.5Z"
                                fill="#79828B"
                              />
                            </svg>
                          </div>
                        </td>
                        <td>Date and Time</td>
                        <td>
                          {moment
                            .unix(selectedRecord.date)
                            .format("MMM DD, HH:mm")}
                        </td>
                      </tr>
                    ) : null}

                    {selectedRecord?.fund_tag &&
                    selectedRecord.fund_tag.length !== 0 ? (
                      <tr>
                        <td>
                          <div>
                            <AttachMoneyTwoToneIcon
                              className={styles.moneyIcon}
                              fontSize="small"
                            />
                          </div>
                        </td>
                        <td>Fund Tag</td>
                        <td
                          title="Predicted by IA, not entirely accurate."
                          className={styles.infoIcon}
                        >
                          {selectedRecord.fund_tag.map((el, index) => {
                            if (index === 0) return `${el}`;
                            return `, ${el}`;
                          })}
                        </td>
                      </tr>
                    ) : null}

                    <tr>
                      <td>
                        <div>
                          <AttachMoneyTwoToneIcon
                            className={styles.moneyIcon}
                            fontSize="small"
                          />
                        </div>
                      </td>
                      <td
                        role="presentation"
                        onClick={openFundTagModal}
                        className={styles.editIcon}
                      >
                        Edit Fund Tag
                      </td>
                      <td>
                        {userAddedFundTag
                          ? userAddedFundTag.map((el, index) => {
                              if (index === 0) return `${el}`;
                              return `, ${el}`;
                            })
                          : null}
                      </td>
                    </tr>

                    {selectedRecord?.ia_generated_count !== 0 && (
                      <tr>
                        <td>
                          <div className={styles.redBall} />
                        </td>
                        <td>Total IA Generated</td>
                        <td>{selectedRecord?.ia_generated_count}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {selectedRecord?.hot_queue &&
          selectedRecord?.hot_queue_count &&
          Object.keys(hotQueueRulesData).length > 0 ? (
            <div>
              <div className={styles.hotqueueInstructionHead}>
                <button
                  type="button"
                  className="drop-btn"
                  onClick={() =>
                    setHotQueueInstructionOpen(!hotQueueInstructionOpen)
                  }
                >
                  <img
                    src={
                      !hotQueueInstructionOpen
                        ? "/assets/hot-queue-dropright.svg"
                        : "/assets/hot-queue-dropdown.svg"
                    }
                    alt="dropdown"
                    className="icon-img"
                  />
                  <div>Hot Queue Notes and Instruction</div>
                </button>
              </div>
              {hotQueueInstructionOpen && (
                <div className={styles.hotqueueIntructionContent}>
                  <h3>{hotQueueRulesData?.rule_name}</h3>
                  {hotQueueRulesData.rule_details.map((e, i) => (
                    <div key={e} className={styles.ruleContainer}>
                      <p className={styles.ruleFont}>
                        Rule {i + 1} : {startCase(e?.condition_param)}{" "}
                        {e?.condition} {e?.condition_data}
                      </p>
                    </div>
                  ))}
                  <p className={styles.underlineFont}>Notes</p>
                  <p className={styles.whiteSpacePre}>
                    {hotQueueRulesData?.hotqueue_notes
                      ? replaceURLs(hotQueueRulesData?.hotqueue_notes)
                      : "--"}
                  </p>
                  <p className={styles.underlineFont}>Instructions</p>
                  <p className={styles.whiteSpacePre}>
                    {hotQueueRulesData?.instruction
                      ? replaceURLs(hotQueueRulesData?.instruction)
                      : "--"}
                  </p>
                </div>
              )}
            </div>
          ) : null}

          {selectedRecord.status === statusFilters.resolved &&
            isInsideModal && <br />}
          {(selectedRecord.dop || selectedRecord.prepared_by) && (
            <div className={styles.previewPreparationDetails}>
              Preparation:
              {selectedRecord.prepared_by && (
                <>&nbsp;{selectedRecord.prepared_by?.trim()}</>
              )}
              {selectedRecord.dop && (
                <>
                  {selectedRecord.prepared_by && ", "}
                  <span style={{ whiteSpace: "nowrap" }}>
                    {moment.unix(selectedRecord.dop).format("Do MMM YYYY")}
                  </span>
                </>
              )}
            </div>
          )}
          {selectedRecord?.tag === "ia" && (
            <div className={styles.generateIAPreview}>
              <div className={styles.redBall} />
              <div className={styles.generateIAText}>IA Generated</div>
            </div>
          )}
          {selectedRecord?.hot_route && (
            <div className={styles.generateIAPreview}>
              <div className={styles.blueBall} />
              <div className={styles.generateIAText}>Client In Hot Queue</div>
            </div>
          )}
          {started && (
            <div style={{ marginTop: 16 }} data-testid="preview-resolve-mail">
              <ResolveMailContainer
                onResolved={onResolved}
                mailRefId={selectedRecord.id}
                isMail
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Preview;

Preview.defaultProps = {
  userAddedFundTag: [],
  threadCount: 0,
  isInsideModal: false,
  tableRef: {},
  hotQueueRulesData: {},
  mailLabels: [],
  labelHandler: () => {},
};

Preview.propTypes = {
  listViewOption: PropTypes.string.isRequired,
  selectedRecord: PropTypes.instanceOf(Object).isRequired,
  isInsideModal: PropTypes.bool,
  openFundTagModal: PropTypes.func.isRequired,
  userAddedFundTag: PropTypes.instanceOf(Array),
  openMailModal: PropTypes.func.isRequired,
  threadCount: PropTypes.number,
  onStartedWorking: PropTypes.func.isRequired,
  onStoppedWorking: PropTypes.func.isRequired,
  onResolved: PropTypes.func.isRequired,
  userInfo: PropTypes.instanceOf(Object).isRequired,
  expandHandler: PropTypes.func.isRequired,
  tableRef: PropTypes.instanceOf(Object),
  hotQueueRulesData: PropTypes.instanceOf(Object),
  mailLabels: PropTypes.instanceOf(Array),
  labelHandler: PropTypes.func,
};
