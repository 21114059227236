import React, { useEffect, useState } from "react";
import { cloneDeep } from "lodash";
import moment from "moment";
import cn from "classnames";
import { Button } from "antd";
import ReportDetails from "./ReportDetails";
import UnitPriceGraph from "./UnitPriceGraph";
import helper from "../../utils/helper";
import SearchBox from "../../components/ProductSearchBox";
import "./style.scss";
import productServices from "../../api/productServices";
import styles from "../dashboard/resources/index.module.scss";
import Sidebar from "../../components/Sidebar";
import MainHeader from "../../components/MainHeader";
import SelectBox from "../../components/SelectBox";

const { getProductId } = helper;

function ProductManagementSearch() {
  const dateOptionsList = [
    { label: "Day", value: "daily" },
    { label: "Week", value: "weekly" },
    { label: "Month", value: "monthly" },
  ];
  const [activeTab, setActiveTab] = useState("productDetails");

  const [selectedFilter, selectFilter] = useState(dateOptionsList[0].value);
  const [dates, setDates] = useState({
    startDate: moment().subtract(30, "days"),
    endDate: moment(),
  });
  const [hackValue, setHackValue] = useState();
  const [hackDates, setHackDates] = useState([]);
  const handleFilter = (filter) => () => {
    selectFilter(filter);
  };
  const [searchKey, setSearchKey] = useState(undefined);
  const [productDetails, setProductDetails] = useState(undefined);
  const [productStaticDetails, setProductStaticDetails] = useState(undefined);
  const [xAxisData, setXAxisData] = useState([]);
  const [yAxisData, setYAxisData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [fullProducts, setFullProducts] = useState([]);
  const [publishedData, setPublishedData] = useState({
    publish_date: "",
    published_price: "",
  });
  const [tableData, setTableData] = useState({
    entryA: [],
    exitA: [],
  });
  const [advancedSearchPopup, setAdvancedSearchPopup] = useState(false);

  /**
   * select start and end date
   *
   */
  const handleDateRanges = (date) => {
    if (date) {
      const dateDetailsCopy = {
        ...dates,
        startDate: date[0],
        endDate: date[1],
      };

      setDates(dateDetailsCopy);
    } else {
      setDates({
        startDate: moment().subtract(30, "days"),
        endDate: moment(),
      });
    }
  };

  const parseAndSetOptions = (res) => {
    let array = [];
    array =
      res.length > 0
        ? res.map((el) => ({
            label: `${
              el?.product_name || el?.fund_data_product_name
            } (${getProductId(el)})`,
            value: getProductId(el),
            id: el.id,
          }))
        : [];
    return array;
  };

  const loadOptions = async (inputValue) => {
    try {
      const result = await productServices.getProductList([
        `q=${inputValue.trim()}`,
      ]);
      setFullProducts(result.results);
      return parseAndSetOptions(result.results);
    } catch (err) {
      return [];
    }
  };
  useEffect(() => {
    if (searchKey) {
      let fromDate = "";
      let toDate = "";
      if (selectedFilter === "monthly") {
        fromDate = moment(dates.startDate)
          .startOf("month")
          .format("YYYY-MM-DD");
        toDate = moment(dates.endDate).endOf("month").format("YYYY-MM-DD");
        if (new Date(toDate) > new Date()) {
          toDate = moment(Date.now()).format("YYYY-MM-DD");
        }
      } else if (selectedFilter === "weekly") {
        fromDate = moment(dates.startDate).startOf("week").format("YYYY-MM-DD");
        toDate = moment(dates.endDate).endOf("week").format("YYYY-MM-DD");
        if (new Date(toDate) > new Date()) {
          toDate = moment(Date.now()).format("YYYY-MM-DD");
        }
      } else {
        fromDate = moment(dates.startDate).format("YYYY-MM-DD");
        toDate = moment(dates.endDate).format("YYYY-MM-DD");
      }
      if (searchKey.value) {
        const payload = {
          id: searchKey.value,
          type: selectedFilter,
          start: fromDate,
          end: toDate,
        };
        setLoader(true);
        productServices
          .getProductGraphDetails(payload)
          .then((res) => {
            setXAxisData(res?.label);
            setYAxisData(res?.reports);
            setPublishedData({
              publish_date: res?.publish_date,
              published_price: res?.published_price,
            });
            setTableData({
              entryA: res?.entry_a,
              exitA: res?.exit_a,
            });
            setLoader(false);
          })
          .catch(() => {
            setLoader(false);
          });

        // productServices.getProductStaticDetails(searchKey.value).then((res) => {
        //   const newObject = {};
        //   Object.keys(res?.product_details).forEach((e) => {
        //     const k = e.replaceAll("-", "_");
        //     newObject[k] = res?.product_details[e];
        //   });
        //   setProductStaticDetails(newObject);
        // });
      }
    } else {
      setProductDetails(undefined);
      setProductStaticDetails(undefined);
    }
  }, [searchKey, selectedFilter, dates]);

  /**
   * Set gradient back ground for graph
   *
   */
  const canvas = document.getElementById("chart");
  const ctx = canvas?.getContext("2d");
  const gradient = ctx?.createLinearGradient(0, 0, 0, 400);
  gradient?.addColorStop(0, "rgba(0, 122, 255, 0.33)");
  gradient?.addColorStop(1, "rgba(255, 255, 255, 0)");
  const data = {
    labels: xAxisData,
    datasets: [
      {
        label: "Unit Price",
        data: yAxisData,
        fill: true,
        backgroundColor: gradient,
        borderColor: "rgba(0, 122, 255, 0.33)",
      },
    ],
  };

  const xAxisLabels = data?.label || [];

  const options = {
    title: {
      display: false,
      text: "Chart Title",
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  /**
   * set search key
   *
   */
  const handleSearchKey = (value) => {
    setSearchKey(value);
    if (value) {
      const product = fullProducts.filter((x) => x.id === value.id);
      setProductDetails(product?.length > 0 ? product[0] : undefined);
    }
  };

  const onOpenChange = (open) => {
    if (open) {
      setHackValue([]);
      setHackDates([]);
    } else {
      setHackValue(undefined);
    }
  };

  // on click for radio button
  const handleRadioButton = (e) => {
    const dateDetailsCopy = cloneDeep(dates);
    selectFilter(e.target.value);
    // setting default values
    if (e.target.value === "monthly")
      dateDetailsCopy.startDate = moment().startOf("year");
    else if (e.target.value === "weekly")
      dateDetailsCopy.startDate = moment().subtract(2, "months");
    else dateDetailsCopy.startDate = moment().startOf("month");
    setDates(dateDetailsCopy);
  };

  const tabHandler = (value) => {
    setActiveTab(value);
  };

  const toggleAdvancedFilter = () => {
    setAdvancedSearchPopup(!advancedSearchPopup);
  };

  const apirListOptions = [
    { value: "user", label: "APIR" },

    {
      value: "business_admin",
      label: "Business Admin",
    },
  ];
  return (
    <div className="layout">
      <Sidebar />
      <div className="layout-container">
        <MainHeader title="Product Lookup" />
        <div className={styles.mainContent}>
          <div className="product-management-search">
            <div className="table">
              <SearchBox
                onClickFilter={toggleAdvancedFilter}
                onChange={handleSearchKey}
                value={searchKey}
                loadOptions={loadOptions}
                placeholder="Enter the product name, code ..."
                // filter={applyFilter}
              />
              {advancedSearchPopup && (
                <div className="advance-popup">
                  <div className="popup-wrapper">
                    <div className="advanced-popup">
                      <div className="filter-options">
                        SearchBy
                        <SelectBox
                          listViewOption={apirListOptions}
                          // onChange={onChangeHandler("userType")}
                          value={apirListOptions.value}
                        />
                      </div>
                      <Button
                        className="apply-filter"
                        onClick={toggleAdvancedFilter}
                      >
                        Apply Filter
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              {productDetails ? (
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "460px 1fr",
                    gridGap: 30,
                  }}
                >
                  {/* <div className="unit-content-overview">
                    <div className="unit-header">
                      <Radio.Group value={page} onChange={onchange}>
                        <Radio className="active">Product Details </Radio>
                        <Radio>Other Static Info</Radio>
                        <Radio>Misc IA Info</Radio>
                      </Radio.Group>
                    </div>
                  </div> */}

                  <div className="details-container">
                    <div className="product-detail ">
                      <div className="popup-tabs">
                        <div
                          role="button"
                          tabIndex={0}
                          onClick={() => tabHandler("productDetails")}
                          onKeyPress={() => tabHandler("productDetails")}
                          className={cn({
                            tab: true,
                            active: activeTab === "productDetails",
                          })}
                        >
                          Product Details
                        </div>
                        {/* <div
                          role="button"
                          tabIndex={0}
                          onClick={() => tabHandler("staticInfo")}
                          onKeyPress={() => tabHandler("staticInfo")}
                          className={cn({
                            tab: true,
                            active: activeTab === "staticInfo",
                          })}
                        >
                          Other Static Info
                        </div> */}
                        {/* <div
                          role="button"
                          tabIndex={0}
                          onClick={() => tabHandler("miscInfo")}
                          onKeyPress={() => tabHandler("miscInfo")}
                          className={cn({
                            tab: true,
                            active: activeTab === "miscInfo",
                          })}
                        >
                          Misc IA Info{" "}
                        </div> */}
                      </div>

                      <ReportDetails
                        productDetails={productDetails}
                        publishedData={publishedData}
                        activeTab={activeTab}
                        productStaticDetails={productStaticDetails}
                      />
                    </div>
                  </div>
                  <div className="unit-price-graph-wrapper">
                    <div className="mx-5 mt-5">
                      <UnitPriceGraph
                        handleFilter={handleFilter}
                        xAxisLabels={xAxisLabels}
                        GraphFilter={dateOptionsList}
                        selectedFilter={selectedFilter}
                        handleDateRanges={handleDateRanges}
                        dates={dates}
                        data={data}
                        options={options}
                        loader={loader}
                        onOpenChange={onOpenChange}
                        hackValue={hackValue}
                        hackDates={hackDates}
                        setHackDates={setHackDates}
                        handleRadioButton={handleRadioButton}
                        tableData={tableData}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="search-box">
                  <div className="row">
                    <div className="flex-item flex flex-row">
                      <div className="search-text">
                        Search for a product to view the details{" "}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ProductManagementSearch;
