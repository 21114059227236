import React, { useCallback, useContext, useEffect, useState } from "react";
import { Route, withRouter } from "react-router-dom";
import { cloneDeep } from "lodash";
import PropTypes from "prop-types";

import AuthContext from "../../context";
import RoleContext from "../../context/roleContext";
import helper from "../../utils/helper";

import Spinner from "../../components/Spinner";
import showAlert from "../../utils/showAlert";
import DashboardPage from "../../pages/dashboard";
import overViewReport from "../../pages/Overview/Reports";

function PrivateRoute({ history, component, allowedUser, ...rest }) {
  const { exact, path } = rest;
  const { roles } = helper;

  const { getAccessToken, setAccessToken, setSignIn, isSignedIn } = useContext(
    AuthContext
  );
  const { userInfo, setUserInfo } = useContext(RoleContext);
  const { userRoles } = userInfo;
  const [hasPermission, setHasPermission] = useState(false);

  const redirectWithNoPermission = (hasPermissionCopy) => {
    /** If no permission then redirect to un-auth file */
    if (!hasPermissionCopy && allowedUser.length) {
      showAlert("Permission Denied!", "warning");
      history.push("/");
    }
    setHasPermission(hasPermissionCopy);
  };

  const checkUserAccess = () => {
    let hasPermissionCopy = cloneDeep(hasPermission);
    if (userRoles.length !== 0) {
      /** Redirecting for "/" according to roles  */
      if (exact && path === "/") {
        // for user

        history.push("/dashboard");
        hasPermissionCopy = true;
        setHasPermission(hasPermissionCopy);
      } else {
        /** Permission checking for User Route */

        allowedUser.forEach((allowedRole) => {
          if (!hasPermissionCopy) {
            if (userRoles.includes(allowedRole)) {
              hasPermissionCopy = true;
            }
          }
        });

        // /** If no permission then redirect to un-auth file */
        redirectWithNoPermission(hasPermissionCopy);
      }
    }
  };

  const getComponent = () => {
    if (userRoles.includes(roles.USER)) {
      return DashboardPage;
    }
    // for super admin and business admin
    if (
      userRoles.includes(roles.SUPER_ADMIN) ||
      userRoles.includes(roles.BUSINESS_ADMIN)
    ) {
      return overViewReport;
    }
    return null;
  };

  useEffect(() => {
    const AccessToken = getAccessToken();

    if (AccessToken) {
      setSignIn(true);
    } else {
      setSignIn(false);
      history.push("/login");
    }
  }, [getAccessToken, setAccessToken, setSignIn]);

  useEffect(() => {
    if (allowedUser && userRoles) {
      checkUserAccess();
    }
  }, [userInfo, setUserInfo, path]);

  const renderFn = (Component) =>
    useCallback(
      (props) =>
        // eslint-disable-next-line react/jsx-props-no-spreading
        isSignedIn && hasPermission ? <Component {...props} /> : <Spinner />,
      [path, isSignedIn, hasPermission]
    );

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Route {...rest} render={renderFn(component ?? getComponent())} />;
}

export default withRouter(PrivateRoute);

PrivateRoute.defaultProps = {
  component: null,
};

PrivateRoute.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  component: PropTypes.node,
  allowedUser: PropTypes.instanceOf(Array).isRequired,
};
