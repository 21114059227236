import React, { useState } from "react";
import { EyeTwoTone, EyeInvisibleTwoTone } from "@ant-design/icons";
import PropTypes from "prop-types";
import appServices from "../../../api/appServices";
import showAlert from "../../../utils/showAlert";
import "./style.scss";

function PasswordPopUp({ closePasswordPopUp }) {
  // ------------------- State variables ----------------
  const [oldPassword, setOldPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [passwordShownOld, setPasswordShownOld] = useState(false);
  const [passwordShownNew, setPasswordShownNew] = useState(false);
  const [passwordShownConfirm, setPasswordShownConfirm] = useState(false);

  const onChangeOldPassword = (e) => {
    setOldPassword(e.target.value);
  };

  const onChangeNewPassword = (e) => {
    setNewPassword(e.target.value);
  };

  const onChangeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
    e.preventDefault();
  };

  const changePassword = async () => {
    const data = {
      old_password: oldPassword,
      password: newPassword,
    };

    try {
      await appServices.resetSettingsData(data);
      closePasswordPopUp();
      showAlert("Profile updated successfully", "success");
    } catch (err) {
      showAlert(err?.response?.data?.message, "error");
    }
  };

  const submitHandler = () => {
    if (newPassword) {
      if (newPassword !== confirmPassword) {
        showAlert(
          "New Password and confirm password should be same",
          "warning"
        );
      } else {
        changePassword();
      }
    }
  };

  // ---------------- Toggle Password Handler ----------------

  const togglePasswordOld = () => {
    setPasswordShownOld(!passwordShownOld);
  };
  const togglePasswordNew = () => {
    setPasswordShownNew(!passwordShownNew);
  };
  const togglePasswordConfirm = () => {
    setPasswordShownConfirm(!passwordShownConfirm);
  };

  return (
    <div className="popup delete-hotqueue-popup">
      <div className="popup-overlay" />
      <div className="popup-wrapper">
        <div className="popup-header">
          <h1>Change Password</h1>
          &ensp;
          <form>
            <div className="label-input">Old Password</div>
            <div className="input-form">
              <input
                onChange={onChangeOldPassword}
                maxLength={100}
                value={oldPassword}
                type={passwordShownOld ? "text" : "password"}
                className="password-input"
              />

              <button
                type="button"
                className="eye-icon"
                onClick={togglePasswordOld}
              >
                {passwordShownOld ? <EyeTwoTone /> : <EyeInvisibleTwoTone />}
              </button>
            </div>
            &ensp;
            <div className="label-input">New Password</div>
            <div className="input-form">
              <input
                onChange={onChangeNewPassword}
                maxLength={100}
                value={newPassword}
                type={passwordShownNew ? "text" : "password"}
                className="password-input"
              />
              <button
                type="button"
                className="eye-icon"
                onClick={togglePasswordNew}
              >
                {passwordShownNew ? <EyeTwoTone /> : <EyeInvisibleTwoTone />}
              </button>
            </div>
            &ensp;
            <div className="label-input">Confirm Password</div>
            <div className="input-form">
              <input
                onChange={onChangeConfirmPassword}
                maxLength={100}
                value={confirmPassword}
                type={passwordShownConfirm ? "text" : "password"}
                className="password-input"
              />
              <button
                type="button"
                className="eye-icon"
                onClick={togglePasswordConfirm}
              >
                {passwordShownConfirm ? (
                  <EyeTwoTone />
                ) : (
                  <EyeInvisibleTwoTone />
                )}
              </button>
            </div>
            <div className="popup-footer">
              <button
                type="button"
                className="cancel-btn"
                onClick={closePasswordPopUp}
              >
                Cancel
              </button>
              <button
                className="save-btn"
                type="button"
                onClick={submitHandler}
              >
                Save Changes{" "}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default PasswordPopUp;

PasswordPopUp.propTypes = {
  closePasswordPopUp: PropTypes.func.isRequired,
};
