import React from "react";
import {
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  withStyles,
} from "@material-ui/core";
import PropTypes from "prop-types";

import styles from "../../../resources/index.module.scss";
import ErrorCode from "../ErrorCode";
import {
  REPAIR_QUEUE_CODES,
  REPUBLISH_CODES,
  SYSTEM_GENERATED_CODES,
} from "../../../../../utils/constantData";

const Dialog = withStyles({
  paper: {
    borderRadius: "22px",
  },
})(MuiDialog);

const DialogContent = withStyles({
  root: {
    padding: "32px 32px 24px 32px",
  },
})(MuiDialogContent);

function ErrorCodeModal({ isErrorModalOpen, closeErrorModal, data }) {
  const toRepublish =
    data.codes.filter((item) => REPUBLISH_CODES.includes(item[0])) || [];
  const repairQueueCodes =
    data.codes.filter((item) => REPAIR_QUEUE_CODES.includes(item[0])) || [];
  const systemGeneratedCodes =
    data.codes.filter((item) => SYSTEM_GENERATED_CODES.includes(item[0])) || [];
  return (
    <Dialog
      open={isErrorModalOpen}
      onBackdropClick={closeErrorModal}
      maxWidth={
        toRepublish.length > 0 && repairQueueCodes.length > 0 ? "xl" : "sm"
      }
    >
      <DialogContent className={styles.errorCodeModal}>
        <div className={styles.referenceNo}>
          <div>
            <img src="/assets/hash.svg" alt="hash" />
          </div>
          <div className="ss">Reference No</div>
          <div>{data?.reference_id}</div>
        </div>
        <hr className={styles.errorHeaderSeparator} />

        {systemGeneratedCodes && systemGeneratedCodes.length > 0 && (
          <div>
            <div className={styles.heading}>System Errors</div>
            <div className={styles.errorWrapper}>
              {data?.codes &&
                systemGeneratedCodes.map((el) => (
                  <div
                    className={styles.errorCodeSeparator}
                    key={`systemGeneratedCode-${el}`}
                  >
                    <ErrorCode
                      code={el[0]}
                      message={el[1]}
                      status={data.status}
                    />
                  </div>
                ))}
            </div>
          </div>
        )}

        <div className={styles.errorGrid}>
          {repairQueueCodes && repairQueueCodes.length > 0 && (
            <div>
              <div className={styles.heading}>
                BCG Errors - Set to Repair Queue
              </div>
              <div className={styles.errorWrapper}>
                {data?.codes &&
                  repairQueueCodes.map((el) => (
                    <div
                      className={styles.errorCodeSeparator}
                      key={`repairQueueCode-${el}`}
                    >
                      <ErrorCode
                        code={el[0]}
                        message={el[1]}
                        status={data.status}
                      />
                    </div>
                  ))}
              </div>
            </div>
          )}

          {toRepublish && toRepublish.length > 0 && (
            <div>
              <div className={styles.heading}>
                BCG Alerts - set to Republish
              </div>
              <div className={styles.errorWrapper}>
                {data?.codes &&
                  toRepublish.map((el) => (
                    <div
                      className={styles.errorCodeSeparator}
                      key={`toRepublish-${el}`}
                    >
                      <ErrorCode
                        code={el[0]}
                        message={el[1]}
                        status={data.status}
                      />
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ErrorCodeModal;

ErrorCodeModal.propTypes = {
  isErrorModalOpen: PropTypes.bool.isRequired,
  closeErrorModal: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
};
