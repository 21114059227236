import React, { useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import userServices from "../../api/userServices";
import "./style.scss";
import showAlert from "../../utils/showAlert";
import FormInput from "../FormInput/Index";

function RetainHoursPopup({ closePopup }) {
  const { register, handleSubmit } = useForm();
  const [updating, setUpdating] = useState(false);

  const updateHandler = ({ hour }) => {
    setUpdating(true);
    userServices
      .emailRetainHour({ fetch_back_hour: hour })
      .then((res) => {
        showAlert(res.message, "success");
        closePopup();
      })
      .catch(() => {
        setUpdating(false);
      });
  };

  return (
    <div className="popup ia-update-popup">
      <div className="popup-overlay" />
      <div className="popup-wrapper">
        <div className="popup-header">
          <h1>Email Retain Hour</h1>
        </div>
        <form onSubmit={handleSubmit(updateHandler)}>
          <FormInput
            icon={AccessTimeIcon}
            label="Retain Hour"
            placeholder="Hour"
            name="hour"
            required
            register={register}
            type="number"
            min="0"
          />
          <div className="popup-footer">
            <button type="button" className="cancel-btn" onClick={closePopup}>
              Cancel
            </button>
            <button disabled={updating} className="save-btn" type="submit">
              {updating ? "Updating..." : "Update"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default RetainHoursPopup;

RetainHoursPopup.propTypes = {
  closePopup: PropTypes.func.isRequired,
};
