import axios from "../utils/axiosClient";
import endpoints from "./endpoints";

const { hotQueue, hotQueueEmailList } = endpoints;

const getHotQueueList = async (query) => {
  try {
    const response = await axios.get(`${hotQueue}?${query.join("&")}`);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const addNewHotQueue = async (payload) => {
  try {
    const response = await axios.post(hotQueue, payload);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const editHotQueue = async (id, payload) => {
  try {
    const response = await axios.put(`${hotQueue}${id}/`, payload);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const deleteHotQueue = async (id) => {
  try {
    const response = await axios.delete(`${hotQueue}${id}/`);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getHotQueueSeachData = async () => {
  try {
    const response = await axios.get(`${hotQueueEmailList}`);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getHotQueueDetailsById = async (id) => {
  try {
    const response = await axios.get(`${hotQueue}${id}/`);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export default {
  getHotQueueList,
  addNewHotQueue,
  editHotQueue,
  deleteHotQueue,
  getHotQueueSeachData,
  getHotQueueDetailsById,
};
