import gitbookAxios from "../utils/gitbookAxiosClient";

const getPageContent = async (url) => {
  try {
    const spaceID = process.env.REACT_APP_CHANGE_LOG_SPACE_ID;
    const response = await gitbookAxios.get(
      `/spaces/${spaceID}/content/path/${url}`
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getSpaceContent = async () => {
  try {
    const spaceID = process.env.REACT_APP_CHANGE_LOG_SPACE_ID;
    const response = await gitbookAxios.get(`/spaces/${spaceID}/content/`);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export default { getPageContent, getSpaceContent };
