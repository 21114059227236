import React, { useState } from "react";
import PropTypes from "prop-types";
import showAlert from "../../../utils/showAlert";
import hotQueueServices from "../../../api/hotQueueServices";

function DeletePopup({
  item,
  setLoader,
  getHotQueueListing,
  closeDeletePopup,
}) {
  const [deleting, setDeleting] = useState(false);

  // deletes hotqueue from database
  const deleteHandler = async (e) => {
    e.preventDefault();
    setDeleting(true);
    setLoader(true);

    try {
      // eslint-disable-next-line no-unused-expressions
      await hotQueueServices.deleteHotQueue(item.id);
      showAlert("Hot Queue deleted!", "success");
      getHotQueueListing();
      closeDeletePopup();
    } catch (err) {
      // error
      setLoader(false);
      setDeleting(false);
    }
  };
  return (
    <div className="popup delete-hotqueue-popup">
      <div className="popup-overlay" />
      <div className="popup-wrapper">
        <div className="popup-header">
          <h1>Delete Hot Queue</h1>
          <p>Are you sure you want to delete the hot queue?</p>
        </div>
        <form onSubmit={deleteHandler}>
          <div className="popup-footer">
            <button
              type="button"
              className="cancel-btn"
              onClick={closeDeletePopup}
            >
              Cancel
            </button>
            <button disabled={deleting} className="save-btn" type="submit">
              {deleting ? "Deleting..." : "Confirm"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default DeletePopup;

DeletePopup.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  setLoader: PropTypes.func.isRequired,
  getHotQueueListing: PropTypes.func.isRequired,
  closeDeletePopup: PropTypes.func.isRequired,
};
