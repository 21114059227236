import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import userServices from "../../api/userServices";
import Spinner from "../../components/Spinner";
import "./style.scss";

function ReleaseEmail({ match, history }) {
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState("");
  const { id } = match.params;

  useEffect(() => {
    setLoader(true);
    const payload = {
      id,
    };
    userServices
      .getReleaseEmail(payload)
      .then(() => {
        setMessage("Release mail has been send!");
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        if (!error?.response?.data?.valid) {
          history.push("/not-found");
        } else
          setMessage(error?.response?.data?.message || "Something went wrong!");
      });
  }, []);
  return (
    <div className="email-container">
      {loader ? <Spinner /> : <p className="tab-box-wrapper">{message} </p>}
    </div>
  );
}

export default ReleaseEmail;

ReleaseEmail.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};
