import React from "react";
import Skeleton from "react-loading-skeleton";

function SideBarSkeleton() {
  return (
    <>
      {Array.from({ length: 12 })
        .map((_, index) => index)
        .map((item) => (
          <div className="sidebar-list-single" key={item}>
            <Skeleton width={30} height={30} circle />
            <Skeleton width={270} height={20} />
          </div>
        ))}
    </>
  );
}

export default SideBarSkeleton;
