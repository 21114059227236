import axios from "../utils/axiosClient";
import endpoints from "./endpoints";

const {
  userListing,
  createUser,
  updateUser,
  activate,
  deactivate,
  fundTags,
  starringReasons,
  mailSearch,
  mailDetails,
  codeGenerator,
  mailAttachments,
  mailAttachmentsPartial,
  startResolving,
  startResolvingAll,
  checkAnyoneIsWorking,
  endResolving,
  endResolvingAll,
  resolveCSV,
  resolveLog,
  userList,
  deleteMailDetails,
  resolveQuarantinedMail,
  releaseEmail,
  addFundTag,
  sendReplyMail,
  readUnreadMail,
  markAsImportant,
  starredUsers,
  taggedUsers,
  resolveInterchange,
  retainHour,
  getVersion,
  manualUpload,
  taggedNotification,
  taggedNotificationStatusChange,
  taggableUserUpdate,
  priceLimit,
  mailLabel,
  removeMailLabel,
  spreadValue,
  IMDFileCreate,
  IMDFileName,
} = endpoints;

const getUserListing = async (query) => {
  try {
    const response = await axios.get(`${userListing}?${query.join("&")}`);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const addNewUser = async (payload) => {
  try {
    const response = await axios.post(createUser, payload);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateUserData = async (payload) => {
  try {
    const response = await axios.put(`${updateUser}${payload.id}/`, payload);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const activateUser = async (payload) => {
  try {
    const response = await axios.post(activate, payload);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const deactivateUser = async (payload) => {
  try {
    const response = await axios.delete(`${deactivate}${payload.id}/`);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getFundTags = async () => {
  try {
    const response = await axios.get(fundTags);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getStarringReasons = async () => {
  try {
    const response = await axios.get(starringReasons);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const markMailAsImportant = async (data) => {
  try {
    axios.interceptors.request.use((config) => {
      const newConfig = config;
      newConfig.headers["Content-Type"] = "application/json";
      return newConfig;
    });
    const response = await axios.post(markAsImportant, data);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const editMailAsImportant = async (data) => {
  try {
    axios.interceptors.request.use((config) => {
      const newConfig = config;
      newConfig.headers["Content-Type"] = "application/json";
      return newConfig;
    });
    const response = await axios.patch(
      `${markAsImportant}${data.mail_detail}/`,
      data
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const markMailAsNotImportant = async (id) => {
  try {
    axios.interceptors.request.use((config) => {
      const newConfig = config;
      newConfig.headers["Content-Type"] = "application/json";
      return newConfig;
    });
    const response = await axios.patch(`${markAsImportant}${id}/`, {
      starred: false,
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getTaggedUsers = async () => {
  try {
    const response = await axios.get(taggedUsers);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getStarredUsers = async () => {
  try {
    const response = await axios.get(starredUsers);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getMailSearch = async (query, count = false) => {
  try {
    const response = await axios.get(
      `${mailSearch}?${query}&is_count=${count}`
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getMailDetails = async (mailId, query) => {
  try {
    const response = await axios.get(`${mailDetails}${mailId}/?${query}`);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getCodeGenerator = async (payload) => {
  try {
    const response = await axios.post(codeGenerator, payload);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getMailAttachments = async (id, query = "") => {
  try {
    const response = await axios.get(`${mailAttachments}${id}/?${query}`);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getMailAttachmentsPartial = async (id) => {
  try {
    const response = await axios.get(`${mailAttachmentsPartial}${id}/`);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getCheckAnyoneIsWorking = async (id, listViewOption) => {
  try {
    let url = `${checkAnyoneIsWorking}${id}/`;
    if (listViewOption !== "all") {
      url = url.concat(`?status=${listViewOption}`);
    }
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getStartResolving = async (payload) => {
  try {
    const response = await axios.post(startResolving, payload);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getStartResolvingAll = async (id, data) => {
  try {
    const response = await axios.post(`${startResolvingAll}${id}/`, data);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getEndResolving = async (payload) => {
  try {
    const response = await axios.post(endResolving, payload);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getEndResolvingAll = async (record, listViewOption) => {
  try {
    const url = `${endResolvingAll}${record.id}/`;
    if (listViewOption !== "all") {
      const data = {};
      if (record?.ref_ids?.length > 0) {
        data.ref_ids = record?.ref_ids;
      }
    }
    const response = await axios.post(url);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const resolveCSVFile = async (payload, id) => {
  try {
    const response = await axios.post(`${resolveCSV}${id}/`, payload);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const resolveInterchangeAPI = async (payload) => {
  try {
    const response = await axios.post(`${resolveInterchange}`, payload);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getResolveLog = async (refId) => {
  try {
    const response = await axios.get(`${resolveLog}${refId}/`);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getUserList = async () => {
  try {
    const response = await axios.get(userList);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const deleteQuarantinedMail = async (payload) => {
  try {
    const response = await axios.post(deleteMailDetails, payload);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getResolveQuarantinedMail = async (payload) => {
  try {
    const response = await axios.post(resolveQuarantinedMail, payload);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getReleaseEmail = async (payload) => {
  try {
    const response = await axios.post(releaseEmail, payload);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const editFundTag = async (id, payload) => {
  try {
    const response = await axios.patch(`${addFundTag}${id}/`, payload);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const sendReply = async (payload) => {
  try {
    const response = await axios.post(sendReplyMail, payload);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const markMailRead = async (payload) => {
  try {
    const response = await axios.post(readUnreadMail, payload);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const emailRetainHour = async (payload) => {
  try {
    const response = await axios.post(retainHour, payload);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchAppVersion = async () => {
  try {
    const response = await axios.get(getVersion);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const manualUploadFile = async (payload) => {
  try {
    const response = await axios.post(manualUpload, payload);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getTaggedNotification = async ({ page }) => {
  try {
    const response = await axios.get(taggedNotification, {
      params: {
        page,
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateTaggedNotificationStatus = async ({ id }) => {
  try {
    const response = await axios.post(
      `${taggedNotificationStatusChange}${id}/`,
      {
        cancel_status: true,
      }
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateTaggableUserList = async (ids) => {
  try {
    const response = await axios.post(`${taggableUserUpdate}`, {
      user_id_list: ids,
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getTaggableUserList = async () => {
  try {
    const response = await axios.get(`${taggableUserUpdate}`);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getCurrentPriceLimit = async () => {
  try {
    const response = await axios.get(`${priceLimit}`);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updatePriceLimit = async (limit) => {
  try {
    const response = await axios.post(`${priceLimit}`, { check_value: limit });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getMailLabel = async () => {
  try {
    const response = await axios.get(`${mailLabel}`);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getMailLabelWithId = async (id) => {
  try {
    const response = await axios.get(`${mailLabel}?id=${id}`);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateMailLabels = async (payload) => {
  try {
    const response = await axios.post(`${mailLabel}`, {
      label_list: [payload],
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateMailLabelWithId = async (payload) => {
  try {
    const response = await axios.post(`${mailLabel}`, {
      message_id: payload.id,
      label_list: payload.list,
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const removeMailLabelWithId = async (payload) => {
  try {
    const response = await axios.post(`${removeMailLabel}`, {
      message_id: payload.message_id,
      label_id: payload.label_id,
      id: payload.id,
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getCurrentSpreadValue = async () => {
  try {
    const response = await axios.get(`${spreadValue}`);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateSpreadValue = async (limit) => {
  try {
    const response = await axios.post(`${spreadValue}`, {
      spread_value: limit,
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const createIMDFile = async (id) => {
  try {
    const response = await axios.post(`${IMDFileCreate}`, {
      ref_id: id,
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const checkIMDFileName = async (id) => {
  try {
    const response = await axios.post(`${IMDFileName}`, {
      task_id: id,
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export default {
  getUserListing,
  addNewUser,
  updateUserData,
  activateUser,
  deactivateUser,
  getFundTags,
  getStarringReasons,
  markMailAsImportant,
  markMailAsNotImportant,
  editMailAsImportant,
  getTaggedUsers,
  getStarredUsers,
  getMailSearch,
  getMailDetails,
  getCodeGenerator,
  getMailAttachments,
  getMailAttachmentsPartial,
  getStartResolving,
  getStartResolvingAll,
  getEndResolving,
  getEndResolvingAll,
  resolveCSVFile,
  getResolveLog,
  getUserList,
  deleteQuarantinedMail,
  getResolveQuarantinedMail,
  getReleaseEmail,
  editFundTag,
  sendReply,
  markMailRead,
  getCheckAnyoneIsWorking,
  resolveInterchangeAPI,
  emailRetainHour,
  fetchAppVersion,
  manualUploadFile,
  getTaggedNotification,
  updateTaggedNotificationStatus,
  updateTaggableUserList,
  getTaggableUserList,
  getCurrentPriceLimit,
  updatePriceLimit,
  getMailLabel,
  getMailLabelWithId,
  updateMailLabels,
  updateMailLabelWithId,
  removeMailLabelWithId,
  getCurrentSpreadValue,
  updateSpreadValue,
  createIMDFile,
  checkIMDFileName,
};
