import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import helper from "../../utils/helper";
import productServices from "../../api/productServices";

const { getProductId, getProductType } = helper;
function ReportDetails({
  productDetails,
  publishedData,
  activeTab,
  productStaticDetails,
}) {
  return (
    <>
      <div className="heading">IA PMDB Basic Details</div>
      <div className="text-base">
        <div className="details-wrapper">
          <div className="detail-label">Name:</div>
          <div className="font-medium">
            {productDetails?.product_name ||
              productDetails?.fund_data_product_name ||
              ""}
          </div>
        </div>
        <div className="details-wrapper">
          <div className="detail-label">Product ID:</div>
          <div className="font-medium">{getProductId(productDetails)}</div>
        </div>
        <div className="details-wrapper">
          <div className="detail-label">Type:</div>
          <div className="font-medium">{getProductType(productDetails)}</div>
        </div>
        <div className="details-wrapper">
          <div className="detail-label">{getProductType(productDetails)}</div>
          <div className="font-medium">{getProductId(productDetails)}</div>
        </div>
      </div>

      <div className="heading">IA PMDB Sender Reference Data</div>
      <div className="text-base">
        <div className="details-wrapper">
          <div className="detail-label">Sender Product Name:</div>
          {productDetails?.fund_data_product_name}
        </div>
        <div className="details-wrapper">
          <div className="detail-label">Sender Code #1:</div>
          <div className="font-medium">
            {productDetails?.user_defined_field_one}
          </div>
        </div>
        <div className="details-wrapper">
          <div className="detail-label">Sender Code #2:</div>
          <div className="font-medium">
            {productDetails?.user_defined_field_two}
          </div>
        </div>
      </div>

      <div className="heading">Last Unit Price</div>
      <div className="text-base">
        <div className="details-wrapper">
          <div className="detail-label">Publish Date:</div>
          <div className="font-medium">{publishedData?.publish_date || ""}</div>
        </div>
        <div className="details-wrapper">
          <div className="detail-label">Published Price:</div>
          <div className="font-medium">
            {publishedData?.published_price || ""}
          </div>
        </div>
      </div>
    </>
  );
}

export default ReportDetails;
ReportDetails.propTypes = {
  productDetails: PropTypes.instanceOf(Object).isRequired,
  publishedData: PropTypes.instanceOf(Object).isRequired,
  productStaticDetails: PropTypes.instanceOf(Object).isRequired,
  activeTab: PropTypes.string.isRequired,
};
