import React from "react";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";

import "./style.scss";
import PropTypes from "prop-types";

function AdvanceSearchInput({
  onChange,
  value,
  inputRef = null,
  placeholder,
  advancePopupOpen,
}) {
  const onChangeHandle = (e) => {
    onChange(e.target.value);
  };

  const clearValue = () => {
    onChange("");
  };

  return (
    <div className="advance-search-wrapper">
      <img src="/assets/loupe.svg" alt="icon" />
      <input
        placeholder={placeholder}
        onChange={onChangeHandle}
        value={value}
        ref={inputRef}
      />
      {value && (
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label
          role="presentation"
          onKeyPress={clearValue}
          className="cancel-icon"
          onClick={clearValue}
        >
          <CancelRoundedIcon />
        </label>
      )}
      {/* <img
        src="/assets/adv-search.svg"
        alt="icon"
        className="adv-icon"
        onClick={advancePopupOpen}
        onKeyPress={advancePopupOpen}
      /> */}
    </div>
  );
}

export default AdvanceSearchInput;

AdvanceSearchInput.defaultProps = {
  onChange: () => {},
  value: "",
  inputRef: "",
};

AdvanceSearchInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  inputRef: PropTypes.instanceOf(Object),
  placeholder: PropTypes.string.isRequired,
  advancePopupOpen: PropTypes.func.isRequired,
};
