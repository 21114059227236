import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { startCase } from "lodash";
import styles from "./index.module.scss";
import NotificationPopup from "../NotificationPopup";
import AuthContext from "../../context";

function MainHeader({ title, enableNotification, notificatioProps }) {
  const [notificationPopup, setNotificationPopup] = useState(false);

  const {
    getData,
    total,
    loading,
    data,
    notificationClickHandler,
    loadMore,
  } = notificatioProps;

  const { getUserDetailsFromDevice, userDetails } = useContext(AuthContext);

  useEffect(() => {
    if (enableNotification) {
      getData();
    }
  }, []);

  const closeNotificationPopup = () => {
    setNotificationPopup(false);
  };

  const openNotificationPopup = () => {
    getData();
    setNotificationPopup(true);
  };

  return (
    <header className={styles.header}>
      <div className={styles.headerContent}>
        <h1 className={styles.title}>{title}</h1>
        <div className={styles.rightContainer}>
          {enableNotification && (
            <div
              className={styles.notificationIcon}
              onClick={openNotificationPopup}
              onKeyDown={openNotificationPopup}
              role="button"
              tabIndex={0}
            >
              <NotificationsIcon className={styles.icon} />
              <div className={styles.notificationNumber}>{total}</div>
              {notificationPopup && (
                <NotificationPopup
                  closePopup={closeNotificationPopup}
                  clickHandler={notificationClickHandler}
                  loading={loading}
                  data={data}
                  total={total}
                  getData={getData}
                  loadMore={loadMore}
                />
              )}
            </div>
          )}
          <div className={styles.profilePic}>
            {userDetails.name
              ? startCase(userDetails.name)
                  .split(" ")
                  .splice(0, 2)
                  .map((name) => name[0])
                  .join("")
                  .toUpperCase()
              : startCase(getUserDetailsFromDevice().name)
                  .split(" ")
                  .splice(0, 2)
                  .map((name) => name[0])
                  .join("")
                  .toUpperCase()}
          </div>
          <div className={styles.profileName}>
            {userDetails.name || getUserDetailsFromDevice().name}
          </div>
        </div>
      </div>
    </header>
  );
}
export default MainHeader;

MainHeader.defaultProps = {
  enableNotification: false,
  notificatioProps: {},
};

MainHeader.propTypes = {
  title: PropTypes.string.isRequired,
  enableNotification: PropTypes.bool,
  notificatioProps: PropTypes.instanceOf(Object),
};
