import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./style.scss";

const alert = ({
  title = "Confirm",
  message = "Are you sure to do this?",
  buttons = [
    {
      label: "Yes",
      onClick: () => {},
      className: "btn-action",
    },
    {
      label: "No",
      onClick: () => {},
      className: "btn-action",
    },
  ],
}) =>
  confirmAlert({
    title,
    message,
    buttons,
    closeOnClickOutside: false,
    closeOnEscape: false,
  });

export default alert;
