import React from "react";
import Skeleton from "react-loading-skeleton";
import PropTypes from "prop-types";

function FileTypeAnalysis({
  style,
  header,
  errors,
  files,
  showEmptyValues,
  products,
  isLoading,
}) {
  return (
    <div className="col" style={style}>
      <h3 className="grid-item-title">{header}</h3>

      <div className="file-analysis-data-top-row" style={{ marginTop: 8 }}>
        <div className="file-analysis-values">
          {isLoading ? (
            <Skeleton width={30} height={24} />
          ) : (
            <p className="label-value-value">{files || 0}</p>
          )}
          <p className="label-value-title" style={{ marginLeft: 5 }}>
            Files
          </p>
        </div>
        {showEmptyValues && (
          <div className="divider-vertical" style={{ height: 50 }} />
        )}
        {showEmptyValues && (
          <div className="file-analysis-values" style={{ marginLeft: 8 }}>
            {isLoading ? (
              <Skeleton width={30} height={24} />
            ) : (
              <p className="label-value-value">{products || 0}</p>
            )}
            <p className="label-value-title" style={{ marginLeft: 5 }}>
              UP Records
            </p>
          </div>
        )}
        {showEmptyValues && (
          <div className="divider-vertical" style={{ height: 50 }} />
        )}
        {showEmptyValues && (
          <div className="file-analysis-values" style={{ marginLeft: 8 }}>
            {isLoading ? (
              <Skeleton width={30} height={24} />
            ) : (
              <p className="label-value-value">{errors || 0}</p>
            )}
            <p className="label-value-title" style={{ marginLeft: 5 }}>
              Errors
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

FileTypeAnalysis.defaultProps = {
  errors: 0,
  files: 0,
  products: 0,
};

FileTypeAnalysis.propTypes = {
  style: PropTypes.instanceOf(Object).isRequired,
  header: PropTypes.string.isRequired,
  errors: PropTypes.number,
  files: PropTypes.number,
  showEmptyValues: PropTypes.bool.isRequired,
  products: PropTypes.number,
  isLoading: PropTypes.bool.isRequired,
};

function FileAnalysisTotal({ style, isLoading, total }) {
  return (
    <div style={style} className="col file-type-analysis-total">
      <h3 className="grid-item-title">Total</h3>

      <div className="file-analysis-data-top-row" style={{ marginTop: 8 }}>
        <div className="file-analysis-values">
          {isLoading ? (
            <Skeleton width={30} height={24} />
          ) : (
            <p className="label-value-value">{total?.files || 0}</p>
          )}
          <p className="label-value-title" style={{ marginLeft: 5 }}>
            Files
          </p>
        </div>
        <div className="divider-vertical" style={{ height: 50 }} />
        <div className="file-analysis-values" style={{ marginLeft: 8 }}>
          {isLoading ? (
            <Skeleton width={30} height={24} />
          ) : (
            <p className="label-value-value">{total?.products || 0}</p>
          )}
          <p className="label-value-title" style={{ marginLeft: 5 }}>
            UP Records
          </p>
        </div>
        <div className="divider-vertical" style={{ height: 50 }} />
        <div className="file-analysis-values">
          {isLoading ? (
            <Skeleton width={30} height={24} />
          ) : (
            <p className="label-value-value">{total?.bcgErrors || 0}</p>
          )}
          <p className="label-value-title" style={{ marginLeft: 5 }}>
            Errors
          </p>
        </div>
      </div>
    </div>
  );
}

FileAnalysisTotal.propTypes = {
  style: PropTypes.instanceOf(Object).isRequired,
  isLoading: PropTypes.bool.isRequired,
  total: PropTypes.instanceOf(Object).isRequired,
};

function FileAnalysisBanner({ heading, result, isLoading, subHeading }) {
  const {
    unitPrices,
    holdings,
    fundSize,
    assetAllocation,
    others,
    total,
  } = result;

  return (
    <div className="file-analysis-top-row" style={{ marginTop: 16 }}>
      <p className="grid-item-heading">
        <img
          src="/assets/error-analysis.svg"
          style={{ marginRight: 5 }}
          alt="error-analysis"
        />
        {heading}
        <div className="sub-heading">{subHeading}</div>
      </p>
      <div className="file-analysis-top-row-grid ">
        <FileTypeAnalysis
          header="IMD02P001 & Non IMD Unit Prices"
          files={unitPrices?.files || 0}
          products={unitPrices?.products || 0}
          errors={unitPrices?.errors || 0}
          style={{ marginTop: 16 }}
          showEmptyValues
          isLoading={isLoading}
        />
        <FileTypeAnalysis
          header="Holdings"
          files={holdings?.files || 0}
          products={holdings?.products || 0}
          errors={holdings?.errors || 0}
          style={{ marginTop: 16 }}
          showEmptyValues={false}
          isLoading={isLoading}
        />
        <FileTypeAnalysis
          header="IMD02A001 & MDU01G010 Fund Sizes"
          files={fundSize?.files || 0}
          products={fundSize?.products || 0}
          errors={fundSize?.errors || 0}
          style={{ marginTop: 16 }}
          showEmptyValues={false}
          isLoading={isLoading}
        />
        <FileTypeAnalysis
          header="Asset Allocation"
          files={assetAllocation?.files || 0}
          products={assetAllocation?.products || 0}
          errors={assetAllocation?.errors || 0}
          style={{ marginTop: 16 }}
          showEmptyValues={false}
          isLoading={isLoading}
        />
        <FileTypeAnalysis
          header="Others"
          files={others?.files || 0}
          products={others?.products || 0}
          errors={others?.errors || 0}
          style={{ marginTop: 16 }}
          showEmptyValues={false}
          isLoading={isLoading}
        />
        <FileAnalysisTotal
          style={{ marginTop: 16 }}
          total={total}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}

export default FileAnalysisBanner;

FileAnalysisBanner.defaultProps = {
  heading: "",
  subHeading: "",
};

FileAnalysisBanner.propTypes = {
  heading: PropTypes.string,
  result: PropTypes.instanceOf(Object).isRequired,
  isLoading: PropTypes.bool.isRequired,
  subHeading: PropTypes.string,
};
