import React, { useEffect, useState } from "react";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import PropTypes from "prop-types";
import { Tooltip } from "@material-ui/core";
import LineGraph from "../../../../../components/LineGraph";
import reportServices from "../../../../../api/reportServices";
import OverviewTabPopup from "../../OverviewTabPopup";
import helper from "../../../../../utils/helper";

const { getUnitDetails } = helper;

function PerformanceSection({
  showPopup,
  handlePopup,
  userPerformance,
  clientPerformance,
}) {
  return (
    <>
      {showPopup.display && (
        <OverviewTabPopup
          tableData={
            showPopup.type === "user"
              ? userPerformance.user_performance
              : clientPerformance.client_performance
          }
          endDate={
            showPopup.type === "user"
              ? userPerformance.week1_start
              : clientPerformance.week1_start
          }
          startDate={
            showPopup.type === "user"
              ? userPerformance.week2_end
              : clientPerformance.week2_end
          }
          showPopup={showPopup}
          handlePopup={handlePopup}
        />
      )}
      <div className="performance-col-single">
        <div className="performance-wrapper">
          <div
            role="button"
            tabIndex="0"
            onKeyPress={handlePopup("user")}
            onClick={handlePopup("user")}
            className="performance-first-row"
          >
            <span className="hint">User</span>
            <div className="performance-single-data">
              <div className="sidebar-list-single__content performance-single-data---up">
                <img
                  className="sidebar-list-single___pic"
                  src="/assets/green-up3.png"
                  alt="arrow"
                />
                <div className="sidebar-list-single___data">
                  <p>
                    {userPerformance?.top_performed_user?.email || "Not Found"}
                  </p>
                  <span>
                    {userPerformance?.top_performed_user?.percentage || 0}% More
                    resolved
                  </span>
                </div>
              </div>
              <div className="sidebar-list-single__content performance-single-data---down">
                <img
                  className="sidebar-list-single___pic"
                  src="/assets/red-down2.png"
                  alt="arrow"
                />
                <div className="sidebar-list-single___data">
                  <p>
                    {userPerformance?.least_performed_user?.email ||
                      "Not Found"}
                  </p>
                  <span>
                    {Math.abs(
                      userPerformance?.least_performed_user?.percentage || 0
                    )}
                    % Less resolved
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            role="button"
            tabIndex="0"
            onKeyPress={handlePopup("client")}
            onClick={handlePopup("client")}
            style={{ padding: 10 }}
          >
            <span className="hint">Client</span>
            <div className="performance-single-data">
              <div className="sidebar-list-single__content performance-single-data---up">
                <img
                  className="sidebar-list-single___pic"
                  src="/assets/green-up3.png"
                  alt="arrow"
                />
                <div className="sidebar-list-single___data">
                  <p>
                    {clientPerformance?.top_performed_client?.email ||
                      "Not Found"}
                  </p>
                  <span>
                    {getUnitDetails(
                      clientPerformance?.top_performed_client?.percentage
                    )}
                  </span>
                </div>
              </div>
              <div className="sidebar-list-single__content performance-single-data---down">
                <img
                  className="sidebar-list-single___pic"
                  src="/assets/red-down2.png"
                  alt="arrow"
                />
                <div className="sidebar-list-single___data">
                  <p>
                    {clientPerformance?.least_performed_client?.email ||
                      "Not Found"}
                  </p>
                  <span>
                    {getUnitDetails(
                      clientPerformance?.least_performed_client?.percentage
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

PerformanceSection.propTypes = {
  showPopup: PropTypes.instanceOf(Object).isRequired,
  handlePopup: PropTypes.func.isRequired,
  userPerformance: PropTypes.instanceOf(Object).isRequired,
  clientPerformance: PropTypes.instanceOf(Object).isRequired,
};

function ErrorTrendCard({ mainLoader, data }) {
  return (
    <div className="error-trend-card">
      <h2 className="box-header">
        <img
          src="/assets/error-analysis.svg"
          alt="rror-analysis"
          style={{ marginRight: 5 }}
        />
        Error Trend
      </h2>
      <div className="error-trend-row">
        <div className="col-1 inner-col">
          <div>
            <p className="title">Highest Error File</p>
            {mainLoader ? (
              <Skeleton width={50} height={18} style={{ marginBottom: 16 }} />
            ) : (
              <p
                className="value ellipsis"
                title={data?.highest_error_file_name}
              >
                {data?.highest_error_file_name || "NA"}
              </p>
            )}
          </div>
          <div className="count-text">
            <p className="title">Count</p>
            {mainLoader ? (
              <Skeleton width={50} height={18} />
            ) : (
              <p className="value">{data?.highest_ref_id_err_count || 0}</p>
            )}
          </div>
        </div>
        <hr />
        <div className="col-2 inner-col">
          <div>
            <p className="title">Highest Error</p>
            {mainLoader ? (
              <Skeleton width={50} height={18} style={{ marginBottom: 16 }} />
            ) : (
              <p className="value">{data?.highest_error?.code || "NA"}</p>
            )}
          </div>
          <div className="count-text">
            <p className="title">Count</p>
            {mainLoader ? (
              <Skeleton width={50} height={18} />
            ) : (
              <p className="value">{data?.highest_error?.total_count || 0}</p>
            )}
          </div>
        </div>
        <hr />
        <div className="col-3 inner-col">
          <div>
            <p className="title">Highest Error (IA)</p>
            {mainLoader ? (
              <Skeleton width={50} height={18} style={{ marginBottom: 16 }} />
            ) : (
              <p className="value ">{data?.highest_ia_error?.code || "NA"}</p>
            )}
          </div>
          <div className="count-text">
            <p className="title">Count</p>
            {mainLoader ? (
              <Skeleton width={50} height={18} />
            ) : (
              <p className="value">
                {data?.highest_ia_error?.total_count || 0}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

ErrorTrendCard.defaultProps = { data: null };

ErrorTrendCard.propTypes = {
  mainLoader: PropTypes.bool.isRequired,
  data: PropTypes.instanceOf(Object),
};

function ProductAnalysisCard({ mainLoader, data }) {
  return (
    <div className="product-analysis-card">
      <Tooltip title="Number of individual records of products with unit price information and dates">
        <h2 className="header">
          <img
            src="/assets/product-analysis.svg"
            alt="product-analysis"
            style={{ marginRight: 5 }}
          />
          Unit Price Records Analysis
        </h2>
      </Tooltip>
      <div className="product-analysis-row">
        <div className="product-analysis-col">
          {mainLoader ? (
            <Skeleton width={50} height={24} />
          ) : (
            <p className="value  value-large">
              {data?.published_count ? data.published_count : 0}
            </p>
          )}
          <p className="title">Published</p>
        </div>
        <div className="product-analysis-col">
          {mainLoader ? (
            <Skeleton width={50} height={24} />
          ) : (
            <p className="value value-large">
              {data?.processing_count ? data.processing_count : 0}
            </p>
          )}
          <p className="title">Processing</p>
        </div>
        <div className="product-analysis-col">
          {mainLoader ? (
            <Skeleton width={50} height={24} />
          ) : (
            <p className="value  value-large">
              {data?.resolved_count ? data.resolved_count : 0}
            </p>
          )}
          <p className="title">Resolved</p>
        </div>
        <div className="product-analysis-col">
          {mainLoader ? (
            <Skeleton width={50} height={24} />
          ) : (
            <p className="value value-large">
              {data?.repair_queued_count ? data.repair_queued_count : 0}
            </p>
          )}
          <p className="title">Repair Queue</p>
        </div>
        <div className="product-analysis-col">
          {mainLoader ? (
            <Skeleton width={50} height={24} />
          ) : (
            <p className="value value-large">
              {data?.others_count ? data.others_count : 0}
            </p>
          )}
          <p className="title">Others</p>
        </div>
      </div>
    </div>
  );
}

ProductAnalysisCard.defaultProps = { data: null };

ProductAnalysisCard.propTypes = {
  mainLoader: PropTypes.bool.isRequired,
  data: PropTypes.instanceOf(Object),
};

function EmailAnalysisCard({ mainLoader, data }) {
  return (
    <div className="email-analysis-card">
      <Tooltip title="Total number of emails that have an IA unit price fundtag prediction">
        <h2 className="header">
          <img
            src="/assets/email-analysis.svg"
            alt="email-analysis"
            style={{ marginRight: 5 }}
          />
          Email Analysis
        </h2>
      </Tooltip>
      <div className="email-analysis-content">
        {mainLoader ? (
          <Skeleton width={50} height={24} style={{ marginBottom: 16 }} />
        ) : (
          <p className="value mb-value value-large">
            {data?.total_email_count || 0}
          </p>
        )}
        <p className="title">Emails</p>
      </div>
    </div>
  );
}

EmailAnalysisCard.defaultProps = { data: null };

EmailAnalysisCard.propTypes = {
  mainLoader: PropTypes.bool.isRequired,
  data: PropTypes.instanceOf(Object),
};

function TrendAnalysis({ dateDetails }) {
  const [mainLoader, setMainLoader] = useState(false);
  const [gaphData, setGraphData] = useState();
  const [countData, setCountData] = useState();
  const [errorData, setErrorData] = useState();
  const [showPopup, setPopup] = useState({ display: false, type: "" });
  const [userPerformance, setUserPerformance] = useState({});
  const [clientPerformance, setClientPerformance] = useState({});
  const { timeOption } = dateDetails;

  const dataset = [
    {
      label: "Emails",
      data: gaphData?.mail_count ? gaphData.mail_count : [],
      fill: false,
      borderColor: "#1C57AD",
      pointBackgroundColor: "#1C57AD",
    },

    {
      label: "Unit Price Records",
      data: gaphData?.product_count ? gaphData.product_count : [],
      fill: false,
      borderColor: "#0CAA63",
      pointBackgroundColor: "#0CAA63",
    },
  ];
  const xAxisLabels = gaphData?.label ? gaphData?.label : [];

  // for opening user and client performance pop up
  const handlePopup = (type) => () => {
    setPopup({ ...showPopup, display: !showPopup.display, type });
  };

  const labelCountData = (value, key, showDate) => {
    const mailCount = [];
    const label = [];
    let prevKey = 24;
    Object.entries(value).forEach(([innerKey, innerValue]) => {
      let date = key;
      if (Number(innerKey) === 24) {
        date = moment(date, "YYYY-MM-DD").add(1, "days").format("YYYY-MM-DD");
      }
      label.push(
        `${showDate ? date : ""} ${prevKey <= 12 ? prevKey : prevKey - 12}${
          prevKey < 12 || Number(prevKey) === 24 ? "am" : "pm"
        } - ${innerKey <= 12 ? innerKey : innerKey - 12}${
          innerKey < 12 || Number(innerKey) === 24 ? "am" : "pm"
        }`
      );

      prevKey = innerKey;
      mailCount.push(innerValue);
    });
    return { label, mailCount };
  };
  function organizeHourly(response, showDate) {
    const mailCountObject = response.mail_count;
    const productCountObject = response.product_count;
    const mailCountArray = [];
    const labels = [];
    const productCountArray = [];

    const totalMailData = [];
    const productData = [];

    Object.entries(mailCountObject).forEach(([key, value]) => {
      const { label, mailCount } = labelCountData(value, key, showDate);
      labels.push(...label);
      mailCountArray.push(...mailCount);
    });
    Object.entries(productCountObject).forEach(([, value]) => {
      Object.entries(value).forEach(([, innerValue]) => {
        productCountArray.push(innerValue);
      });
    });

    const dataPoints = labels.length;
    for (let index = 0; index < dataPoints; index += 1) {
      const totalMailCount = mailCountArray[index];
      const totalProductsCount = productCountArray[index];
      totalMailData.push(totalMailCount);
      productData.push(totalProductsCount);
    }

    setGraphData({
      ...response,
      mail_count: totalMailData,
      product_count: productData,
      label: labels,
    });
  }

  function getTrendAnalysis() {
    setMainLoader(true);
    const filterText = [];
    const fromDate = moment(dateDetails.fromDate).format("YYYY-MM-DD");
    const toDate = moment(dateDetails.toDate).format("YYYY-MM-DD");
    if (dateDetails.durationType) filterText.push(`duration_type=${"daily"}`);
    if (dateDetails.fromDate) filterText.push(`start_duration=${fromDate}`);
    if (dateDetails.toDate) filterText.push(`end_duration=${toDate}`);
    if (dateDetails.durationType === "daily" && timeOption !== 24) {
      filterText.push(`hour_split=true`);
      filterText.push(`split_interval=${timeOption}`);
    }
    reportServices.getTrendAnalysis(filterText, "graph").then((resp) => {
      if (dateDetails.durationType === "daily") {
        organizeHourly(resp);
      } else {
        let labels = resp.label;
        if (dateDetails.durationType === "weekly") {
          labels = labels.map((item) => {
            const date = moment(item)?.format("YYYY-MM-DD (ddd)") || item;
            return date.toUpperCase();
          });
        }
        setGraphData({ ...resp, label: labels });
      }
      setMainLoader(false);
    });
    reportServices.getTrendAnalysis(filterText, "email").then((resp) => {
      setCountData({ ...resp });
      setMainLoader(false);
    });
    reportServices.getTrendAnalysis(filterText, "error").then((resp) => {
      setErrorData({ ...resp });
      setMainLoader(false);
    });
  }

  /**
   * component did mount/ did update
   * calls the trend analysis api here
   *
   */
  useEffect(() => {
    if (dateDetails) getTrendAnalysis();
  }, [dateDetails]);

  /**
   * calls the user and client performance api here
   *
   */
  useEffect(() => {
    reportServices.getUserPerformance().then((resp) => {
      setUserPerformance(resp);
    });
    reportServices.getClientPerformance().then((resp) => {
      setClientPerformance(resp);
    });
  }, []);

  return (
    <div className="trend-analysis-container">
      <div className="trend-col-1">
        <div className="trend-row">
          <ProductAnalysisCard mainLoader={mainLoader} data={countData} />
          <EmailAnalysisCard mainLoader={mainLoader} data={countData} />
        </div>
        {mainLoader ? (
          <Skeleton width={700} height={350} />
        ) : (
          <LineGraph dataSet={dataset} xAxisLabels={xAxisLabels} />
        )}
      </div>
      <div className="trend-col-2">
        <ErrorTrendCard mainLoader={mainLoader} data={errorData} />
        <div className="performance-card">
          <div className="performance-header">
            <h1 className="box-header">
              <img
                src="/assets/performance.svg"
                alt="performance"
                style={{ marginRight: 5 }}
              />
              Performance
            </h1>
            {/* <p>Comparison period (last two weeks)</p> */}
          </div>
          <PerformanceSection
            clientPerformance={clientPerformance}
            userPerformance={userPerformance}
            handlePopup={handlePopup}
            showPopup={showPopup}
          />
        </div>
      </div>
    </div>
  );
}

export default TrendAnalysis;

TrendAnalysis.propTypes = {
  dateDetails: PropTypes.instanceOf(Object).isRequired,
};
