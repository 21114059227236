import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";

import styles from "../../../resources/index.module.scss";

function BackToDashboardButton({
  setMailThreadData,
  setSelectedExtension,
  setThreadFilter,
  selectedRecord,
  setFilter,
  filter,
  mailThreadData,
  history,
}) {
  return (
    <div
      role="button"
      tabIndex="0"
      className={cn({
        [styles.listViewPrefOption]: true,
        [styles.backBtn]: true,
      })}
      onKeyPress={() => {
        history.push("/dashboard");
        setMailThreadData([]);
        setSelectedExtension(undefined);
        setThreadFilter({ currentPage: 1, pageSize: 25 });
        if (selectedRecord?.ref_ids?.length >= 50) {
          setFilter({ ...filter });
        }
      }}
      onClick={() => {
        history.push("/dashboard");
        setMailThreadData([]);
        setSelectedExtension(undefined);
        setThreadFilter({ currentPage: 1, pageSize: 25 });
        if (selectedRecord?.ref_ids?.length >= 50) {
          setFilter({ ...filter });
        }
      }}
    >
      <img src="/assets/back-arrow.svg" alt="back" />
      &ensp;
      <span>
        Details: {mailThreadData[0]?.mail_from?.split("<")[0]?.trim()}
      </span>
    </div>
  );
}

export default BackToDashboardButton;

BackToDashboardButton.propTypes = {
  setMailThreadData: PropTypes.func.isRequired,
  setSelectedExtension: PropTypes.func.isRequired,
  setThreadFilter: PropTypes.func.isRequired,
  selectedRecord: PropTypes.instanceOf(Object).isRequired,
  setFilter: PropTypes.func.isRequired,
  filter: PropTypes.instanceOf(Object).isRequired,
  mailThreadData: PropTypes.instanceOf(Array).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};
