import React from "react";
import Skeleton from "react-loading-skeleton";

import styles from "../../../resources/index.module.scss";

function DashboardTableSkeleton() {
  return (
    <div
      className={styles.listView}
      style={{ backgroundColor: "#eceff2", border: "4px white solid" }}
    >
      {Array.from({ length: 7 }).map((el) => (
        <React.Fragment key={el}>
          <Skeleton className={styles.record} style={{ height: "78px" }} />
          <hr className={styles.recordSeparatorSkeleton} />
        </React.Fragment>
      ))}
    </div>
  );
}

export default DashboardTableSkeleton;
