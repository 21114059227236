import React, { memo } from "react";
import cn from "classnames";
import PropTypes from "prop-types";

import styles from "../../../resources/index.module.scss";
import MailExtensionItem from "../../../../../components/MailExtensionItem";
import ExtensionTableSkeleton from "../../../../../components/ExtensionTableSkeleton";

function MailExtension({
  mail,
  extensionData,
  isViewAllLoading,
  setSelectedThreadMail,
  setSelectedExtension,
  setHasThread,
  selectedThreadMail,
  selectedExtension,
  hasThread,
  isMailSelected,
  selectRecord,
  hotQueueClickHandler,
  setHotQueueRulesData,
  hotQueueRulefetcher,
}) {
  return (
    <div
      data-testid="mail-extension"
      className={cn({
        [styles.mailExtensionContainer]: isMailSelected,
      })}
      style={{
        paddingRight: !isMailSelected ? "10px" : "",
        paddingBottom: !isMailSelected ? "1rem" : "",
      }}
    >
      {isViewAllLoading ? (
        <div className={styles.mailExtensionLoading}>
          <ExtensionTableSkeleton totalCount={mail?.total_attachment_count} />
        </div>
      ) : (
        <div
          role="button"
          tabIndex="0"
          onKeyPress={() => {
            setHotQueueRulesData({});
            selectRecord(mail);
            hotQueueRulefetcher();
          }}
          className={styles.mailExtension}
          onClick={() => {
            setHotQueueRulesData({});
            selectRecord(mail);
            hotQueueRulefetcher();
          }}
        >
          <div className={styles.mailExtensionItemsRoot}>
            {Array.isArray(extensionData) &&
              extensionData.map((threadMail) => {
                const {
                  id,
                  mail_attachment: mailAttachment,
                  tag,
                  status,
                  date,
                  child,
                } = threadMail;
                return (
                  <React.Fragment key={id}>
                    <MailExtensionItem
                      mailAttachment={mailAttachment}
                      id={id}
                      tag={tag}
                      status={status}
                      date={date}
                      selected={
                        !hasThread &&
                        !selectedExtension &&
                        id === selectedThreadMail?.id
                      }
                      onClick={() => {
                        setHotQueueRulesData({});
                        setSelectedThreadMail(threadMail);
                        setSelectedExtension(null);
                        setHasThread(null);
                        hotQueueRulefetcher();
                      }}
                      hotQueueClickHandler={hotQueueClickHandler}
                    />
                    {/* <div className={styles.rowMiniDivider} /> */}
                    {child.length !== 0 && (
                      <ul className={styles.listStyleNone}>
                        {child.map((currentChild) => {
                          const {
                            id: childId,
                            mail_attachment: childMailAttachment,
                            tag: childTag,
                            status: childStatus,
                            sub_child: subChild,
                          } = currentChild;
                          return (
                            <li key={childId}>
                              <MailExtensionItem
                                selected={
                                  !hasThread &&
                                  selectedExtension?.id === childId
                                }
                                id={childId}
                                tag={childTag}
                                status={childStatus}
                                mailAttachment={childMailAttachment}
                                onClick={() => {
                                  setHotQueueRulesData({});
                                  setSelectedExtension(currentChild);
                                  setSelectedThreadMail(threadMail);
                                  setHasThread(null);
                                  hotQueueRulefetcher();
                                }}
                                hotQueueClickHandler={hotQueueClickHandler}
                              />
                              {/* <div className={styles.rowMiniDivider} /> */}
                              {subChild.length !== 0 && (
                                <ul className={styles.listStyleNone}>
                                  {subChild.map((currentSubChild) => {
                                    const {
                                      id: subChildId,
                                      mail_attachment: subChildMailAttachment,
                                      tag: subChildTag,
                                      status: subChildStatus,
                                    } = currentSubChild;
                                    return (
                                      <li key={subChildId}>
                                        <MailExtensionItem
                                          selected={
                                            !hasThread &&
                                            selectedExtension?.id === subChildId
                                          }
                                          id={subChildId}
                                          tag={subChildTag}
                                          status={subChildStatus}
                                          mailAttachment={
                                            subChildMailAttachment
                                          }
                                          isSubChild
                                          onClick={() => {
                                            setHotQueueRulesData({});
                                            setSelectedExtension(
                                              currentSubChild
                                            );
                                            setSelectedThreadMail(threadMail);
                                            setHasThread(null);
                                            hotQueueRulefetcher();
                                          }}
                                          hotQueueClickHandler={
                                            hotQueueClickHandler
                                          }
                                        />
                                        {/* <div
                                          className={styles.rowMiniDivider}
                                        /> */}
                                      </li>
                                    );
                                  })}
                                </ul>
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </React.Fragment>
                );
              })}
          </div>

          {/* <div className={styles.totalDisplay} style={{ display: "flex" }}>
            <div style={{ width: "80%", flexBasis: "80%" }} />
            <div
              data-testid="mail-extension-list-btn"
              role="button"
              tabIndex="0"
              className={cn({
                [styles.previewFileDownloadLink]: true,
              })}
              onKeyPress={setPopUp}
              onClick={setPopUp}
              style={{
                textAlign: "end",
                marginTop: "2%",
                width: "12%",
              }}
            >
              <img
                alt="arrow"
                className={cn({
                  [styles.star]: true,
                })}
                src={
                  popUp
                    ? "/assets/dropUpArrow.svg"
                    : "/assets/dropDownArrow.svg"
                }
              />
            </div>
          </div> */}
        </div>
      )}
    </div>
  );
}

export default memo(MailExtension);

MailExtension.defaultProps = {
  selectedExtension: null,
  mail: {},
  extensionData: [],
  isMailSelected: false,
};

MailExtension.propTypes = {
  mail: PropTypes.instanceOf(Object),
  setHasThread: PropTypes.func.isRequired,
  setSelectedThreadMail: PropTypes.func.isRequired,
  setSelectedExtension: PropTypes.func.isRequired,
  selectedThreadMail: PropTypes.instanceOf(Object).isRequired,
  selectedExtension: PropTypes.instanceOf(Object),
  hasThread: PropTypes.bool.isRequired,
  extensionData: PropTypes.instanceOf(Array),
  isViewAllLoading: PropTypes.bool.isRequired,
  isMailSelected: PropTypes.bool,
  selectRecord: PropTypes.func.isRequired,
  hotQueueClickHandler: PropTypes.func.isRequired,
  setHotQueueRulesData: PropTypes.func.isRequired,
  hotQueueRulefetcher: PropTypes.func.isRequired,
};
