import React, { useState } from "react";
import cn from "classnames";
import { Radio } from "antd";
import PropTypes from "prop-types";

import Metrics from "./Metrics";
import TrendAnalysis from "./TrendAnalysis";

function OverviewMetricsAndAnalysis({ dateDetails, selectHourWindow }) {
  const [tab, setTab] = useState("metrics");
  const [viewType, setViewType] = useState("list");
  const { dateDifference } = dateDetails;
  const { timeOption } = dateDetails;

  const timeOptionsList = [
    { label: "Per Hr", value: 1, disabled: dateDifference > 1 },
    { label: "4 Hrs", value: 4, disabled: dateDifference > 4 },
    { label: "6 Hrs", value: 6, disabled: dateDifference > 6 },
    { label: "12 Hrs", value: 12, disabled: dateDifference > 12 },
  ];

  const handleMetricsTab = () => {
    setViewType("list");
    setTab("metrics");
  };

  const handleTrendTab = () => {
    setTab("trendAnalysis");
  };

  function setHourly(hour) {
    selectHourWindow(hour);
  }

  const handleRadioButton = (e) => {
    setHourly(e.target.value);
  };

  return (
    <>
      <div className="overview-container">
        <div className="overview-nav">
          <div
            role="button"
            tabIndex="0"
            onKeyPress={handleMetricsTab}
            className={cn({ selected: tab === "metrics", navButton: true })}
            onClick={handleMetricsTab}
          >
            Metrics
          </div>
          <div
            role="button"
            tabIndex="0"
            className={cn({
              selected: tab === "trendAnalysis",
              navButton: true,
            })}
            onKeyPress={handleTrendTab}
            onClick={handleTrendTab}
          >
            Trend Analysis
          </div>
        </div>
        <div className="right-content">
          {tab === "metrics" && (
            <div className="switch-container">
              <div
                className={cn({
                  switchSelected: viewType === "list",
                  switch: true,
                })}
                onClick={() => setViewType("list")}
              >
                <img
                  style={{ marginRight: 4 }}
                  src={
                    viewType === "list"
                      ? "/assets/list-selected.svg"
                      : "/assets/list.svg"
                  }
                />
                {viewType === "list" && "List"}
              </div>
              <div
                className={cn({
                  switchSelected: viewType === "graph",
                  switch: true,
                })}
                onClick={() => setViewType("graph")}
              >
                <img
                  style={{ marginRight: 4 }}
                  src={
                    viewType === "graph"
                      ? "/assets/graph-selected.svg"
                      : "/assets/graph.svg"
                  }
                />
                {viewType === "graph" && "Graph"}
              </div>
            </div>
          )}

          {dateDetails.durationType === "daily" && tab === "trendAnalysis" && (
            <Radio.Group
              options={timeOptionsList}
              onChange={handleRadioButton}
              value={timeOption}
              buttonStyle="solid"
              optionType="button"
              className="time-window-radio-btn"
              style={{ marginLeft: 20 }}
            />
          )}

          <div className="overview-container-text">UNIT PRICES</div>
        </div>
      </div>

      {tab === "metrics" && (
        <Metrics dateDetails={dateDetails} viewType={viewType} />
      )}

      {tab === "trendAnalysis" && (
        <TrendAnalysis dateDetails={dateDetails} timeOption={timeOption} />
      )}
    </>
  );
}

export default OverviewMetricsAndAnalysis;

OverviewMetricsAndAnalysis.propTypes = {
  dateDetails: PropTypes.instanceOf(Object).isRequired,
  selectHourWindow: PropTypes.func.isRequired,
};
