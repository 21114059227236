import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import cn from "classnames";
import { TextField } from "@material-ui/core";
import PropTypes from "prop-types";
import { Dots } from "react-activity";

import SingleSelect from "../../../../../components/SingleSelect";
import MultiSelect from "../../../../../components/MultiSelect";
import styles from "../../../resources/index.module.scss";
import "react-activity/dist/Dots.css";

function StarMailPopup({
  users,
  setStarPopupOpen,
  starringReasons,
  isStarPopupOpen,
  isStarred,
  onSubmit,
  starDetails,
  removeStar,
  isSubmitting,
}) {
  function getDefaultReason() {
    const defaultReason = starDetails?.comment_text;
    if (defaultReason?.length < 1) {
      return "";
    }
    if (starringReasons.some((reason) => defaultReason === reason.value))
      return defaultReason;
    return "Others";
  }

  const {
    register: registerSearch,
    handleSubmit: handleSearchSubmit,
    control: controlSearch,
    watch,
  } = useForm({
    defaultValues: {
      reason: getDefaultReason(),
      other_reason:
        getDefaultReason() === "Others" ? starDetails?.comment_text || "" : "",
      tags: starDetails?.tagged_user
        ? starDetails?.tagged_user.map((u) => u.id.toString())
        : [],
    },
  });

  const [inputDisabled, setInputDisabled] = useState(false);
  const [reasonWarning, showReasonWarning] = useState(false);

  const watchFields = watch();

  const filterSelectUsers = () => {
    const taggesUsersId = starDetails?.tagged_user.map((u) => u.id.toString());
    const listUsersId = users.map((u) => u.id);
    const diffArray = taggesUsersId.filter((x) => !listUsersId.includes(x));
    const extraItems = starDetails?.tagged_user
      .filter((x) => diffArray.includes(x.id.toString()))
      .map((m) => ({ id: m.id.toString(), value: m.name, label: m.name }));

    return [...users, ...extraItems];
  };

  function submitData(data) {
    if (!data.reason) return;
    const taggedUsers = filterSelectUsers().filter((item) =>
      data.tags.some((tag) => tag === item.id)
    );
    const taggedUserIds = taggedUsers?.map((user) => user.id);
    let dataToSubmit;
    let reason = "";
    if (data.reason === "Others") {
      dataToSubmit = {
        other_comment: data.other_reason,
        tagged_users: taggedUserIds,
        is_update: isStarred,
      };
    } else {
      reason = starringReasons?.find(
        (currentReason) => currentReason.value === data.reason
      );
      dataToSubmit = {
        comment: reason?.id,
        tagged_users: taggedUserIds,
        is_update: isStarred,
      };
    }
    onSubmit(dataToSubmit);
  }

  useEffect(() => {
    setInputDisabled(watchFields.reason !== "Others");
  }, [watchFields]);

  return (
    <form
      style={{
        ...(!isStarPopupOpen && { display: "none" }),
      }}
      className={styles.starPopup}
      onSubmit={handleSearchSubmit(submitData)}
    >
      <div data-testid="star-mail-popup">
        <table>
          <tbody>
            <tr className={styles.starPopupPopupRow}>
              <td>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="fund-tag">Reason</label>
              </td>
              <td>
                <Controller
                  data-testid="star-mail-popup-select-reason"
                  required
                  control={controlSearch}
                  name="reason"
                  inputProps={{ ref: registerSearch }}
                  render={({ onChange, value }) => (
                    <SingleSelect
                      id="star-reason"
                      optionList={[
                        ...starringReasons,
                        { id: -1, value: "Others" },
                      ]}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </td>
            </tr>
          </tbody>
        </table>
        {reasonWarning && !watchFields.reason && (
          <p className={styles.warning}>Please select your reason</p>
        )}
        {!inputDisabled && (
          <TextField
            data-testid="star-mail-popup-enter-reason"
            required
            autoFocus
            placeholder="Enter reason"
            className={styles.captchaInput}
            style={{ marginTop: 20 }}
            multiline
            rowsMax={4}
            inputProps={{ ref: registerSearch }}
            name="other_reason"
          />
        )}
        <table style={{ marginTop: 20 }}>
          <tbody>
            <tr className={styles.starPopupPopupRow}>
              <td>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="fund-tag">Tag Users</label>
              </td>
              <td>
                <Controller
                  data-testid="star-mail-popup-select-tags"
                  control={controlSearch}
                  name="tags"
                  defaultValue={[]}
                  render={({ onChange, value }) => (
                    <MultiSelect
                      id="tagged-user"
                      onChange={onChange}
                      value={value}
                      listOptions={filterSelectUsers()}
                    />
                  )}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={styles.footer}>
        {/* eslint-disable-next-line react/button-has-type */}
        <button
          type="reset"
          className={styles.cancel}
          onClick={() => setStarPopupOpen(false)}
        >
          Cancel
        </button>
        {isStarred && (
          <button
            type="button"
            className={styles.clear}
            onClick={() => {
              setStarPopupOpen(false);
              removeStar();
            }}
          >
            Remove Star
          </button>
        )}
        <button
          data-testid="star-mail-popup-add-start-btn"
          type="submit"
          className={cn({
            [styles.search]: true,
            [styles.dotsPadding]: isSubmitting && !isStarred,
            [styles.dotsPaddingForUpdate]: isSubmitting && isStarred,
          })}
          disabled={isSubmitting}
          onClick={() => {
            if (!watchFields.reason) showReasonWarning(true);
          }}
        >
          {(isSubmitting && (
            <div style={{ textAlign: "center" }}>
              <Dots />
            </div>
          )) ||
          isStarred
            ? "Update"
            : "Add Star"}
        </button>
      </div>
    </form>
  );
}

export default StarMailPopup;

StarMailPopup.defaultProps = {
  starDetails: null,
};

StarMailPopup.propTypes = {
  users: PropTypes.instanceOf(Array).isRequired,
  setStarPopupOpen: PropTypes.func.isRequired,
  starringReasons: PropTypes.instanceOf(Array).isRequired,
  isStarPopupOpen: PropTypes.bool.isRequired,
  isStarred: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  starDetails: PropTypes.instanceOf(Object),
  removeStar: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};
