import React, { memo } from "react";
import PropTypes from "prop-types";

import styles from "../../../resources/index.module.scss";
import MailItem from "../../../../../components/MailItem";

function DashboardTable({
  currentDataList,
  selectRecord,
  selectedRecord,
  setHasThread,
  starringReasons,
  users,
  onUpdate,
  setShowAll,
  setSelectedThreadMail,
  setSelectedExtension,
  selectedThreadMail,
  selectedExtension,
  hasThread,
  hasMailListChanged,
  setHasMailListChanged,
  setPreviewLoading,
  tableRef,
  hotQueueClickHandler,
  setHotQueueRulesData,
  openHotQueueModal,
  getMailLabel,
  setLabel,
}) {
  return (
    <div
      className={styles.listView}
      data-testid="dashboard-table"
      id="dashboard-table"
      ref={tableRef}
    >
      {currentDataList?.map((d) => (
        <MailItem
          mail={d}
          key={d.id}
          selectRecord={selectRecord}
          selectedRecord={selectedRecord}
          setHasThread={setHasThread}
          starringReasons={starringReasons}
          users={users}
          onUpdate={onUpdate}
          setShowAll={setShowAll}
          setSelectedThreadMail={setSelectedThreadMail}
          setSelectedExtension={setSelectedExtension}
          selectedThreadMail={selectedThreadMail}
          selectedExtension={selectedExtension}
          hasThread={hasThread}
          hasMailListChanged={hasMailListChanged}
          setHasMailListChanged={setHasMailListChanged}
          currentDataList={currentDataList}
          setPreviewLoading={setPreviewLoading}
          tableRef={tableRef}
          hotQueueClickHandler={hotQueueClickHandler}
          setHotQueueRulesData={setHotQueueRulesData}
          openHotQueueModal={openHotQueueModal}
          getMailLabel={getMailLabel}
          setLabel={setLabel}
        />
      ))}
    </div>
  );
}

export default memo(DashboardTable);

DashboardTable.defaultProps = {
  selectedThreadMail: null,
  selectedExtension: null,
  hasThread: false,
  tableRef: {},
};

DashboardTable.propTypes = {
  currentDataList: PropTypes.instanceOf(Array).isRequired,
  selectRecord: PropTypes.func.isRequired,
  selectedRecord: PropTypes.instanceOf(Object).isRequired,
  setHasThread: PropTypes.func.isRequired,
  starringReasons: PropTypes.instanceOf(Array).isRequired,
  users: PropTypes.instanceOf(Array).isRequired,
  onUpdate: PropTypes.func.isRequired,
  setShowAll: PropTypes.func.isRequired,
  setSelectedThreadMail: PropTypes.func.isRequired,
  setSelectedExtension: PropTypes.func.isRequired,
  selectedThreadMail: PropTypes.instanceOf(Object),
  selectedExtension: PropTypes.instanceOf(Object),
  hasThread: PropTypes.bool,
  hasMailListChanged: PropTypes.bool.isRequired,
  setHasMailListChanged: PropTypes.func.isRequired,
  setPreviewLoading: PropTypes.func.isRequired,
  tableRef: PropTypes.instanceOf(Object),
  hotQueueClickHandler: PropTypes.func.isRequired,
  setHotQueueRulesData: PropTypes.func.isRequired,
  openHotQueueModal: PropTypes.func.isRequired,
  getMailLabel: PropTypes.func.isRequired,
  setLabel: PropTypes.func.isRequired,
};
