import { Checkbox, FormControlLabel, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import PropTypes from "prop-types";

import BarGraph from "../../../../components/BarGraph";
import FileAnalysisBanner from "../FileAnalysisBanner";
import MultiSelect from "../../../../components/MultiSelect";
import {
  BCG_ERRORS,
  BCG_WARNINGS,
  CODE_LIST_FOR_FILTER,
  SYSTEM_GENERATED_CODES,
} from "../../../../utils/constantData";
import reportServices from "../../../../api/reportServices";
import globalStyles from "../../../../global.module.scss";

const useStyles = makeStyles({
  errorLabel: {
    color: "#BD0303 !important",
    fontWeight: "bold",
    fontSize: "12px !important",
  },
  warningLabel: {
    color: "#B97434 !important",
    fontWeight: "bold",
    fontSize: "12px !important",
  },
  systemLabel: {
    color: "#FF7A00 !important",
    fontWeight: "bold",
    fontSize: "12px !important",
  },
  errorRoot: {
    color: "#BD0303 !important",
  },
  warningRoot: {
    color: "#B97434 !important",
  },
  systemRoot: {
    color: "#FF7A00 !important",
  },
  graphLegendText: {
    color: "#9c9c9c",
  },
  graphLegend: {
    marginLeft: 20,
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
  },
});

function FilterForm({
  isPopupOpen,
  closePopup,
  controlFilter,
  handleFilterSubmit,
  onSubmit,
  classes,
  setValue,
  codesToFilter,
}) {
  return (
    <div className="filter-btn-popup">
      <div
        role="presentation"
        onKeyPress={closePopup}
        style={{ ...(!isPopupOpen && { display: "none" }) }}
        onClick={closePopup}
        className={globalStyles.scrim}
      />
      <form
        style={{ display: isPopupOpen ? "" : "none" }}
        onSubmit={handleFilterSubmit(onSubmit)}
      >
        <div className="filter-set">
          <Controller
            control={controlFilter}
            name="bcgErrors"
            defaultValue={false}
            render={({ onChange, value }) => (
              <FormControlLabel
                classes={{
                  label: classes.errorLabel,
                }}
                control={
                  <Checkbox
                    checked={value}
                    onChange={(e) => {
                      onChange(e.target.checked);
                      setValue("errorCodesList", codesToFilter());
                    }}
                    classes={{ root: classes.errorRoot }}
                  />
                }
                label="BCG Errors"
              />
            )}
          />

          <Controller
            control={controlFilter}
            name="bcgWarnings"
            defaultValue={false}
            render={({ onChange, value }) => (
              <FormControlLabel
                classes={{
                  label: classes.warningLabel,
                }}
                control={
                  <Checkbox
                    checked={value}
                    onChange={(e) => {
                      onChange(e.target.checked);
                      setValue("errorCodesList", codesToFilter());
                    }}
                    classes={{ root: classes.warningRoot }}
                  />
                }
                label="BCG Warnings"
              />
            )}
          />

          <Controller
            control={controlFilter}
            name="systemErrors"
            defaultValue={false}
            render={({ onChange, value }) => (
              <FormControlLabel
                classes={{
                  label: classes.systemLabel,
                }}
                control={
                  <Checkbox
                    checked={value}
                    onChange={(e) => {
                      onChange(e.target.checked);
                      setValue("errorCodesList", codesToFilter());
                    }}
                    classes={{ root: classes.systemRoot }}
                  />
                }
                label="System Errors"
              />
            )}
          />
        </div>
        <div className="error-code-search">
          <p>Search by Error Codes</p>
          <Controller
            control={controlFilter}
            name="errorCodesList"
            defaultValue={[]}
            render={({ onChange, value }) => (
              <MultiSelect
                id="error-code"
                onChange={onChange}
                value={value}
                listOptions={CODE_LIST_FOR_FILTER}
              />
            )}
          />
        </div>
        <div className="footer">
          <button
            type="button"
            onClick={() => {
              setValue("errorCodesList", []);
              setValue("bcgErrors", false);
              setValue("bcgWarnings", false);
              setValue("systemErrors", false);
            }}
            className="clear"
          >
            Clear all
          </button>
          <button type="submit" className="search">
            Search
          </button>
        </div>
      </form>
    </div>
  );
}

FilterForm.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  closePopup: PropTypes.func.isRequired,
  controlFilter: PropTypes.instanceOf(Object).isRequired,
  handleFilterSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
  setValue: PropTypes.func.isRequired,
  codesToFilter: PropTypes.func.isRequired,
};

function ErrorAnalysis({ dateDetails }) {
  const classes = useStyles();
  const [mainLoader, setMainLoader] = useState(false);
  const [data, setData] = useState();
  const [labels, setLabels] = useState([]);

  const [isPopupOpen, setPopupOpen] = useState(false);

  const {
    handleSubmit: handleFilterSubmit,
    control: controlFilter,
    setValue,
    getValues,
  } = useForm();

  const codesToFilter = () => {
    let array = [];
    if (getValues().bcgErrors) {
      array = [...array, ...BCG_ERRORS];
    }
    if (getValues().bcgWarnings) {
      array = [...array, ...BCG_WARNINGS];
    }
    if (getValues().systemErrors) {
      array = [...array, ...SYSTEM_GENERATED_CODES];
    }
    return array;
  };

  const [result, setResult] = useState({});

  const openPopup = () => {
    setPopupOpen(true);
  };
  const closePopup = () => {
    setPopupOpen(false);
  };

  const [dataSets, setDataSets] = useState([]);

  function organizeGraphData(errorCodes) {
    const newLabels = [];
    const colors = [];
    const descriptions = [];
    const graphPlot = Object.entries(errorCodes).map(([key, val]) => {
      newLabels.push(key);
      const err = CODE_LIST_FOR_FILTER.filter((x) => x.value === key);
      descriptions.push(err[0].title);
      if (BCG_WARNINGS.includes(key)) {
        colors.push("#B97434");
      } else if (SYSTEM_GENERATED_CODES.includes(key)) {
        colors.push("#FF7A00");
      } else if (BCG_ERRORS.includes(key)) {
        colors.push("#BD0303");
      }
      return val;
    });
    setLabels(newLabels);
    setDataSets([
      {
        label: "# of Errors",
        data: graphPlot || [],
        backgroundColor: colors,
        maxBarThickness: 40,
        descriptions,
      },
    ]);
  }

  const xAxisLabels = labels || [];

  function getErrorAnalysis() {
    setMainLoader(true);
    const filterText = [];
    const fromDate = moment(dateDetails.fromDate).format("YYYY-MM-DD");
    const toDate = moment(dateDetails.toDate).format("YYYY-MM-DD");
    if (dateDetails.durationType) filterText.push(`duration_type=${"daily"}`);
    if (dateDetails.fromDate) filterText.push(`start_duration=${fromDate}`);
    if (dateDetails.toDate) filterText.push(`end_duration=${toDate}`);
    reportServices.getErrorAnalysis(filterText).then((resp) => {
      setData(resp);
      setResult({
        unitPrices: {
          files: resp?.unit_price?.files,
          products: resp?.unit_price?.products,
          errors: resp?.unit_price?.errors,
        },
        holdings: {
          files: resp?.holdings?.files,
          products: resp?.holdings?.products,
          errors: resp?.holdings?.errors,
        },
        fundSize: {
          files: resp?.fund_size?.files,
          products: resp?.fund_size?.products,
          errors: resp?.fund_size?.errors,
        },
        assetAllocation: {
          files: resp?.asset_allocation?.files,
          products: resp?.asset_allocation?.products,
          errors: resp?.asset_allocation?.errors,
        },
        others: {
          files: resp?.others?.files,
          products: resp?.others?.products,
          errors: resp?.others?.errors,
        },
        total: {
          files: resp?.total?.files,
          products: resp?.total?.products,
          bcgErrors: resp?.total?.errors,
          bcgRepublished: 0,
          iaErrors: 0,
        },
      });
      organizeGraphData(resp.error_codes);
      setMainLoader(false);
    });
  }

  useEffect(() => {
    if (dateDetails) getErrorAnalysis();
  }, [dateDetails]);

  const onSubmit = (filterData) => {
    const filterArray = filterData.errorCodesList;
    const graphData = {};
    filterArray.forEach((code) => {
      graphData[code] = data.error_codes[code];
    });
    if (filterArray.length < 1) {
      organizeGraphData(data.error_codes);
    } else organizeGraphData(graphData);
  };

  return (
    <>
      <FileAnalysisBanner
        heading="Error Analysis"
        subHeading="Errors per File Type"
        isLoading={mainLoader}
        result={result}
      />
      <div className="error-analysis-filter">
        <div />
        <div className="filter-button-area">
          <button type="button" className="filter-btn" onClick={openPopup}>
            <img src="/assets/filter.svg" alt="filter" />
            Filter
            <svg
              style={{ ...(isPopupOpen && { transform: "scaleY(-1)" }) }}
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4H12.796C12.9882 3.99984 13.1765 4.05509 13.3381 4.15914C13.4998 4.26319 13.628 4.41164 13.7074 4.58669C13.7869 4.76175 13.8142 4.956 13.7861 5.14618C13.758 5.33636 13.6757 5.51441 13.549 5.659L8.753 11.139C8.65913 11.2464 8.54338 11.3325 8.41351 11.3915C8.28363 11.4505 8.14264 11.481 8 11.481C7.85735 11.481 7.71636 11.4505 7.58649 11.3915C7.45661 11.3325 7.34086 11.2464 7.247 11.139V11.14Z"
                fill="#79828B"
              />
            </svg>
          </button>
          <FilterForm
            classes={classes}
            controlFilter={controlFilter}
            closePopup={closePopup}
            codesToFilter={codesToFilter}
            handleFilterSubmit={handleFilterSubmit}
            isPopupOpen={isPopupOpen}
            onSubmit={onSubmit}
            setValue={setValue}
          />
        </div>
      </div>

      <br />
      {mainLoader ? (
        <Skeleton width="100%" height={360} />
      ) : (
        <BarGraph dataSets={dataSets} xAxisLabels={xAxisLabels} height={90} />
      )}
      <div
        style={{
          paddingLeft: 30,
          paddingRight: 30,
          marginTop: 15,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div className={classes.graphLegend}>
          <div
            style={{
              width: 10,
              height: 10,
              borderRadius: 5,
              backgroundColor: "#BD0303",
            }}
          />
          <p
            className={classes.graphLegendText}
            style={{ marginLeft: 5, fontSize: 12, color: "##9C9C9C" }}
          >
            BCG Errors
          </p>
        </div>
        <div className={classes.graphLegend}>
          <div
            style={{
              width: 10,
              height: 10,
              borderRadius: 5,
              backgroundColor: "#B97434",
            }}
          />
          <p
            className={classes.graphLegendText}
            style={{ marginLeft: 5, fontSize: 12, color: "##9C9C9C" }}
          >
            BCG Warnings
          </p>
        </div>
        <div className={classes.graphLegend}>
          <div
            style={{
              width: 10,
              height: 10,
              borderRadius: 5,
              backgroundColor: "#FF7A00",
            }}
          />
          <p
            className={classes.graphLegendText}
            style={{ marginLeft: 5, fontSize: 12, color: "##9C9C9C" }}
          >
            System Errors
          </p>
        </div>
      </div>
    </>
  );
}

export default ErrorAnalysis;

ErrorAnalysis.propTypes = {
  dateDetails: PropTypes.instanceOf(Object).isRequired,
};
