import React, { useContext, useState, useEffect } from "react";
import { NavLink, useLocation, withRouter } from "react-router-dom";
import cn from "classnames";
import { Tooltip } from "@material-ui/core";
import styles from "./index.module.scss";
import appServices from "../../api/appServices";
import AuthContext from "../../context";
import RoleContext from "../../context/roleContext";
import helper from "../../utils/helper";
import RetainHoursPopup from "../RetainHoursPopup";
import ChangeLogPoup from "../ChangeLogPopup";

export default withRouter(({ history }) => {
  const { deleteAccessToken, iconSidebar, setIconSidebar } = useContext(
    AuthContext
  );
  const { userInfo } = useContext(RoleContext);
  const { userRoles, fullName } = userInfo;
  const { roles, appVersion } = helper;

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [retainHourPopup, setRetainHourPopup] = useState(false);
  const [changeLogPopup, setChangeLogPopup] = useState(false);
  const [fullSidebar, setFullSidebar] = useState(false);
  const [versionName, setVersionName] = useState("");

  const location = useLocation();
  const currentLocation = location.pathname;
  let isActive = false;
  if (location.pathname.includes("/dashboard")) {
    isActive = true;
  }

  const logOutHandler = () => {
    appServices
      .logOutUser()
      .then(() => {
        deleteAccessToken();
        window.location.reload();
      })
      .catch(() => {});
  };

  const gotoDashBoard = () => {
    history.push("/dashboard");
  };

  const gotoFAQ = () => {
    window.open(process.env.REACT_APP_FAQ_LINK, "_blank");
  };

  const openRetainHourPopup = () => {
    setRetainHourPopup(true);
  };

  const closeRetainHourPopup = () => {
    setRetainHourPopup(false);
  };

  const openChangeLogPopup = () => {
    setChangeLogPopup(true);
  };

  const closeChangeLogPopup = () => {
    setChangeLogPopup(false);
  };

  const getVersionName = async () => {
    try {
      const version = await appVersion();
      setVersionName(version);
    } catch (e) {
      // e
    }
  };

  const fullSideBarHandler = (value) => {
    if (!iconSidebar && !isDropdownOpen) {
      setFullSidebar(value);
    }
  };

  useEffect(() => {
    getVersionName();
  }, []);

  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <header
      onMouseOver={() => fullSideBarHandler(true)}
      onMouseLeave={() => {
        fullSideBarHandler(false);
      }}
      className={cn({
        [styles.header]: true,
        [styles.fullWidth]: fullSidebar,
        [styles.smallWidth]: !fullSidebar,
      })}
    >
      <div className={styles.headerContent}>
        <div
          className={cn({
            [styles.headerIconContainer]: !fullSidebar,
          })}
        >
          <img
            className={styles.headerIcon}
            alt="logo"
            src="/assets/Logo.svg"
          />
        </div>
        <div className={styles.navLinks}>
          <div
            className={cn({
              [styles.navLinksItems]: true,
              [styles.navLinksItemsCenter]: !fullSidebar,
            })}
          >
            <NavLink
              className={`${cn({
                [styles.link]: true,
                [styles.linkWithIcon]: !fullSidebar,
              })} ${isActive ? styles.activeLink : ""}`}
              to="/dashboard/reports"
            >
              <Tooltip title={fullSidebar ? "" : "Overview"}>
                <img
                  alt="overview"
                  src={
                    isActive
                      ? "/assets/overview-select.svg"
                      : "/assets/overview.svg"
                  }
                  className={styles.iconImg}
                />
              </Tooltip>
              {fullSidebar && "Overview"}
            </NavLink>
            {userRoles.includes(roles.SUPER_ADMIN) && (
              <>
                <NavLink
                  className={`${cn({
                    [styles.link]: true,
                    [styles.linkWithIcon]: !fullSidebar,
                  })} ${
                    currentLocation.includes("/management/user")
                      ? styles.activeLink
                      : ""
                  }`}
                  to="/management/user"
                >
                  <Tooltip title={fullSidebar ? "" : "User Management"}>
                    <img
                      alt="user-management"
                      src={
                        currentLocation.includes("/management/user")
                          ? "/assets/user-mng-select.svg"
                          : "/assets/user-mng.svg"
                      }
                      className={styles.iconImg}
                    />
                  </Tooltip>
                  {fullSidebar && "User Management"}
                </NavLink>
                <NavLink
                  className={`${cn({
                    [styles.link]: true,
                    [styles.linkWithIcon]: !fullSidebar,
                  })} ${
                    currentLocation.includes("/management/sender")
                      ? styles.activeLink
                      : ""
                  }`}
                  to="/management/sender"
                >
                  <Tooltip title={fullSidebar ? "" : "Sender Management"}>
                    <img
                      alt="sender-management"
                      src={
                        currentLocation.includes("/management/sender")
                          ? "/assets/sender-mng-select.svg"
                          : "/assets/sender-mng.svg"
                      }
                      className={styles.iconImg}
                    />
                  </Tooltip>
                  {fullSidebar && "Sender Management"}
                </NavLink>
                <NavLink
                  className={`${cn({
                    [styles.link]: true,
                    [styles.linkWithIcon]: !fullSidebar,
                  })} ${
                    currentLocation.includes("/management/product")
                      ? styles.activeLink
                      : ""
                  }`}
                  to="/management/product"
                >
                  <Tooltip title={fullSidebar ? "" : "Product Management"}>
                    <img
                      alt="product-management"
                      src={
                        currentLocation.includes("/management/product")
                          ? "/assets/product-mng-select.svg"
                          : "/assets/product-mng.svg"
                      }
                      className={styles.iconImg}
                    />
                  </Tooltip>
                  {fullSidebar && "Product Management"}
                </NavLink>
              </>
            )}

            {userRoles.includes(roles.SUPER_ADMIN) && (
              <NavLink
                activeClassName={styles.activeLink}
                className={cn({
                  [styles.link]: true,
                  [styles.linkWithIcon]: !fullSidebar,
                })}
                to="/hot-queue"
              >
                <Tooltip title={fullSidebar ? "" : "Hot Queue"}>
                  <img
                    alt="hotqueue"
                    src={
                      currentLocation === "/hot-queue"
                        ? "/assets/hotqueue-select.svg"
                        : "/assets/hotqueue.svg"
                    }
                    className={styles.iconImg}
                  />
                </Tooltip>
                {fullSidebar && "Hot Queue"}
              </NavLink>
            )}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            cursor: "pointer",
          }}
          role="button"
          tabIndex="0"
          onClick={() => {
            setDropdownOpen(!isDropdownOpen);
          }}
          onKeyPress={() => {
            setDropdownOpen(!isDropdownOpen);
          }}
        >
          <div className={styles.profileContainer}>
            <img alt="profile" src="/assets/profile-icon.svg" />
            {fullSidebar && (
              <div className={styles.profileName}>{fullName}</div>
            )}

            <div
              role="button"
              tabIndex="0"
              className={styles.dropdownContainer}
              onClick={() => {
                setDropdownOpen(!isDropdownOpen);
              }}
              onKeyPress={() => {
                setDropdownOpen(!isDropdownOpen);
              }}
            >
              {fullSidebar && (
                <img
                  alt="arrow"
                  src={
                    isDropdownOpen
                      ? "/assets/uparrow.svg"
                      : "/assets/downarrow.svg"
                  }
                  className={styles.dropdownIcon}
                />
              )}
              {isDropdownOpen && (
                <>
                  <div
                    aria-label="closeDropdown"
                    role="button"
                    tabIndex="0"
                    className={styles.scrim}
                    onClick={() => {
                      setDropdownOpen(false);
                    }}
                    onKeyPress={() => {
                      setDropdownOpen(false);
                    }}
                  />
                  <div
                    className={cn({
                      [styles.dropdown]: true,
                      [styles.fullDropdown]: fullSidebar,
                      [styles.smallDropdown]: !fullSidebar,
                    })}
                  >
                    <div className={styles.switchContainer}>
                      <div
                        className={cn({
                          [styles.switch]: true,
                          [styles.switchActive]: iconSidebar,
                        })}
                        role="button"
                        tabIndex="0"
                        onClick={() => {
                          setIconSidebar(true);
                          setFullSidebar(false);
                        }}
                        onKeyPress={() => {
                          setIconSidebar(true);
                          setFullSidebar(false);
                        }}
                      >
                        Icon View
                      </div>
                      <div
                        className={cn({
                          [styles.switch]: true,
                          [styles.switchActive]: !iconSidebar,
                        })}
                        role="button"
                        tabIndex="0"
                        onClick={() => {
                          setIconSidebar(false);
                        }}
                        onKeyPress={() => {
                          setIconSidebar(false);
                        }}
                      >
                        Full View
                      </div>
                    </div>
                    <button
                      type="button"
                      className={styles.logoutButton}
                      onClick={gotoDashBoard}
                    >
                      <img
                        alt="user-dashboard"
                        src="/assets/admin-user-dash.svg"
                        className={styles.iconImg}
                      />
                      User Dashboard
                    </button>
                    <button
                      type="button"
                      className={styles.logoutButton}
                      onClick={gotoFAQ}
                    >
                      <img
                        alt="faq"
                        src="/assets/faq.svg"
                        className={styles.iconImg}
                      />
                      FAQ
                    </button>
                    <button
                      aria-label="retain-hours-btn"
                      type="button"
                      className={styles.logoutButton}
                      onClick={openRetainHourPopup}
                    >
                      <img
                        alt="retain-hour"
                        src="/assets/retain-hour.svg"
                        className={styles.iconImg}
                      />
                      Retain Hours
                    </button>

                    <button
                      aria-label="change-log-btn"
                      type="button"
                      className={styles.logoutButton}
                      onClick={openChangeLogPopup}
                    >
                      <img
                        alt="change-log"
                        src="/assets/change-log.svg"
                        className={styles.iconImg}
                      />
                      Change Log
                    </button>
                    <button
                      type="button"
                      className={styles.logoutButton}
                      onClick={logOutHandler}
                    >
                      <img
                        alt="logout"
                        src="/assets/logout.svg"
                        className={styles.iconImg}
                      />
                      Log Out
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
          {fullSidebar && versionName && (
            <div className={styles.versionContainer}>
              <p className={styles.versionNumber}>V{versionName}</p>
            </div>
          )}
        </div>
      </div>
      {retainHourPopup && (
        <RetainHoursPopup closePopup={closeRetainHourPopup} />
      )}
      {changeLogPopup && <ChangeLogPoup closePopup={closeChangeLogPopup} />}
    </header>
  );
});
