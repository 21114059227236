import React, { useEffect, useState } from "react";
import { cloneDeep, findIndex, startCase } from "lodash";
import { DatePicker, Radio } from "antd";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import PropTypes from "prop-types";
import "antd/dist/antd.css";

import MainHeader from "../../../components/MainHeader";
import ErrorStats from "../../../components/ErrorStats";
import LineChart from "../../../components/LineChart";
import BoxSkeleton from "../../../components/Skeletons/BoxSkeleton";
import CardSkeleton from "../../../components/Skeletons/CardSkeleton";
import SidebarList from "../../../components/SidebarList";
import TabHeader from "../TabHeader";
import clientServices from "../../../api/clientServices";
import reportServices from "../../../api/reportServices";
import helper from "../../../utils/helper";
import "./style.scss";
import MainLayout from "../../../components/MainLayout";

const { RangePicker } = DatePicker;
const { dateOptionsList, timeSince, getProfilePic, disabledDate } = helper;

function RecentActivitySection({ mainLoader, clientReports }) {
  if (mainLoader) {
    return <BoxSkeleton />;
  }
  if (clientReports?.recent_activity?.length) {
    return clientReports.recent_activity.map((item) => (
      <div className="recent-activity-single" key={item}>
        <div className="recent-activity-single___img-box">
          {/* <img src="/assets/code.png" alt="code" /> */}
          <img src={getProfilePic(item.photo)} alt="profile-pic" />
        </div>
        <div className="recent-activity-single___data-box">
          <div className="recent-activity-single___top-data">
            <h1>{startCase(item.full_name) || item?.email}</h1>
            <span>{timeSince(item.created_at)}</span>
          </div>
          <p>{item.log}</p>
        </div>
      </div>
    ));
  }
  return null;
}

RecentActivitySection.propTypes = {
  mainLoader: PropTypes.bool.isRequired,
  clientReports: PropTypes.instanceOf(Array).isRequired,
};

function ClientReportTab() {
  const [sideLoader, setSideLoader] = useState(false);
  const [mainLoader, setMainLoader] = useState(false);

  const [dateDetails, setDateDetails] = useState({
    durationType: "daily", // monthly or weekly or daily,
    fromDate: moment().startOf("month"),
    toDate: moment(),
  });
  // for setting date offset
  const [hackValue, setHackValue] = useState();
  const [dates, setDates] = useState([]);

  const [filter, setFilter] = useState({
    currentPage: 1,
    searchKey: "",
  });
  const [navigation, setNavigation] = useState({
    itemCount: undefined,
    totalCount: undefined,
    nextPageNum: undefined,
    prevPageNum: undefined,
    hasNextPage: undefined,
    hasPrevPage: undefined,
    dataPerPage: 25,
  });

  const [clientList, setClientList] = useState([]);
  const [clientReports, setClientReports] = useState({});
  const [client, setClient] = useState({});

  const getClientReports = () => {
    setMainLoader(true);
    const filterText = [];

    const fromDate = moment(dateDetails.fromDate).format("YYYY-MM-DD");
    const toDate = moment(dateDetails.toDate).format("YYYY-MM-DD");

    if (client.id) filterText.push(`id=${client.id}`);
    if (dateDetails.durationType)
      filterText.push(`duration_type=${dateDetails.durationType}`);
    if (dateDetails.fromDate) filterText.push(`start_duration=${fromDate}`);
    if (dateDetails.toDate) filterText.push(`end_duration=${toDate}`);

    reportServices.getSenderReports(filterText).then((resp) => {
      setClientReports(resp);
      setMainLoader(false);
    });
  };

  const getClientListDetails = () => {
    const filterText = [];
    if (filter.currentPage) filterText.push(`page=${filter.currentPage}`);
    if (filter.searchKey.length !== 0) filterText.push(`q=${filter.searchKey}`);
    filterText.push(`page_size=${25}`);
    clientServices.getSenderListing(filterText).then((resp) => {
      const navigationCopy = cloneDeep(navigation);
      navigationCopy.itemCount = resp.results.length;
      navigationCopy.totalCount = resp.total_count;
      navigationCopy.nextPageNum = resp.next_page_num;
      navigationCopy.prevPageNum = resp.prev_page_num;
      navigationCopy.hasNextPage = resp.has_next_page;
      navigationCopy.hasPrevPage = resp.has_prev_page;
      navigationCopy.dataPerPage = resp.data_per_page;
      setNavigation(navigationCopy);
      const newResp = resp;
      // preselecting the first client
      if (newResp.results.length !== 0) {
        newResp.results[0].isActive = true;
        setClient(newResp.results[0]);
      } else {
        setMainLoader(false);
      }
      setClientList(newResp.results);
      setSideLoader(false);
    });
  };

  // component did update for client
  useEffect(() => {
    if (client.id) {
      getClientReports();
    }
  }, [client, dateDetails]);

  // component did mount and did update for filter
  useEffect(() => {
    let autoSaveTimer;
    setSideLoader(true);
    setMainLoader(true);
    autoSaveTimer = setTimeout(() => getClientListDetails(), 1000);
    return () => {
      // cancel the request when component un mounts
      clearInterval(autoSaveTimer);
      autoSaveTimer = null;
    };
  }, [filter]);

  const handleRadioButton = (e) => {
    const dateDetailsCopy = cloneDeep(dateDetails);
    dateDetailsCopy.durationType = e.target.value;
    // setting default values
    if (e.target.value === "monthly")
      dateDetailsCopy.fromDate = moment().startOf("year");
    else if (e.target.value === "weekly" || e.target.value === "daily")
      dateDetailsCopy.fromDate = moment().startOf("month");
    setDateDetails(dateDetailsCopy);
  };

  const handleUser = (item) => {
    setClient(item);
    const clientListCopy = cloneDeep(clientList);

    const oldIndex = findIndex(
      clientListCopy,
      (clientEl) => clientEl.isActive === true
    );
    const newIndex = findIndex(
      clientListCopy,
      (clientEl) => clientEl.id === item.id
    );

    clientListCopy[oldIndex].isActive = false;
    clientListCopy[newIndex].isActive = true;

    setClientList(clientListCopy);
  };

  const handleDate = (date) => {
    const dateDetailsCopy = cloneDeep(dateDetails);
    const [firstDate, secondDate] = date;
    dateDetailsCopy.fromDate = firstDate;
    dateDetailsCopy.toDate = secondDate;
    setDateDetails(dateDetailsCopy);
  };
  const searchHandler = (value) => {
    const filterCopy = cloneDeep(filter);
    filterCopy.searchKey = value;
    filterCopy.currentPage = 1;
    setFilter(filterCopy);
  };
  const downloadPDF = () => {
    window.print();
  };

  const onOpenChange = (open) => {
    if (open) {
      setHackValue([]);
      setDates([]);
    } else {
      setHackValue(undefined);
    }
  };

  return (
    <MainLayout>
      <MainHeader title="Overview" />
      <div className="tab-content-wrapper">
        <TabHeader onChange={searchHandler} value={filter.searchKey} />
        <div className="tab-content client-report-tab">
          <div className="client-reports-sidebar">
            <SidebarList
              loader={sideLoader}
              dataSet={clientList}
              title="Senders"
              handleUser={handleUser}
              filter={filter}
              setFilter={setFilter}
              navigation={navigation}
            />
            {/* <div className="recent-activity">
              <div className="recent-activity-header">
                <h1 className="box-header">RECENT ACTIVITY</h1>
              </div>
              <div className="recent-activity-list">
                <RecentActivitySection
                  mainLoader={mainLoader}
                  clientReports={clientReports}
                />
              </div>
            </div> */}
          </div>

          <div className="client-report-data-col">
            <div className="tab-box-wrapper  client-tab-container ">
              <div className="client-report-data-last-row">
                <div className="graph-wrapper">
                  <div className="graph-header">
                    <div className="graph-header-left">
                      <h1>
                        {`${startCase(dateDetails.durationType)} Statistics`}
                      </h1>
                      <Radio.Group
                        options={dateOptionsList}
                        onChange={handleRadioButton}
                        value={dateDetails.durationType}
                        buttonStyle="solid"
                        optionType="button"
                        className="graph-header-radio-btn"
                      />
                    </div>
                    <div className="graph-header-right">
                      <RangePicker
                        format="DD-MM-YYYY"
                        value={
                          hackValue || [
                            dateDetails.fromDate,
                            dateDetails.toDate,
                          ]
                        }
                        onChange={handleDate}
                        allowClear={false}
                        disabledDate={(current) =>
                          disabledDate(current, dates, dateDetails.durationType)
                        }
                        onCalendarChange={(val) => setDates(val)}
                        onOpenChange={onOpenChange}
                      />
                    </div>
                  </div>

                  {mainLoader ? (
                    <Skeleton width="100%" height={350} />
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        justifyContent: "center",
                        marginTop: 50,
                      }}
                    >
                      <LineChart
                        published={clientReports?.published}
                        resolved={clientReports?.resolved}
                        repairQueue={clientReports?.repair_queue}
                        xAxisLabels={clientReports?.label}
                      />
                    </div>
                  )}
                  <div className="graph-items-group">
                    <p className="graph-item-dot green-dot">Published</p>
                    <p className="graph-item-dot blue-dot">Resolved</p>
                    <p className="graph-item-dot red-dot">Repair Queue</p>
                  </div>
                  {/* </div> */}
                </div>
                <div className="client-report-data-top-row">
                  <div className="tab-box-wrapper overview-stats-count">
                    <p>Total Files sent</p>
                    <div className="inline-img">
                      <div className="green-round">
                        <img src="/assets/green-up.png" alt="blue=arrow" />
                      </div>
                      <h1>
                        {mainLoader ? (
                          <CardSkeleton />
                        ) : (
                          clientReports?.total_files_sent
                        )}
                      </h1>
                    </div>
                  </div>
                  <div className="tab-box-wrapper overview-stats-count">
                    <p>Total Published</p>
                    <div className="inline-img">
                      <div className="green-round">
                        <img src="/assets/green-up.png" alt="green-arrow" />
                      </div>
                      <h1>
                        {mainLoader ? (
                          <CardSkeleton />
                        ) : (
                          clientReports?.total_published
                        )}
                      </h1>
                    </div>
                  </div>
                  <div className="tab-box-wrapper overview-stats-count">
                    <p>Total Resolved</p>
                    <div className="inline-img">
                      <div className="blue-round">
                        <img src="/assets/blue-up.png" alt="green-arrow" />
                      </div>
                      <h1>
                        {mainLoader ? (
                          <CardSkeleton />
                        ) : (
                          clientReports?.total_resolved
                        )}
                      </h1>
                    </div>
                  </div>
                  <div className="tab-box-wrapper overview-stats-count">
                    <p>Total Repair Queue</p>
                    <div className="inline-img">
                      <div className="red-round">
                        <img src="/assets/red-up.png" alt="red-arrow" />
                      </div>
                      <h1>
                        {mainLoader ? (
                          <CardSkeleton />
                        ) : (
                          clientReports?.total_repair_queue
                        )}
                      </h1>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="area-graph-footer-action client-report-download"
                    onClick={downloadPDF}
                  >
                    <img src="/assets/download.svg" alt="download" />
                    <p>Download sender report</p>
                  </button>
                </div>
              </div>

              <div className="sender-report-footer">
                <div style={{ padding: 22 }}>
                  <div className="recent-activity-header">
                    <h1 className="box-header">Recent Activity</h1>
                  </div>
                  <div className="recent-activity-list">
                    <RecentActivitySection
                      mainLoader={mainLoader}
                      clientReports={clientReports}
                    />
                  </div>
                </div>
                <div className="client-report___error-stats">
                  <ErrorStats
                    errorStatus={clientReports?.error_stats}
                    showToolTipSide="left"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default ClientReportTab;
