import React from "react";
import Skeleton from "react-loading-skeleton";
import PropTypes from "prop-types";

import styles from "../../pages/dashboard/resources/index.module.scss";

function ExtensionTableSkeleton({ totalCount }) {
  const maximumNumberOfItems = 10;
  return (
    <div className={styles.mailExtensionLoading}>
      {Array.from({
        length:
          totalCount > maximumNumberOfItems ? maximumNumberOfItems : totalCount,
      })
        .map((_, index) => index)
        .map((item) => (
          <React.Fragment key={item}>
            <Skeleton className={styles.recordExtensionLoading} />
            <hr className={styles.recordSeparatorSkeleton} />
          </React.Fragment>
        ))}
    </div>
  );
}

export default ExtensionTableSkeleton;

ExtensionTableSkeleton.defaultProps = {
  totalCount: 3,
};

ExtensionTableSkeleton.propTypes = {
  totalCount: PropTypes.number,
};
