import axios from "../utils/axiosClient";
import endpoints from "./endpoints";

const {
  productList,
  addProduct,
  editProduct,
  clientIdList,
  productGraph,
  uploadFile,
  productStaticData,
  downloadTransactionLink,
  downlaodTMDLink,
  downloadProductsCreate,
  downloadProducts,
} = endpoints;

const getProductList = async (query) => {
  try {
    const response = await axios.get(`${productList}?${query.join("&")}`);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const downlaodProductList = async (query) => {
  try {
    const response = await axios.get(`${productList}?${query.join("&")}`, {
      responseType: "blob",
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const createDownlaodProductList = async (query) => {
  try {
    const response = await axios.post(downloadProductsCreate, {
      q: query,
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const checkDownlaodProductList = async (id) => {
  try {
    const response = await axios.post(downloadProducts, {
      task_id: id,
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const addNewProduct = async (payload) => {
  try {
    const response = await axios.post(addProduct, payload);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const editProductDetails = async (id, payload) => {
  try {
    const response = await axios.put(`${editProduct}${id}/`, payload);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const changeProductStatus = async (id, payload) => {
  try {
    const response = await axios.patch(`${editProduct}${id}/`, payload);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
const deleteProduct = async (id) => {
  try {
    const response = await axios.delete(`${editProduct}${id}/`);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
const getClientIdList = async () => {
  try {
    const response = await axios.get(clientIdList);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fileUpload = async (payload) => {
  try {
    const response = await axios.post(uploadFile, payload);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getUploadTemplate = async () => {
  try {
    const response = await axios.get(uploadFile);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
const getProductGraphDetails = async (payload) => {
  try {
    const response = await axios.get(
      `${productGraph}?product_identifier=${payload.id}&duration_type=${payload.type}&start_duration=${payload.start}&end_duration=${payload.end}`
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getProductStaticDetails = async (id) => {
  try {
    const response = await axios.get(`${productStaticData}${id}/`);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getTransactionLink = async (id) => {
  try {
    const response = await axios.get(
      `${downloadTransactionLink}?product_id=${id}`
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getTMDLink = async (id) => {
  try {
    const response = await axios.get(`${downlaodTMDLink}?product_id=${id}`);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export default {
  getProductList,
  addNewProduct,
  editProductDetails,
  getClientIdList,
  changeProductStatus,
  deleteProduct,
  getProductGraphDetails,
  fileUpload,
  getUploadTemplate,
  downlaodProductList,
  getProductStaticDetails,
  getTransactionLink,
  getTMDLink,
  createDownlaodProductList,
  checkDownlaodProductList,
};
