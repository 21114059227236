import React from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
import makeAnimated from "react-select/animated";
import { ReactComponent as FilterIcon } from "../../assets/filter-icon.svg";

const animatedComponents = makeAnimated();

function SearchBox({
  placeholder,
  loadOptions,
  onChange,
  value,
  onClickFilter,
  noOptionsMessage,
  filter,
}) {
  return (
    <div className="search-container">
      <div>
        <AsyncSelect
          // cacheOptions
          placeholder={placeholder}
          components={animatedComponents}
          loadOptions={loadOptions}
          onChange={onChange}
          value={value}
          noOptionsMessage={() => noOptionsMessage}
          isClearable
        />
      </div>
      {filter && (
        <div
          tabIndex="0"
          role="button"
          className="filter-btn"
          onClick={() => onClickFilter()}
          onKeyDown={() => onClickFilter()}
        >
          <FilterIcon />
        </div>
      )}
    </div>
  );
}
export default SearchBox;

SearchBox.defaultProps = {
  noOptionsMessage: "No Products Found",
  filter: false,
};
SearchBox.propTypes = {
  placeholder: PropTypes.string.isRequired,
  loadOptions: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  noOptionsMessage: PropTypes.string,
  filter: PropTypes.bool,
  onClickFilter: PropTypes.func.isRequired,
};
