import React from "react";
import "./style.scss";
import PropTypes from "prop-types";

import Pagination from "../Pagination";
import SideBarSkeleton from "../Skeletons/SideBarSkeleton";
import PaginationSkeleton from "../Skeletons/PaginationSkeleton";
import SideBarListSection from "../SideBarListSection";

function SidebarList({
  loader,
  dataSet,
  title,
  handleUser,
  filter,
  setFilter,
  navigation,
}) {
  return (
    <div className="sidebar-list">
      <div className="sidebar-header">
        <h1>{title}</h1>
        {loader ? (
          <PaginationSkeleton />
        ) : (
          <Pagination
            filter={filter}
            setFilter={setFilter}
            navigation={navigation}
          />
        )}
      </div>

      <div className="sidebar-list-wrapper">
        {loader ? (
          <SideBarSkeleton />
        ) : (
          <SideBarListSection
            dataSet={dataSet}
            handleUser={handleUser}
            title={title}
          />
        )}
      </div>
    </div>
  );
}

export default SidebarList;

SidebarList.defaultProps = {
  dataSet: [],
  handleUser: () => {},
};

SidebarList.propTypes = {
  loader: PropTypes.bool.isRequired,
  dataSet: PropTypes.instanceOf(Array),
  title: PropTypes.string.isRequired,
  handleUser: PropTypes.func,
  filter: PropTypes.instanceOf(Object).isRequired,
  setFilter: PropTypes.func.isRequired,
  navigation: PropTypes.instanceOf(Object).isRequired,
};
