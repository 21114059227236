import React, { useState } from "react";
import {
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@material-ui/core";
import cn from "classnames";
import { Controller, useForm } from "react-hook-form";
import { Dots } from "react-activity";
import PropTypes from "prop-types";

import helper from "../../../../../utils/helper";
import userServices from "../../../../../api/userServices";
import styles from "../../../resources/index.module.scss";
import showAlert from "../../../../../utils/showAlert";
import "react-activity/dist/Dots.css";

const { resolveComments } = helper;

function ResolveMailContainer({
  errorCode,
  referenceId,
  onResolved,
  mailRefId,
  isMail,
}) {
  const [resolving, setResolveLoading] = useState(false);

  const {
    register: registerResolve,
    handleSubmit: handleResolve,
    watch: watchResolve,
    control,
  } = useForm();

  const resolve = async (data) => {
    setResolveLoading(true);

    const formData = new FormData();

    const finalComment =
      data.selectComments === resolveComments.other
        ? data.comments
        : data.selectComments;
    if (data.reference_number && !isMail) {
      formData.append("reference_number", data.reference_number);
    }
    if (finalComment) formData.append("comments", finalComment);
    // file upload - currently not in use

    /// if condition to check interchange resolve need or not
    if (data.resolveFilter && data.resolveFilter !== "No Changes") {
      if (data.resolveFilter === "Reprocess") {
        formData.append("inter_change", false);
      } else {
        formData.append("inter_change", true);
      }
      userServices
        .resolveInterchangeAPI(formData, mailRefId)
        .then(() => {
          showAlert("Resolve request sent Successfully!", "success");
          setResolveLoading(false);
          onResolved(mailRefId, data.reference_number);
        })
        .catch((err) => {
          setResolveLoading(false);
          showAlert(err?.response?.data?.message, "error");
        });
    } else {
      userServices
        .resolveCSVFile(formData, mailRefId)
        .then(() => {
          showAlert("Resolve request sent Successfully!", "success");
          setResolveLoading(false);
          onResolved(mailRefId, data.reference_number);
        })
        .catch((err) => {
          setResolveLoading(false);
          showAlert(err?.response?.data?.message, "error");
        });
    }
  };
  return (
    <div>
      <form
        id="resolve-form"
        className={styles.form}
        onSubmit={handleResolve(resolve)}
      >
        <div>Choose comments from the below field to resolve</div>
        <br />
        <input
          data-testid="resolve-reference-number"
          name="reference_number"
          ref={registerResolve}
          style={{
            display: "none",
          }}
          value={referenceId}
          onChange={() => {}}
        />

        <Controller
          data-testid="resolve-select-comments"
          control={control}
          name="selectComments"
          defaultValue=""
          as={
            <Select id="select-comments" style={{ width: "100%" }}>
              {Object.entries(resolveComments).map((el) => (
                <MenuItem key={el[1]} value={el[1]}>
                  {el[1]}
                </MenuItem>
              ))}
            </Select>
          }
        />

        {watchResolve("selectComments") === "Other" && (
          <>
            <br />
            <br />
            <TextField
              required
              className={styles.captchaInput}
              multiline
              rowsMax={4}
              inputProps={{ ref: registerResolve }}
              name="comments"
              label="Comments"
            />
          </>
        )}
        <br />
        <br />
        {errorCode && (
          <Controller
            control={control}
            name="resolveFilter"
            defaultValue="No Changes"
            as={
              <RadioGroup aria-label="resolve-filter" id="resolveFilter">
                <FormControlLabel
                  value="No Changes"
                  control={<Radio size="small" color="primary" />}
                  label="Investigate further, do not publish"
                />
                <FormControlLabel
                  value="Reprocess"
                  control={<Radio size="small" color="primary" />}
                  label="Future date is correct, force publish"
                />
                <FormControlLabel
                  value="Correct date format and reprocess"
                  control={<Radio size="small" color="primary" />}
                  label="MM/DD format, allow IA to change to DD/MM and publish"
                />
              </RadioGroup>
            }
          />
        )}
      </form>
      <br />

      {resolving ? (
        <div
          className={cn({
            [styles.button]: true,
            [styles.darkGreen]: true,
            [styles.fullWidth]: true,
          })}
        >
          <Dots />
        </div>
      ) : (
        <input
          data-testid="resolve-btn"
          form="resolve-form"
          disabled={resolving}
          className={cn({
            [styles.button]: true,
            [styles.darkGreen]: true,
            [styles.fullWidth]: true,
          })}
          type="submit"
          value={isMail ? "Resolve All" : "Resolve"}
        />
      )}
    </div>
  );
}

export default ResolveMailContainer;

ResolveMailContainer.defaultProps = {
  errorCode: false,
  referenceId: null,
};

ResolveMailContainer.propTypes = {
  errorCode: PropTypes.bool,
  referenceId: PropTypes.number,
  onResolved: PropTypes.func.isRequired,
  mailRefId: PropTypes.number.isRequired,
  isMail: PropTypes.bool.isRequired,
};
