import React, { useState } from "react";
import { cloneDeep, findIndex, startCase } from "lodash";
import PropTypes from "prop-types";
import { Switch } from "antd";
import clientServices from "../../../api/clientServices";
import TableSkeleton from "../../../components/Skeletons/TableSkeleton";
import "./style.scss";
import showAlert from "../../../utils/showAlert";

function ClientManagementTable({
  loader,
  setLoader,
  tableData,
  setTableData,
  popUpData,
  setPopUpData,
  openHotqueuePopup,
  getClientListDetails,
  totalCount,
  ascendingName,
  descendingName,
}) {
  // ----------------- State Variables AlphabetOrder -----------------

  const [alphabeticalEmail, setAlphabeticalEmail] = useState(true);
  const [alphabeticalName, setAlphabeticalName] = useState(true);
  const [alphabeticalOrganization, setAlphabeticalOrganization] = useState(
    true
  );

  const editHandler = (item) => {
    const popUpDataCopy = cloneDeep(popUpData);
    popUpDataCopy.isShowPopUp = true;
    popUpDataCopy.isEditMode = true;
    popUpDataCopy.data = item;
    setPopUpData(popUpDataCopy);
  };

  const statusHandler = (item) => {
    const payload = {
      id: item.id,
      status: !item.status,
    };

    clientServices
      .updateSenderStatus(payload)
      .then(() => {
        showAlert("Sender status updated!", "success");
        const tableDataCopy = cloneDeep(tableData);
        const index = findIndex(tableDataCopy, (user) => user.id === item.id);
        tableDataCopy[index].status = !tableDataCopy[index].status;
        setTableData(tableDataCopy);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  // ----------------- Sort FIelds -----------------

  const sortName = (value) => {
    if (alphabeticalName) {
      ascendingName(value);
      setAlphabeticalName(false);
    } else {
      descendingName(value);
      setAlphabeticalName(true);
    }
  };

  const sortOrganization = (value) => {
    if (alphabeticalOrganization) {
      ascendingName(value);
      setAlphabeticalOrganization(false);
    } else {
      descendingName(value);
      setAlphabeticalOrganization(true);
    }
  };

  const sortEmail = (value) => {
    if (alphabeticalEmail) {
      ascendingName(value);
      setAlphabeticalEmail(false);
    } else {
      descendingName(value);
      setAlphabeticalEmail(true);
    }
  };

  const tableSkeletonSection = () => (
    <>
      {new Array(7).fill("").map((el, index) => (
        <TableSkeleton key={el} index={index} />
      ))}
    </>
  );

  const tableDataSection = () => (
    <div>
      {/* <InfiniteScroll
      dataLength={tableData.length}
      next={getClientListDetails}
      hasMore={totalCount !== tableData.length}
      loader={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ReactLoading type="bubbles" color="#1c57ad" />
        </div>
      }
      endMessage={
        totalCount > 10 ? (
          <p style={{ textAlign: "center", marginTop: 20 }}>
            <b>You have reached the end of the page.</b>
          </p>
        ) : null
      }
      scrollableTarget="infinte-scroll-table"
      style={{ overflow: "unset" }}
    > */}
      {tableData.map((item) => (
        <div className="table-row" key={item}>
          <div className="table-col profile-col">
            <div className="profile-pic">
              {item.sender_name
                ? item.sender_name
                    .split(" ")
                    .splice(0, 2)
                    .map((name) => name[0])
                    .join("")
                    .toUpperCase()
                : item.email
                    .split(" ")
                    .splice(0, 2)
                    .map((name) => name[0])
                    .join("")
                    .toUpperCase()}
            </div>
            <p data-testid="name">{startCase(item.sender_name)}</p>
          </div>
          <div className="table-col email-col">
            <p data-testid="email">{item.organization}</p>
          </div>
          <div className="table-col email-col">
            <p data-testid="email">{item.email}</p>
          </div>
          <div className="table-col action-col">
            <div className="table-action___single">
              <button
                type="button"
                onClick={() => editHandler(item)}
                data-testid="edit-btn"
              >
                {" "}
                <img src="/assets/edit.svg" alt="edit" />
                Edit
              </button>
            </div>
            <div className="table-action___single" style={{ display: "none" }}>
              <button type="button">
                {" "}
                <img src="/assets/download.svg" alt="download" />
                Logs
              </button>
            </div>
            <div className="table-action__double">
              {/* <div className="table-action___single">
                <button type="button" onClick={() => statusHandler(item)}>
                  {" "}
                  <img src="/assets/padlock.svg" alt="padlock" />
                  {item.active === true ? "Deactivate" : <>Activate</>}
                </button>
              </div> */}
              <div className="table-action___single">
                <button type="button" onClick={() => openHotqueuePopup(item)}>
                  {" "}
                  <img src="/assets/add.svg" alt="add" />
                  Hot Queue Rules
                </button>
              </div>
            </div>
          </div>
          <div className="table-col action-col">
            <Switch
              data-testid="status-switch"
              checked={item.status}
              onChange={() => statusHandler(item)}
            />
          </div>
        </div>
      ))}
      {/* if there are no records  */}
      {tableData.length === 0 && (
        <div className="table-row table-row-no-data">No Record Found!</div>
      )}
      {/* // </InfiniteScroll> */}
    </div>
  );

  return (
    <div className="table" data-testid="client-management-table">
      <div className="table-header">
        <p>
          Name{" "}
          <button type="button" onClick={() => sortName("sender_name")}>
            {" "}
            {alphabeticalName ? (
              <img alt="sort" src="/assets/bx_sort-z-a.svg" />
            ) : (
              <img alt="sort" src="/assets/bx_sort-a-z.svg" />
            )}
          </button>
        </p>
        <p>
          Organization{" "}
          <button
            type="button"
            onClick={() => sortOrganization("organization")}
          >
            {" "}
            {alphabeticalOrganization ? (
              <img alt="sort" src="/assets/bx_sort-z-a.svg" />
            ) : (
              <img alt="sort" src="/assets/bx_sort-a-z.svg" />
            )}
          </button>
        </p>
        <p>
          Email{" "}
          <button type="button" onClick={() => sortEmail("email")}>
            {" "}
            {alphabeticalEmail ? (
              <img alt="sort" src="/assets/bx_sort-z-a.svg" />
            ) : (
              <img alt="sort" src="/assets/bx_sort-a-z.svg" />
            )}
          </button>
        </p>

        <p>Actions</p>
        <p>Account Status</p>
      </div>
      <div className="table-body" id="infinte-scroll-table">
        {loader ? tableSkeletonSection() : tableDataSection()}
      </div>
    </div>
  );
}

export default ClientManagementTable;

ClientManagementTable.propTypes = {
  loader: PropTypes.bool.isRequired,
  setLoader: PropTypes.func.isRequired,
  tableData: PropTypes.instanceOf(Array).isRequired,
  setTableData: PropTypes.func.isRequired,
  popUpData: PropTypes.instanceOf(Object).isRequired,
  setPopUpData: PropTypes.func.isRequired,
  openHotqueuePopup: PropTypes.func.isRequired,
  getClientListDetails: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  ascendingName: PropTypes.func.isRequired,
  descendingName: PropTypes.func.isRequired,
};
