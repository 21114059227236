import React from "react";
import PropTypes from "prop-types";

import styles from "../../../resources/index.module.scss";

function ScrollTopBtn({ scrollToTop }) {
  return (
    <div
      style={{
        position: "fixed",
        bottom: 36,
        right: 75,
        backgroundColor: "#1c57ad",
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 15,
        paddingRight: 15,
        borderRadius: 22,
      }}
    >
      <button
        type="button"
        className={styles.scrollToTop}
        style={{ color: "white" }}
        onClick={() => scrollToTop()}
      >
        Scroll to top
      </button>
    </div>
  );
}

export default ScrollTopBtn;

ScrollTopBtn.propTypes = { scrollToTop: PropTypes.func.isRequired };
