import React, { useEffect, useState } from "react";
import { Radio } from "antd";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { round } from "lodash";
import PropTypes from "prop-types";
import { Controller, useForm } from "react-hook-form";
import {
  Checkbox,
  FormControlLabel,
  makeStyles,
  Tooltip,
} from "@material-ui/core";

import globalStyles from "../../../../global.module.scss";
import {
  BCG_ERRORS,
  BCG_WARNINGS,
  ERROR_CODE_LIST,
  SYSTEM_GENERATED_CODES,
} from "../../../../utils/constantData";
import MultiSelect from "../../../../components/MultiSelect";
import userServices from "../../../../api/userServices";
import reportServices from "../../../../api/reportServices";
import BarGraph from "../../../../components/BarGraph";
import showAlert from "../../../../utils/showAlert";

const useStyles = makeStyles({
  errorLabel: {
    color: "#BD0303 !important",
    fontWeight: "bold",
    fontSize: "12px !important",
  },
  warningLabel: {
    color: "#B97434 !important",
    fontWeight: "bold",
    fontSize: "12px !important",
  },
  systemLabel: {
    color: "#FF7A00 !important",
    fontWeight: "bold",
    fontSize: "12px !important",
  },
  errorRoot: {
    color: "#BD0303 !important",
  },
  warningRoot: {
    color: "#B97434 !important",
  },
  systemRoot: {
    color: "#FF7A00 !important",
  },
  graphLegendText: {
    color: "#9c9c9c",
  },
  graphLegend: {
    marginLeft: 20,
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
  },
});

function LabelValue({
  mainLoader,
  style,
  isLeftAligned,
  value,
  isTotal,
  title,
  divider,
}) {
  let rootClassName;
  if (isLeftAligned) {
    if (divider) {
      rootClassName = "label-value";
    } else {
      rootClassName = "label-value-last";
    }
  } else if (divider) {
    rootClassName = "label-value-center-aligned";
  } else {
    rootClassName = "label-value-center-aligned-last";
  }
  return (
    <div style={style} className={rootClassName}>
      <div
        className={
          isLeftAligned === true ? "" : "label-value-center-aligned-inner-div"
        }
      >
        {mainLoader ? (
          <Skeleton width={50} height={24} />
        ) : (
          <p className="label-value-value">{value || 0}</p>
        )}
        <Tooltip title={title}>
          <p className="label-value-title ellipsis">{title}</p>
        </Tooltip>
      </div>
    </div>
  );
}

LabelValue.defaultProps = {
  isLeftAligned: false,
  value: 0,
  isTotal: false,
  divider: false,
  style: null,
};

LabelValue.propTypes = {
  mainLoader: PropTypes.bool.isRequired,
  style: PropTypes.instanceOf(Object),
  isLeftAligned: PropTypes.bool,
  value: PropTypes.number,
  isTotal: PropTypes.bool,
  title: PropTypes.string.isRequired,
  divider: PropTypes.bool,
};

function NumberOfEmails({
  emailsData,
  mainLoader,
  detailAnalysisData,
  showNA,
}) {
  const percentage =
    emailsData?.received === 0
      ? 0
      : ((emailsData?.no_actions_required || 0) / (emailsData?.received || 0)) *
        100;
  return (
    <div
      className="email-analysis-second-row-right-column"
      style={{ marginTop: 0 }}
    >
      <div className="email-analysis-headers">Email stats</div>
      <div className="email-analysis-container">
        <div style={{ display: "flex" }}>
          <div
            style={{
              // marginTop: 20,
              padding: 20,
              height: "100%",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            {mainLoader ? (
              <Skeleton width={180} height={180} />
            ) : (
              <CircularProgressbar
                value={emailsData?.no_actions_required || 0}
                maxValue={emailsData?.received || 0}
                strokeWidth={11}
                counterClockwise
                text={`${round(percentage)}%`}
                styles={buildStyles({
                  // Rotation of path and trail, in number of turns (0-1)
                  rotation: 0,

                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                  strokeLinecap: "round",

                  // Text size
                  textSize: "16px",

                  // How long animation takes to go from one percentage to another, in seconds
                  pathTransitionDuration: 0.5,

                  // Can specify path transition in more detail, or remove it entirely
                  // pathTransition: 'none',

                  // Colors
                  pathColor: "#BD0303", // `rgba(62, 152, 199, ${46 / 100})`,
                  textColor: "#374151",
                  trailColor: "##F3F4F6",
                  backgroundColor: "#3e98c7",
                })}
              />
            )}
          </div>
          <div className="email-analysis-count-grid">
            <LabelValue
              title="Received"
              value={emailsData?.received || 0}
              divider={false}
              mainLoader={mainLoader}
            />
            <LabelValue
              title="No Actions Required"
              value={emailsData?.no_actions_required || 0}
              divider={false}
              mainLoader={mainLoader}
            />
          </div>
        </div>

        <DetailAnalysis
          mainLoader={mainLoader}
          detailAnalysisData={detailAnalysisData}
        />

        <CumulativeDetailAnalysis
          mainLoader={mainLoader}
          detailAnalysisData={detailAnalysisData}
          showNA={showNA}
        />
      </div>
    </div>
  );
}

NumberOfEmails.propTypes = {
  emailsData: PropTypes.instanceOf(Object).isRequired,
  mainLoader: PropTypes.bool.isRequired,
  detailAnalysisData: PropTypes.instanceOf(Object).isRequired,

  showNA: PropTypes.bool.isRequired,
};

function GraphLegend({ classes }) {
  return (
    <div
      style={{
        paddingLeft: 30,
        paddingRight: 30,
        marginTop: 15,
        display: "flex",
        flexDirection: "row",
      }}
    >
      <div className={classes.graphLegend}>
        <div
          style={{
            width: 10,
            height: 10,
            borderRadius: 5,
            backgroundColor: "#1C57AD",
          }}
        />
        <p
          className={classes.graphLegendText}
          style={{ marginLeft: 5, fontSize: 12, color: "#9C9C9C" }}
        >
          Total Mails
        </p>
      </div>
      <div className={classes.graphLegend}>
        <div
          style={{
            width: 10,
            height: 10,
            borderRadius: 5,
            backgroundColor: "#BD0303",
          }}
        />
        <p
          className={classes.graphLegendText}
          style={{ marginLeft: 5, fontSize: 12, color: "#9C9C9C" }}
        >
          No Actions Required
        </p>
      </div>
    </div>
  );
}

GraphLegend.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

function FilterForm({
  isPopupOpen,
  closePopup,
  controlFilter,
  fundTagOptions,
  handleFilterSubmit,
  onSubmit,
  classes,
  setValue,
  codesToFilter,
}) {
  return (
    <div className="filter-btn-popup">
      <div
        role="presentation"
        onKeyPress={closePopup}
        style={{ ...(!isPopupOpen && { display: "none" }) }}
        onClick={closePopup}
        className={globalStyles.scrim}
      />
      <form
        style={{ display: isPopupOpen ? "" : "none" }}
        onSubmit={handleFilterSubmit(onSubmit)}
      >
        <div className="error-code-search">
          <p>Filter by Tags</p>
          <Controller
            control={controlFilter}
            name="fundTagsList"
            defaultValue={[]}
            render={({ onChange, value }) => (
              <MultiSelect
                id="fund-tag"
                onChange={onChange}
                value={value}
                listOptions={fundTagOptions}
              />
            )}
          />
        </div>

        <div className="error-code-search">
          <p>Filter by Error Codes</p>
          <Controller
            control={controlFilter}
            name="errorCodesList"
            defaultValue={[]}
            render={({ onChange, value }) => (
              <MultiSelect
                id="error-code"
                onChange={onChange}
                value={value}
                listOptions={ERROR_CODE_LIST}
              />
            )}
          />
        </div>

        <div className="filter-set" style={{ marginTop: 20 }}>
          <Controller
            control={controlFilter}
            name="bcgErrors"
            defaultValue={false}
            render={({ onChange, value }) => (
              <FormControlLabel
                classes={{
                  label: classes.errorLabel,
                }}
                control={
                  <Checkbox
                    checked={value}
                    onChange={(e) => {
                      onChange(e.target.checked);
                      setValue("errorCodesList", codesToFilter());
                    }}
                    classes={{ root: classes.errorRoot }}
                  />
                }
                label="BCG Errors"
              />
            )}
          />

          <Controller
            control={controlFilter}
            name="bcgWarnings"
            defaultValue={false}
            render={({ onChange, value }) => (
              <FormControlLabel
                classes={{
                  label: classes.warningLabel,
                }}
                control={
                  <Checkbox
                    checked={value}
                    onChange={(e) => {
                      onChange(e.target.checked);
                      setValue("errorCodesList", codesToFilter());
                    }}
                    classes={{ root: classes.warningRoot }}
                  />
                }
                label="BCG Warnings"
              />
            )}
          />

          <Controller
            control={controlFilter}
            name="systemErrors"
            defaultValue={false}
            render={({ onChange, value }) => (
              <FormControlLabel
                classes={{
                  label: classes.systemLabel,
                }}
                control={
                  <Checkbox
                    checked={value}
                    onChange={(e) => {
                      onChange(e.target.checked);
                      setValue("errorCodesList", codesToFilter());
                    }}
                    classes={{ root: classes.systemRoot }}
                  />
                }
                label="System Errors"
              />
            )}
          />
        </div>

        <div className="footer">
          <button
            type="button"
            onClick={() => {
              setValue("errorCodesList", []);
              setValue("fundTagsList", []);
              setValue("bcgErrors", false);
              setValue("bcgWarnings", false);
              setValue("systemErrors", false);
            }}
            className="clear"
          >
            Clear all
          </button>
          <button type="submit" className="search">
            Search
          </button>
        </div>
      </form>
    </div>
  );
}

FilterForm.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  closePopup: PropTypes.func.isRequired,
  controlFilter: PropTypes.instanceOf(Object).isRequired,
  fundTagOptions: PropTypes.instanceOf(Array).isRequired,
  handleFilterSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
  setValue: PropTypes.func.isRequired,
  codesToFilter: PropTypes.func.isRequired,
};

function DetailAnalysis({ mainLoader, detailAnalysisData }) {
  const unitPriceCount = detailAnalysisData?.unit_price_count || 0;
  const holdingsCount = detailAnalysisData?.holdings_count || 0;
  const fundSizeCount = detailAnalysisData?.fund_size_count || 0;
  const assetAllocationCount = detailAnalysisData?.asset_allocation_count || 0;
  const othersCount = detailAnalysisData?.others || 0;
  const totalCount = detailAnalysisData?.total_mail || 0;
  return (
    <div className="email-analysis-detail-container">
      <div className="email-analysis-detailed" style={{ marginTop: 16 }}>
        <LabelValue
          title="Total"
          value={totalCount}
          isLeftAligned={false}
          divider={false}
          isTotal
          mainLoader={mainLoader}
        />
        <div>
          <div className="divider-vertical" style={{ height: 50 }} />
        </div>
        <div className="email-analysis-detail-column">
          <LabelValue
            title="IMD02P001 & Non IMD Unit Prices"
            value={unitPriceCount}
            isLeftAligned
            divider={false}
            mainLoader={mainLoader}
          />
        </div>
        <div>
          <div className="divider-vertical" style={{ height: 50 }} />
        </div>
        <div className="email-analysis-detail-column">
          <LabelValue
            title="Holdings"
            value={holdingsCount}
            isLeftAligned
            divider={false}
            mainLoader={mainLoader}
          />
        </div>
      </div>
      <div className="email-analysis-detailed" style={{ marginTop: 16 }}>
        <div className="email-analysis-detail-column">
          <LabelValue
            title="IMD02A001 & MDU01G010 Fund Sizes"
            value={fundSizeCount}
            isLeftAligned
            divider={false}
            mainLoader={mainLoader}
          />
        </div>
        <div>
          <div className="divider-vertical" style={{ height: 50 }} />
        </div>
        <div className="email-analysis-detail-column">
          <LabelValue
            title="Asset Allocation"
            value={assetAllocationCount}
            isLeftAligned
            divider={false}
            mainLoader={mainLoader}
          />
        </div>
        <div>
          <div className="divider-vertical" style={{ height: 50 }} />
        </div>
        <div className="email-analysis-detail-column">
          <LabelValue
            title="Others"
            value={othersCount}
            isLeftAligned
            divider={false}
            mainLoader={mainLoader}
          />
        </div>
      </div>
    </div>
  );
}

DetailAnalysis.propTypes = {
  mainLoader: PropTypes.bool.isRequired,
  detailAnalysisData: PropTypes.instanceOf(Object).isRequired,
};

function CumulativeDetailAnalysis({ mainLoader, detailAnalysisData, showNA }) {
  const dataRender = (data) => {
    if (showNA) return "N/A";
    return data;
  };
  return mainLoader ? (
    <Skeleton width="100%" height={200} style={{ marginTop: 30 }} />
  ) : (
    <div className="cumulative-email-analysis-detail-container">
      <div className="count-card">
        <div className="red">
          Error <span>{dataRender(detailAnalysisData?.err_count)}</span>
        </div>
        <div className="green">
          Published
          <span>{dataRender(detailAnalysisData?.published_count)}</span>
        </div>
        <div className="orange">
          Hot Queue
          <span>{dataRender(detailAnalysisData?.hot_queue_count)}</span>
        </div>
        <div className="violet">
          Historical Price
          <span>{dataRender(detailAnalysisData?.historical_price_count)}</span>
        </div>
        <div className="red">
          Inactive{" "}
          <span>{dataRender(detailAnalysisData?.inactive_error_count)}</span>
        </div>
        <div className="red">
          Not In DB{" "}
          <span>{dataRender(detailAnalysisData?.invalid_error_count)}</span>
        </div>
        <div className="red">
          Not A Product
          <span>{dataRender(detailAnalysisData?.not_a_product_count)}</span>
        </div>
        <div className="red">
          Zip File
          <span>{dataRender(detailAnalysisData?.zipfile_error_count)}</span>
        </div>
        <div className="lightBlue">
          EWKA
          <span>{dataRender(detailAnalysisData?.known_attachment_count)}</span>
        </div>
        <div className="lightBlue">
          EWOA
          <span>{dataRender(detailAnalysisData?.no_attachment_count)}</span>
        </div>
        <div className="lightYellow">
          Quarantine
          <span>{dataRender(detailAnalysisData?.quarantined_count)}</span>
        </div>
      </div>
    </div>
  );
}

CumulativeDetailAnalysis.propTypes = {
  mainLoader: PropTypes.bool.isRequired,
  detailAnalysisData: PropTypes.instanceOf(Object).isRequired,
  showNA: PropTypes.bool.isRequired,
};

function EmailAnalysis({ dateDetails, selectHourWindow, title }) {
  const [mainLoader, setMainLoader] = useState(false);
  const [dataSets, setDataSets] = useState([]);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const { timeOption } = dateDetails;
  const { dateDifference } = dateDetails;
  const [emailsData, setEmailData] = useState({
    received: 0,
    actions_required: 0,
    no_actions_required: 0,
  });
  const [xAxisLabels, setXAxisLabels] = useState([]);
  const [fundTagOptions, setFundTagOptions] = useState([]);

  const [filterData, setFilterData] = useState({});

  const [detailAnalysisData, setDetailAnalysis] = useState({});

  const [showNA, setShowNA] = useState(false);

  const timeOptionsList = [
    { label: "Per Hr", value: 1, disabled: dateDifference > 1 },
    { label: "4 Hrs", value: 4, disabled: dateDifference > 4 },
    { label: "6 Hrs", value: 6, disabled: dateDifference > 6 },
    { label: "12 Hrs", value: 12, disabled: dateDifference > 12 },
  ];

  const {
    handleSubmit: handleFilterSubmit,
    control: controlFilter,
    setValue,
    getValues,
  } = useForm();

  const classes = useStyles();

  const codesToFilter = () => {
    let array = [];
    if (getValues().bcgErrors) {
      array = [...array, ...BCG_ERRORS];
    }
    if (getValues().bcgWarnings) {
      array = [...array, ...BCG_WARNINGS];
    }
    if (getValues().systemErrors) {
      array = [...array, ...SYSTEM_GENERATED_CODES];
    }
    return array;
  };

  const labelsCountData = (value, key, showDate) => {
    const label = [];
    const mailCount = [];
    let prevKey = 24;
    Object.entries(value).forEach(([innerKey, innerValue]) => {
      let date = key;
      if (Number(innerKey) === 24) {
        date = moment(date, "YYYY-MM-DD").add(1, "days").format("YYYY-MM-DD");
      }
      label.push(
        `${showDate ? date : ""} ${prevKey <= 12 ? prevKey : prevKey - 12}${
          prevKey < 12 || Number(prevKey) === 24 ? "am" : "pm"
        } - ${innerKey <= 12 ? innerKey : innerKey - 12}${
          innerKey < 12 || Number(innerKey) === 24 ? "am" : "pm"
        }`
      );

      prevKey = innerKey;
      mailCount.push(innerValue);
    });
    return { label, mailCount };
  };

  const labelsAndCount = (mailCountObject, showDate) => {
    const labels = [];
    const mailCountArray = [];
    Object.entries(mailCountObject).forEach(([key, value]) => {
      const { label, mailCount } = labelsCountData(value, key, showDate);
      labels.push(...label);
      mailCountArray.push(...mailCount);
    });
    return { labels, mailCountArray };
  };

  function organizeHourly(response, showDate) {
    const mailCountObject = response.total_mail_count;
    const resCountObject = response.res_possible_count;
    const noResCountObject = response.res_not_possible_count;

    const { labels, mailCountArray } = labelsAndCount(
      mailCountObject,
      showDate
    );
    const resCountArray = [];
    const noResCountArray = [];

    const totalMailData = [];
    const errorData = [];
    const noResData = [];
    const totalMailColors = [];
    const errorColors = [];
    const noResColors = [];
    let totalMail = 0;
    let totalError = 0;
    let totalNoRes = 0;

    Object.entries(resCountObject).forEach(([, value]) => {
      Object.entries(value).forEach(([, innerValue]) => {
        resCountArray.push(innerValue);
      });
    });
    Object.entries(noResCountObject).forEach(([, value]) => {
      Object.entries(value).forEach(([, innerValue]) => {
        noResCountArray.push(innerValue);
      });
    });

    const dataPoints = labels.length;
    for (let index = 0; index < dataPoints; index += 1) {
      const totalMailCount = mailCountArray[index];
      const resolutionPossibleCount = resCountArray[index];
      const noResCount = noResCountArray[index];
      totalMailData.push(totalMailCount);
      totalMailColors.push("#1C57AD");
      errorData.push(resolutionPossibleCount);
      noResData.push(noResCount);
      errorColors.push("#BD0303");
      noResColors.push("#BD0303");
      totalMail += totalMailCount;
      totalError += resolutionPossibleCount;
      totalNoRes += noResCount;
    }
    setEmailData({
      received: totalMail,
      actions_required: totalError,
      no_actions_required: totalNoRes,
    });
    const totalMailDataSet = {
      data: totalMailData,
      backgroundColor: totalMailColors,
    };
    const errorDataSet = {
      data: errorData,
      backgroundColor: errorColors,
    };
    const noResDataSet = {
      data: noResData,
      backgroundColor: noResColors,
    };
    setDetailAnalysis(response.detail_analysis_data);
    return { totalMailDataSet, errorDataSet, noResDataSet, labels };
  }

  function organizeDataSet(response) {
    const dataPoints = response.label.length;
    const totalMailData = [];
    const errorData = [];
    const noResData = [];
    const totalMailColors = [];
    const errorColors = [];
    const noResColors = [];
    let labels = response.label;
    if (dateDetails.durationType === "weekly") {
      labels = labels.map((item) => {
        const date = moment(item)?.format("YYYY-MM-DD (ddd)") || item;
        return date.toUpperCase();
      });
    }
    let totalMail = 0;
    let totalError = 0;
    let totalNoRes = 0;
    for (let index = 0; index < dataPoints; index += 1) {
      const totalMailCount = response.total_mail_count[index];
      const resolutionPossibleCount = response.res_possible_count[index];
      const noResCount = response.res_not_possible_count[index];
      totalMailData.push(totalMailCount);
      totalMailColors.push("#1C57AD");
      errorData.push(resolutionPossibleCount);
      noResData.push(noResCount);
      errorColors.push("#BD0303");
      noResColors.push("#BD0303");
      totalMail += totalMailCount;
      totalError += resolutionPossibleCount;
      totalNoRes += noResCount;
    }
    setEmailData({
      received: totalMail,
      actions_required: totalError,
      no_actions_required: totalNoRes,
    });
    const totalMailDataSet = {
      data: totalMailData,
      backgroundColor: totalMailColors,
    };
    const errorDataSet = {
      data: errorData,
      backgroundColor: errorColors,
    };
    const noResDataSet = {
      data: noResData,
      backgroundColor: noResColors,
    };
    setDetailAnalysis(response.detail_analysis_data);
    return { totalMailDataSet, errorDataSet, noResDataSet, labels };
  }

  useEffect(() => {
    userServices.getFundTags().then((resp) => {
      setFundTagOptions(resp.fund_tags);
    });
  }, []);

  const queryBuilder = () => {
    const filterText = [];
    const fromDate = moment(dateDetails.fromDate).format("YYYY-MM-DD");
    const toDate = moment(dateDetails.toDate).format("YYYY-MM-DD");
    if (dateDetails.durationType) filterText.push(`duration_type=${"daily"}`);
    if (dateDetails.fromDate) filterText.push(`start_duration=${fromDate}`);
    if (dateDetails.toDate) filterText.push(`end_duration=${toDate}`);

    if (dateDetails.durationType === "daily" && timeOption !== 24) {
      filterText.push(`hour_split=true`);
      filterText.push(`split_interval=${timeOption}`);
    }

    if (
      Array.isArray(filterData?.fundTagsList) &&
      filterData?.fundTagsList.length
    ) {
      filterData.fundTagsList.forEach((el) =>
        filterText.push(`fund_tag=${el}`)
      );
    }

    if (
      Array.isArray(filterData?.errorCodesList) &&
      filterData?.errorCodesList.length
    )
      filterData.errorCodesList.forEach((el) =>
        filterText.push(`error_code=${el}`)
      );

    return filterText;
  };

  const dataContitions = () => {
    const fromDate = moment(dateDetails.fromDate).format("YYYY-MM-DD");

    if (
      dateDetails.durationType === "daily" ||
      dateDetails.durationType === "weekly" ||
      dateDetails.durationType === "custom"
    ) {
      if (moment(fromDate).isBefore("2021-12-05", "day")) {
        showAlert("IA was not introduced yet. Data Unavailable", "warning");
      } else if (moment(fromDate).isBefore("2023-02-03", "day")) {
        showAlert(
          "Cumulative stats not available during this timeframe",
          "warning"
        );
      }
    } else {
      if (moment(fromDate).isSame("2023-02-01", "day")) {
        showAlert("Data might be inaccurate", "warning");
      }

      if (
        moment(fromDate).isSameOrBefore("2023-01-31", "day") &&
        moment(fromDate).isSameOrAfter("2021-12-01", "day")
      ) {
        showAlert(
          "Cumulative stats not available during this timeframe",
          "warning"
        );
      }

      if (moment(fromDate).isBefore("2021-12-01", "day")) {
        showAlert("IA was not introduced yet. Data Unavailable", "warning");
      }
    }
  };

  function getEmailAnalysis() {
    setShowNA(false);
    setMainLoader(true);
    const filterText = queryBuilder();
    const fromDate = moment(dateDetails.fromDate).format("YYYY-MM-DD");
    const toDate = moment(dateDetails.toDate).format("YYYY-MM-DD");

    dataContitions();

    reportServices.getCumulativeEmailAnalysis(filterText).then((response) => {
      setMainLoader(false);
      const { totalMailDataSet, noResDataSet, labels } =
        dateDetails.durationType === "daily" && timeOption !== 24
          ? organizeHourly(response, fromDate !== toDate)
          : organizeDataSet(response);
      setXAxisLabels(labels);
      setDataSets([
        {
          label: "# of Mails",
          ...totalMailDataSet,
        },
        {
          label: "# of No Action Required Emails",
          ...noResDataSet,
        },
      ]);
    });
  }

  useEffect(() => {
    if (dateDetails) getEmailAnalysis();
  }, [dateDetails, filterData]);

  const onSubmit = (newFilterData) => {
    setFilterData(newFilterData);
  };

  function setHourly(hour) {
    selectHourWindow(hour);
  }

  const handleRadioButton = (e) => {
    setHourly(e.target.value);
  };

  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  return (
    <div className="email-analysis-first-row">
      <div className="email-analysis-first-row-first-column">
        <div
          className="email-analysis-filter-header"
          style={{ marginBottom: 20 }}
        >
          <div className="email-analysis-headers">
            <img
              src="/assets/email-analysis.svg"
              style={{ marginRight: 5 }}
              alt="email-analysis"
            />
            {title}
          </div>
          {dateDetails.durationType === "daily" && (
            <Radio.Group
              options={timeOptionsList}
              onChange={handleRadioButton}
              value={timeOption}
              buttonStyle="solid"
              optionType="button"
              className="time-window-radio-btn"
            />
          )}
          <div className="email-analysis-filter">
            <div />
            <div className="filter-button-area">
              <button
                type="button"
                className="filter-btn"
                onClick={togglePopup}
              >
                <img src="/assets/filter.svg" alt="filter" />
                Filter
                <svg
                  style={{ ...(isPopupOpen && { transform: "scaleY(-1)" }) }}
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4H12.796C12.9882 3.99984 13.1765 4.05509 13.3381 4.15914C13.4998 4.26319 13.628 4.41164 13.7074 4.58669C13.7869 4.76175 13.8142 4.956 13.7861 5.14618C13.758 5.33636 13.6757 5.51441 13.549 5.659L8.753 11.139C8.65913 11.2464 8.54338 11.3325 8.41351 11.3915C8.28363 11.4505 8.14264 11.481 8 11.481C7.85735 11.481 7.71636 11.4505 7.58649 11.3915C7.45661 11.3325 7.34086 11.2464 7.247 11.139V11.14Z"
                    fill="#79828B"
                  />
                </svg>
              </button>
              <FilterForm
                classes={classes}
                controlFilter={controlFilter}
                closePopup={closePopup}
                codesToFilter={codesToFilter}
                fundTagOptions={fundTagOptions}
                handleFilterSubmit={handleFilterSubmit}
                isPopupOpen={isPopupOpen}
                onSubmit={onSubmit}
                setValue={setValue}
              />
            </div>
          </div>
        </div>

        {mainLoader ? (
          <Skeleton width={900} height={410} />
        ) : (
          <>
            <BarGraph
              dataSets={dataSets}
              xAxisLabels={xAxisLabels}
              height={120}
              type="email"
            />
            <GraphLegend classes={classes} />
          </>
        )}
      </div>
      <div className="email-analysis-first-row-second-column">
        <NumberOfEmails
          mainLoader={mainLoader}
          emailsData={emailsData}
          detailAnalysisData={detailAnalysisData}
          showNA={showNA}
        />
      </div>
    </div>
  );
}

export default EmailAnalysis;

EmailAnalysis.propTypes = {
  dateDetails: PropTypes.instanceOf(Object).isRequired,
  selectHourWindow: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};
