import React, { useContext, useEffect, useMemo, useState } from "react";
import { cloneDeep } from "lodash";
import PropTypes from "prop-types";

import appServices from "../../api/appServices";
import AuthContext from "../../context";
import RoleContext from "../../context/roleContext";

export default function RoleProvider({ children }) {
  const [userInfo, setUserInfo] = useState({
    email: "",
    firstName: "",
    lastName: "",
    fullName: "",
    userRoles: [],
    userName: "",
    userId: 0,
    gmailURL: null,
  });

  const { getAccessToken, isSignedIn } = useContext(AuthContext);

  useEffect(() => {
    const AccessToken = getAccessToken();
    if (AccessToken) {
      appServices.getUserInfo().then((resp) => {
        const userInfoCopy = cloneDeep(userInfo);
        userInfoCopy.email = resp.email;
        userInfoCopy.firstName = resp.first_name;
        userInfoCopy.lastName = resp.last_name;
        userInfoCopy.fullName = resp.full_name;
        userInfoCopy.userRoles = [resp.type_of_user];
        userInfoCopy.userName = resp.username;
        userInfoCopy.userId = resp.id;
        userInfoCopy.gmailURL = resp.gmail_url;
        setUserInfo(userInfoCopy);
      });
    }
  }, [isSignedIn]);

  const contextValue = useMemo(() => ({ userInfo, setUserInfo }), [userInfo]);
  return (
    <RoleContext.Provider value={contextValue}>{children}</RoleContext.Provider>
  );
}

RoleProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
