import React from "react";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import "./style.scss";
import { NavLink } from "react-router-dom";

function Index() {
  return (
    <div className="not-found">
      <div className="mainbox">
        <div className="err">4</div>
        <HelpOutlineOutlinedIcon className="far spin" />
        <div className="err2">4</div>
        <div className="msg">
          Maybe this page moved? Got deleted? Is hiding out in quarantine? Never
          existed in the first place?
          <p>
            Let&apos;s go <NavLink to="/">home</NavLink> and try from there.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Index;
