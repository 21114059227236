import React, { memo, useEffect, useState, useRef } from "react";

import { Select } from "antd";
import PropTypes from "prop-types";
import { cloneDeep } from "lodash";

import "./styles.scss";

import showAlert from "../../../../../utils/showAlert";
import userServices from "../../../../../api/userServices";
import Input from "../../../../../components/Input";

import UploadInput from "../../../../../components/UploadInput";
import clientServices from "../../../../../api/clientServices";

function ManualUploadModal({ closePopup }) {
  const [senderLoading, setSenderLoading] = useState(false);
  const [senderList, setSenderList] = useState([]);
  const [saving, setSaving] = useState(false);
  const [formDetails, setFormDetails] = useState({
    subject: "",
    sender_details: "",
    file: "",
  });
  const [senderSearch, setSenderSearch] = useState("");
  const selectRef = useRef();

  const loadSenderList = async () => {
    try {
      setSenderLoading(true);
      const res = await clientServices.getSenderListing([`q=${senderSearch}`]);
      const array = res.results.map((e) => ({
        label: e.email,
        value: e.id,
      }));
      setSenderList(array);
      setSenderLoading(false);
    } catch (e) {
      setSenderLoading(false);
    }
  };

  useEffect(() => {
    let autoSaveTimer;
    autoSaveTimer = setTimeout(() => loadSenderList(), 500);
    return () => {
      // cancel the request when component un mounts
      clearInterval(autoSaveTimer);
      autoSaveTimer = null;
    };
  }, [senderSearch]);

  const submitData = async () => {
    setSaving(true);

    const payload = new FormData();

    formDetails.file.forEach((f) => {
      payload.append("file", f);
    });
    payload.append("subject", formDetails.subject);
    payload.append("sender_details", formDetails.sender_details);
    try {
      await userServices.manualUploadFile(payload);
      closePopup();
      showAlert("File Uploaded successfully!", "success");
    } catch (error) {
      setSaving(false);
      showAlert(error?.response?.data?.message, "error");
    }
  };

  const submitHandler = async () => {
    if (!formDetails.sender_details && formDetails.sender_details === "")
      showAlert("Please select a sender!", "warning");
    else if (!formDetails.file && formDetails.file === "")
      showAlert("Please select a file!", "warning");
    else await submitData();
  };

  const onChangeHandler = (key) => (value) => {
    const formDetailsCopy = cloneDeep(formDetails);
    formDetailsCopy[key] = value;
    if (key === "sender_details") selectRef.current.blur();
    setFormDetails(formDetailsCopy);
  };

  const selectClear = () => {
    const formDetailsCopy = cloneDeep(formDetails);
    formDetailsCopy.sender_details = "";
    setFormDetails(formDetailsCopy);
  };

  const onSelectChange = (value) => {
    const formDetailsCopy = cloneDeep(formDetails);
    formDetailsCopy.sender_details = value.slice(-1).pop();
    setFormDetails(formDetailsCopy);
  };

  return (
    <div className="popup" data-testid="client-management-popup">
      <div className="popup-overlay" />
      <div className="popup-wrapper">
        <div className="popup-header">
          <h1>Manual Upload</h1>
        </div>
        <p className="upload-subheading">
          Upload file manually for the system process
        </p>

        <div className="popup-body">
          <div className="upload-popup-row">
            <div>
              <Input
                placeholder="Subject"
                onChange={onChangeHandler("subject")}
                value={formDetails.subject}
                maxLength={160}
                minLength={3}
              />
            </div>
          </div>

          <div className="upload-popup-row" id="select-box">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <div
              style={{
                color: "#b0bac9",
                fontSize: 11,
                marginBottom: 8,
                letterSpacing: 1.13,
              }}
            >
              SENDER
            </div>
            <Select
              ref={selectRef}
              data-testid="sender-select-box"
              placeholder="Please select"
              mode="multiple"
              onDeselect={selectClear}
              maxTagCount={1}
              maxTagTextLength={50}
              maxLength={5}
              loading={senderLoading}
              size="large"
              showArrow
              options={senderList}
              onSelect={onChangeHandler("sender_details")}
              onChange={onSelectChange}
              onSearch={(e) => setSenderSearch(e)}
              onBlur={() => setSenderSearch("")}
              value={
                formDetails.sender_details && formDetails.sender_details !== ""
                  ? formDetails.sender_details
                  : undefined
              }
              bordered={false}
              filterOption={false}
              getPopupContainer={() => document.getElementById("select-box")}
              style={{
                width: "100%",
                maxWidth: 860,
                height: 42,
                paddingRight: 10,
                background: "rgba(224, 231, 255, 0.2)",
                border: "1px solid #e0e7ff",
                borderRadius: 5,
                zIndex: 999999,
              }}
            />
          </div>

          <div className="upload-popup-row">
            <UploadInput onChange={onChangeHandler("file")} multi />
          </div>
        </div>

        <div className="popup-footer">
          <button type="button" className="cancel-btn" onClick={closePopup}>
            Cancel
          </button>
          <button
            type="button"
            onClick={submitHandler}
            disabled={saving}
            className="save-btn"
          >
            {saving ? "Uploading..." : " Upload File"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default memo(ManualUploadModal);

ManualUploadModal.propTypes = {
  closePopup: PropTypes.func.isRequired,
};
