import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import EmailIcon from "@material-ui/icons/Email";
import PropTypes from "prop-types";

import FormInput from "../../components/FormInput/Index";
import Layout from "../../components/Layout";
import appServices from "../../api/appServices";
import styles from "./index.module.scss";

function ResetSuccessSection() {
  return (
    <div className={styles.successSection}>
      <div className={styles.successImage}>
        <img src="/assets/tick.svg" alt="tick" />
      </div>
      <p className={styles.formDescription}>
        A Password reset link has been sent to you registered email address.
        Please follow the steps to reset your account password
      </p>
      <div className={styles.resetLink}>
        <Link to="/login">Back to Login Page →</Link>
      </div>
    </div>
  );
}

function ResetSection({ resetting, register, isInvalidCred }) {
  return (
    <>
      <h2 className={styles.formTitle}>Reset your password</h2>
      <p className={styles.formDescription}>
        If you can&apos;t remember your password, BC Gateways can send you a
        link to email address associated with your account
      </p>

      <FormInput
        disabled={resetting}
        icon={EmailIcon}
        label="E-Mail"
        placeholder="Email"
        name="email"
        required
        register={register}
        type="email"
      />

      {isInvalidCred.invalid && (
        <div className={styles.invalidCredentials}>{isInvalidCred.message}</div>
      )}

      <input
        disabled={resetting}
        className={styles.formSubmitButton}
        type="submit"
        value={resetting ? "Sending..." : "Send Password Reset Link"}
      />

      <div className={styles.resetLink}>
        <Link to="/login">Back to Login Page →</Link>
      </div>
    </>
  );
}

ResetSection.propTypes = {
  resetting: PropTypes.bool.isRequired,
  register: PropTypes.instanceOf(Object).isRequired,
  isInvalidCred: PropTypes.instanceOf(Object).isRequired,
};

function ForgotPassword() {
  const { register, handleSubmit } = useForm();
  const [isInvalidCred, setInvalidCred] = useState({
    invalid: false,
    message: "",
  });
  const [resetting, setResetting] = useState(false);
  const [mailSend, setMailSend] = useState(false);

  const resetPassword = (data) => {
    setResetting(true);
    setInvalidCred({
      invalid: false,
      message: "",
    });
    const payload = {
      ...data,
      redirect_url: `${window.location.origin}/reset-password`,
    };
    appServices
      .sendResetPasswordMail(payload)
      .then(() => {
        setResetting(false);
        setMailSend(true);
      })
      .catch((error) => {
        setResetting(false);
        setMailSend(false);
        setInvalidCred({
          invalid: true,
          message: error.response.data.email[0],
        });
      });
  };

  return (
    <Layout>
      <form className={styles.form} onSubmit={handleSubmit(resetPassword)}>
        {mailSend ? (
          <ResetSuccessSection />
        ) : (
          <ResetSection
            resetting={resetting}
            register={register}
            isInvalidCred={isInvalidCred}
          />
        )}
      </form>
    </Layout>
  );
}

export default ForgotPassword;
