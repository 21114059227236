import React, { useEffect } from "react";
import Chart from "chart.js";
import PropTypes from "prop-types";

Chart.defaults.global.defaultFontFamily = "'Montserrat', sans-serif";
Chart.defaults.global.legend.display = false;

function AreaGraph({ resolved, xAxisLabels }) {
  const chartRef = React.createRef();

  useEffect(() => {
    const myChartRef = chartRef.current.getContext("2d");

    const gradient = myChartRef.createLinearGradient(0, 0, 0, 450);
    gradient.addColorStop(0, "rgb(183, 217, 255)");
    gradient.addColorStop(0.5, "rgb(218, 236, 255)");
    gradient.addColorStop(1, "rgba(255, 255, 255, 0)");
    try {
      // eslint-disable-next-line no-new
      new Chart(myChartRef, {
        type: "line",
        data: {
          // Bring in data
          labels: xAxisLabels,
          datasets: [
            {
              label: "Resolved",
              backgroundColor: gradient,
              data: resolved,
              borderColor: "#007aff",
              borderWidth: 1.5,
            },
          ],
        },
        options: {
          responsive: true,
          layout: {
            padding: {
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            },
          },
          elements: {
            point: {
              radius: 0,
            },
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });
    } catch (e) {
      // error
    }
  }, [xAxisLabels]);

  return (
    <div className="chart-style">
      <canvas id="myChart" ref={chartRef} />
    </div>
  );
}

export default AreaGraph;

AreaGraph.defaultProps = {
  resolved: [],
  xAxisLabels: [],
};

AreaGraph.propTypes = {
  resolved: PropTypes.shape(Array),
  xAxisLabels: PropTypes.shape(Array),
};
