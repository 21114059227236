import React from "react";
import PropTypes from "prop-types";

import "../SidebarList/style.scss";

function SideBarListSection({ handleUser, dataSet, title }) {
  return (
    <>
      {dataSet.map((item) => (
        <div
          role="button"
          tabIndex="0"
          onKeyPress={() => handleUser(item)}
          className={`sidebar-list-single ${item.isActive ? "active" : ""}`}
          onClick={() => handleUser(item)}
          key={item}
        >
          <div className="sidebar-list-single__content">
            <img
              className="sidebar-list-single___pic"
              // src={`${
              //   item.profile_pic ? item.profile_pic : "/assets/avatar.png"
              // }`}
              src="/assets/avatar.png"
              alt="profile-pic"
            />
            <div
              style={{ flexBasis: "85%", lineBreak: "anywhere" }}
              className="sidebar-list-single___data"
            >
              {/* <p>{startCase(item.email)}</p> */}
              <p>{item.email}</p>
              <span>{`${
                title === "Users"
                  ? item?.total_files_send_or_resolved
                  : item.total_files_send
              } ${title === "Users" ? "Resolved" : "Files sent"}`}</span>
            </div>
          </div>
          <img src="/assets/next.png" alt="next" />
        </div>
      ))}
      {dataSet.length === 0 && (
        <div className="sidebar-list-single">
          <div className="sidebar-list-single__content">
            <div className="sidebar-list-no-data">No Record Found!</div>
          </div>
        </div>
      )}
    </>
  );
}

export default SideBarListSection;

SideBarListSection.defaultProps = {
  dataSet: [],
  handleUser: () => {},
};

SideBarListSection.propTypes = {
  dataSet: PropTypes.instanceOf(Array),
  title: PropTypes.string.isRequired,
  handleUser: PropTypes.func,
};
