import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";
import styles from "../../../resources/index.module.scss";

function ListViewPrefOptions({
  badge,
  name,
  value,
  filter,
  statusFilters,
  listViewOption,
  selectRecord,
  setFilter,
  loading,
}) {
  return (
    <div
      role="button"
      tabIndex="0"
      className={cn({
        [styles.listViewPrefOption]: true,
        [styles.loading]: value === listViewOption && loading,
        [styles.selected]: value === listViewOption && !loading,
      })}
      onKeyPress={() => {
        if (!loading) {
          selectRecord({});
          setFilter({ ...filter, currentPage: 1, status: value });
        }
      }}
      onClick={() => {
        if (!loading) {
          selectRecord({});
          setFilter({ ...filter, currentPage: 1, status: value });
        }
      }}
    >
      {name}
      {loading ? (
        <Skeleton width={20} height={17} className={styles.badgeLoader} />
      ) : (
        badge > 0 && (
          <div
            className={cn({
              [styles.badge]: true,
              [styles.all]: value === statusFilters.all,
              [styles.green]:
                value === statusFilters.published || statusFilters.fdPublished,
              [styles.yellow]: value === statusFilters.processing,
              [styles.red]: value === statusFilters.error,
              [styles.blue]: value === statusFilters.resolved,
              [styles.lightYellow]: value === statusFilters.quarantine,
              [styles.hide]:
                value !== listViewOption &&
                listViewOption !== statusFilters.all,
            })}
          >
            {badge}
          </div>
        )
      )}
    </div>
  );
}

export default ListViewPrefOptions;

ListViewPrefOptions.propTypes = {
  badge: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  filter: PropTypes.instanceOf(Object).isRequired,
  statusFilters: PropTypes.instanceOf(Object).isRequired,
  listViewOption: PropTypes.string.isRequired,
  selectRecord: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
