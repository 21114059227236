import React from "react";
import { ToastContainer } from "react-toastify";
import AuthProvider from "./providers/AuthProvider";
import RoleProvider from "./providers/RoleProvider";
import Routes from "./Routes";
import "./App.css";
import "./global.styles.scss";

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <RoleProvider>
          <ToastContainer />
          <Routes />
        </RoleProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
