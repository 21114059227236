import React, { useEffect, useRef, useState } from "react";
import { cloneDeep } from "lodash";
import useKeyboardShortcut from "use-keyboard-shortcut";
import { Tooltip } from "@material-ui/core";
import moment from "moment";
import ReactLoading from "react-loading";
import MainHeader from "../../components/MainHeader";
import SearchInput from "../../components/SearchInput";
import AddButton from "../../components/AddButton";
import PaginationSkeleton from "../../components/Skeletons/PaginationSkeleton/index";
import Pagination from "../../components/Pagination/index";
import ProductManagementTable from "./ProductManagementTable";
import DeletePopup from "./DeletePopup";
import "./style.scss";
import ProductManagementPopup from "./ProductManagementPopup";
import productServices from "../../api/productServices";
import MainLayout from "../../components/MainLayout";
import BulkUploadPopup from "./BulkUploadPopup";
import showAlert from "../../utils/showAlert";

function ProductManagement() {
  const findRef = useRef(null);

  const [downloadStart, setDownloadStart] = useState(false);

  const [loader, setLoader] = useState(false);
  const [popUpData, setPopUpData] = useState({
    isShowPopUp: false,
    isEditMode: false,
    data: {},
  });
  const [showDeletePopUp, setDeletePopUp] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [filter, setFilter] = useState({
    currentPage: 1,
    searchKey: "",
  });
  const [navigation, setNavigation] = useState({
    itemCount: undefined,
    totalCount: undefined,
    nextPageNum: undefined,
    prevPageNum: undefined,
    hasNextPage: undefined,
    hasPrevPage: undefined,
    dataPerPage: undefined,
  });
  const [addButtonOpen, setAddButtonOpen] = useState(false);
  const [bulkUploadPopUp, setBulkUploadPopUp] = useState(false);

  const addButtonHandle = () => {
    const popUpDataCopy = cloneDeep(popUpData);
    popUpDataCopy.isShowPopUp = true;
    popUpDataCopy.isEditMode = false;
    popUpDataCopy.data = {};
    setPopUpData(popUpDataCopy);
    setAddButtonOpen(false);
  };
  const openBulkUploadPopup = () => {
    setBulkUploadPopUp(true);
    setAddButtonOpen(false);
  };
  const closeBulkUploadPopup = () => {
    setBulkUploadPopUp(false);
  };

  const onChangeHandler = (key) => (value) => {
    const filterCopy = cloneDeep(filter);
    if (key === "searchKey") {
      filterCopy.searchKey = value;
      filterCopy.currentPage = 1;
      setFilter(filterCopy);
    }
  };

  const closePopup = () => {
    const popUpDataCopy = cloneDeep(popUpData);
    popUpDataCopy.isShowPopUp = false;
    popUpDataCopy.isEditMode = false;
    popUpDataCopy.data = {};
    setPopUpData(popUpDataCopy);
  };

  const openDeletePopup = () => {
    setDeletePopUp(true);
  };

  const closeDeletePopup = () => {
    setDeletePopUp(false);
  };

  // keyboard shortcuts
  useKeyboardShortcut(["Control", "a"], () => addButtonHandle(), {
    overrideSystem: true,
  });
  useKeyboardShortcut(["Escape"], () => closePopup());
  useKeyboardShortcut(["Control", "f"], () => findRef.current.focus(), {
    overrideSystem: true,
  });

  const getProductListing = () => {
    const filterText = [];
    if (filter.currentPage) filterText.push(`page=${filter.currentPage}`);
    if (filter.searchKey.length !== 0)
      filterText.push(`q=${filter.searchKey.trim()}`);
    productServices.getProductList(filterText).then((resp) => {
      setTableData(resp?.results);
      const navigationCopy = cloneDeep(navigation);
      navigationCopy.itemCount = resp.results.length;
      navigationCopy.totalCount = resp.total_count;
      navigationCopy.nextPageNum = resp.next_page_num;
      navigationCopy.prevPageNum = resp.prev_page_num;
      navigationCopy.hasNextPage = resp.has_next_page;
      navigationCopy.hasPrevPage = resp.has_prev_page;
      navigationCopy.dataPerPage = resp.data_per_page;
      setNavigation(navigationCopy);
      setLoader(false);
    });
  };
  useEffect(() => {
    setLoader(true);
    let autoSaveTimer;
    autoSaveTimer = setTimeout(() => getProductListing(), 1000);
    return () => {
      // cancel the request when component un mounts
      clearInterval(autoSaveTimer);
      autoSaveTimer = null;
    };
  }, [filter]);

  useEffect(() => {
    document.title = "BCG IA | Product Management";
    productServices.getClientIdList().then((resp) => {
      setClientList(resp);
    });
  }, []);

  // --------------- Download File Handler---------------
  const bulkDownloadHandler = async () => {
    try {
      let fileName = "IA PMDB Products";
      let search = "";
      if (filter.searchKey.trim().length !== 0) {
        search = filter.searchKey.trim();
        fileName = `${fileName} Search by ${filter.searchKey.trim()}_${moment().format(
          "YYYYMMDD[-]HHmm"
        )}.xlsx`;
      } else {
        fileName = `${fileName} All_${moment().format("YYYYMMDD[-]HHmm")}.xlsx`;
      }
      const res = await productServices.createDownlaodProductList(search);
      setDownloadStart(true);
      showAlert(res?.message, "success");
      const timer = setInterval(async () => {
        if (res?.task_id) {
          const statusRes = await productServices.checkDownlaodProductList(
            res?.task_id
          );
          if (statusRes.output_file) {
            clearInterval(timer);
            showAlert(statusRes?.message, "success");
            setDownloadStart(false);
            const href = statusRes?.output_file;
            const a = document.createElement("a");
            a.href = href;
            a.setAttribute("download", fileName);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }
        } else {
          setDownloadStart(false);
          clearInterval(timer);
        }
      }, 15000);
    } catch (e) {
      setDownloadStart(false);
      showAlert(e?.response?.data?.message, "error");
    }
  };

  return (
    <MainLayout>
      <MainHeader title="Product Management" />
      {/* <ManagementTabs totalCount={navigation.totalCount} totalText="Products" /> */}
      <div className="container product-management">
        <div className="page-header">
          <div className="page-header___right">
            <SearchInput
              onChange={onChangeHandler("searchKey")}
              value={filter.searchKey}
              inputRef={findRef}
              placeholder="Search for product name, code ..."
            />
            <div style={{ position: "relative" }}>
              <AddButton
                onClickHandle={() => setAddButtonOpen(!addButtonOpen)}
              />
              {addButtonOpen && (
                <div className="add-button-popup ">
                  <button type="button" onClick={addButtonHandle}>
                    Single Product
                  </button>
                  <button type="button" onClick={openBulkUploadPopup}>
                    Bulk Upload
                  </button>
                </div>
              )}
            </div>
            {downloadStart ? (
              <Tooltip title="Bulk Download in progress">
                <div style={{ marginLeft: 20 }}>
                  <ReactLoading
                    type="spin"
                    color="#1c57ad"
                    height={20}
                    width={20}
                  />
                </div>
              </Tooltip>
            ) : (
              <Tooltip title="Bulk Download">
                <button
                  type="button"
                  onClick={bulkDownloadHandler}
                  style={{ marginLeft: 10 }}
                >
                  <img
                    alt="download"
                    className="imgDownload"
                    src="/assets/bxs_cloud-download.svg"
                  />
                </button>
              </Tooltip>
            )}
          </div>
          {loader ? (
            <PaginationSkeleton />
          ) : (
            <Pagination
              filter={filter}
              setFilter={setFilter}
              navigation={navigation}
            />
          )}
        </div>

        <ProductManagementTable
          loader={loader}
          tableData={tableData}
          popUpData={popUpData}
          setPopUpData={setPopUpData}
          setLoader={setLoader}
          setTableData={setTableData}
          getProductListing={getProductListing}
          openDeletePopup={openDeletePopup}
        />

        {popUpData.isShowPopUp && (
          <ProductManagementPopup
            setLoader={setLoader}
            popUpData={popUpData}
            closePopup={closePopup}
            clientList={clientList}
            getProductListing={getProductListing}
          />
        )}

        {showDeletePopUp && (
          <DeletePopup
            item={popUpData.data}
            setLoader={setLoader}
            getProductListing={getProductListing}
            closeDeletePopup={closeDeletePopup}
            filter={filter}
            setFilter={setFilter}
            navigation={navigation}
            count={tableData?.length}
          />
        )}
        {bulkUploadPopUp && (
          <BulkUploadPopup closePopup={closeBulkUploadPopup} />
        )}
      </div>
    </MainLayout>
  );
}

export default ProductManagement;
