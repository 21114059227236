import React, { useEffect, useState } from "react";
import { DatePicker, Radio } from "antd";

import moment from "moment";
import { cloneDeep } from "lodash";
import TabHeader from "../TabHeader";
import MainHeader from "../../../components/MainHeader";
import OverviewMetricsAndAnalysis from "./OverviewMetricsAndAnalysis";
import FileAnalysis from "./FileAnalysis";
import ErrorAnalysis from "./ErrorAnalysis";
import EmailAnalysis from "./EmailAnalysis";
import helper from "../../../utils/helper";
import MainLayout from "../../../components/MainLayout";

import "./style.scss";
import "antd/dist/antd.css";

const { RangePicker } = DatePicker;
const { reportPages, dateOptionsListExtended, disabledDatesForHourlyOption } =
  helper;

function OverviewTab() {
  const [page, setPage] = useState(reportPages.OVERVIEW);
  const [dateDetails, setDateDetails] = useState({
    durationType: "daily",
    timeOption: 1,
    dateDifference: 1,
    fromDate: moment(),
    toDate: moment(),
  });
  // for setting date offset
  const [hackValue, setHackValue] = useState();
  const [dates, setDates] = useState([]);
  const [durationType, setDurationType] = useState("daily");

  const selectHourWindow = (window) => {
    setDateDetails({
      ...dateDetails,
      timeOption: window,
    });
  };

  useEffect(() => {
    if (durationType === "weekly") {
      const dateDetailsCopy = cloneDeep(dateDetails);
      dateDetailsCopy.durationType = durationType;
      dateDetailsCopy.fromDate = moment().startOf("week");
      let end = moment().endOf("week");
      if (end > Date.now()) {
        end = moment();
      }
      dateDetailsCopy.toDate = end;
      setDateDetails(dateDetailsCopy);
    } else if (durationType === "monthly" || durationType === "custom") {
      const dateDetailsCopy = cloneDeep(dateDetails);
      dateDetailsCopy.durationType = durationType;
      dateDetailsCopy.fromDate = moment().startOf("month");
      let end = moment().endOf("month");
      if (end > Date.now()) {
        end = moment();
      }
      dateDetailsCopy.toDate = end;
      setDateDetails(dateDetailsCopy);
    }
  }, [durationType]);

  // controller for setting page
  const handlePage = (e) => {
    setPage(e.target.value);
  };

  const handleRadioButton = (e) => {
    const dateDetailsCopy = cloneDeep(dateDetails);
    dateDetailsCopy.durationType = e.target.value;
    // setting default values
    if (e.target.value === "monthly")
      dateDetailsCopy.fromDate = moment().startOf("year");
    else if (e.target.value === "weekly")
      dateDetailsCopy.fromDate = moment().startOf("month");
    else if (e.target.value === "daily") {
      dateDetailsCopy.fromDate = moment();
      dateDetailsCopy.toDate = moment();
    }
    setDurationType(e.target.value);
    const difference = dateDetailsCopy.toDate.diff(
      dateDetailsCopy.fromDate,
      "days"
    );
    if (difference > 1 && difference <= 4) {
      dateDetailsCopy.timeOption = 4;
    } else if (difference > 4 && difference <= 6) {
      dateDetailsCopy.timeOption = 6;
    } else if (difference > 6 && difference <= 12) {
      dateDetailsCopy.timeOption = 12;
    } else if (difference > 12) {
      dateDetailsCopy.timeOption = 24;
    }
    dateDetailsCopy.dateDifference = difference;
    if (e.target.value === "daily") setDateDetails(dateDetailsCopy);
  };

  const handleDay = (date) => {
    const dateDetailsCopy = cloneDeep(dateDetails);
    dateDetailsCopy.durationType = "daily";
    dateDetailsCopy.fromDate = moment(date);
    let end = moment(date);
    if (end > Date.now()) {
      end = moment();
    }
    dateDetailsCopy.toDate = end;
    setDateDetails(dateDetailsCopy);
  };

  const handleWeek = (date) => {
    const dateDetailsCopy = cloneDeep(dateDetails);
    dateDetailsCopy.durationType = "weekly";
    dateDetailsCopy.fromDate = moment(date).startOf("week");
    let end = moment(date).endOf("week");
    if (end > Date.now()) {
      end = moment();
    }
    dateDetailsCopy.toDate = end;
    setDateDetails(dateDetailsCopy);
  };

  const handleMonth = (date) => {
    const dateDetailsCopy = cloneDeep(dateDetails);
    dateDetailsCopy.durationType = "monthly";
    dateDetailsCopy.fromDate = moment(date).startOf("month");
    let end = moment(date).endOf("month");
    if (end > Date.now()) {
      end = moment();
    }
    dateDetailsCopy.toDate = end;
    setDateDetails(dateDetailsCopy);
  };

  const handleDate = ([fromDate, toDate]) => {
    const dateDetailsCopy = cloneDeep(dateDetails);
    dateDetailsCopy.fromDate = fromDate;
    dateDetailsCopy.toDate = toDate;
    const difference = dateDetailsCopy.toDate.diff(
      dateDetailsCopy.fromDate,
      "days"
    );
    if (difference > 1 && difference <= 4) {
      dateDetailsCopy.timeOption = 4;
    } else if (difference > 4 && difference <= 6) {
      dateDetailsCopy.timeOption = 6;
    } else if (difference > 6 && difference <= 12) {
      dateDetailsCopy.timeOption = 12;
    } else if (difference > 12) {
      dateDetailsCopy.timeOption = 24;
    }
    dateDetailsCopy.dateDifference = difference;
    setDateDetails(dateDetailsCopy);
  };

  const handleDatePickerDisabledDates = (current) =>
    current && current.valueOf() > Date.now();

  const handleDisabledDates = (current) =>
    disabledDatesForHourlyOption(
      current,
      dates,
      dateDetails.durationType,
      dateDetails.timeOption
    );

  const onOpenChange = (open) => {
    if (open) {
      setHackValue([]);
      setDates([]);
    } else {
      setHackValue(undefined);
    }
  };

  return (
    <MainLayout>
      <MainHeader title="Overview" />
      <div className="tab-content-wrapper">
        <TabHeader showSearch={false} />
        <div className="tab-content-overview">
          <div className="reports-nav">
            <Radio.Group value={page} onChange={handlePage}>
              <Radio value={reportPages.OVERVIEW}>Overview</Radio>
              <Radio value={reportPages.FILE_ANALYSIS}>Workflow Analysis</Radio>
              <Radio value={reportPages.ERROR_ANALYSIS}>Error Analysis</Radio>
              {/* <Radio value={reportPages.EMAIL_ANALYSIS}>Email Analysis</Radio> */}
              <Radio value={reportPages.CUMLT_EMAIL_ANALYSIS}>
                Email Analysis
              </Radio>
            </Radio.Group>
            <div className="graph-header-left">
              <Radio.Group
                style={{ marginRight: 10 }}
                options={dateOptionsListExtended}
                onChange={handleRadioButton}
                value={durationType}
                buttonStyle="solid"
                optionType="button"
                className="graph-header-radio-btn"
              />
              {durationType === "custom" && (
                <RangePicker
                  format="DD-MM-YYYY"
                  value={
                    hackValue || [dateDetails.fromDate, dateDetails.toDate]
                  }
                  onChange={handleDate}
                  allowClear={false}
                  disabledDate={handleDisabledDates}
                  onCalendarChange={(val) => setDates(val)}
                  onOpenChange={onOpenChange}
                />
              )}
              {durationType === "daily" && (
                <DatePicker
                  format="DD-MM-YYYY"
                  style={{ minWidth: 262 }}
                  onChange={handleDay}
                  allowClear={false}
                  inputReadOnly
                  disabledDate={handleDatePickerDisabledDates}
                  value={moment(dateDetails.fromDate)}
                />
              )}
              {durationType === "weekly" && (
                <DatePicker
                  format="DD-MM-YYYY"
                  style={{ minWidth: 262 }}
                  picker="week"
                  onChange={handleWeek}
                  allowClear={false}
                  inputReadOnly
                  disabledDate={handleDatePickerDisabledDates}
                  value={moment(dateDetails.fromDate)}
                />
              )}
              {durationType === "monthly" && (
                <DatePicker
                  format="YYYY MMM"
                  style={{ minWidth: 262 }}
                  picker="month"
                  onChange={handleMonth}
                  allowClear={false}
                  inputReadOnly
                  disabledDate={handleDatePickerDisabledDates}
                  value={moment(dateDetails.fromDate)}
                />
              )}
            </div>
          </div>
          <div className="overview-content-box">
            {page === reportPages.OVERVIEW && (
              <OverviewMetricsAndAnalysis
                dateDetails={dateDetails}
                selectHourWindow={selectHourWindow}
              />
            )}
            {page === reportPages.FILE_ANALYSIS && (
              <FileAnalysis
                dateDetails={dateDetails}
                selectHourWindow={selectHourWindow}
              />
            )}
            {page === reportPages.ERROR_ANALYSIS && (
              <ErrorAnalysis dateDetails={dateDetails} />
            )}
            {page === reportPages.EMAIL_ANALYSIS && (
              <EmailAnalysis
                dateDetails={dateDetails}
                selectHourWindow={selectHourWindow}
                title="Email Analysis"
              />
            )}
            {page === reportPages.CUMLT_EMAIL_ANALYSIS && (
              <EmailAnalysis
                dateDetails={dateDetails}
                selectHourWindow={selectHourWindow}
                title="Email Analysis"
              />
            )}
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default OverviewTab;
