import React from "react";
import { Route, Switch } from "react-router-dom";
import LoginPage from "./pages/login";
import ForgotPassword from "./pages/ForgotPassword";
import SetPassword from "./pages/SetPassword";
import ResetPassword from "./pages/ResetPassword";
import ReleaseEmail from "./pages/ReleaseEmail";
import PrivateRoute from "./routes/PrivateRoute";
import DashboardPage from "./pages/dashboard";
import OverviewTab from "./pages/Overview/Reports";
import UserReportTab from "./pages/Overview/UserReportTab";
import ClientReportTab from "./pages/Overview/ClientReportTab";
import UserManagement from "./pages/UserManagement";
import ClientManagement from "./pages/ClientManagement";
import ProductManagement from "./pages/ProductManagement";
import ProductLookup from "./pages/ProductLookup";
import NotFound from "./pages/NotFound";
import helper from "./utils/helper";
import HotQueue from "./pages/HotQueue";
import Settings from "./pages/Settings";

function Switches() {
  const { roles } = helper;
  return (
    <Switch>
      <Route path="/login" exact component={LoginPage} />
      <Route path="/forgot-password" exact component={ForgotPassword} />
      <Route path="/set-password/:uuid/:token" exact component={SetPassword} />
      <Route
        path="/reset-password/:uuid/:token"
        exact
        component={ResetPassword}
      />
      <Route path="/release-email/:id" exact component={ReleaseEmail} />
      <PrivateRoute
        path="/"
        exact
        allowedUser={[roles.USER, roles.BUSINESS_ADMIN, roles.SUPER_ADMIN]}
      />
      <PrivateRoute
        path="/dashboard"
        exact
        component={DashboardPage}
        allowedUser={[roles.USER, roles.BUSINESS_ADMIN, roles.SUPER_ADMIN]}
      />
      <PrivateRoute
        path="/dashboard/mail/:mailRefId/:status"
        exact
        component={DashboardPage}
        allowedUser={[roles.USER, roles.BUSINESS_ADMIN, roles.SUPER_ADMIN]}
      />
      <PrivateRoute
        path="/dashboard/reports"
        exact
        component={OverviewTab}
        allowedUser={[roles.SUPER_ADMIN, roles.BUSINESS_ADMIN]}
      />
      <PrivateRoute
        path="/dashboard/user-reports"
        component={UserReportTab}
        allowedUser={[roles.SUPER_ADMIN, roles.BUSINESS_ADMIN]}
      />
      <PrivateRoute
        path="/dashboard/client-reports"
        exact
        component={ClientReportTab}
        allowedUser={[roles.SUPER_ADMIN, roles.BUSINESS_ADMIN]}
      />
      <PrivateRoute
        path="/management/user"
        exact
        component={UserManagement}
        allowedUser={[roles.SUPER_ADMIN]}
      />
      <PrivateRoute
        path="/management/sender"
        exact
        component={ClientManagement}
        allowedUser={[roles.SUPER_ADMIN, roles.BUSINESS_ADMIN]}
      />
      <PrivateRoute
        path="/management/product"
        exact
        component={ProductManagement}
        allowedUser={[roles.SUPER_ADMIN]}
      />
      <PrivateRoute
        path="/hot-queue"
        exact
        component={HotQueue}
        allowedUser={[roles.SUPER_ADMIN]}
      />
      <PrivateRoute
        path="/settings"
        exact
        component={Settings}
        allowedUser={[roles.SUPER_ADMIN]}
      />
      <PrivateRoute
        path="/product-lookup"
        exact
        component={ProductLookup}
        allowedUser={[roles.USER, roles.BUSINESS_ADMIN, roles.SUPER_ADMIN]}
      />
      <Route path="/not-found" component={NotFound} />
      <Route component={NotFound} />
    </Switch>
  );
}

export default Switches;
