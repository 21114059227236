import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";

import helper from "../../../../../utils/helper";
import styles from "../../../resources/index.module.scss";
import {
  REPAIR_QUEUE_CODES,
  REPUBLISH_CODES,
  SYSTEM_GENERATED_CODES,
} from "../../../../../utils/constantData";

const { statusFilters } = helper;

function ErrorCode({ code, message, status = "" }) {
  const checkPriority = (inputArray = []) => inputArray.includes(code);
  return (
    <div
      className={cn({
        [styles.errorCodeCard]: true,
        [styles.green]:
          status === statusFilters.published ||
          status === statusFilters.fdPublished,
        [styles.red]: checkPriority(REPAIR_QUEUE_CODES) || code === "NA",
        [styles.orange]: checkPriority(SYSTEM_GENERATED_CODES),
        [styles.yellow]: checkPriority(REPUBLISH_CODES),
      })}
    >
      <div>
        {status !== statusFilters.published &&
        status !== statusFilters.fdPublished
          ? "Error Code"
          : "Status Code"}
        : <span>{code}</span>
      </div>
      <p>{message}</p>
    </div>
  );
}

export default ErrorCode;

ErrorCode.defaultProps = {
  status: "",
};

ErrorCode.propTypes = {
  code: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  status: PropTypes.string,
};
