/* eslint-disable jsx-a11y/no-autofocus */
import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  TextField,
  withStyles,
} from "@material-ui/core";
import { Editor as TinyEditor } from "@tinymce/tinymce-react";
import cn from "classnames";
import PropTypes from "prop-types";

import userServices from "../../../../../api/userServices";
import showAlert from "../../../../../utils/showAlert";
import helper from "../../../../../utils/helper";
import styles from "../../../resources/index.module.scss";
import modalStyles from "./index.module.scss";

const { getEmail } = helper;

const Dialog = withStyles({
  paper: {
    borderRadius: "22px",
    width: "75%",
  },
})(MuiDialog);

const DialogContent = withStyles({
  root: {
    padding: "32px 32px 24px 32px",
  },
})(MuiDialogContent);

function SingleThread({
  email,
  name,
  sendToMail,
  time,
  body,
  index,
  handleOpen,
  createMarkup,
  listOpen,
}) {
  const avatarName = email.substring(0, 2).toUpperCase();
  return (
    <div className={modalStyles.singleMailRecord} key={index}>
      <div className={modalStyles.avatar} data-avatar={avatarName || "NA"} />
      <div
        className={cn({
          [modalStyles.mailBody]: true,
        })}
      >
        <div
          role="button"
          tabIndex="0"
          className={modalStyles.senderDetails}
          onKeyPress={() => handleOpen(index)}
          onClick={() => handleOpen(index)}
        >
          <div className={modalStyles.senderName}>
            {`${email}`}
            {name && ` (${name})`}
          </div>
          <div
            className={modalStyles.mailTime}
            title={moment.unix(time).format("DD MMM YYYY, HH:mm")}
          >
            {moment.unix(time).format("MMM DD, HH:mm")}
          </div>
        </div>
        <div className={modalStyles.toEmail}>To {sendToMail}</div>
        <div
          className={cn({
            [modalStyles.mailBodyMax]: true,
            [modalStyles.autoHeight]: listOpen[index],
          })}
          dangerouslySetInnerHTML={createMarkup(body)}
        />
      </div>
    </div>
  );
}

SingleThread.propTypes = {
  email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  sendToMail: PropTypes.string.isRequired,
  time: PropTypes.number.isRequired,
  body: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  handleOpen: PropTypes.func.isRequired,
  createMarkup: PropTypes.func.isRequired,
  listOpen: PropTypes.instanceOf(Array).isRequired,
};

function MailModal({
  selectedRecord,
  isMailModalOpen,
  closeMailModal,
  filter,
  setFilter,
}) {
  // editor content
  const [value, setValue] = useState(null);
  // state for expanding and collapsing thread
  const [listOpen, setListOpen] = useState([]);
  // complete mail thread list
  const [mailData, setMailData] = useState([]);
  // to email coma separated list
  const [toEmail, setToEmail] = useState("");
  const [sending, setSending] = useState(false);

  const handleOpen = (index) => {
    const listOpenCopy = [...listOpen];
    listOpenCopy[index] = !listOpenCopy[index];
    setListOpen(listOpenCopy);
  };

  const handleChange = (e) => {
    setToEmail(e.target.value);
  };

  const sendReplyMail = (e) => {
    e.preventDefault();
    if (value !== "" && value !== null) {
      setSending(true);
      const payload = {
        mail_detail: selectedRecord.id,
        reply_body: value,
        mail_to: toEmail,
      };
      userServices
        .sendReply(payload)
        .then(() => {
          showAlert("Reply mail send successfully!", "success");
          setFilter({ ...filter });
          setValue("");
          setSending(false);
        })
        .catch((error) => {
          setSending(false);
          const message =
            error.response.data.message || error.response.data.mail_to[0];
          showAlert(message, "error");
        });
    }
    return null;
  };

  //  0 = fromEmail ; other = toEmail(BCG)
  const getEmailId = (index) => {
    if (index === 0)
      return selectedRecord?.manual_upload
        ? selectedRecord?.sender_details?.sender_email
        : getEmail(selectedRecord.mail_from);
    return getEmail(selectedRecord.mail_to);
  };

  useEffect(() => {
    // setting default emailId for toEmail
    setToEmail(getEmailId(0));
    const totalMail = selectedRecord.reply_email_details.length + 1;
    const mailArray = new Array(totalMail);
    mailArray.forEach(() => {
      mailArray.push(false);
    });
    // keeping the first thread as open(default)
    mailArray[0] = true;
    setListOpen(mailArray);

    const firstThread = {
      created_at: selectedRecord.date,
      mail_detail: selectedRecord.id,
      reply_body: selectedRecord?.full_body || selectedRecord.body,
      send_by: "",
      mail_to: getEmailId(1),
    };
    const mailThreads = [firstThread, ...selectedRecord.reply_email_details];
    setMailData(mailThreads);
  }, [selectedRecord]);

  const createMarkup = (body) => ({ __html: body });

  return (
    <Dialog open={isMailModalOpen.open} maxWidth={false}>
      <DialogContent className={modalStyles.MailModal}>
        <div
          role="button"
          tabIndex="0"
          onKeyPress={closeMailModal}
          className={modalStyles.closeBtn}
          onClick={closeMailModal}
          aria-label="close-btn"
        >
          Close
        </div>
        <div className={modalStyles.mailSub}>{selectedRecord.subject}</div>

        <div className={modalStyles.mailContainer} data-testid="mail-popup">
          {mailData.map((el, index) => (
            <SingleThread
              key={el}
              email={getEmailId(index)}
              name={el.send_by}
              time={el.created_at}
              body={el.reply_body}
              index={index}
              sendToMail={el.mail_to}
              listOpen={listOpen}
              createMarkup={createMarkup}
              handleOpen={handleOpen}
            />
          ))}

          <br />
          <br />

          {/* reply feature currently enable for one time only */}
          {selectedRecord.reply_email_details.length === 0 && (
            <>
              <div className={modalStyles.editorContainer}>
                <form id="send-reply-mail" onSubmit={(e) => sendReplyMail(e)}>
                  <div>
                    <TextField
                      id="to-email-id"
                      label="To"
                      className="material-input"
                      fullWidth
                      required
                      onChange={handleChange}
                      value={toEmail}
                    />
                  </div>
                  <div className={modalStyles.toEmail}>
                    (Use comma-separated Email-Ids for sending to multiple
                    users)
                  </div>

                  <br />

                  <TinyEditor
                    apiKey={process.env.REACT_APP_TINY_API_KEY}
                    value={value}
                    init={{
                      height: 200,
                      menubar: false,
                      auto_focus: isMailModalOpen.replyClicked,
                      plugins: ["lists  advlist help"],
                      toolbar:
                        "undo redo | formatselect | " +
                        "bold italic underline | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent lineheight | " +
                        "removeformat | help",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                      lineheight_formats: "1 1.1 1.2 1.3 1.4 1.5 2",
                    }}
                    onEditorChange={(content) => {
                      setValue(content);
                    }}
                  />
                </form>
              </div>

              <div className={modalStyles.sendButton}>
                <button
                  form="send-reply-mail"
                  type="submit"
                  autoFocus={isMailModalOpen.replyClicked}
                  className={cn({
                    [styles.button]: true,
                    [styles.darkGreen]: true,
                    [styles.fullWidth]: true,
                  })}
                  disabled={sending}
                >
                  {sending ? "Sending..." : "Reply"}
                </button>
              </div>
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default MailModal;

MailModal.propTypes = {
  selectedRecord: PropTypes.instanceOf(Object).isRequired,
  isMailModalOpen: PropTypes.bool.isRequired,
  closeMailModal: PropTypes.func.isRequired,
  filter: PropTypes.instanceOf(Object).isRequired,
  setFilter: PropTypes.func.isRequired,
};
