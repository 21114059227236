import React from "react";
import Skeleton from "react-loading-skeleton";

import styles from "../../../resources/index.module.scss";

function PreviewSkeleton() {
  return (
    <div className={styles.preview}>
      <Skeleton height="150px" />
      <br />
      <br />
      <Skeleton height="16px" />
      <br />
      <br />
      <Skeleton height="2px" />
      <br />
      <br />
      <Skeleton height="16px" />
      <br />
      <br />
      <Skeleton height="16px" />
      <br />
      <br />
      <Skeleton height="16px" />
      <br />
      <br />
      <Skeleton height="16px" />
      <br />
      <br />
      <br />
      <Skeleton height="30px" />
    </div>
  );
}

export default PreviewSkeleton;
