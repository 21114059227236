import React from "react";
import { cloneDeep, findIndex } from "lodash";
import Skeleton from "react-loading-skeleton";
import PropTypes from "prop-types";
import { Switch } from "antd";
import { Divider } from "@material-ui/core";
import showAlert from "../../../utils/showAlert";
import productServices from "../../../api/productServices";

function ProductManagementTable({
  loader = false,
  tableData,
  popUpData,
  setPopUpData,
  setLoader,
  setTableData,
  openDeletePopup,
}) {
  // Saving the data to render on popup while editing
  const editHandler = (item) => {
    const popUpDataCopy = cloneDeep(popUpData);
    popUpDataCopy.isShowPopUp = true;
    popUpDataCopy.isEditMode = true;
    popUpDataCopy.data = item;
    setPopUpData(popUpDataCopy);
  };

  const deleteHandler = (item) => {
    // saving the item that needs to be deleted in state variable
    const popUpDataCopy = cloneDeep(popUpData);
    popUpDataCopy.isShowPopUp = false;
    popUpDataCopy.isEditMode = false;
    popUpDataCopy.data = item;
    setPopUpData(popUpDataCopy);

    // shows the delete popup
    openDeletePopup();
  };

  // activate/deactivate is like a soft delete
  const statusHandler = (item) => {
    if (item.status === true) {
      // deactivate product
      setLoader(true);
      productServices
        .changeProductStatus(item.id, { status: false })
        .then(() => {
          showAlert("Product deactivated!", "success");
          const tableDataCopy = cloneDeep(tableData);
          const index = findIndex(
            tableDataCopy,
            (product) => product.id === item.id
          );
          tableDataCopy[index].status = false;
          setTableData(tableDataCopy);
          setLoader(false);
        });
    } else {
      // Activate  product
      setLoader(true);
      productServices
        .changeProductStatus(item.id, { status: true })
        .then(() => {
          showAlert("Product activated!", "success");
          const tableDataCopy = cloneDeep(tableData);
          const index = findIndex(
            tableDataCopy,
            (product) => product.id === item.id
          );
          tableDataCopy[index].status = true;
          setTableData(tableDataCopy);
          setLoader(false);
        });
    }
  };

  const tableSkeletonSection = () => (
    <>
      {Array.from({ length: 7 })
        .map((_, index) => index)
        .map((el) => (
          <div className="table-row table-row-skeleton" key={el}>
            <Skeleton width="99%" height={20} />
          </div>
        ))}
    </>
  );

  const tableDataSection = () => (
    <>
      {tableData.map((item) => (
        <div className="table-row" key={item.id}>
          <div className="table-col product-name">
            {item.product_name || item.fund_data_product_name}
          </div>
          <div className="table-col">{item.iress_code}</div>
          <div className="table-col">{item.APIR_code}</div>
          <div className="table-col edit-col">
            <button type="button" onClick={() => editHandler(item)}>
              <img src="/assets/edit.svg" alt="edit" />
              Edit
            </button>
            <Divider className="vertical-divider" orientation="vertical" />
            <button type="button" onClick={() => deleteHandler(item)}>
              <img src="/assets/delete.svg" alt="delete" />
              Delete
            </button>
          </div>
          <div>
            <Switch
              data-testid="status-switch"
              checked={item.status}
              onChange={() => statusHandler(item)}
            />
          </div>
        </div>
      ))}
      {/* if there are no records  */}
      {tableData.length === 0 && (
        <div className="table-row table-row-no-data">No Record Found!</div>
      )}
    </>
  );
  return (
    <div className="table">
      <div className="table-header">
        <p>Product Name</p>
        <p>IRESS Code</p>
        <p>APIR Code</p>
        <p>Actions</p>
        <p>Account Status</p>
      </div>
      <div className="table-body">
        {loader ? tableSkeletonSection() : tableDataSection()}
      </div>
    </div>
  );
}

export default ProductManagementTable;

ProductManagementTable.defaultProps = {
  loader: false,
};

ProductManagementTable.propTypes = {
  loader: PropTypes.bool,
  tableData: PropTypes.instanceOf(Array).isRequired,
  popUpData: PropTypes.instanceOf(Object).isRequired,
  setPopUpData: PropTypes.func.isRequired,
  setLoader: PropTypes.func.isRequired,
  setTableData: PropTypes.func.isRequired,
  openDeletePopup: PropTypes.func.isRequired,
};
