import React, { Fragment, useEffect, useState } from "react";
import { Popover } from "antd";
import styled from "styled-components";
import PropTypes from "prop-types";
import { cloneDeep, find, round } from "lodash";

import "./style.scss";
import { ERROR_CODE_LIST } from "../../utils/constantData";
import BoxSkeleton from "../Skeletons/BoxSkeleton";

const initialData = [
  {
    percentage: 0,
    color: "007aff",
    name: "NA",
    errorText: "",
  },
  {
    percentage: 0,
    color: "E74C3C",
    name: "NA",
    errorText: "",
  },
  {
    percentage: 0,
    color: "8E44AD",
    name: "NA",
    errorText: "",
  },
  {
    percentage: 0,
    color: "F39C12",
    name: "NA",
  },
  {
    percentage: 0,
    color: "2ECC71 ",
    name: "NA",
  },
  {
    percentage: 0,
    color: "80a1d1",
    name: "NA",
  },
];

function ToolTip(percentage, text) {
  return (
    <p>
      {percentage}% {`: ${text}`}
    </p>
  );
}

function ErrorStats({
  errorStatus = {},
  loader,
  showToolTipSide = "right",
  openModal = () => {},
}) {
  const { main, others } = errorStatus;

  const [dataSet, setDataSet] = useState(initialData);

  const handleItem = () => {
    openModal();
  };

  const calculateError = (totalError) => {
    const dataSetCopy = cloneDeep(initialData);
    main.forEach((el, index) => {
      dataSetCopy[index].name = el.code;
      dataSetCopy[index].percentage = round(
        (el.total_count / totalError) * 100
      );
      const foundError = find(ERROR_CODE_LIST, ["value", el.code]);
      dataSetCopy[index].errorText = foundError?.title || "";
    });
    if (others.total_count__sum) {
      const lastIndex = dataSetCopy.length - 1;
      dataSetCopy[lastIndex].name = "Others";
      dataSetCopy[lastIndex].percentage = round(
        (others.total_count__sum / totalError) * 100
      );
    }
    setDataSet(dataSetCopy);
  };

  useEffect(() => {
    let totalError = 0;
    if (main && others) {
      main.forEach((element) => {
        totalError += element.total_count;
      });
      if (others.total_count__sum) {
        totalError += others.total_count__sum;
      }
      calculateError(totalError);
    }
  }, [errorStatus]);

  return (
    <div className="error-wrapper">
      <h1 className="box-header">Error Stats</h1>
      {loader ? (
        <BoxSkeleton />
      ) : (
        <div className="bar-container">
          <div className="bar-wrapper">
            {dataSet.map((item) => (
              <Fragment key={JSON.stringify(item)}>
                <Popover
                  content={ToolTip(item.percentage, item.errorText)}
                  placement={showToolTipSide}
                >
                  <Bar color={item.color} width={item.percentage}>
                    <p>{item.percentage}</p>
                  </Bar>
                </Popover>
              </Fragment>
            ))}
          </div>
          <div
            role="button"
            tabIndex="0"
            className="item-wrapper"
            onClick={handleItem}
            onKeyPress={handleItem}
          >
            {dataSet.map((item) => (
              <Fragment key={JSON.stringify(item)}>
                <Item color={item.color}>
                  <p>{item.name}</p>
                </Item>
              </Fragment>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

const Bar = styled.div`
  height: 30px;
  width: ${(props) => props.width}%;
  background: #${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 8px;
  font-size: 15px;
  color: white;
`;

const Item = styled.div`
  padding-left: 20px;
  font-size: 12px;
  color: white;
  position: relative;
  color: #9c9c9c;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 3px;
    width: 8px;
    height: 8px;
    border-radius: 20px;
    background: #${(props) => props.color};
  }
`;

export default ErrorStats;

ErrorStats.defaultProps = {
  errorStatus: {},
  showToolTipSide: "right",
  openModal: () => {},
  loader: false,
};

ErrorStats.propTypes = {
  errorStatus: PropTypes.instanceOf(Object),
  loader: PropTypes.bool,
  showToolTipSide: PropTypes.string,
  openModal: PropTypes.func,
};
