import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

import SecondaryInput from "../../../components/SecondaryInput";
import "./style.scss";

function TabHeader({ showSearch = true, onChange = () => {}, value = "" }) {
  return (
    <div className="tab-header">
      <div className="tab-links">
        <NavLink to="/dashboard/reports">Reports</NavLink>
        <NavLink to="/dashboard/user-reports">User Reports</NavLink>
        <NavLink to="/dashboard/client-reports">Sender Reports</NavLink>
      </div>
      {showSearch && <SecondaryInput onChange={onChange} value={value} />}
    </div>
  );
}

export default TabHeader;

TabHeader.defaultProps = {
  showSearch: true,
  onChange: () => {},
  value: "",
};

TabHeader.propTypes = {
  showSearch: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
};
