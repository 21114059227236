import React from "react";
import {
  Checkbox,
  ListItemText,
  makeStyles,
  MenuItem,
} from "@material-ui/core";
import { Select } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactLoading from "react-loading";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  menuOption: {
    height: "37px",
    padding: "6px",
  },
  listItem: {
    fontSize: "13px",
  },
  checked: {
    color: "#1c57ad !important",
  },
  menuFont: {
    fontSize: "13px !important",
  },
  menuPaper: {
    maxHeight: 300,
    width: 207,
  },
  loadingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dropDownContainer: {
    background: "white",
    maxHeight: 300,
    overflow: "scroll",
    width: "100%",
  },
  infiniteScroll: {
    overflow: "unset !important",
  },
  menuItemContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  selectStyle: {
    width: "100%",
  },
  infiniteScrollEndMessage: {
    textAlign: "center",
    marginTop: 20,
  },
  infiniteScrollLoader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tagStyle: {
    background: "#f5f5f5",
    border: "1px solid #f0f0f0",
    borderRadius: 2,
    padding: 5,
    margin: 5,
  },
});

function MultiSelectInfiniteScroll({
  onChange,
  value,
  listOptions,

  loadMore,
  totalData,
  selectValue,
  setSearch,
}) {
  const classes = useStyles();

  const handleChange = (key, v) => {
    if (key === "delete") {
      const list = listOptions
        .filter((x) => v.includes(x.value))
        .map((m) => ({ id: m.id, value: m.value }));
      onChange("delete", list);
    }
    if (key === "single") {
      if (value.includes(v.id)) {
        onChange("single", { ...v, check: false });
      } else {
        onChange("single", { ...v, check: true });
      }
    }
  };

  const tagRender = (label) => <div className={classes.tagStyle}>{label}</div>;

  const dropDownRender = () => (
    <div className={classes.dropDownContainer} id="infinte-scroll-table">
      <InfiniteScroll
        dataLength={listOptions.length}
        next={loadMore}
        hasMore={totalData !== listOptions.length}
        loader={
          <div className={classes.infiniteScrollLoader}>
            <ReactLoading type="bubbles" color="#1c57ad" />
          </div>
        }
        endMessage={
          totalData > 0 ? (
            <p className={classes.infiniteScrollEndMessage}>
              <b>No more data.</b>
            </p>
          ) : null
        }
        scrollableTarget="infinte-scroll-table"
        className={classes.infiniteScroll}
      >
        {listOptions.map((item) => (
          <MenuItem
            key={item.id}
            value={item.id}
            title={item.title || ""}
            classes={{
              root: classes.menuOption,
            }}
            onClick={() => handleChange("single", item)}
          >
            <div className={classes.menuItemContainer}>
              <Checkbox
                classes={{
                  checked: classes.checked,
                }}
                size="small"
                checked={value?.includes(item.id)}
                // onChange={() => handleChange("single", item.id)}
              />
              <ListItemText
                classes={{ primary: classes.listItem }}
                primary={item.value}
              />
            </div>
          </MenuItem>
        ))}
      </InfiniteScroll>
    </div>
  );
  return (
    <Select
      mode="tags"
      className={classes.selectStyle}
      maxTagCount={5}
      placeholder="Please select"
      value={selectValue}
      allowClear={false}
      tagRender={({ label }) => tagRender(label)}
      // onChange={(e) => handleChange("delete", e)}
      dropdownRender={() => dropDownRender()}
      onSearch={(e) => setSearch(e)}
    />
  );
}

export default MultiSelectInfiniteScroll;

MultiSelectInfiniteScroll.defaultProps = {
  listOptions: [],
};

MultiSelectInfiniteScroll.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Array).isRequired,
  selectValue: PropTypes.instanceOf(Array).isRequired,
  listOptions: PropTypes.instanceOf(Array),

  loadMore: PropTypes.func.isRequired,
  setSearch: PropTypes.func.isRequired,

  totalData: PropTypes.number.isRequired,
};
