import React from "react";
import PropTypes from "prop-types";

import styles from "./index.module.scss";

function Layout({ children }) {
  return (
    <div className={styles.page}>
      <div className={styles.pageContent}>
        <div className={styles.imageContainer}>
          <img className={styles.icon} alt="logo" src="/assets/logo.svg" />
        </div>
        <div className={styles.formContainer}>{children}</div>
      </div>
    </div>
  );
}

export default Layout;

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
