import React from "react";
import { BrowserRouter } from "react-router-dom";
import Switches from "./Switches";

function Routes() {
  return (
    <BrowserRouter>
      <Switches />
    </BrowserRouter>
  );
}

export default Routes;
