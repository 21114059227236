import React, { memo, useEffect, useState } from "react";
import {
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  withStyles,
} from "@material-ui/core";
import cn from "classnames";
import PropTypes from "prop-types";

import styles from "../../../resources/index.module.scss";
import "./resolveHeader.scss";
import DashboardTableSkeleton from "../DashboardTableSkeleton";
import ErrorCode from "../ErrorCode";
import showAlert from "../../../../../utils/showAlert";
import userServices from "../../../../../api/userServices";

const Dialog = withStyles({
  paper: {
    borderRadius: "22px",
  },
})(MuiDialog);

const DialogContent = withStyles({
  root: {
    padding: "32px 32px 24px 32px",
  },
})(MuiDialogContent);

const resolveHistoryskeletonSection = () => (
  <>
    {new Array(3).fill("").map((el, index) => (
      <DashboardTableSkeleton key={el} index={index} />
    ))}
  </>
);

function ResolveHistoryModal({
  isResolveHistoryOpen,
  closeResolveHistory,
  data,
}) {
  const [loader, setLoader] = useState(true);
  const [history, setHistory] = useState([]);

  useEffect(() => {
    setLoader(true);
    userServices
      .getResolveLog(data.reference_id)
      .then((res) => {
        setHistory(res.results);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        showAlert(err?.response?.data?.message, "error");
      });
  }, [data]);

  return (
    <Dialog
      open={isResolveHistoryOpen}
      onBackdropClick={closeResolveHistory}
      maxWidth={800}
    >
      <DialogContent
        className={styles.errorCodeModal}
        style={{ minWidth: 800 }}
      >
        {loader ? (
          resolveHistoryskeletonSection()
        ) : (
          <div className="resolve-log-modal">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="heading">Resolve History</div>
              <div
                role="button"
                tabIndex="0"
                className="close-btn"
                onKeyPress={closeResolveHistory}
                onClick={closeResolveHistory}
              >
                Close
              </div>
            </div>
            <div className="table">
              <div className="table-header">
                <p>Resolved by</p>
                <p>Email</p>
                <p>Comment</p>
                <p>Status</p>
                <p>Response Message</p>
              </div>
              <div className="table-body">
                {history &&
                  history.length > 0 &&
                  history.map((h) => (
                    <div key={h} className="table-row">
                      <div className="table-col">
                        <p>{h.resolved_by_name}</p>
                      </div>
                      <div className="table-col">
                        <p>{h.resolved_by}</p>
                      </div>
                      <div className="table-col">
                        <p>{h.resolver_comments}</p>
                      </div>
                      <div className="table-col">
                        {h.status_flag === "res" && (
                          <div
                            className={cn({
                              [styles.currentStatus]: true,
                              [styles.blueText]: true,
                            })}
                          >
                            Resolved
                          </div>
                        )}
                        {h.status_flag === "err" && (
                          <div
                            className={cn({
                              [styles.currentStatus]: true,
                              [styles.redText]: true,
                            })}
                          >
                            Error
                          </div>
                        )}
                      </div>
                      <div className="table-col">
                        {h.status_flag === "res" && h.api_status ? (
                          <div>{h.api_status}</div>
                        ) : (
                          <div>-not available-</div>
                        )}
                        {h.status_flag !== "res" &&
                        !h.api_status &&
                        h.formatted_response.length > 0 ? (
                          h.formatted_response.map((f) => (
                            <div key={f} style={{ marginTop: 5 }}>
                              <ErrorCode
                                code={f[0]}
                                message={f[1]}
                                status={h.status_flag}
                              />
                            </div>
                          ))
                        ) : (
                          <div>-not available-</div>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default memo(ResolveHistoryModal);

ResolveHistoryModal.propTypes = {
  isResolveHistoryOpen: PropTypes.bool.isRequired,
  closeResolveHistory: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
};
