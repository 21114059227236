import React, { useEffect } from "react";
import Chart from "chart.js";
import PropTypes from "prop-types";

Chart.defaults.global.defaultFontFamily = "'Montserrat', sans-serif";
Chart.defaults.global.legend.display = false;

function ProductChart({ data }) {
  const chartRef = React.createRef();
  useEffect(() => {
    if (chartRef.current) {
      const myChartRef = chartRef.current.getContext("2d");

      if (myChartRef) {
        const gradient = myChartRef.createLinearGradient(0, 0, 0, 450);
        gradient.addColorStop(0, "rgb(183, 217, 255)");
        gradient.addColorStop(0.5, "rgb(218, 236, 255)");
        gradient.addColorStop(1, "rgba(255, 255, 255, 0)");

        window.myChart = new Chart(myChartRef, {
          type: "line",
          data: {
            // Bring in data
            labels: data?.labels,
            datasets: [
              {
                label: "Unit Price",
                backgroundColor: gradient,
                data: data?.datasets[0]?.data,
                borderColor: "#007aff",
                borderWidth: 1.5,
              },
            ],
          },
          // data,
          options: {
            responsive: true,
            layout: {
              padding: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              },
            },
            elements: {
              point: {
                radius: 0,
              },
            },
            legend: {
              display: true,
            },
          },
        });
      }
    }
    return () => {
      // deletes the old instance of chart
      if (window.myChart instanceof Chart) {
        window.myChart.destroy();
      }
    };
  }, [chartRef.current]);

  return (
    <div className="" data-testid="product-graph">
      <canvas id="myChart" ref={chartRef} />
    </div>
  );
}

export default ProductChart;

ProductChart.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};
