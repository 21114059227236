import React from "react";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import cn from "classnames";
import PropTypes from "prop-types";

import styles from "../../../resources/index.module.scss";

function LastPrefOption({
  nav,
  filter,
  statusFilters,
  otherStatusOptions,
  listViewOption,
  handleOthers,
}) {
  const badgeValue = () => {
    let badge;
    if (filter.status === statusFilters.hotQueue)
      badge = nav.statusCount.hotQueue;
    else if (filter.status === statusFilters.NoAttachment)
      badge = nav.statusCount.noAttach;
    else if (filter.status === statusFilters.historicalPrice)
      badge = nav.statusCount.hisPrice;
    else if (filter.status === statusFilters.inactive)
      badge = nav.statusCount.inactive;
    else if (filter.status === statusFilters.invalid)
      badge = nav.statusCount.invalid;
    else if (filter.status === statusFilters.notAProduct)
      badge = nav.statusCount.notAProduct;
    else if (filter.status === statusFilters.zipFile)
      badge = nav.statusCount.zipFile;
    else if (filter.status === statusFilters.quarantine)
      badge = nav.statusCount.qua;
    else if (filter.status === statusFilters.review)
      badge = nav.statusCount.review;
    else if (filter.status === statusFilters.all)
      badge =
        nav.statusCount.noAttach +
        nav.statusCount.hotQueue +
        nav.statusCount.hisPrice +
        nav.statusCount.inactive +
        nav.statusCount.invalid +
        nav.statusCount.notAProduct +
        nav.statusCount.zipFile +
        nav.statusCount.ewka +
        nav.statusCount.review;
    else badge = 0;

    return badge;
  };

  const badge = badgeValue();

  const found = otherStatusOptions.find((el) => el.value === filter.status);
  const hotQueueLabel =
    nav.statusCount.hotQueue > 0
      ? `Hot Queue (${nav.statusCount.hotQueue})`
      : "Hot Queue";
  const EWOALabel =
    nav.statusCount.noAttach > 0
      ? `EWOA (${nav.statusCount.noAttach})`
      : "EWOA";
  const HistoryLabel =
    nav.statusCount.hisPrice > 0
      ? `Historical Price (${nav.statusCount.hisPrice})`
      : "Historical Price";
  const InactiveLabel =
    nav.statusCount.inactive > 0
      ? `Inactive Product (${nav.statusCount.inactive})`
      : "Inactive Product";
  const InvalidLabel =
    nav.statusCount.invalid > 0
      ? `Not In DB (${nav.statusCount.invalid})`
      : "Not In DB";
  const NotAProductLabel =
    nav.statusCount.notAProduct > 0
      ? `Not A Product (${nav.statusCount.notAProduct})`
      : "Not A Product";
  const ZipFileLabel =
    nav.statusCount.zipFile > 0
      ? `Zip File (${nav.statusCount.zipFile})`
      : "Zip File";
  const QuaratineLabel =
    nav.statusCount.qua > 0
      ? `Quarantine (${nav.statusCount.qua})`
      : "Quarantine";
  const ReviewLabel =
    nav.statusCount.review > 0
      ? `Review (${nav.statusCount.review})`
      : "Review";
  const EWKALabel =
    nav.statusCount.ewka > 0 ? `EWKA (${nav.statusCount.ewka})` : "EWKA";
  return (
    <div
      className={cn({
        [styles.lastOption]: true,
        [styles.listViewPrefOption]: true,
        [styles.selected]:
          listViewOption === statusFilters.hotQueue ||
          listViewOption === statusFilters.NoAttachment ||
          listViewOption === statusFilters.historicalPrice ||
          listViewOption === statusFilters.inactive ||
          listViewOption === statusFilters.invalid,
      })}
    >
      Others
      {found?.label && <span className={styles.hint}>({found.label})</span>}
      {badge > 0 && (
        <div
          className={cn({
            [styles.badge]: true,
            [styles.all]: true,
          })}
        >
          {badge}
        </div>
      )}
      <img src="/assets/dropdown.svg" alt="dropdown" />
      {/* other button area  */}
      <div className={styles.otherButtonsWrapper}>
        <RadioGroup
          aria-label="filter-status"
          name="filterStatus"
          value={filter.status}
          onChange={handleOthers}
        >
          <FormControlLabel
            value={statusFilters.hotQueue}
            control={<Radio size="small" color="primary" />}
            label={hotQueueLabel}
          />
          <FormControlLabel
            value={statusFilters.NoAttachment}
            control={<Radio size="small" color="primary" />}
            label={EWOALabel}
          />
          <FormControlLabel
            value={statusFilters.historicalPrice}
            control={<Radio size="small" color="primary" />}
            label={HistoryLabel}
          />
          <FormControlLabel
            value={statusFilters.inactive}
            control={<Radio size="small" color="primary" />}
            label={InactiveLabel}
          />
          <FormControlLabel
            value={statusFilters.invalid}
            control={<Radio size="small" color="primary" />}
            label={InvalidLabel}
          />
          <FormControlLabel
            value={statusFilters.notAProduct}
            control={<Radio size="small" color="primary" />}
            label={NotAProductLabel}
          />
          <FormControlLabel
            value={statusFilters.zipFile}
            control={<Radio size="small" color="primary" />}
            label={ZipFileLabel}
          />
          <FormControlLabel
            value={statusFilters.quarantine}
            control={<Radio size="small" color="primary" />}
            label={QuaratineLabel}
          />
          <FormControlLabel
            value={statusFilters.review}
            control={<Radio size="small" color="primary" />}
            label={ReviewLabel}
          />
          <FormControlLabel
            value={statusFilters.ewka}
            control={<Radio size="small" color="primary" />}
            label={EWKALabel}
          />
        </RadioGroup>
      </div>
    </div>
  );
}

export default LastPrefOption;

LastPrefOption.propTypes = {
  nav: PropTypes.instanceOf(Object).isRequired,
  filter: PropTypes.instanceOf(Object).isRequired,
  statusFilters: PropTypes.instanceOf(Object).isRequired,
  otherStatusOptions: PropTypes.instanceOf(Array).isRequired,
  listViewOption: PropTypes.string.isRequired,
  handleOthers: PropTypes.func.isRequired,
};
