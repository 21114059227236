import React from "react";
import {
  Checkbox,
  ListItemText,
  makeStyles,
  MenuItem,
  TextField,
} from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  menuOption: {
    height: "37px",
    padding: "6px",
  },
  listItem: {
    fontSize: "13px",
  },
  checked: {
    color: "#1c57ad !important",
  },
  menuFont: {
    fontSize: "13px !important",
  },
  menuPaper: {
    maxHeight: 280,
    width: 207,
  },
});

function MultiSelect({
  id,
  onChange,
  value,
  listOptions,
  required,
  disabled,
  additionalList,
}) {
  const classes = useStyles();
  return (
    <TextField
      disabled={disabled}
      select
      required={required}
      id={id}
      classes={{
        root: classes.root,
      }}
      SelectProps={{
        classes: {
          selectMenu: classes.menuFont,
        },
        multiple: true,
        value,
        renderValue:
          id === "tagged-user"
            ? (selected) =>
                listOptions
                  .filter((x) => selected.includes(x.id))
                  .map((m) => m.value)
                  .join(", ")
            : (selected) => selected.join(", "),
        onChange,
        MenuProps: {
          classes: {
            paper: classes.menuPaper,
          },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        },
      }}
    >
      {additionalList.length > 0 &&
        additionalList.map((item) => (
          <MenuItem
            key={item.id || item.value}
            value={item.value}
            title={item.title || ""}
            classes={{
              root: classes.menuOption,
            }}
          >
            <Checkbox
              classes={{
                checked: classes.checked,
              }}
              size="small"
              checked={value?.includes(item.value)}
            />
            <ListItemText
              classes={{ primary: classes.listItem }}
              primary={item.value}
            />
          </MenuItem>
        ))}
      {additionalList.length > 0 && <div className="starredlist-row-divider" />}
      {listOptions.map((item) => (
        <MenuItem
          key={item.id || item.value}
          value={id === "tagged-user" ? item.id : item.value}
          title={item.title || ""}
          classes={{
            root: classes.menuOption,
          }}
        >
          <Checkbox
            classes={{
              checked: classes.checked,
            }}
            size="small"
            checked={value?.includes(
              id === "tagged-user" ? item.id : item.value
            )}
          />
          <ListItemText
            classes={{ primary: classes.listItem }}
            primary={item.value}
          />
        </MenuItem>
      ))}
    </TextField>
  );
}

export default MultiSelect;

MultiSelect.defaultProps = {
  listOptions: [],
  required: false,
  disabled: false,
  additionalList: [],
};

MultiSelect.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Array).isRequired,
  listOptions: PropTypes.instanceOf(Array),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  additionalList: PropTypes.instanceOf(Array),
};
