/* eslint-disable no-nested-ternary */
import React from "react";
import {
  ChartBullet,
  getResizeObserver,
  ChartThemeColor,
} from "@patternfly/react-charts";
import { Tooltip } from "@material-ui/core";
import Skeleton from "react-loading-skeleton";
import PropTypes from "prop-types";
import { ReactComponent as FileAnalysisIcon } from "../../assets/fileAnalysis.svg";
import { ReactComponent as ProductAnalysisIcon } from "../../assets/productAnalysis.svg";
import { ReactComponent as FilesIcon } from "../../assets/files.svg";

class BulletChart extends React.Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.observer = () => {};
    this.state = {
      width: 0,
    };
    this.handleResize = () => {
      if (this.containerRef?.current?.clientWidth) {
        this.setState({ width: this.containerRef.current.clientWidth });
      }
    };
  }

  componentDidMount() {
    this.observer = getResizeObserver(
      this.containerRef.current,
      this.handleResize
    );
  }

  componentWillUnmount() {
    this.observer();
  }

  changeName = (name) => {
    const { title } = this.props;

    let label;

    if (title === "Workflow Status Analysis") {
      switch (name) {
        case "published_count":
          label = "Published";
          break;
        case "processing_count":
          label = "Processing";
          break;
        case "quarantined_count":
          label = "Quarantine";
          break;
        case "repair_queued_count":
          label = "Repair Queue";
          break;
        case "resolved_count":
          label = "Resolved";
          break;
        case "others_count":
          label = "Others";
          break;
        default:
          label = "";
      }
    } else if (title === "Unit Price Records Analysis") {
      switch (name) {
        case "published_products_count":
          label = "Published";
          break;
        case "processing_products_count":
          label = "Processing";
          break;
        case "other_products_count":
          label = "Others";
          break;
        case "repair_queued_products_count":
          label = "Repair Queue";
          break;
        case "resolved_products_count":
          label = "Resolved";
          break;

        default:
          label = "";
      }
    } else if (title === "Files") {
      switch (name) {
        case "total_attachment_count":
          label = "Total";
          break;
        case "received_count":
          label = "Recieved";
          break;
        case "ia_generated_count":
          label = "IA Generated";
          break;

        default:
          label = "";
      }
    }
    return label;
  };

  render() {
    const { width } = this.state;
    const { title, mainLoader, data, totalValue, tooltip } = this.props;

    const { secondaryDataFields, primaryDataFields } = this.props;
    const convertData = Object.keys(data);
    const primaryGraphFilteredData = [];
    const pushData = [];
    convertData.forEach((el) => {
      pushData.push({
        name: this.changeName(el),
        y: data[el],
        symbol: {
          type: "circle",
        },
      });
    });
    // For Primary Data
    primaryGraphFilteredData.push({
      name: "Total Emails",
      y: totalValue,
      symbol: {
        type: "circle",
        // fill: "white",
      },
    });
    primaryDataFields.forEach((el) => {
      pushData.forEach((ele) => {
        if (ele.name === el) primaryGraphFilteredData.push(ele);
      });
    });

    const secondaryGraphFilteredData = [];

    secondaryDataFields.forEach((el) => {
      pushData.forEach((ele) => {
        if (ele.name === el) secondaryGraphFilteredData.push(ele);
      });
    });

    return (
      <div style={{ padding: "16px 30px" }} ref={this.containerRef}>
        {mainLoader ? (
          <Skeleton width="100%" height={200} style={{ marginTop: 30 }} />
        ) : (
          <div className="mt-30">
            <div>
              {title === "Workflow Status Analysis" && <FileAnalysisIcon />}
              {title === "Files" && <FilesIcon />}
              {title === "Unit Price Records Analysis" && (
                <ProductAnalysisIcon />
              )}

              <Tooltip title={tooltip}>
                <span className="bullet-graph-title"> {title} </span>
              </Tooltip>
            </div>
            <ChartBullet
              key={data}
              ariaDesc={title}
              ariaTitle={title}
              // constrainToVisibleArea
              // maxDomain={50}
              legendItemsPerRow={20}
              height={130}
              labels={({ datum }) => `${datum.name}: ${datum.y}`}
              legendAllowWrap
              legendPosition="bottom-left"
              padding={{
                bottom: 50,
                left: 5,
                right: 50,
                // top: 100, // Adjusted to accommodate labels
              }}
              primarySegmentedMeasureData={primaryGraphFilteredData}
              primarySegmentedMeasureLegendData={primaryGraphFilteredData}
              qualitativeRangeData={secondaryGraphFilteredData}
              qualitativeRangeLegendData={secondaryGraphFilteredData}
              themeColor={ChartThemeColor.blue}
              width={width}
              tick
            />
          </div>
        )}
      </div>
    );
  }
}

export default BulletChart;

BulletChart.defaultProps = {
  primaryDataFields: [],
  data: [],
  secondaryDataFields: [],
  mainLoader: false,
  totalValue: 0,
  tooltip: "",
};

BulletChart.propTypes = {
  primaryDataFields: PropTypes.shape([]),
  data: PropTypes.shape([]),
  secondaryDataFields: PropTypes.shape([]),
  title: PropTypes.string.isRequired,
  mainLoader: PropTypes.bool,
  totalValue: PropTypes.number,
  tooltip: PropTypes.string,
};
