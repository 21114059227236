const endpoints = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
  gitBookURL: "https://api.gitbook.com/v1",

  // auth
  login: "/api/v1/mail/login1/",
  logout: "/mail/logout/",
  changePassword: "/api/v1/mail/password-reset-confirm/",
  validateToken: "/api/v1/mail/password-reset/",
  resetPasswordMail: "/api/v1/mail/request-reset-email/",
  sendReplyMail: "/api/v1/mail/send-replay-email/",

  // settings
  changeSettingsDetails: "api/v1/mail/change-password/",
  emailNotificationStatus: "api/v1/mail/email-notification/",
  autoTaggingStatus: "api/v1/mail/auto-tagging-management/",
  gmailURL: "api/v1/mail/gmail-url/",

  // user info
  userInfo: "/api/v1/management/user-details/",
  clientIP: "/api/v1/management/add-ip/",

  // dashboard apis
  // mailSearch: "/api/v1/mail/search1/",
  mailSearch: "/api/v1/mail/search_v1/",
  mailDetails: "/api/v1/mail/mail-details/",
  mailAttachments: "/api/v1/mail/mail-attachments/",
  mailAttachmentsPartial: "/api/v1/mail/mail-attachments-partial-details/",
  checkAnyoneIsWorking: "/api/v1/mail/check-any-one-is-working/",
  startResolving: "/api/v1/mail/start-resolving/",
  startResolvingAll: "api/v1/mail/start-resolve-all-attachments/",
  endResolving: "/mail/remove-from-start-resolving/",
  endResolvingAll: "/mail/remove-all-from-start-resolving/",
  userList: "/api/v1/management/all-users/",
  deleteMailDetails: "/api/v1/mail/delete-mail-detail/",
  resolveQuarantinedMail: "/api/v1/mail/resolve-quarantined-email/",
  releaseEmail: "/api/v1/mail/release-quarantined-email/",
  addFundTag: "/api/v1/mail/add-fund-tag/",
  manualUpload: "/api/v1/mail/add-files-manually/",
  IMDFileCreate: "/api/v1/mail/fund_size_imd_sheet_create/",
  IMDFileName: "/api/v1/mail/fund_size_imd_sheet_name/",

  resolveInterchange: "/api/v1/mail/resolve-future-date-issue/",
  resolveCSV: "/api/v1/mail/resolve1/",
  // resolveCSV: "/mail/resolve/",
  resolveLog: "/api/v1/mail/resolve-log/",
  fundTags: "/api/v1/mail/fund-tags/",
  starringReasons: "/api/v1/mail/starring-reasons/",
  readUnreadMail: "/api/v1/mail/read-or-unread-email/",
  dashboardMetrics: "/api/v1/mail/dashboard-data-counts/",
  markAsImportant: "/api/v1/mail/mark-as-important/",
  starredUsers: "/api/v1/mail/get_starred_users/",
  taggedUsers: "/api/v1/mail/get_tagged_users/",
  taggedNotification: "/api/v1/mail/tag-notification/",
  taggedNotificationStatusChange: "/api/v1/mail/notification-status-change/",
  taggableUserUpdate: "api/v1/management/user-list-management1/",
  priceLimit: "api/v1/management/manage-price-limit/",
  spreadValue: "api/v1/management/manage-spread-limit/",
  mailLabel: "api/v1/mail/email-label-management/",
  removeMailLabel: "api/v1/mail/remove-message-label/",

  // code gen
  codeGenerator: "/code-generator/",

  // user management apis
  userListing: "/api/v1/management/user1/",
  createUser: "api/v1/management/user1/",
  updateUser: "/api/v1/management/user1/",
  activate: "/api/v1/management/activate/",
  deactivate: "/api/v1/management/user1/",
  uploadPic: "/api/v1/management/upload-file-to-s3/",
  trendAnalysis: "/api/v1/management/reports-overview/",
  fileAnalysis: "/api/v1/management/get-file-analysis-data/",
  errorAnalysis: "/api/v1/management/error-analysis/",

  // client management apis
  clientListing: "/api/v1/management/client1/",
  createClient: "/api/v1/management/client1/",
  updateClient: "/api/v1/management/client1/",
  clientActivate: "/api/v1/management/activate/",
  clientDeactivate: "/api/v1/management/client1/",
  hotRoute: "/api/v1/management/client/hotroute1/",
  senderListing: "/api/v1/management/sender-management/",
  senderCreate: "/api/v1/management/sender-management/",
  senderOrganizationListing: "/api/v1/management/get-all-organizations/",
  senderProductsListing: "/api/v1/management/sender-products/",

  // product management apis
  productList: "/api/v3/client/products/",
  addProduct: "/api/v3/client/products/",
  editProduct: "/api/v3/client/products/",
  productGraph: "/api/v3/client/product-unitprice-overview/",
  clientIdList: "/api/v1/management/all-clients/",
  uploadFile: "/api/v3/client/upload-products/",
  productStaticData: "/api/v3/client/product-statc-data/",
  downloadTransactionLink: "/api/v1/management/download-transaction-link/",
  downlaodTMDLink: "/api/v1/management/download-tmd-link/",
  downloadProductsCreate: "/api/v1/mail/product_download_create/",
  downloadProducts: "/api/v1/mail/product_download_file/",

  // reports
  clientReports: "/api/v1/management/reports/client/datas/",
  senderReports: "/api/v1/management/reports/sender-data/",
  userReports: "/api/v1/management/reports/user/datas/",
  overView: "/api/v1/management/reports/overview/",
  userPerformance: "/api/v1/management/user-performance/",
  clientPerformance: "/api/v1/management/client-performance/",
  emailAnalysis: "/api/v1/management/get-email-analysis-data/",
  cumulativeEmailAnalysis:
    "/api/v1/management/get-email-previous-status-analysis/",

  // email retain hour
  retainHour: "/api/v1/mail/fetch-emails-for-particular-hours/",

  // Hot Queue
  hotQueue: "api/v1/management/hotqueue-rule/",
  hotQueueEmailList: "api/v1/management/user-emails/",

  // versioning
  getVersion: "api/v1/mail/app-versions/",
};

export default endpoints;
