import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import moment from "moment";
import cn from "classnames";
import { useForm } from "react-hook-form";
import useKeyboardShortcut from "use-keyboard-shortcut";
import {
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  TextField,
  withStyles,
  Tooltip,
} from "@material-ui/core";
import { cloneDeep, find, isEmpty, remove } from "lodash";
import PropTypes from "prop-types";

import Preview from "./modules/containers/Preview";
import userServices from "../../api/userServices";
import appServices from "../../api/appServices";
import DashboardTable from "./modules/containers/DashboardTable";
import DashboardTableSkeleton from "./modules/containers/DashboardTableSkeleton";
import PreviewSkeleton from "./modules/containers/PreviewSkeleton";
import BottomPagination from "./modules/containers/BottomPagination";
import helper from "../../utils/helper";
import MailDetailPreview from "./modules/containers/MailDetailPreview";
import ErrorCodeModal from "./modules/containers/ErrorCodeModal";
import styles from "./resources/index.module.scss";
import globalStyles from "../../global.module.scss";
import "react-datetime/css/react-datetime.css";
import showAlert from "../../utils/showAlert";
import MultipleValue from "../../components/MultipleValue";
import RoleContext from "../../context/roleContext";
import EditFundTagModal from "./modules/containers/EditFundTagModal";
import MailModal from "./modules/containers/MailModal";
import DateAndTime from "./modules/containers/DateAndTime";
import LastPrefOption from "./modules/containers/LastPrefOption";
import FilterForm from "./modules/containers/FilterForm";
import statusFilters from "./modules/constants/statusFilter";
import ListViewPrefOptions from "./modules/containers/ListViewPrefOption";
import ScrollTopBtn from "./modules/containers/ScrollTopBtn";
import getSearchText from "../../utils/getSearchText";
import DashboardButton from "./modules/containers/DashboardButtons";
import ResolveHistoryModal from "./modules/containers/ResolveHistoryModal";
import Sidebar from "../../components/Sidebar";
import MainHeader from "../../components/MainHeader";
import ManualUploadModal from "./modules/containers/ManualUploadModal";
import hotQueueServices from "../../api/hotQueueServices";
import MultiSelect from "../../components/MultiSelect";
import HotQueueNotesInstructionPopup from "./modules/containers/HotQueueNotesInstructionPopup";
import LabelModal from "./modules/containers/LabelModal";
import LabelsContainer from "./modules/containers/LabelsContainer";

const { statusFilterOptions, otherStatusOptions, getEOWAMappedMails } = helper;

const dateFormat = "DD-MM-YYYY";
const defaultFilterFromDate = moment().format(dateFormat);
const defaultFilterToDate = moment().format(dateFormat);

const Dialog = withStyles({
  paper: {
    borderRadius: "22px",
  },
})(MuiDialog);

const DialogContent = withStyles({
  root: {
    padding: "32px 32px 24px 32px",
  },
})(MuiDialogContent);

const DialogActions = withStyles({
  root: {
    boxShadow: "rgba(0, 0, 0, 0.4) 0 -32px 1px -32px",
    padding: "24px 32px 32px 32px",
    zIndex: 10,
  },
})(MuiDialogActions);

function DateField({
  id,
  name,
  placeholder,
  highZIndex,
  halfWidth,
  register,
  alignCenter,
  disabled,
  onChange,
}) {
  return (
    <DateAndTime
      {...{
        id,
        name,
        placeholder,
        highZIndex,
        halfWidth,
        register,
        alignCenter,
        disabled,
        onChange,
      }}
    />
  );
}

DateField.defaultProps = {
  highZIndex: false,
  halfWidth: false,
  alignCenter: false,
  disabled: false,
  onChange: () => {},
};

DateField.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  highZIndex: PropTypes.bool,
  halfWidth: PropTypes.bool,
  register: PropTypes.func.isRequired,
  alignCenter: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

const initialFilter = {
  currentPage: 1,
  pageSize: 25,
  from: "",
  date_from: "",
  date_to: "",
  subject: "",
  body: "",
  status: statusFilters.all,
  manager_name: "",
  prepared_by: "",
  dop: "",
  reference_id: "",
  errorCodesList: [],
  fundTagList: [], // default value
  editedFundTagList: [], // default value
  starringReasons: [], // default value
  taggedUsers: [], // default value
  starredBy: [], // default value
  read: false,
  unread: false,
  all: false,
};

const initialSelectedFilter = {
  assignedTo: [],
};

export default function Dashboard({ history, match, location }) {
  const { userInfo, setUserInfo } = useContext(RoleContext);

  const {
    register: registerSearch,
    handleSubmit: handleSearchSubmit,
    reset: resetSearchForm,
    control: controlSearch,
    setValue,
  } = useForm({
    defaultValues: {
      status: statusFilters.all,
      fundTagList: [],
      editedFundTagList: [],
      starringReasons: [],
      taggedUsers: [],
      starredBy: [],
      all: false,
      read: false,
      unread: false,
    },
  });
  const { register: registerKey, handleSubmit: handleKey, watch } = useForm({
    defaultValues: { fundName: "", countryCode: "" },
  });

  const tableRef = useRef(); // listing table ref

  const { mailRefId, status } = match.params;
  const threadViewPage = location.pathname.includes("mail");
  const [hasThread, setHasThread] = useState(true);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [loadingData, setDataLoading] = useState(true);
  const [loadingCount, setCountLoading] = useState(false);
  const [codeGenLoading, setCodeGenLoading] = useState(false);
  const [manualUploadPopup, setManualUploadPopup] = useState(false);
  const [hotQueuePopup, setHotQueuePopup] = useState(false);
  const [code, setCode] = useState("");
  const [assignee, setAssignee] = useState([]);
  const [assigneeList, setAssigneeList] = useState([]);
  const [hotQueueRulesData, setHotQueueRulesData] = useState({});
  const [filter, setFilter] = useState(initialFilter);
  const [threadFilter, setThreadFilter] = useState({
    currentPage: 1,
    pageSize: 25,
  });
  const filterRefBool = useRef(true);
  const [nav, setNav] = useState({
    statusCount: {
      all: undefined,
      pub: undefined,
      pro: undefined,
      err: undefined,
      res: undefined,
      qua: undefined,
      hotQueue: undefined,
      noAttach: undefined,
      hisPrice: undefined,
      inactive: undefined,
      invalid: undefined,
      notAProduct: undefined,
      zipFile: undefined,
      review: undefined,
    },
    dataPerPage: undefined,
    itemCount: undefined,
    totalCount: undefined,
    totalPages: undefined,
    nextPageNum: undefined,
    prevPageNum: undefined,
    hasNextPage: undefined,
    hasPrevPage: undefined,
  });

  const [, setSelectionIndex] = useState(0);

  const [listViewOption, setListViewOption] = useState(statusFilters.all);
  const [shouldRenderCalendar, setCalendarRender] = useState(true);
  const [gotoRepairQueue, setGotoRepairQueue] = useState(false);
  const [notificationMail, setNotificationMail] = useState(false);

  // selectedRecord can be any record in dashboard page
  const [selectedRecord, selectRecord] = useState({});

  // selectedMail will contain 'selectedRecord' record having no thread in mail dashboard page
  const [selectedMail, setSelectedMail] = useState({});
  const [, setShowMoreDetails] = useState(false);

  // selectedThreadMail can be any record selected in thread view table
  const [selectedThreadMail, setSelectedThreadMail] = useState({});

  const [selectedExtension, setSelectedExtension] = useState(undefined);

  // dashboard table data
  const [organizedData, setOrganizedData] = useState([]);

  // thread view table data
  const [mailThreadData, setMailThreadData] = useState([]);

  const [isPopupOpen, setPopupOpen] = useState(false);
  const [isKeyModalOpen, setKeyModalOpen] = useState(false);
  const [isResolveHistoryOpen, setResolveHistoryModalOpen] = useState(false);
  const [isFundTagModalOpen, setFundTagModal] = useState(false);
  const [, setHistoryModal] = useState(false);
  const [isMailModalOpen, setMailModal] = useState({
    open: false,
    replyClicked: false,
  });

  const [scrollToTopVisible, setScrollToTopVisible] = useState(false);
  const [isErrorModalOpen, setErrorModalOpen] = useState(false);
  const [fundTagOptions, setFundTagOptions] = useState([]);
  const [previousSelectedId, setPreviousId] = useState(0);
  const [starringReasons, setStarringReasons] = useState([]);
  const [users, setUsers] = useState([]);
  const [taggedUsers, setTaggedUsers] = useState([]);
  const [starredUsers, setStarredUsers] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(initialSelectedFilter);

  // View All
  const [showingAll, setShowAll] = useState(false);
  const [, setSelectedRecordMailThread] = useState([]);
  const [, setViewAllLoading] = useState(false);

  // state for tracking changes to the mail data list
  const [hasMailListChanged, setHasMailListChanged] = useState(false);

  // state for stopping tracking changes to main filter while secondary filter
  // is changed. Used mainly for stopping side effect on 'filter' state when
  // 'selectedFilter' is changed
  const [resetMainFilter, setResetMainFilter] = useState(false);

  // notification related variables
  const [notificationData, setNotificationData] = useState([]);
  const [notificationTotal, setNotificationTotal] = useState(0);
  const [notificationLoading, setnotificationLoading] = useState(false);
  const [notificationPage, setnotificationPage] = useState(1);
  const notificationMailRef = useRef(notificationMail);
  notificationMailRef.current = notificationMail;

  // mail labels
  const [label, setLabel] = useState([]);
  const [labelPopup, setLabelPopup] = useState(false);
  const [labelsFullList, setLabelsFullList] = useState([]);

  // background refreshing
  const [canRefresh, setCanRefresh] = useState(false);
  const assigneeRef = useRef(assignee);
  assigneeRef.current = assignee;
  const filterRef = useRef(filter);
  filterRef.current = filter;
  const organizedDataRef = useRef(organizedData);
  organizedDataRef.current = organizedData;
  const taggedUsersRef = useRef(taggedUsers);
  taggedUsersRef.current = taggedUsers;
  const starredUsersRef = useRef(starredUsers);
  starredUsersRef.current = starredUsers;
  const selectedFilterRef = useRef(selectedFilter);
  selectedFilterRef.current = selectedFilter;

  const openErrorModal = () => {
    setErrorModalOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalOpen(false);
  };

  const openManualUploadModal = () => {
    setManualUploadPopup(true);
  };
  const closeManualUploadModal = () => {
    setManualUploadPopup(false);
  };

  const openHotQueueModal = () => {
    setHotQueuePopup(true);
  };

  const closeHotQueueModal = () => {
    setHotQueuePopup(false);
  };

  const openKeyModal = () => {
    setKeyModalOpen(true);
  };
  const closeKeyModal = () => {
    setCode("");
    setKeyModalOpen(false);
  };

  const openResolveHistoryModal = () => {
    setResolveHistoryModalOpen(true);
  };
  const closeResolveHistoryModal = () => {
    setResolveHistoryModalOpen(false);
  };
  const openFundTagModal = () => {
    setFundTagModal(true);
  };
  const closeFundTagModal = () => {
    setFundTagModal(false);
  };
  const openMailModal = (replyButton) => {
    setMailModal({
      open: true,
      replyClicked: replyButton,
    });
  };
  const closeMailModal = () => {
    setMailModal({
      open: false,
      replyClicked: false,
    });
  };

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setScrollToTopVisible(true);
    } else if (scrolled <= 300) {
      setScrollToTopVisible(false);
    }
  };

  window.addEventListener("scroll", toggleVisible);

  const generateKey = (data) => {
    setCodeGenLoading(true);
    const payload = {
      fund_name: data.fundName,
      country_code: data.countryCode,
    };
    userServices
      .getCodeGenerator(payload)
      .then((resp) => {
        if (resp.success) setCode(resp.product_code);
        setCodeGenLoading(false);
      })
      .catch(() => {
        setCodeGenLoading(false);
      });
  };

  const deleteQuarantinedMail = () => {
    // deleting quarantined mails from system
    if (selectedRecord?.id && !hasThread && !threadViewPage) {
      // quarantined mails won't be having thread
      const payload = {
        id: selectedRecord.id,
      };
      userServices
        .deleteQuarantinedMail(payload)
        .then(() => {
          showAlert("Mail deleted successfully!", "success");
          setFilter({ ...filter, currentPage: 1 });
        })
        .catch(() => {});
    }
  };

  const resolveQuarantinedMail = () => {
    // resolving quarantined mails from system
    if (selectedRecord?.id && !hasThread && !threadViewPage) {
      // quarantined mails won't be having thread
      const payload = {
        id: selectedRecord.id,
        url: `${window.location.origin}/release-email`,
      };
      userServices
        .getResolveQuarantinedMail(payload)
        .then(() => {
          showAlert("Mail send successfully!", "success");
          setFilter({ ...filter, currentPage: 1 });
        })
        .catch(() => {});
    }
  };

  const currentDataList = organizedData;

  const parseAssigneeList = (dataset) => {
    const assigneeListCopy = remove(
      dataset,
      (item) => item.id === userInfo.userId
    );
    if (assigneeListCopy.length !== 0) {
      assigneeListCopy[0].full_name = "Current User";
      setAssigneeList([...assigneeListCopy, ...dataset]);
    } else setAssigneeList(dataset);
  };

  useEffect(() => {
    document.title = "BCG IA | Dashboard";
    userServices.getFundTags().then((resp) => {
      setFundTagOptions(resp.fund_tags);
    });

    // for updating user info
    appServices.getUserInfo().then((resp) => {
      const userInfoCopy = cloneDeep(userInfo);
      userInfoCopy.email = resp.email;
      userInfoCopy.firstName = resp.first_name;
      userInfoCopy.lastName = resp.last_name;
      userInfoCopy.fullName = resp.full_name;
      userInfoCopy.userRoles = [resp.type_of_user];
      userInfoCopy.userName = resp.username;
      userInfoCopy.userId = resp.id;
      userInfoCopy.gmailURL = resp.gmail_url;
      setUserInfo(userInfoCopy);
    });
  }, []);

  useEffect(() => {
    userServices.getStarringReasons().then((resp) => {
      setStarringReasons(resp.starring_reasons);
    });
  }, []);

  const getUserListDetails = () => {
    userServices.getTaggableUserList().then((resp) => {
      setUsers(
        resp.data.map((item) => ({
          value: item.full_name,
          id: item.id,
          label: item.full_name,
        }))
      );
    });
  };

  useEffect(() => {
    getUserListDetails();
  }, []);

  useEffect(() => {
    userServices.getTaggedUsers().then((resp) => {
      setTaggedUsers(
        resp.map((item) => ({
          value: item.full_name,
          id: item.user_profile_id,
          label: item.full_name,
        }))
      );
    });
  }, []);

  useEffect(() => {
    userServices.getStarredUsers().then((resp) => {
      setStarredUsers(
        resp.map((item) => ({
          value: item.full_name,
          id: item.user_profile_id,
          label: item.full_name,
        }))
      );
    });
  }, []);

  useEffect(() => {
    if (filter.status === statusFilters.processing)
      userServices.getUserList().then((resp) => {
        parseAssigneeList(resp);
      });
  }, [filter]);

  // after setting assignee, call mail search api with currentPage=1
  useEffect(() => {
    // skipping first run
    if (filterRefBool.current) {
      filterRefBool.current = false;
      return;
    }
    setFilter({ ...filter, currentPage: 1 });
  }, [assignee]);

  useEffect(() => {
    if (gotoRepairQueue) {
      setTimeout(() => {
        setFilter({ ...filter, currentPage: 1, status: statusFilters.error });
      }, 100);
    }
  }, [gotoRepairQueue]);

  const clearFilter = () => {
    setCalendarRender(false);
    setTimeout(() => {
      setCalendarRender(true);
    }, 50);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const getNotificationData = async () => {
    setnotificationLoading(true);
    try {
      const res = await userServices.getTaggedNotification({
        page: 1,
      });
      setNotificationData(res.results.data);
      setNotificationTotal(res?.total_count);
      setnotificationPage(res?.next_page_num);
      setnotificationLoading(false);
    } catch (e) {
      setnotificationLoading(false);
    }
  };

  const loadMoreNotificationData = async () => {
    try {
      const res = await userServices.getTaggedNotification({
        page: notificationPage ?? 1,
      });

      setNotificationData([...notificationData, ...res.results.data]);
      setNotificationTotal(res?.total_count);
      setnotificationPage(res?.next_page_num);
    } catch (e) {
      // error
    }
  };

  function getReasonId(reasonText) {
    const reason = starringReasons?.find(
      (newReason) => newReason.value === reasonText
    );
    return reason?.id;
  }

  const filterHasEOWAMails = () =>
    [
      statusFilters.all,
      statusFilters.processing,
      statusFilters.NoAttachment,
      statusFilters.resolved,
    ].includes(filter.status);

  const getData = async ({ assignedTo } = selectedFilter) => {
    setDataLoading(true);
    setPreviewLoading(true);
    setCountLoading(true);

    const filterText = [];

    const dop = moment(filter.dop, "DD-MM-YYYY").format("YYYY-MM-DD");

    if (filter.currentPage) filterText.push(`page=${filter.currentPage}`);
    if (filter.pageSize) filterText.push(`page_size=${filter.pageSize}`);
    if (filter.from) filterText.push(`mail_from=${filter.from}`);
    if (!filter?.all && !assignedTo.length) {
      const fromDate = moment(
        filter.date_from || defaultFilterFromDate,
        "DD-MM-YYYY"
      ).format("YYYY-MM-DD");
      const toDate = moment(
        filter.date_to || defaultFilterToDate,
        "DD-MM-YYYY"
      ).format("YYYY-MM-DD");
      filterText.push(`date_from=${fromDate}`);
      filterText.push(`date_to=${toDate}`);
    }
    if (filter.subject) filterText.push(`subject=${filter.subject}`);
    if (filter.body) filterText.push(`body=${filter.body}`);
    if (filter.status) setListViewOption(filter.status);
    if (filter.status && filter.status !== statusFilters.all)
      filterText.push(`status=${filter.status}`);

    if (filter.manager_name) filterText.push(`manager=${filter.manager_name}`);
    if (filter.prepared_by)
      filterText.push(`prepared_by=${filter.prepared_by}`);
    if (filter.dop) filterText.push(`dop=${dop}`);
    if (filter.reference_id)
      filterText.push(`reference_id=${filter.reference_id.slice(1)}`);
    if (filter.errorCodesList.length !== 0)
      filter.errorCodesList.forEach((el) =>
        filterText.push(`error_code=${el}`)
      );
    if (filter.fundTagList.length !== 0)
      filter.fundTagList.forEach((el) => filterText.push(`fund_tag=${el}`));
    if (filter.editedFundTagList.length !== 0)
      filter.editedFundTagList.forEach((el) =>
        filterText.push(`edit_fund_tag=${el}`)
      );
    if (filter.status === statusFilters.processing && assignee.length !== 0)
      assignee.forEach((el) => filterText.push(`start_by=${el.id}`));

    if (filter.starringReasons.length !== 0) {
      const reasonIds = filter.starringReasons.map((el) => getReasonId(el));
      filterText.push(
        `comment=${reasonIds
          .map((item) => (item == null ? "other" : item))
          .join(",")}`
      );
    }
    if (filter?.read || filter?.unread) {
      let isReadValue = "";
      if (filter?.read) {
        isReadValue = "True";
      } else if (filter?.unread) {
        isReadValue = "False";
      }
      filterText.push(`is_read=${isReadValue}`);
    }

    if (filter.taggedUsers.length || assignedTo.length) {
      const taggedUsersFiltered = taggedUsers.filter((item) =>
        filter.taggedUsers.some((tag) => tag === item.label)
      );
      let taggedUserIds = taggedUsersFiltered?.map((user) => user.id);
      taggedUserIds = [
        ...taggedUserIds,
        ...assignedTo.map(
          (assignedToUserName) =>
            taggedUsers.find(
              (taggedUser) => taggedUser.label === assignedToUserName
            )?.id
        ),
      ];
      if (taggedUserIds.length) {
        filterText.push(`tagged_user=${taggedUserIds.join(",")}`);
      }
    }

    if (filter.starredBy.length !== 0) {
      const starredByUsers = starredUsers.filter((item) =>
        filter.starredBy.some((tag) => tag === item.label)
      );
      const starredByUsersIds = starredByUsers?.map((user) => user.id);
      if (starredByUsersIds.length > 0) {
        filterText.push(`starred_by=${starredByUsersIds.join(",")}`);
      }
    }

    const query = filterText.join("&");
    let newMailData = [];
    try {
      const resData = await userServices.getMailSearch(query, false);
      const mailData = resData?.results?.data;

      if (mailData.length > 0) {
        setHasThread(true);
        setSelectionIndex(0);
      }
      newMailData = mailData;
      if (filterHasEOWAMails()) {
        newMailData = await getEOWAMappedMails(mailData);
      }
      setOrganizedData(newMailData);
      setPreviewLoading(false);
      setDataLoading(false);
      const resStatusData = await userServices.getMailSearch(query, true);
      const {
        pub,
        pro,
        err,
        res,
        quarantined,
        hot_queue: hotQueue,
        no_attachment: noAttachment,
        historical_price_error: historicalPriceError,
        inactive_error: inactiveError,
        invalid_error: invalidError,
        not_a_product_error: notAProductError,
        zip_file_error: zipFileError,
        known_attachment_error: knownAttachmentError,
        review_error: reviewError,
      } = resStatusData?.status_count || {};
      setNav({
        statusCount: {
          all:
            pub +
            pro +
            err +
            res +
            quarantined +
            hotQueue +
            noAttachment +
            historicalPriceError +
            inactiveError +
            invalidError +
            notAProductError +
            zipFileError +
            knownAttachmentError +
            reviewError,
          pub,
          pro,
          err,
          res,
          qua: quarantined,
          hotQueue,
          noAttach: noAttachment,
          hisPrice: historicalPriceError,
          inactive: inactiveError,
          invalid: invalidError,
          notAProduct: notAProductError,
          zipFile: zipFileError,
          ewka: knownAttachmentError,
          review: reviewError,
        },
        // no of data in a page (not a constant)
        totalCount: resData?.total_count,
        // pagination constant 25
        dataPerPage: resData?.data_per_page,
        itemCount: resData?.results?.data.length,
        nextPageNum: resData?.next_page_num,
        prevPageNum: resData?.prev_page_num,
        hasNextPage: resData?.has_next_page || null,
        hasPrevPage: resData?.has_prev_page || null,
      });
      setCountLoading(false);
      window.scrollTo(0, 0);
      await getNotificationData();
    } catch (error) {
      // error occured while fetching mail list
      setPreviewLoading(false);
      setDataLoading(false);
      setCountLoading(false);
    }

    setResetMainFilter(false);
    return newMailData;
  };

  useEffect(() => {
    if (!resetMainFilter) {
      getData();
      setShowAll(false);
    }
  }, [filter]);

  const queryParsing = (refresh) => {
    const { assignedTo } = selectedFilterRef.current;
    const filterText = [];

    const dop = moment(filterRef.current.dop, "DD-MM-YYYY").format(
      "YYYY-MM-DD"
    );

    if (filterRef.current.currentPage)
      filterText.push(`page=${filterRef.current.currentPage}`);
    if (filterRef.current.pageSize)
      filterText.push(`page_size=${filterRef.current.pageSize}`);
    if (filterRef.current.from)
      filterText.push(`mail_from=${filterRef.current.from}`);
    if (!filterRef.current?.all && !assignedTo.length) {
      const fromDate = moment(
        filterRef.current.date_from || defaultFilterFromDate,
        "DD-MM-YYYY"
      ).format("YYYY-MM-DD");
      const toDate = moment(
        filterRef.current.date_to || defaultFilterToDate,
        "DD-MM-YYYY"
      ).format("YYYY-MM-DD");
      filterText.push(`date_from=${fromDate}`);
      filterText.push(`date_to=${toDate}`);
    }
    if (filterRef.current.subject)
      filterText.push(`subject=${filterRef.current.subject}`);
    if (filterRef.current.body)
      filterText.push(`body=${filterRef.current.body}`);
    if (filterRef.current.status) setListViewOption(filterRef.current.status);
    if (
      filterRef.current.status &&
      filterRef.current.status !== statusFilters.all
    )
      filterText.push(`status=${filterRef.current.status}`);

    if (filterRef.current.manager_name)
      filterText.push(`manager=${filterRef.current.manager_name}`);
    if (filterRef.current.prepared_by)
      filterText.push(`prepared_by=${filterRef.current.prepared_by}`);
    if (filterRef.current.dop) filterText.push(`dop=${dop}`);
    if (filterRef.current.reference_id)
      filterText.push(
        `reference_id=${filterRef.current.reference_id.slice(1)}`
      );
    if (filterRef.current.errorCodesList.length !== 0)
      filterRef.current.errorCodesList.forEach((el) =>
        filterText.push(`error_code=${el}`)
      );
    if (filterRef.current.fundTagList.length !== 0)
      filterRef.current.fundTagList.forEach((el) =>
        filterText.push(`fund_tag=${el}`)
      );
    if (filterRef.current.editedFundTagList.length !== 0)
      filterRef.current.editedFundTagList.forEach((el) =>
        filterText.push(`fund_tag=${el}`)
      );
    if (
      filterRef.current.status === statusFilters.processing &&
      assigneeRef.current.length !== 0
    )
      assigneeRef.current.forEach((el) => filterText.push(`start_by=${el.id}`));

    if (filterRef.current.starringReasons.length !== 0) {
      const reasonIds = filterRef.current.starringReasons.map((el) =>
        getReasonId(el)
      );
      filterText.push(
        `comment=${reasonIds
          .map((item) => (item == null ? "other" : item))
          .join(",")}`
      );
    }
    if (filterRef.current?.read || filterRef.current?.unread) {
      let isReadValue = "";
      if (filterRef.current?.read) {
        isReadValue = "True";
      } else if (filterRef.current?.unread) {
        isReadValue = "False";
      }
      filterText.push(`is_read=${isReadValue}`);
    }

    if (filterRef.current.taggedUsers.length || assignedTo.length) {
      const taggedUsersFiltered = taggedUsersRef.current.filter((item) =>
        filterRef.current.taggedUsers.some((tag) => tag === item.label)
      );
      let taggedUserIds = taggedUsersFiltered?.map((user) => user.id);
      taggedUserIds = [
        ...taggedUserIds,
        ...assignedTo.map(
          (assignedToUserName) =>
            taggedUsersRef.current.find(
              (taggedUser) => taggedUser.label === assignedToUserName
            )?.id
        ),
      ];
      if (taggedUserIds.length) {
        filterText.push(`tagged_user=${taggedUserIds.join(",")}`);
      }
    }

    if (filterRef.current.starredBy.length !== 0) {
      const starredByUsers = starredUsersRef.current.filter((item) =>
        filterRef.current.starredBy.some((tag) => tag === item.label)
      );
      const starredByUsersIds = starredByUsers?.map((user) => user.id);
      if (starredByUsersIds.length > 0) {
        filterText.push(`starred_by=${starredByUsersIds.join(",")}`);
      }
    }

    if (refresh) {
      filterText.push(`refresh=true`);
      if (organizedDataRef?.current?.length > 0)
        filterText.push(
          `latest_id=${
            organizedDataRef?.current?.length > 0
              ? organizedDataRef?.current[0]?.id
              : ""
          }`
        );
    }

    const query = filterText.join("&");

    return query;
  };

  const repeatDataCall = async () => {
    const query = queryParsing(true);
    try {
      if (!notificationMailRef.current) {
        const resData = await userServices.getMailSearch(query, false);

        if (resData?.status) {
          setCanRefresh(true);
        }
      }
    } catch (e) {
      // error
    }
  };

  const OnRefreshForNewData = async () => {
    const query = queryParsing();
    setPreviewLoading(true);
    setDataLoading(true);
    let newMailData = [];
    try {
      const resData = await userServices.getMailSearch(query, false);
      const mailData = resData?.results?.data;
      if (mailData?.length > 0) {
        setHasThread(true);
        setSelectionIndex(0);
      }
      newMailData = mailData;
      if (filterHasEOWAMails()) {
        newMailData = await getEOWAMappedMails(mailData);
      }
      setOrganizedData(newMailData);
      setPreviewLoading(false);
      setDataLoading(false);

      const resStatusData = await userServices.getMailSearch(query, true);
      const {
        pub,
        pro,
        err,
        res,
        quarantined,
        hot_queue: hotQueue,
        no_attachment: noAttachment,
        historical_price_error: historicalPriceError,
        inactive_error: inactiveError,
        invalid_error: invalidError,
        not_a_product_error: notAProductError,
        zip_file_error: zipFileError,
        known_attachment_error: knownAttachmentError,
      } = resStatusData?.status_count || {};
      setNav({
        statusCount: {
          all:
            pub +
            pro +
            err +
            res +
            quarantined +
            hotQueue +
            noAttachment +
            historicalPriceError +
            inactiveError +
            invalidError +
            notAProductError +
            zipFileError +
            knownAttachmentError,
          pub,
          pro,
          err,
          res,
          qua: quarantined,
          hotQueue,
          noAttach: noAttachment,
          hisPrice: historicalPriceError,
          inactive: inactiveError,
          invalid: invalidError,
          notAProduct: notAProductError,
          zipFile: zipFileError,
          ewka: knownAttachmentError,
        },
        // no of data in a page (not a constant)
        totalCount: resData?.total_count,
        // pagination constant 25
        dataPerPage: resData?.data_per_page,
        itemCount: resData?.results?.data.length,
        nextPageNum: resData?.next_page_num,
        prevPageNum: resData?.prev_page_num,
        hasNextPage: resData?.has_next_page || null,
        hasPrevPage: resData?.has_prev_page || null,
      });
      window.scrollTo(0, 0);
      setCanRefresh(false);
    } catch (e) {
      // error
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      repeatDataCall();
    }, 20000);
    return () => clearInterval(interval);
  }, []);

  const attachmentQuery = (urlParams) => {
    const queryText = [];
    if (threadFilter.currentPage)
      queryText.push(`page=${threadFilter.currentPage}`);
    if (threadFilter.pageSize)
      queryText.push(`page_size=${threadFilter.pageSize}`);
    if (status === statusFilters.all && urlParams) {
      queryText.push(urlParams);
    } else if (
      selectedRecord?.ref_ids?.length !== 0 &&
      selectedRecord?.ref_ids?.length < 50
    ) {
      if (selectedRecord?.ref_ids?.length !== 0)
        selectedRecord.ref_ids.forEach((el) =>
          queryText.push(`reference_id=${el}`)
        );
    } else {
      if (status !== statusFilters.all) queryText.push(`status_flag=${status}`);
      if (urlParams) queryText.push(urlParams);
    }

    return queryText;
  };

  // for getting mail thread view
  useEffect(() => {
    if (mailRefId && status && threadViewPage) {
      setGotoRepairQueue(false);
      setPreviewLoading(true);
      const urlParams = location.search.replace("?", "");
      setDataLoading(true);
      const queryText = attachmentQuery(urlParams);

      const query = queryText.join("&");
      userServices
        .getMailAttachments(mailRefId, query)
        .then((resp) => {
          setDataLoading(false);
          // if there is no data in thread view page while status = processing, redirect to repair queue
          if (status === statusFilters.processing && resp?.length === 0) {
            setGotoRepairQueue(true);
            history.push("/dashboard");
          } else {
            setMailThreadData(resp);
            setSelectedExtension(undefined);
            setDataLoading(false);
          }
          setPreviewLoading(false);
        })
        .catch(() => {
          setDataLoading(false);
          setPreviewLoading(false);
          history.push("/dashboard");
        });
    }
  }, [location, threadFilter]);

  /**
   *
   * for selecting record in mail dashboard table
   *
   * */
  useEffect(() => {
    // EWOA and quarantine will not be having thread. It wil be a single record al the time.
    if (organizedData.length) {
      const selectRecordCopy = find(organizedData, ["id", selectedRecord.id]);
      if (isEmpty(selectedRecord) || selectRecordCopy === undefined) {
        setHasThread(true);
        selectRecord(organizedData[0]);
      } else {
        setHasThread(cloneDeep(hasThread));
        selectRecord({ ...selectRecordCopy });
      }
    }
  }, [organizedData]);

  function getAttachmentWithRefId(
    refId,
    showLoading,
    isInitialLoading = false
  ) {
    const queryText = [];

    if (!isInitialLoading) {
      if (selectedExtension) {
        mailThreadData.forEach((item) => {
          if (item.child.some((child) => child.reference_id === refId)) {
            item.child.forEach((child) => {
              queryText.push(`reference_id=${child.reference_id}`);
            });
          }
        });
      } else if (refId) queryText.push(`reference_id=${refId}`);
    } else if (refId) queryText.push(`reference_id=${refId}`);

    const queryTextURL = queryText.join("&");
    setPreviewLoading(showLoading);
    userServices
      .getMailAttachments(selectedRecord.id, queryTextURL)
      .then((resp) => {
        if (threadViewPage) {
          if (resp.length > 0) {
            const indexToReplace = mailThreadData.findIndex(
              (item) => item.reference_id === refId
            );
            if (indexToReplace >= 0 && indexToReplace < mailThreadData.length) {
              const data = mailThreadData;
              const [firstResponse] = resp;
              data[indexToReplace] = firstResponse;
              setMailThreadData([...data]);
              setSelectedExtension(undefined);
            } else {
              let parentIndex = -1;
              let childIndex = -1;
              mailThreadData.forEach((item, index) => {
                item.child.forEach((child, cIndex) => {
                  if (child.reference_id === refId) {
                    parentIndex = index;
                    childIndex = cIndex;
                  }
                });
              });
              if (parentIndex >= 0 && parentIndex < mailThreadData.length) {
                const data = mailThreadData;
                const [firstResponse] = resp;
                if (
                  childIndex >= 0 &&
                  childIndex < data[parentIndex].child.length
                ) {
                  const childToReplace = firstResponse.child.find(
                    (item) => item.reference_id === refId
                  );
                  if (childToReplace) {
                    data[parentIndex].child[childIndex] = childToReplace;
                    setSelectedExtension(childToReplace);
                  } else {
                    data[parentIndex] = firstResponse;
                  }
                } else {
                  data[parentIndex] = firstResponse;
                }
                setMailThreadData([...data]);
                if (showLoading) setSelectedExtension(undefined);
              }
            }
          }
        } else {
          try {
            if (resp[0].child.length < 1) {
              setSelectedMail(resp[0]);
            } else {
              setSelectedMail(resp[0].child[0]);
            }
            setShowMoreDetails(resp[0].child.length > 0);
          } catch (error) {
            setSelectedMail(resp[0]);
            setShowMoreDetails(false);
          }
        }
        setPreviewLoading(false);
      })
      .catch(() => {
        setPreviewLoading(false);
      });
  }

  const attachmentQuery2 = () => {
    const queryText = [];
    const dop = moment(filter.dop, "DD-MM-YYYY").format("YYYY-MM-DD");

    if (threadFilter.currentPage)
      queryText.push(`page=${threadFilter.currentPage}`);
    if (filter.reference_id)
      queryText.push(`reference_id=${filter.reference_id.slice(1)}`);
    if (filter.manager_name) queryText.push(`manager=${filter.manager_name}`);
    if (filter.prepared_by) queryText.push(`prepared_by=${filter.prepared_by}`);
    if (filter.status && filter.status !== statusFilters.all)
      queryText.push(`status_flag=${filter.status}`);
    if (filter.dop) queryText.push(`dop=${dop}`);
    if (filter.errorCodesList.length !== 0)
      filter.errorCodesList.forEach((el) => {
        queryText.push(`error_code=${el}`);
      });
    if (filter.status === statusFilters.processing && assignee.length !== 0)
      assignee.forEach((el) => queryText.push(`start_by=${el.id}`));

    return queryText;
  };

  /**
   *
   * for fetching the mail attachment details(preview data) in dashboard page if there is no thread
   *
   */
  useEffect(() => {
    if (selectedRecord?.id && !hasThread && !threadViewPage) {
      if (selectedRecord.ref_ids.length > 0) {
        getAttachmentWithRefId(
          selectedRecord.ref_ids[0],
          previousSelectedId !== selectedRecord?.id,
          true
        );
      } else {
        const queryText = attachmentQuery2();

        const queryTextURL = queryText.join("&");
        setPreviewLoading(previousSelectedId !== selectedRecord?.id);
        userServices
          .getMailAttachments(selectedRecord.id, queryTextURL)
          .then((resp) => {
            try {
              if (resp[0].child.length < 1) {
                setSelectedMail(resp[0]);
              } else {
                setSelectedMail(resp[0].child[0]);
              }
              setShowMoreDetails(resp[0].child.length > 0);
            } catch (error) {
              setSelectedMail(resp[0]);
              setShowMoreDetails(false);
            }
            setPreviewLoading(false);
          })
          .catch(() => {
            setPreviewLoading(false);
          });
      }
    }
    setPreviousId(selectedRecord?.id);
  }, [selectedRecord, hasThread]);

  useEffect(() => {
    if (showingAll === true && selectedRecord?.id) {
      setViewAllLoading(true);
      userServices
        .getMailAttachmentsPartial(selectedRecord.id)
        .then((resp) => {
          if (resp.length > 0) {
            setSelectedRecordMailThread(resp);
          }
          setViewAllLoading(false);
        })
        .catch(() => {
          setViewAllLoading(false);
        });
    }
  }, [selectedRecord, showingAll]);

  function getMailDetails() {
    const queryText = [];
    if (listViewOption !== statusFilters.all) {
      if (
        selectedRecord.ref_ids.length !== 0 &&
        selectedRecord.ref_ids.length < 50
      ) {
        selectedRecord.ref_ids.forEach((el) => queryText.push(`ref_id=${el}`));
      } else {
        queryText.push(`status=${listViewOption}`);
      }
    }

    const query = queryText.join("&");

    if (selectedRecord?.id !== null)
      userServices
        .getMailDetails(selectedRecord?.id, query)
        .then(async (response) => {
          const indexToReplace = organizedData.findIndex(
            (item) => item.id === selectedRecord.id
          );
          if (indexToReplace >= 0 && indexToReplace < organizedData.length) {
            const data = organizedData;
            data[indexToReplace] = response;
            let newMailData = data;
            if (filterHasEOWAMails()) {
              newMailData = await getEOWAMappedMails(data);
            }
            setOrganizedData(newMailData);
            setHasMailListChanged(true);
          }
        });
  }

  function updateMailWithRefId(refId) {
    const isQuarantined = !(
      selectedRecord?.req_quarantined_count === 0 &&
      selectedRecord?.quarantined_count === 0 &&
      selectedRecord?.qua_release_count === 0
    );

    const hasNoAttachments =
      (selectedRecord?.total_attachment_count === 1 && isQuarantined) ||
      selectedRecord?.no_attachment_count !== 0;

    // Update status of the mail with a multiple items from Filter All Page
    if (selectedThreadMail || selectedExtension || !hasNoAttachments) {
      getMailDetails();
      return;
    }

    // Update status of the mail with a single item
    const filterText = [];
    filterText.push(`reference_id=${refId}`);
    const query = filterText.join("&");
    userServices.getMailSearch(query, false).then(async (resData) => {
      const mailData = resData?.results?.data;
      if (mailData.length) {
        const indexToReplace = organizedData.findIndex(
          (item) => item.id === selectedRecord.id
        );
        if (indexToReplace !== -1) {
          const data = organizedData;
          const [firstMailData] = mailData;
          data[indexToReplace] = firstMailData;
          let newMailData = data;
          if (filterHasEOWAMails()) {
            newMailData = await getEOWAMappedMails(data);
          }
          setOrganizedData(newMailData);
          setHasMailListChanged(true);
        }
      }
    });
  }

  // keyboard shortcuts
  useKeyboardShortcut(["Control", "f"], () => setPopupOpen(true), {
    overrideSystem: true,
  });
  useKeyboardShortcut(["Escape"], () => setPopupOpen(false));

  const handleOthers = (event) => {
    setFilter({
      ...filter,
      currentPage: 1,
      status: event.target.value,
    });
  };

  const ListViewPrefOption = useCallback(
    ({ badge, name, value = "" }) => (
      <ListViewPrefOptions
        {...{
          badge,
          name,
          value,
          filter,
          statusFilters,
          listViewOption,
          selectRecord,
          setFilter,
          loading: loadingData || previewLoading || loadingCount,
        }}
      />
    ),
    [filter, listViewOption, loadingData, previewLoading, loadingCount]
  );

  const LastPrefOptions = useCallback(
    () => (
      <LastPrefOption
        {...{
          filter,
          statusFilters,
          nav,
          otherStatusOptions,
          listViewOption,
          handleOthers,
        }}
      />
    ),
    [filter, nav, otherStatusOptions, listViewOption]
  );

  const DashboardButtons = useCallback(
    () => (
      <DashboardButton
        {...{
          threadViewPage,
          setMailThreadData,
          setSelectedExtension,
          setThreadFilter,
          selectedRecord,
          setFilter,
          filter,
          mailThreadData,
          history,
          nav,
          ListViewPrefOption,
          LastPrefOptions,
        }}
      />
    ),
    [
      threadViewPage,
      selectedRecord,
      filter,
      mailThreadData,
      history,
      nav,
      ListViewPrefOption,
      LastPrefOptions,
    ]
  );

  const backToAllResults = async () => {
    setNotificationMail(false);
    await getData();
  };

  const onAssignedToMe = (itemRefID) => {
    // Including the current user in the assignee list for avoiding data being filtered out
    if (assignee.length > 0) {
      if (!assignee.some((item) => item.id === userInfo.userId)) {
        setAssignee([
          ...assignee,
          {
            full_name: "Current User",
            id: userInfo.userId,
          },
        ]);
      }
    }

    getAttachmentWithRefId(itemRefID, false);
    updateMailWithRefId(itemRefID);
  };

  const onResolved = async (_, itemRefID) => {
    getAttachmentWithRefId(itemRefID, false);
    updateMailWithRefId(itemRefID);
    if (listViewOption === statusFilters.processing)
      setNav({
        ...nav,
        statusCount: { ...nav.statusCount, pro: nav.statusCount.pro - 1 },
      });

    await getNotificationData();
  };

  const onResolvedAll = async () => {
    getMailDetails();
    await getNotificationData();
  };

  const swapViewAll = () => {
    setShowAll(!showingAll);
  };

  const onUpdate = () => {
    getNotificationData();
    getMailDetails();
  };

  const onStartedWorking = (itemRefID) => {
    // A hack to invoke the method to refresh the page
    getAttachmentWithRefId(itemRefID, false);
    updateMailWithRefId(itemRefID);

    // To update counts in the Nav Bar
    switch (listViewOption) {
      case statusFilters.all:
      case statusFilters.processing:
        setNav({
          ...nav,
          statusCount: { ...nav.statusCount, pro: nav.statusCount.pro + 1 },
        });

        break;
      case statusFilters.error:
        setNav({
          ...nav,
          statusCount: { ...nav.statusCount, err: nav.statusCount.err - 1 },
        });
        break;
      case statusFilters.hotQueue:
        setNav({
          ...nav,
          statusCount: {
            ...nav.statusCount,
            hotQueue: nav.statusCount.hotQueue - 1,
          },
        });
        break;
      case statusFilters.NoAttachment:
        setNav({
          ...nav,
          statusCount: {
            ...nav.statusCount,
            noAttach: nav.statusCount.noAttach - 1,
          },
        });
        break;
      case statusFilters.historicalPrice:
        setNav({
          ...nav,
          statusCount: {
            ...nav.statusCount,
            hisPrice: nav.statusCount.hisPrice - 1,
          },
        });
        break;
      case statusFilters.inactive:
        setNav({
          ...nav,
          statusCount: {
            ...nav.statusCount,
            inactive: nav.statusCount.inactive - 1,
          },
        });
        break;
      case statusFilters.invalid:
        setNav({
          ...nav,
          statusCount: {
            ...nav.statusCount,
            invalid: nav.statusCount.invalid - 1,
          },
        });
        break;
      case statusFilters.notAProduct:
        setNav({
          ...nav,
          statusCount: {
            ...nav.statusCount,
            notAProduct: nav.statusCount.notAProduct - 1,
          },
        });
        break;
      case statusFilters.zipFile:
        setNav({
          ...nav,
          statusCount: {
            ...nav.statusCount,
            zipFile: nav.statusCount.zipFile - 1,
          },
        });
        break;
      case statusFilters.ewka:
        setNav({
          ...nav,
          statusCount: {
            ...nav.statusCount,
            ewka: nav.statusCount.ewka - 1,
          },
        });
        break;
      default:
        break;
    }
  };

  const onStartedWorkingOnAll = () => {
    getMailDetails();
  };

  const onStoppedWorking = (itemRefID) => {
    // A hack to invoke the method to refresh the page
    getAttachmentWithRefId(itemRefID, false);
    updateMailWithRefId(itemRefID);

    // To update counts in the Nav Bar
    switch (listViewOption) {
      case statusFilters.all:
      case statusFilters.processing:
        setNav({
          ...nav,
          statusCount: { ...nav.statusCount, pro: nav.statusCount.pro - 1 },
        });
        break;
      case statusFilters.error:
        setNav({
          ...nav,
          statusCount: { ...nav.statusCount, err: nav.statusCount.err + 1 },
        });
        break;
      case statusFilters.hotQueue:
        setNav({
          ...nav,
          statusCount: {
            ...nav.statusCount,
            hotQueue: nav.statusCount.hotQueue + 1,
          },
        });
        break;
      case statusFilters.NoAttachment:
        setNav({
          ...nav,
          statusCount: {
            ...nav.statusCount,
            noAttach: nav.statusCount.noAttach + 1,
          },
        });
        break;
      case statusFilters.historicalPrice:
        setNav({
          ...nav,
          statusCount: {
            ...nav.statusCount,
            hisPrice: nav.statusCount.hisPrice + 1,
          },
        });
        break;
      case statusFilters.inactive:
        setNav({
          ...nav,
          statusCount: {
            ...nav.statusCount,
            inactive: nav.statusCount.inactive + 1,
          },
        });
        break;
      case statusFilters.invalid:
        setNav({
          ...nav,
          statusCount: {
            ...nav.statusCount,
            invalid: nav.statusCount.invalid + 1,
          },
        });
        break;
      case statusFilters.notAProduct:
        setNav({
          ...nav,
          statusCount: {
            ...nav.statusCount,
            notAProduct: nav.statusCount.notAProduct + 1,
          },
        });
        break;
      case statusFilters.zipFile:
        setNav({
          ...nav,
          statusCount: {
            ...nav.statusCount,
            zipFile: nav.statusCount.zipFile + 1,
          },
        });
        break;
      case statusFilters.ewka:
        setNav({
          ...nav,
          statusCount: {
            ...nav.statusCount,
            ewka: nav.statusCount.ewka + 1,
          },
        });
        break;
      default:
        break;
    }
  };

  const onStoppedWorkingOnAll = () => {
    getMailDetails();
  };

  const onEditedFundTag = () => {
    getMailDetails();
  };

  const onPageChange = (page, pageSize) => {
    if (threadViewPage) {
      setThreadFilter({ currentPage: page, pageSize });
    } else {
      setFilter({ ...filter, currentPage: page, pageSize });
    }
    setSelectedExtension(null);
    setSelectedThreadMail(null);
  };

  const onSelectedFilter = (event) => {
    const {
      target: { name, checked },
    } = event;
    let { read, unread } = filter;
    if (name === "read") {
      read = true;
      unread = false;
    }
    if (name === "unread") {
      read = false;
      unread = true;
    }

    setFilter((prevState) => {
      const newValue = {
        [name]: checked,
      };
      return {
        ...prevState,
        currentPage: 1,
        // all: !["read", "unread"].includes(name),
        read,
        unread,
        ...newValue,
      };
    });
    setResetMainFilter(true);
  };

  const hotQueueClickHandler = async (ruleId) => {
    if (ruleId) {
      try {
        const res = await hotQueueServices.getHotQueueDetailsById(ruleId);
        setHotQueueRulesData(res);
      } catch (err) {
        // catch error
      }
    }
  };

  useEffect(() => {
    if (organizedData.length > 0) {
      hotQueueClickHandler(organizedData[0].hot_queue);
    }
  }, [organizedData]);

  useEffect(() => {
    (async () => {
      if (resetMainFilter) {
        const newOrganizedData = await getData(selectedFilter);
        if (newOrganizedData.length) {
          selectRecord(newOrganizedData[0]);
        }
      }
    })();
  }, [selectedFilter]);

  // inner expansion handler when mouse down
  const handlerInner = (mouseDownEvent) => {
    const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };
    const table = document.getElementById("dashboard-table");
    const preview = document.getElementById("preview");
    const leftWidth = table.getBoundingClientRect().width;

    function onMouseMove(mouseMoveEvent) {
      document.body.style.cursor = "col-resize";

      table.style.userSelect = "none";
      table.style.pointerEvents = "none";
      preview.style.userSelect = "none";
      preview.style.pointerEvents = "none";
      const dx = mouseMoveEvent.pageX - startPosition.x;
      const width = leftWidth + dx;

      if (width >= 790) {
        table.style.width = `${width}px`;
      }
    }

    function onMouseUp() {
      document.body.removeEventListener("mousemove", onMouseMove);
      // uncomment the following line if not using `{ once: true }`
      document.body.removeEventListener("mouseup", onMouseUp);

      document.body.style.removeProperty("cursor");
      table.style.userSelect = "text";
      table.style.removeProperty("pointer-events");
      preview.style.userSelect = "text";
      preview.style.removeProperty("pointer-events");
    }

    document.body.addEventListener("mousemove", onMouseMove);
    document.body.addEventListener("mouseup", onMouseUp);
  };

  // outter expansion handler on mouse down
  const handler = (mouseDownEvent) => {
    const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };
    const table = document.getElementById("dashboard-table");

    const preview = document.getElementById("preview");

    const previewContainer = document.getElementById("preview-container");
    const previewContainerWidth = previewContainer.getBoundingClientRect()
      .width;

    function onMouseMove(mouseMoveEvent) {
      document.body.style.cursor = "col-resize";

      table.style.userSelect = "none";
      table.style.pointerEvents = "none";
      preview.style.userSelect = "none";
      preview.style.pointerEvents = "none";

      const dx = mouseMoveEvent.pageX - startPosition.x;

      const width = previewContainerWidth + dx;

      // to make a max and min move of expansion
      if (width > 400) {
        previewContainer.style.width = `${width}px`;
      }
    }
    function onMouseUp() {
      document.body.removeEventListener("mousemove", onMouseMove);
      // uncomment the following line if not using `{ once: true }`
      // document.body.removeEventListener("mouseup", onMouseUp);

      document.body.style.removeProperty("cursor");
      table.style.userSelect = "text";
      table.style.removeProperty("pointer-events");
      preview.style.userSelect = "text";
      preview.style.removeProperty("pointer-events");
    }

    document.body.addEventListener("mousemove", onMouseMove);
    document.body.addEventListener("mouseup", onMouseUp, { once: true });
  };

  const notificationClickHandler = async (record) => {
    selectRecord(record);
    setOrganizedData([record]);
    setHasThread(true);
    setNotificationMail(true);
  };

  const getMailLabel = async (id) => {
    try {
      const messageID = id ?? selectedRecord?.message_id;
      if (messageID) {
        const res = await userServices.getMailLabelWithId(messageID);
        setLabel(
          res?.email_labels.filter(
            (x) => !["IMPORTANT", "CATEGORY_PERSONAL"].includes(x.label_id)
          )
        );
      }
    } catch (e) {
      // errr
    }
  };

  const loadlabelList = async () => {
    try {
      const res = await userServices.getMailLabel();

      setLabelsFullList(res.email_labels);
    } catch (e) {
      // err
    }
  };

  useEffect(() => {
    loadlabelList();
    setLabel([]);
    getMailLabel();
  }, [selectedRecord]);

  const labelHandler = async (id, action) => {
    if (selectedRecord?.message_id) {
      let actionType = "";
      const list = [...labelsFullList];
      const labelsList = [...label];
      const elementIndex = labelsList.map((m) => m.id).indexOf(id);
      let removeLabelID = 0;
      if (elementIndex > -1) {
        actionType = "remove";
        removeLabelID = labelsList[elementIndex].label_id;
        labelsList.splice(elementIndex, 1);
      } else {
        actionType = "update";
        labelsList.push(list.filter((x) => x.id === id)[0]);
      }
      try {
        if (actionType === "update") {
          const payload = {
            id: selectedRecord?.message_id,
            list: labelsList.map((m) => ({
              label_id: m.label_id,
              label_name: m.label_name,
              text_color: m?.color?.textColor ?? "",
              background_color: m?.color?.backgroundColor ?? "",
            })),
          };
          await userServices.updateMailLabelWithId(payload);
        }
        if (actionType === "remove") {
          const payload = {
            message_id: selectedRecord?.message_id,
            label_id: removeLabelID,
            id,
          };
          await userServices.removeMailLabelWithId(payload);
        }
        showAlert("Label updated successfully", "success");
        setLabel(labelsList);
      } catch (e) {
        // err
        showAlert("Label update failed", "error");
      }
    }
  };

  const renderPreview = () => {
    if (previewLoading) {
      return <PreviewSkeleton />;
    }
    if (hasThread) {
      if (organizedData.length) {
        return (
          <Preview
            listViewOption={listViewOption}
            fundTagOptions={fundTagOptions}
            selectedRecord={selectedRecord}
            openFundTagModal={openFundTagModal}
            userAddedFundTag={selectedRecord?.user_added_fund_tag || []}
            threadCount={
              selectedRecord.reply_email_details &&
              selectedRecord.reply_email_details.length + 1
            }
            openMailModal={openMailModal}
            onStartedWorking={onStartedWorkingOnAll}
            onStoppedWorking={onStoppedWorkingOnAll}
            onResolved={onResolvedAll}
            showingAll={showingAll}
            swapViewAll={swapViewAll}
            userInfo={userInfo}
            expandHandler={handlerInner}
            tableRef={tableRef}
            hotQueueRulesData={hotQueueRulesData}
            mailLabels={label}
            labelHandler={labelHandler}
          />
        );
      }
    } else if (organizedData.length) {
      return (
        <MailDetailPreview
          mailRefId={mailRefId || selectedRecord.id}
          status={status}
          threadFilter={threadFilter}
          setThreadFilter={setThreadFilter}
          filter={filter}
          setFilter={setFilter}
          fundTagOptions={fundTagOptions}
          selectedRecord={selectedExtension || selectedThreadMail}
          threadCount={
            selectedRecord.reply_email_details &&
            selectedRecord.reply_email_details.length + 1
          }
          deleteQuarantinedMail={deleteQuarantinedMail}
          resolveQuarantinedMail={resolveQuarantinedMail}
          openErrorModal={openErrorModal}
          openFundTagModal={openFundTagModal}
          openMailModal={openMailModal}
          openHistoryModal={() => setHistoryModal(true)}
          userAddedFundTag={selectedRecord?.user_added_fund_tag || []}
          onAssignedToMe={onAssignedToMe}
          onResolved={onResolved}
          onStartedWorking={onStartedWorking}
          onStoppedWorking={onStoppedWorking}
          showingAll={showingAll}
          swapViewAll={swapViewAll}
          openResolveHistoryModal={openResolveHistoryModal}
          userInfo={userInfo}
          expandHandler={handlerInner}
          tableRef={tableRef}
          hotQueueRulesData={hotQueueRulesData}
          mailLabels={label}
          labelHandler={labelHandler}
          hotQueue={!!selectedRecord?.hot_queue}
        />
      );
    }
    return null;
  };

  return (
    <div className={styles.page}>
      <div className={styles.pageContent}>
        <div className="layout">
          <Sidebar />
          <div className="layout-container">
            <MainHeader
              title="Dashboard"
              enableNotification
              notificatioProps={{
                getData: getNotificationData,
                data: notificationData,
                total: notificationTotal,
                loading: notificationLoading,
                notificationClickHandler,
                loadMore: loadMoreNotificationData,
              }}
            />
            <div className={styles.mainContent}>
              <div className={styles.listViewPref}>
                <div className={styles.listViewPrefOptions}>
                  {!notificationMail && <DashboardButtons />}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div className={styles.assignedToContainer}>
                    {selectedFilter.assignedTo.length === 0 && (
                      <div className={styles.label}>Tagged Users</div>
                    )}
                    <MultiSelect
                      disabled={loadingData || previewLoading}
                      onChange={(event) => {
                        setSelectedFilter((prevState) => {
                          const newAssignedTo = event.target.value;
                          if (Array.isArray(newAssignedTo)) {
                            return {
                              ...prevState,
                              assignedTo: newAssignedTo,
                            };
                          }
                          return prevState;
                        });
                        setFilter(initialFilter);
                        resetSearchForm();
                        setResetMainFilter(true);
                      }}
                      value={selectedFilter.assignedTo}
                      listOptions={taggedUsers}
                      placeholder="dfd"
                    />
                  </div>

                  <Tooltip title="Manual Upload">
                    <button
                      type="button"
                      onClick={openManualUploadModal}
                      className={styles.manualUploadButton}
                    >
                      <img alt="manual-upload" src="/assets/upload-cloud.svg" />
                    </button>
                  </Tooltip>
                  <div className={styles.listViewPrefSearch}>
                    <div
                      className={cn({
                        [styles.listViewPrefSearchBox]: true,
                        [styles.popupOpen]: isPopupOpen,
                        [styles.hide]: threadViewPage,
                      })}
                    >
                      <svg
                        className={styles.listViewPrefSearchIcon}
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20.815 19.29L17.415 15.9C18.512 14.5025 19.1073 12.7767 19.105 11C19.105 9.41775 18.6359 7.87103 17.7568 6.55544C16.8778 5.23985 15.6283 4.21447 14.1665 3.60897C12.7047 3.00347 11.0962 2.84504 9.54432 3.15372C7.99248 3.4624 6.56701 4.22433 5.44819 5.34315C4.32937 6.46197 3.56744 7.88743 3.25876 9.43928C2.95008 10.9911 3.10851 12.5997 3.71401 14.0615C4.31951 15.5233 5.34489 16.7727 6.66048 17.6518C7.97608 18.5308 9.52279 19 11.105 19C12.8817 19.0022 14.6075 18.407 16.005 17.31L19.395 20.71C19.488 20.8037 19.5986 20.8781 19.7205 20.9289C19.8423 20.9797 19.973 21.0058 20.105 21.0058C20.2371 21.0058 20.3678 20.9797 20.4896 20.9289C20.6115 20.8781 20.7221 20.8037 20.815 20.71C20.9088 20.617 20.9832 20.5064 21.0339 20.3846C21.0847 20.2627 21.1108 20.132 21.1108 20C21.1108 19.868 21.0847 19.7373 21.0339 19.6154C20.9832 19.4936 20.9088 19.383 20.815 19.29ZM5.10505 11C5.10505 9.81332 5.45694 8.65328 6.11623 7.66658C6.77552 6.67989 7.71259 5.91085 8.80894 5.45673C9.9053 5.0026 11.1117 4.88378 12.2756 5.11529C13.4395 5.3468 14.5086 5.91825 15.3477 6.75736C16.1868 7.59648 16.7582 8.66558 16.9898 9.82946C17.2213 10.9933 17.1024 12.1997 16.6483 13.2961C16.1942 14.3925 15.4252 15.3295 14.4385 15.9888C13.4518 16.6481 12.2917 17 11.105 17C9.51375 17 7.98762 16.3679 6.8624 15.2426C5.73719 14.1174 5.10505 12.5913 5.10505 11Z"
                          fill="#79828B"
                        />
                      </svg>
                      <svg
                        className={styles.listViewPrefDropdownIcon}
                        style={{
                          transform: isPopupOpen ? "scaleY(-1)" : "none",
                        }}
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4H12.796C12.9882 3.99984 13.1765 4.05509 13.3381 4.15914C13.4998 4.26319 13.628 4.41164 13.7074 4.58669C13.7869 4.76175 13.8142 4.956 13.7861 5.14618C13.758 5.33636 13.6757 5.51441 13.549 5.659L8.753 11.139C8.65913 11.2464 8.54338 11.3325 8.41351 11.3915C8.28363 11.4505 8.14264 11.481 8 11.481C7.85735 11.481 7.71636 11.4505 7.58649 11.3915C7.45661 11.3325 7.34086 11.2464 7.247 11.139V11.14Z"
                          fill="#79828B"
                        />
                      </svg>
                      <input
                        disabled
                        className={styles.listViewPrefSearchInput}
                        placeholder="Search for transactions"
                        value={
                          getSearchText({
                            filter,
                            defaultFilterFromDate,
                            defaultFilterToDate,
                            statusFilterOptions,
                          }).value
                        }
                      />
                      <input
                        style={{ opacity: 0 }}
                        type="checkbox"
                        className={styles.listViewPrefSearchInput}
                        onClick={() => {
                          setPopupOpen(true);
                        }}
                        defaultChecked={isPopupOpen}
                        title={
                          getSearchText({
                            filter,
                            defaultFilterFromDate,
                            defaultFilterToDate,
                            statusFilterOptions,
                          }).title
                        }
                      />
                      <div
                        role="presentation"
                        style={{ ...(!isPopupOpen && { display: "none" }) }}
                        onClick={() => {
                          setPopupOpen(false);
                        }}
                        className={globalStyles.scrim}
                      />
                      <FilterForm
                        {...{
                          setValue,
                          setPopupOpen,
                          handleSearchSubmit,
                          isPopupOpen,
                          selectRecord,
                          setFilter,
                          filter,
                          registerSearch,
                          DateField,
                          controlSearch,
                          fundTagOptions,
                          shouldRenderCalendar,
                          defaultFilterFromDate,
                          statusFilters,
                          statusFilterOptions,
                          defaultFilterToDate,
                          starringReasons,
                          taggedUsers,
                          starredUsers,
                          openKeyModal,
                          resetSearchForm,
                          clearFilter,
                          initialFilter,
                          selectedFilter,
                          onSelectedFilter,
                          loadingData,
                          previewLoading,
                          setSelectedFilter,
                          setResetMainFilter,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.paginationContainer}>
                <div className={styles.assignee}>
                  {filter.status === statusFilters.processing &&
                    !threadViewPage && (
                      <MultipleValue
                        setValue={setAssignee}
                        optionList={assigneeList}
                        value={assignee}
                      />
                    )}
                </div>
              </div>

              <div
                style={{
                  marginTop: 20,
                  paddingBottom: 10,
                  paddingTop: 10,
                  paddingLeft: 20,
                  paddingRight: 20,
                  borderRadius: 10,
                  display: "flex",
                  flexDirection: "row",
                  // justifyContent: "space-between",
                  alignItems: "center",
                  // background: "#ffffff",
                }}
              >
                <div />
                {notificationMail ? (
                  <div
                    className={styles.backToResults}
                    onClick={backToAllResults}
                    onKeyDown={backToAllResults}
                    role="button"
                    tabIndex={0}
                  >
                    <img src="/assets/back-grey.svg" alt="back-arrow" />{" "}
                    <p>All Results</p>
                  </div>
                ) : (
                  <BottomPagination
                    pageSize={
                      threadViewPage ? threadFilter.pageSize : filter.pageSize
                    }
                    currentPage={
                      threadViewPage
                        ? threadFilter?.currentPage
                        : filter?.currentPage
                    }
                    totalCount={nav?.totalCount}
                    onChange={onPageChange}
                    disabled={loadingData || previewLoading || loadingCount}
                  />
                )}
                <div />
                <LabelsContainer
                  setLabelPopup={setLabelPopup}
                  labels={label}
                  labelsFullList={labelsFullList}
                  labelsHandler={labelHandler}
                />
                {canRefresh && (
                  <Tooltip title="Refresh">
                    <img
                      alt="refresh-icon"
                      className={styles.refreshIcon}
                      src="/assets/refresh.svg"
                      onClick={() => OnRefreshForNewData()}
                    />
                  </Tooltip>
                )}
              </div>

              <div
                id="user-main-container"
                className={styles.listViewPreviewContainer}
                // style={{ width: mainSize.x }} // size of parent box used when expands
              >
                {loadingData ? <DashboardTableSkeleton /> : null}
                {!loadingData && organizedData.length !== 0 && (
                  <DashboardTable
                    currentDataList={organizedData}
                    selectRecord={selectRecord}
                    selectedRecord={selectedRecord}
                    setHasThread={setHasThread}
                    starringReasons={starringReasons}
                    users={users}
                    onUpdate={onUpdate}
                    setShowAll={setShowAll}
                    setSelectedThreadMail={setSelectedThreadMail}
                    setSelectedExtension={setSelectedExtension}
                    selectedThreadMail={selectedThreadMail}
                    selectedExtension={selectedExtension}
                    hasThread={hasThread}
                    hasMailListChanged={hasMailListChanged}
                    setHasMailListChanged={setHasMailListChanged}
                    setPreviewLoading={setPreviewLoading}
                    tableRef={tableRef}
                    hotQueueClickHandler={hotQueueClickHandler}
                    setHotQueueRulesData={setHotQueueRulesData}
                    openHotQueueModal={openHotQueueModal}
                    getMailLabel={getMailLabel}
                    setLabel={setLabel}
                  />
                )}
                <div
                  id="preview-container"
                  // style={{ flex: 1, width: 400 }}
                  style={{ width: 400 }}
                >
                  {renderPreview()}
                </div>

                {/* outter expansion line and button */}
                {!loadingData && organizedData.length !== 0 && (
                  <div
                    className={styles.expandMainContainer}
                    onMouseDown={handler}
                    role="button"
                    tabIndex={0}
                  >
                    <div className={styles.expandBtnContainer}>
                      <div className={styles.expandBtn}>
                        <img src="/assets/white-arrow.svg" alt="arrow" />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {!loadingData &&
                ((threadViewPage && mailThreadData.length === 0) ||
                  (!threadViewPage && currentDataList.length === 0)) && (
                  <div className={styles.emptyInboxContainer}>
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 16 16"
                      className={styles.emptyInboxIcon}
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.98 4a.5.5 0 0 0-.39.188L1.54 8H6a.5.5 0 0 1 .5.5 1.5 1.5 0 1 0 3 0A.5.5 0 0 1 10 8h4.46l-3.05-3.812A.5.5 0 0 0 11.02 4H4.98zm9.954 5H10.45a2.5 2.5 0 0 1-4.9 0H1.066l.32 2.562a.5.5 0 0 0 .497.438h12.234a.5.5 0 0 0 .496-.438L14.933 9zM3.809 3.563A1.5 1.5 0 0 1 4.981 3h6.038a1.5 1.5 0 0 1 1.172.563l3.7 4.625a.5.5 0 0 1 .105.374l-.39 3.124A1.5 1.5 0 0 1 14.117 13H1.883a1.5 1.5 0 0 1-1.489-1.314l-.39-3.124a.5.5 0 0 1 .106-.374l3.7-4.625z"
                      />
                    </svg>
                  </div>
                )}

              {!threadViewPage &&
                currentDataList.length !== 0 &&
                nav.itemCount && (
                  <div
                    style={{
                      marginTop: 20,
                      paddingBottom: 5,
                      paddingTop: 5,
                      paddingLeft: 20,
                      paddingRight: 20,
                      borderRadius: 10,
                      display: "flex",
                      flexDirection: "row",
                      // justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div />
                    {!notificationMail && (
                      <BottomPagination
                        pageSize={
                          threadViewPage
                            ? threadFilter.pageSize
                            : filter.pageSize
                        }
                        currentPage={
                          threadViewPage
                            ? threadFilter?.currentPage
                            : filter?.currentPage
                        }
                        totalCount={nav?.totalCount}
                        onChange={onPageChange}
                        disabled={loadingData || previewLoading}
                      />
                    )}
                    <div />
                  </div>
                )}

              {scrollToTopVisible && <ScrollTopBtn scrollToTop={scrollToTop} />}
            </div>
          </div>
        </div>

        {/** ** key modal ** */}
        <Dialog open={isKeyModalOpen} onBackdropClick={closeKeyModal}>
          <DialogContent className={styles.completeEmail}>
            <h2 className={styles.codeGenHeading}>Code Generator Demo</h2>
            <form
              id="generate-key-form"
              className={styles.form}
              onSubmit={handleKey(generateKey)}
            >
              <TextField
                className={styles.captchaInput}
                inputProps={{ ref: registerKey({ required: true }) }}
                name="fundName"
                label="Fund Name"
                value={watch("fundName").toUpperCase()}
              />
              <br />
              <br />
              <TextField
                className={styles.captchaInput}
                inputProps={{ ref: registerKey({ required: true }) }}
                name="countryCode"
                label="Country Code"
                value={watch("countryCode").toUpperCase()}
              />
            </form>
            <br />
            {code && <div className={styles.code}>Code : {code}</div>}
          </DialogContent>
          <DialogActions>
            <input
              form="generate-key-form"
              className={cn({
                [styles.button]: true,
                [styles.darkGreen]: true,
              })}
              type="submit"
              value={codeGenLoading ? "Generating..." : "Generate"}
              disabled={codeGenLoading}
            />
          </DialogActions>
        </Dialog>

        {isErrorModalOpen && (
          <ErrorCodeModal
            isErrorModalOpen={isErrorModalOpen}
            closeErrorModal={closeErrorModal}
            data={selectedExtension || selectedThreadMail || selectedMail}
          />
        )}

        {isFundTagModalOpen && (
          <EditFundTagModal
            id={selectedRecord.id}
            isFundTagModalOpen={isFundTagModalOpen}
            closeFundTagModal={closeFundTagModal}
            fundTagOptions={fundTagOptions}
            onEditedFundTag={onEditedFundTag}
          />
        )}

        {isMailModalOpen.open && (
          <MailModal
            selectedRecord={selectedRecord}
            isMailModalOpen={isMailModalOpen}
            closeMailModal={closeMailModal}
            filter={filter}
            setFilter={setFilter}
          />
        )}

        {isResolveHistoryOpen && (
          <ResolveHistoryModal
            data={
              threadViewPage
                ? selectedExtension || selectedThreadMail
                : selectedMail
            }
            isResolveHistoryOpen={isResolveHistoryOpen}
            closeResolveHistory={closeResolveHistoryModal}
          />
        )}

        {manualUploadPopup && (
          <ManualUploadModal closePopup={closeManualUploadModal} />
        )}

        {selectedRecord?.hot_queue && hotQueuePopup && (
          <HotQueueNotesInstructionPopup
            closePopup={closeHotQueueModal}
            hotQueueRulesData={hotQueueRulesData}
          />
        )}
        {labelPopup && (
          <LabelModal
            closePopup={() => setLabelPopup(false)}
            loadlabelList={loadlabelList}
          />
        )}
      </div>
    </div>
  );
}

Dashboard.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
};
