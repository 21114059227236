import React from "react";
import styles from "./index.module.css";

function CustomBullet() {
  return (
    <div className={styles.root}>
      <svg
        width="9"
        height="9"
        viewBox="0 0 9 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="4.50042" cy="4.49993" r="3.71429" fill="#E0E0E0" />
      </svg>
    </div>
  );
}

export default CustomBullet;
