const getSearchText = ({
  filter,
  defaultFilterFromDate,
  defaultFilterToDate,
  statusFilterOptions,
}) => {
  const searchText = [];
  const fundText = [];
  const editedFundText = [];
  const errorText = [];
  const fromDate = filter.date_from || defaultFilterFromDate;
  const toDate = filter.date_to || defaultFilterToDate;
  const { label: status } = statusFilterOptions.find(
    (el) => el.value === filter.status
  );

  if (filter.from) searchText.push(`From(${filter.from})`);
  if (filter.subject) searchText.push(`Subject(${filter.subject})`);
  if (filter.reference_id)
    searchText.push(`Reference ID(${filter.reference_id})`);

  if (filter.fundTagList.length !== 0)
    filter.fundTagList.forEach((el) => fundText.push(el));
  if (filter.editedFundTagList.length !== 0)
    filter.editedFundTagList.forEach((el) => editedFundText.push(el));
  if (fundText.length !== 0)
    searchText.push(`Fund Tag (${fundText.join(",")})`);
  if (editedFundText.length !== 0)
    searchText.push(`Edited Fund Tag (${editedFundText.join(",")})`);

  if (filter.status) searchText.push(`Status(${status})`);
  if (filter.manager_name) searchText.push(`Manager(${filter.manager_name})`);
  if (filter.prepared_by) searchText.push(`Prepared By(${filter.prepared_by})`);
  searchText.push(`Date(${fromDate} to ${toDate})`);
  if (filter.dop) searchText.push(`Dop(${filter.dop})`);

  if (filter.errorCodesList.length !== 0)
    filter.errorCodesList.forEach((el) => errorText.push(el));
  if (errorText.length !== 0)
    searchText.push(`Error code (${errorText.join(",")})`);

  const value = `${[searchText[0], searchText[1]].join(", ")} + ${
    searchText.length - 2
  }`;

  return { title: searchText.join(", "), value };
};
export default getSearchText;
