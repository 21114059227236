import React from "react";
import { TextField, MenuItem } from "@material-ui/core";
import { DatePicker } from "antd";

function AdvanceSearchBox() {
  return (
    <div className="advance-search_container">
      <form onSubmit={() => {}} style={{ width: "100%" }}>
        <div className="top_part">
          <div className="top_left">
            <TextField
              variant="outlined"
              size="small"
              select
              className="input-field"
              placeholder="Email address"
              label="User"
              classes={{ root: { color: "red" } }}
              //   onChange={onChangeHandler("email")}
              //   value={formDetails.email}
            >
              <MenuItem value="admin">Admin</MenuItem>
            </TextField>
            <TextField
              variant="outlined"
              size="small"
              className="input-field"
              placeholder="Company Name"
              label="Organization"
              //   onChange={onChangeHandler("email")}
              //   value={formDetails.email}
            />
            <DatePicker
              format="DD-MM-YYYY"
              className="input-field"
              placeholder="dd/mm/yyyy"
              picker="day"
              // onChange={handleWeek}
              allowClear={false}
              inputReadOnly
              // disabledDate={handleDatePickerDisabledDates}
              // value={moment(dateDetails.fromDate)}
            />
            <TextField
              variant="outlined"
              select
              size="small"
              className="input-field"
              placeholder="Status"
              label="Access"
              //   onChange={onChangeHandler("email")}
              //   value={formDetails.email}
            />
          </div>
          <div>
            <button type="submit" className="save_btn">
              Apply Filter
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AdvanceSearchBox;
