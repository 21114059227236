/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from "react";
import PropTypes, { array } from "prop-types";
import {
  Checkbox,
  ListItemText,
  MenuItem,
  Tooltip,
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  withStyles,
} from "@material-ui/core";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";

import styles from "../../../resources/index.module.scss";

const Dialog = withStyles({
  paper: {
    borderRadius: "22px",
  },
})(MuiDialog);

const DialogContent = withStyles({
  root: {
    padding: "32px 32px 24px 32px",
  },
})(MuiDialogContent);

function LabelsContainer({
  setLabelPopup,
  labels,
  labelsFullList,
  labelsHandler,
}) {
  const [list, setList] = useState(labelsFullList);
  const [search, setSearch] = useState("");
  const [popup, setPopup] = useState(false);

  const clearSearch = () => {
    setSearch("");
  };

  const handleSearch = () => {
    if (search) {
      const newList = labelsFullList.filter((x) =>
        x.label_name.toLowerCase().includes(search.toLowerCase())
      );
      setList(newList);
    } else setList(labelsFullList);
  };

  useEffect(() => {
    handleSearch();
  }, [search]);

  useEffect(() => {
    clearSearch();
    setList(labelsFullList);
  }, [labelsFullList]);

  const handleCheckbox = (id) => {
    labelsHandler(id, "update");
    clearSearch();
  };

  const closePopup = () => {
    setPopup(false);
  };

  const onStartCreateLabel = () => {
    setPopup(false);
    setLabelPopup(true);
  };

  return (
    <div className={styles.labelIconContainer}>
      <Tooltip title="Labels">
        <img
          className={styles.labelIcon}
          src="/assets/label.svg"
          alt="label-icon"
          onClick={() => setPopup(true)}
        />
      </Tooltip>
      <Dialog open={popup} onBackdropClick={closePopup}>
        <DialogContent className={styles.labelButtonsWrapper}>
          <div className={styles.heading}>Label as</div>

          <div className={styles.searchWrapper}>
            <img src="/assets/loupe.svg" alt="icon" />
            <input
              placeholder="Search for labels ..."
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />
            {search && (
              <label
                role="presentation"
                onKeyPress={clearSearch}
                className={styles.cancelIcon}
                onClick={clearSearch}
              >
                <CancelRoundedIcon style={{ width: 12 }} />
              </label>
            )}
          </div>
          <div className={styles.checkboxContainer}>
            {list && list.length > 0 ? (
              list
                .sort(
                  (a, b) =>
                    labels
                      .map((m) => m.id)
                      .reverse()
                      .indexOf(b.id) -
                    labels
                      .map((m) => m.id)
                      .reverse()
                      .indexOf(a.id)
                )
                .map((item) => (
                  <MenuItem
                    key={item.id || item.label_name}
                    value={item.label_name}
                    classes={{
                      root: styles.menuOption,
                    }}
                    onClick={() => handleCheckbox(item.id)}
                  >
                    <Checkbox
                      size="small"
                      checked={labels.map((m) => m.id).includes(item.id)}
                      classes={{
                        checked: styles.checked,
                        root: styles.checkboxPadding,
                      }}
                    />

                    <Tooltip title={item.label_name}>
                      <ListItemText
                        primary={item.label_name}
                        classes={{ primary: styles.listItem }}
                      />
                    </Tooltip>
                  </MenuItem>
                ))
            ) : (
              <div style={{ marginTop: 10, textAlign: "center" }}>
                No Lables found
              </div>
            )}
          </div>
          <div className={styles.createButtonContainer}>
            <button
              type="button"
              onClick={() => onStartCreateLabel()}
              className={styles.saveBtn}
            >
              Create New Label
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default LabelsContainer;

LabelsContainer.propTypes = {
  setLabelPopup: PropTypes.func.isRequired,
  labelsHandler: PropTypes.func.isRequired,
  labels: PropTypes.instanceOf(array).isRequired,
  labelsFullList: PropTypes.instanceOf(array).isRequired,
};
