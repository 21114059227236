import React, { useState } from "react";
import PropTypes from "prop-types";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import "./style.scss";

function LoaderSection({ fileName }) {
  return (
    <div
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      <img src="/assets/upload-cloud.svg" alt="profile-pic" />
      <p>{fileName ?? "Select File"} </p>
    </div>
  );
}
LoaderSection.propTypes = {
  fileName: PropTypes.string.isRequired,
};

function UploadInput({ onChange, accept, multi }) {
  const [fileName, setFileName] = useState(null);
  const [files, setFiles] = useState([]);

  const uploadImage = (e) => {
    const file = e.target.files;
    const newFiles = [...files];
    if (multi) {
      Object.values(file).forEach((f) => {
        newFiles.push(f);
      });
      setFiles(newFiles);
      onChange(newFiles);
    } else {
      onChange(file[0]);
      setFileName(file[0].name);
    }
  };

  const deleteFileHandler = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
    onChange(newFiles);
  };

  return (
    <div className="upload-input">
      <div className="file-input-upload">
        <label htmlFor="itemFilename">
          <input
            multiple={multi}
            type="file"
            name="itemFilename"
            id="itemFilename"
            accept={accept ?? ".xlsx,.xls,.csv"}
            onChange={(e) => {
              uploadImage(e);
            }}
            style={{ display: "none" }}
          />
          <LoaderSection
            fileName={multi ? `${files.length} files selected` : fileName}
          />
        </label>
      </div>
      {files.length > 0 && (
        <>
          <div className="table-header">
            <p>Action </p>
            <p>File Name </p>
            <p>File Size </p>
          </div>
          <div className="table-body">
            {files.map((f, i) => (
              <div className="table-row" key={f}>
                <div
                  className="table-col"
                  onClick={() => deleteFileHandler(i)}
                  onKeyDown={() => deleteFileHandler(i)}
                  role="button"
                  tabIndex={0}
                >
                  <CancelRoundedIcon />
                </div>
                <div className="table-col">{f.name}</div>
                <div className="table-col">{Math.floor(f.size / 1000)}kb</div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default UploadInput;
UploadInput.defaultProps = {
  accept: ".xlsx,.xls,.csv",
  multi: false,
};

UploadInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  accept: PropTypes.string,
  multi: PropTypes.bool,
};
