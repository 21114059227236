import React from "react";
import PropTypes from "prop-types";
import BackToDashboardButton from "../BackToDashboardButton";
import statusFilters from "../../constants/statusFilter";

function DashboardButton({
  threadViewPage,
  setMailThreadData,
  setSelectedExtension,
  setThreadFilter,
  selectedRecord,
  setFilter,
  filter,
  mailThreadData,
  history,
  nav,
  LastPrefOptions,
  ListViewPrefOption,
}) {
  return threadViewPage ? (
    <BackToDashboardButton
      {...{
        setMailThreadData,
        setSelectedExtension,
        setThreadFilter,
        selectedRecord,
        setFilter,
        filter,
        mailThreadData,
        history,
      }}
    />
  ) : (
    <>
      <ListViewPrefOption
        badge={nav.statusCount.all}
        value={statusFilters.all}
        name="All"
      />
      <ListViewPrefOption
        badge={nav.statusCount.pub}
        value={statusFilters.published}
        name="Published"
      />
      <ListViewPrefOption
        badge={nav.statusCount.pro}
        value={statusFilters.processing}
        name="Processing"
      />
      <ListViewPrefOption
        badge={nav.statusCount.res}
        value={statusFilters.resolved}
        name="Resolved"
      />
      <ListViewPrefOption
        badge={nav.statusCount.err}
        value={statusFilters.error}
        name="Repair Queue"
      />
      {/* <ListViewPrefOption
        badge={nav.statusCount.qua}
        value={statusFilters.quarantine}
        name="Quarantine"
      /> */}
      <LastPrefOptions name="Other" />
    </>
  );
}

export default DashboardButton;

DashboardButton.propTypes = {
  threadViewPage: PropTypes.bool.isRequired,
  setMailThreadData: PropTypes.func.isRequired,
  setSelectedExtension: PropTypes.func.isRequired,
  setThreadFilter: PropTypes.func.isRequired,
  selectedRecord: PropTypes.instanceOf(Object).isRequired,
  setFilter: PropTypes.func.isRequired,
  filter: PropTypes.instanceOf(Object).isRequired,
  mailThreadData: PropTypes.instanceOf(Array).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  nav: PropTypes.instanceOf(Object).isRequired,
  LastPrefOptions: PropTypes.node.isRequired,
  ListViewPrefOption: PropTypes.node.isRequired,
};
