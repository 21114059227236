import React, { useEffect } from "react";
import Chart from "chart.js";
import PropTypes from "prop-types";

Chart.defaults.global.defaultFontFamily = "'Montserrat', sans-serif";
Chart.defaults.global.legend.display = false;

function LineChart({
  published = [],
  resolved = [],
  repairQueue = [],
  xAxisLabels,
}) {
  const chartRef = React.createRef();

  useEffect(() => {
    const myChartRef = chartRef.current.getContext("2d");
    if (window.myCharts !== undefined) {
      window.myCharts.destroy();
    }
    window.myCharts = new Chart(myChartRef, {
      type: "line",
      data: {
        // Bring in data
        labels: xAxisLabels,
        datasets: [
          {
            label: "Published",
            // data: [86, 67, 91, 60, 50, 40, 57],
            data: published,
            fill: false,
            borderColor: "#10c172",
            pointBackgroundColor: "#10c172",
          },

          {
            label: "Resolved",
            data: resolved,
            fill: false,
            borderColor: "#1c57ad",
            pointBackgroundColor: "#1c57ad",
          },

          {
            label: "Repair Queue",
            data: repairQueue,
            fill: false,
            borderColor: "#eb5945",
            pointBackgroundColor: "#eb5945",
          },
        ],
      },
      options: {
        responsive: true,
        point: {
          backgroundColor: "white",
        },
        layout: {
          padding: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    });
  }, [xAxisLabels, published, resolved, repairQueue]);

  return (
    <div className="chart-style">
      <canvas id="myChart" ref={chartRef} />
    </div>
  );
}

export default LineChart;

LineChart.defaultProps = {
  published: [],
  resolved: [],
  repairQueue: [],
  xAxisLabels: [],
};

LineChart.propTypes = {
  published: PropTypes.instanceOf(Array),
  resolved: PropTypes.instanceOf(Array),
  repairQueue: PropTypes.instanceOf(Array),
  xAxisLabels: PropTypes.instanceOf(Array),
};
