import React, { memo } from "react";
import PropTypes from "prop-types";
import { startCase } from "lodash";
import Skeleton from "react-loading-skeleton";
import helper from "../../../../../utils/helper";
import "./styles.scss";

function HotQueueNotesInstructions({ closePopup, hotQueueRulesData }) {
  const { replaceURLs } = helper;

  const loadingSkeletonSection = () => (
    <>
      {Array.from({ length: 7 })
        .map((_, index) => index)
        .map((i) => (
          <div className="content-skeleton" key={i}>
            <Skeleton width="99%" height={50} />
          </div>
        ))}
    </>
  );

  return (
    <div className="popup hotQueue-rules-instructions-modal">
      <div className="popup-overlay" />
      <div className="popup-wrapper">
        <div className="popup-header">
          <h1>Hot Queue Notes and Instructions</h1>
          <div
            role="button"
            tabIndex="0"
            className="close-btn"
            onKeyDown={closePopup}
            onClick={closePopup}
          >
            Close
          </div>
        </div>
        {Object.keys(hotQueueRulesData).length > 0 ? (
          <div className="popup-body">
            <h3 className="bold-text">{hotQueueRulesData?.rule_name}</h3>
            {hotQueueRulesData.rule_details.map((e, i) => (
              <div key={e}>
                <p className="bold-text">
                  Rule {i + 1} : {startCase(e?.condition_param)}{" "}
                  {startCase(e?.condition)} {e?.condition_data}
                </p>
              </div>
            ))}
            <p className="bold-text underline-text">Notes</p>
            <p className="white-space-pre">
              {hotQueueRulesData?.hotqueue_notes &&
              hotQueueRulesData?.hotqueue_notes !== ""
                ? replaceURLs(hotQueueRulesData?.hotqueue_notes)
                : "--"}
            </p>

            <p className="bold-text underline-text">Instructions</p>
            <p className="white-space-pre">
              {hotQueueRulesData?.instruction &&
              hotQueueRulesData?.instruction !== ""
                ? replaceURLs(hotQueueRulesData?.instruction)
                : "--"}
            </p>
          </div>
        ) : (
          loadingSkeletonSection()
        )}
      </div>
    </div>
  );
}

export default memo(HotQueueNotesInstructions);

HotQueueNotesInstructions.propTypes = {
  closePopup: PropTypes.func.isRequired,
  hotQueueRulesData: PropTypes.instanceOf(Object).isRequired,
};
