import React, { useState } from "react";
import {
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  withStyles,
} from "@material-ui/core";
import cn from "classnames";
import { Controller, useForm } from "react-hook-form";
import PropTypes from "prop-types";

import MultiSelect from "../../../../../components/MultiSelect";
import styles from "../../../resources/index.module.scss";
import userServices from "../../../../../api/userServices";
import showAlert from "../../../../../utils/showAlert";

const Dialog = withStyles({
  paper: {
    borderRadius: "22px",
  },
})(MuiDialog);

const DialogContent = withStyles({
  root: {
    padding: "32px 32px 24px 32px",
  },
})(MuiDialogContent);

const DialogActions = withStyles({
  root: {
    boxShadow: "rgba(0, 0, 0, 0.4) 0 -32px 1px -32px",
    padding: "24px 32px 32px 32px",
    zIndex: 10,
  },
})(MuiDialogActions);

function EditFundTagModal({
  id,
  isFundTagModalOpen,
  closeFundTagModal,
  fundTagOptions,
  onEditedFundTag,
}) {
  const { handleSubmit, control } = useForm();

  const [saving, setSaving] = useState(false);

  const onSubmit = (data) => {
    if (data.fundTag.length !== 0) {
      setSaving(true);
      const userAddedFundTags = [];
      fundTagOptions.forEach((el) => {
        if (data.fundTag.includes(el.value)) userAddedFundTags.push(el.id);
      });
      const payload = {
        user_added_fund_tag: userAddedFundTags,
      };
      userServices
        .editFundTag(id, payload)
        .then(() => {
          showAlert("Fund tag saved successfully!", "success");
          setSaving(false);
          closeFundTagModal();
          onEditedFundTag();
        })
        .catch(() => {
          setSaving(false);
        });
    }
  };

  return (
    <Dialog
      open={isFundTagModalOpen}
      onBackdropClick={closeFundTagModal}
      data-testid="edit-fund-tag-modal"
    >
      <DialogContent className={styles.fundTagModal}>
        <h2>Edit Fund Tag</h2>

        <form id="edit-fund-tag-form" onSubmit={handleSubmit(onSubmit)}>
          <p>Fund Tag</p>
          <Controller
            data-testid="edit-fund-tag-modal-form-tag"
            control={control}
            name="fundTag"
            defaultValue={[]}
            render={({ onChange, value }) => (
              <MultiSelect
                // data-testid="edit-fund-tag-modal-form-tag"
                id="edit-fund-tag"
                onChange={onChange}
                value={value}
                listOptions={fundTagOptions}
                required
              />
            )}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <input
          data-testid="edit-fund-tag-modal-form-btn"
          form="edit-fund-tag-form"
          className={cn({
            [styles.button]: true,
            [styles.darkGreen]: true,
          })}
          type="submit"
          value={saving ? "Saving" : "Save"}
          disabled={saving}
        />
      </DialogActions>
    </Dialog>
  );
}

export default EditFundTagModal;

EditFundTagModal.propTypes = {
  id: PropTypes.number.isRequired,
  isFundTagModalOpen: PropTypes.bool.isRequired,
  closeFundTagModal: PropTypes.func.isRequired,
  fundTagOptions: PropTypes.instanceOf(Array).isRequired,
  onEditedFundTag: PropTypes.func.isRequired,
};
