import React, { useState } from "react";
import cn from "classnames";
import "antd/dist/antd.css";
import PropTypes from "prop-types";
import { DatePicker, Radio } from "antd";
import LineGraphSkeleton from "../../components/Skeletons/LineGraphSkeleton/index";
import ProductChart from "../../components/ProductChart";
import helper from "../../utils/helper";

const { disabledDate } = helper;

function UnitPriceGraph({
  GraphFilter,
  selectedFilter,
  handleDateRanges,
  dates,
  // xAxisLabels,
  data,
  // options,
  loader,
  hackValue,
  onOpenChange,
  setHackDates,
  handleRadioButton,
  tableData,
}) {
  const { RangePicker } = DatePicker;

  const dateTypePicker = () => {
    if (selectedFilter === "monthly") {
      return "month";
    }
    if (selectedFilter === "weekly") {
      return "week";
    }
    return "date";
  };

  // const datasetSample = [200, 1500, 600, 800, 2000];
  // sample XAxis Labels
  const xAxisLabelsSample = ["Mon", "Tue", "Wed", "Thu", "Fri"];
  const [viewType, setViewType] = useState("graph");

  return (
    <div className="unit-graph">
      <div>
        <div className="graph-heading">
          <div className="unit-content-overview">
            {/* <div className="unit-header">
             <div className="heading">
            h
          </div> 
          </div>  */}
            <div className="switch-content">
              <div className="unit-text">UNIT PRICES</div>
              <div className="switch-container">
                <div
                  className={cn({
                    switchSelected: viewType === "graph",
                    switch: true,
                  })}
                  onClick={() => setViewType("graph")}
                >
                  <img
                    style={{ marginRight: 4 }}
                    src={
                      viewType === "graph"
                        ? "/assets/graph-selected.svg"
                        : "/assets/graph.svg"
                    }
                  />
                  {viewType === "graph" && "Graph"}
                </div>
                <div
                  className={cn({
                    switchSelected: viewType === "table",
                    switch: true,
                  })}
                  onClick={() => setViewType("table")}
                >
                  <img
                    style={{ marginRight: 4 }}
                    src={
                      viewType === "table"
                        ? "/assets/list-selected.svg"
                        : "/assets/list.svg"
                    }
                  />
                  {viewType === "table" && "Table"}
                </div>
              </div>
              <div className="overview-radio">
                <div className="duration">
                  <Radio.Group
                    options={GraphFilter}
                    onChange={handleRadioButton}
                    value={selectedFilter}
                    buttonStyle="solid"
                    optionType="button"
                    className="graph-header-radio-btn"
                    disabled={loader}
                    style={{ display: "flex" }}
                  />
                </div>
              </div>
              <RangePicker
                picker={dateTypePicker()}
                allowClear={false}
                onChange={handleDateRanges}
                value={hackValue || [dates.startDate, dates.endDate]}
                disabledDate={(current) =>
                  disabledDate(current, dates, selectedFilter)
                }
                onCalendarChange={(val) => setHackDates(val)}
                onOpenChange={onOpenChange}
                // format={dateFormat}
              />
            </div>
          </div>
        </div>
      </div>
      {viewType === "graph" &&
        (loader ? (
          <LineGraphSkeleton xAxisLabels={xAxisLabelsSample} />
        ) : (
          <ProductChart data={data} />
        ))}

      {viewType === "table" &&
        (loader ? (
          <LineGraphSkeleton xAxisLabels={xAxisLabelsSample} />
        ) : (
          <div className="graph-table ">
            <div className="table-header">
              <p>Date</p>
              <p>Entry</p>
              <p>Mid</p>
              <p>Exit</p>
            </div>
            <div className="table-body">
              {data?.labels.map((item, index) => (
                <div className="table-row" key={item}>
                  <div className="table-col">{item}</div>
                  <div className="table-col">{tableData?.entryA[index]}</div>
                  <div className="table-col">
                    {data?.datasets[0]?.data[index]}
                  </div>

                  <div className="table-col">{tableData?.exitA[index]}</div>
                </div>
              ))}
            </div>
          </div>
        ))}
    </div>
  );
}

export default UnitPriceGraph;
UnitPriceGraph.propTypes = {
  handleDateRanges: PropTypes.func.isRequired,
  handleRadioButton: PropTypes.func.isRequired,
  onOpenChange: PropTypes.func.isRequired,
  setHackDates: PropTypes.func.isRequired,
  dates: PropTypes.instanceOf(Object).isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  tableData: PropTypes.instanceOf(Object).isRequired,
  loader: PropTypes.bool.isRequired,
  hackValue: PropTypes.instanceOf(Array).isRequired,
  GraphFilter: PropTypes.instanceOf(Array).isRequired,
  selectedFilter: PropTypes.string.isRequired,
  // viewType: PropTypes.string.isRequired,
  // xAxisLabels: PropTypes.shape(Array),
};
