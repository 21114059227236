import React, { useEffect, useState } from "react";

import moment from "moment";
import cn from "classnames";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";

import LineGraph from "../../../../components/LineGraph";
import reportServices from "../../../../api/reportServices";

function FileAnalysisTotal({ mainLoader, style, data }) {
  return (
    <div style={style} className="file-type-analysis-total col">
      <h3 className="grid-item-title">Total</h3>

      <div className="file-analysis-data-top-row" style={{ marginTop: 8 }}>
        <div className="file-analysis-values">
          {mainLoader ? (
            <Skeleton width={30} height={24} />
          ) : (
            <p className="label-value-value">{data?.total?.files || 0}</p>
          )}
          <p className="label-value-title">Files</p>
          <div className="divider-vertical" />
        </div>
        <div className="divider-vertical" style={{ height: 50 }} />
        <div className="file-analysis-values" style={{ marginLeft: 8 }}>
          {mainLoader ? (
            <Skeleton width={30} height={24} />
          ) : (
            <p className="label-value-value">{data?.total?.products || 0}</p>
          )}
          <p className="grid-title" style={{ marginLeft: 5 }}>
            UP Records
          </p>
        </div>
        <div className="divider-vertical" style={{ height: 50 }} />
        <div className="file-analysis-values">
          {mainLoader ? (
            <Skeleton width={30} height={24} />
          ) : (
            <p className="label-value-value">{data?.total?.errors || 0}</p>
          )}
          <p className="label-value-title" style={{ marginLeft: 5 }}>
            Errors
          </p>
        </div>
      </div>
    </div>
  );
}

FileAnalysisTotal.propTypes = {
  mainLoader: PropTypes.bool.isRequired,
  style: PropTypes.instanceOf(Object).isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
};

function FileTypeAnalysis({
  style,
  header,
  files,
  showEmptyValues,
  errors,
  mainLoader,
  products,
}) {
  return (
    <div className="col" style={style}>
      <h3 className="grid-item-title">{header}</h3>
      <div className="file-analysis-data-top-row" style={{ marginTop: 8 }}>
        <div className="file-analysis-values">
          <div>
            {mainLoader ? (
              <Skeleton height={24} />
            ) : (
              <p className="label-value-value">{files}</p>
            )}
          </div>
          <div>
            <p className="grid-title">Files</p>
          </div>
        </div>
        {showEmptyValues && (
          <div className="divider-vertical" style={{ height: 50 }} />
        )}
        {showEmptyValues && (
          <div className="file-analysis-values" style={{ marginLeft: 8 }}>
            {/* <div>
              <hr className="vertical-line" />
            </div> */}
            <div>
              <p className="label-value-value">
                {mainLoader ? (
                  <Skeleton width={30} height={24} />
                ) : (
                  <p className="label-value-value">{products}</p>
                )}
              </p>
            </div>
            <div>
              <p className="grid-title">UP Records</p>
            </div>
          </div>
        )}
        {showEmptyValues && (
          <div className="divider-vertical" style={{ height: 50 }} />
        )}
        {showEmptyValues && (
          <div className="file-analysis-values" style={{ marginLeft: 8 }}>
            <div>
              {mainLoader ? (
                <Skeleton width={30} height={24} />
              ) : (
                <p className="label-value-value">{errors}</p>
              )}
            </div>
            <div>
              <p className="grid-title">Errors</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

FileTypeAnalysis.defaultProps = {
  files: 0,
  products: 0,
  errors: 0,
};

FileTypeAnalysis.propTypes = {
  style: PropTypes.instanceOf(Object).isRequired,
  header: PropTypes.string.isRequired,
  files: PropTypes.number,
  showEmptyValues: PropTypes.bool.isRequired,
  errors: PropTypes.number,
  mainLoader: PropTypes.bool.isRequired,
  products: PropTypes.number,
};

function LabelValue({ title, value, mainLoader }) {
  return (
    <div className="label-value-last">
      {mainLoader ? (
        <Skeleton width={50} height={28} style={{ marginTop: 8 }} />
      ) : (
        <p className="label-value-value" style={{ marginTop: 8 }}>
          {value}
        </p>
      )}
      <p className="label-value-title">{title}</p>
    </div>
  );
}

LabelValue.defaultProps = {
  value: 0,
};

LabelValue.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number,
  mainLoader: PropTypes.bool.isRequired,
};

function FileAnalysis({ dateDetails, selectHourWindow }) {
  const [mainLoader, setMainLoader] = useState(false);
  const [graphData, setGraphData] = useState();
  const [countData, setCountData] = useState();
  const [errorData, setErrorData] = useState();

  const timeOption = 1;

  const [activeTab, setActiveTab] = useState("file-analysis");

  const dataset = [
    {
      label: "Resolved",
      data: graphData?.resolved_count || [],
      fill: false,
      borderColor: "#1C57AD",
      pointBackgroundColor: "#1C57AD",
    },
    {
      label: "Published",
      data: graphData?.published_count || [],
      fill: false,
      borderColor: "#0CAA63",
      pointBackgroundColor: "#0CAA63",
    },
    {
      label: "Repair Queue",
      data: graphData?.repair_queue_count || [],
      fill: false,
      borderColor: "#FF0000",
      pointBackgroundColor: "#FF0000",
    },
  ];

  const xAxisLabels = graphData?.label || [];

  const labelCountData = (value, key, showDate) => {
    const publishedCount = [];
    const label = [];
    let prevKey = 24;
    Object.entries(value).forEach(([innerKey, innerValue]) => {
      let date = key;
      if (Number(innerKey) === 24) {
        date = moment(date, "YYYY-MM-DD").add(1, "days").format("YYYY-MM-DD");
      }
      label.push(
        `${showDate ? date : ""} ${prevKey <= 12 ? prevKey : prevKey - 12}${
          prevKey < 12 || Number(prevKey) === 24 ? "am" : "pm"
        } - ${innerKey <= 12 ? innerKey : innerKey - 12}${
          innerKey < 12 || Number(innerKey) === 24 ? "am" : "pm"
        }`
      );

      prevKey = innerKey;
      publishedCount.push(innerValue);
    });
    return { label, publishedCount };
  };

  const labelsAndCount = (publishedCountObject, showDate) => {
    const publishedCountArray = [];
    const labels = [];
    Object.entries(publishedCountObject).forEach(([key, value]) => {
      const { label, publishedCount } = labelCountData(value, key, showDate);
      labels.push(...label);
      publishedCountArray.push(...publishedCount);
    });

    return { labels, publishedCountArray };
  };

  function organizeHourly(response, showDate) {
    const publishedCountObject = response.published_count;
    const repairQueueCountObject = response.repair_queue_count;
    const resolvedCountObject = response.resolved_count;

    const { labels, publishedCountArray } = labelsAndCount(
      publishedCountObject,
      showDate
    );
    const repairQueueCountArray = [];
    const resolvedCountArray = [];

    const publishedCountData = [];
    const repairQueueCountData = [];
    const resolvedCountData = [];
    let totalPublishedCount = 0;
    let totalRepairQueue = 0;
    let totalResolved = 0;

    Object.entries(repairQueueCountObject).forEach(([, value]) => {
      Object.entries(value).forEach(([, innerValue]) => {
        repairQueueCountArray.push(innerValue);
      });
    });
    Object.entries(resolvedCountObject).forEach(([, value]) => {
      Object.entries(value).forEach(([, innerValue]) => {
        resolvedCountArray.push(innerValue);
      });
    });

    const dataPoints = labels.length;
    for (let index = 0; index < dataPoints; index += 1) {
      const publishedCount = publishedCountArray[index];
      const repairQueueCount = repairQueueCountArray[index];
      const resolvedCount = resolvedCountArray[index];
      publishedCountData.push(publishedCount);
      repairQueueCountData.push(repairQueueCount);
      resolvedCountData.push(resolvedCount);
      totalPublishedCount = publishedCount + totalPublishedCount;
      totalRepairQueue = repairQueueCount + totalRepairQueue;
      totalResolved = resolvedCount + totalResolved;
    }

    setGraphData({
      ...response,
      published_count: publishedCountData,
      repair_queue_count: repairQueueCountData,
      resolved_count: resolvedCountData,
      label: labels,
    });
  }

  function getFileAnalysis() {
    setMainLoader(true);
    const filterText = [];
    const fromDate = moment(dateDetails.fromDate).format("YYYY-MM-DD");
    const toDate = moment(dateDetails.toDate).format("YYYY-MM-DD");
    if (dateDetails.durationType) filterText.push(`duration_type=${"daily"}`);
    if (dateDetails.fromDate) filterText.push(`start_duration=${fromDate}`);
    if (dateDetails.toDate) filterText.push(`end_duration=${toDate}`);
    if (dateDetails.durationType === "daily" && timeOption !== 24) {
      filterText.push(`hour_split=true`);
      filterText.push(`split_interval=${timeOption}`);
    }
    reportServices.getFileAnalysis(filterText, "graph").then((resp) => {
      if (dateDetails.durationType === "daily") {
        organizeHourly(resp, fromDate !== toDate);
      } else {
        let labels = resp.label;
        if (dateDetails.durationType === "weekly") {
          labels = labels.map((item) => {
            const date = moment(item)?.format("YYYY-MM-DD (ddd)") || item;
            return date.toUpperCase();
          });
        }
        setGraphData({ ...resp, label: labels });
      }
      setMainLoader(false);
    });

    reportServices.getFileAnalysis(filterText, "error").then((resp) => {
      setCountData({ ...resp });

      setMainLoader(false);
    });
    reportServices.getFileAnalysis(filterText, "email").then((resp) => {
      setErrorData({ ...resp });

      setMainLoader(false);
    });
  }

  useEffect(() => {
    if (dateDetails) getFileAnalysis();
  }, [dateDetails]);

  return (
    <>
      <div className="file-analysis-top-row" style={{ marginTop: 16 }}>
        <p className="item-heading">
          <img
            alt="add"
            src="/assets/group-files.svg"
            style={{ marginRight: 5 }}
          />
          Files and File Type Analysis
        </p>

        <div className="file-analysis-top-row-grid">
          <FileTypeAnalysis
            header="IMD02P001 & Non IMD Unit Prices"
            files={countData?.unit_price?.files}
            products={countData?.unit_price?.products}
            errors={countData?.unit_price?.errors}
            style={{ marginTop: 16 }}
            showEmptyValues
            mainLoader={mainLoader}
          />
          <FileTypeAnalysis
            header="Holdings"
            files={countData?.holdings?.files}
            products={countData?.holdings?.products}
            errors={countData?.holdings?.errors}
            style={{ marginTop: 16 }}
            showEmptyValues={false}
            mainLoader={mainLoader}
          />
          <FileTypeAnalysis
            header="IMD02A001 & MDU01G010 Fund Sizes"
            files={countData?.fund_size?.files}
            products={countData?.fund_size?.products}
            errors={countData?.fund_size?.errors}
            style={{ marginTop: 16 }}
            showEmptyValues={false}
            mainLoader={mainLoader}
          />
          <FileTypeAnalysis
            header="Asset Allocation"
            files={countData?.asset_allocation?.files}
            products={countData?.asset_allocation?.products}
            errors={countData?.asset_allocation?.errors}
            style={{ marginTop: 16 }}
            showEmptyValues={false}
            mainLoader={mainLoader}
          />
          <FileTypeAnalysis
            header="Others"
            files={countData?.others?.files}
            products={countData?.others?.products}
            errors={countData?.others?.errors}
            style={{ marginTop: 16 }}
            showEmptyValues={false}
            mainLoader={mainLoader}
          />
          <FileAnalysisTotal
            mainLoader={mainLoader}
            data={countData}
            style={{ marginTop: 16 }}
          />
        </div>
      </div>
      <div className="file-analysis-second-row">
        <div>
          <div style={{ marginTop: 20 }}>
            {mainLoader ? (
              <Skeleton width={800} height={450} />
            ) : (
              <LineGraph dataSet={dataset} xAxisLabels={xAxisLabels} />
            )}
          </div>
        </div>
        <div style={{ marginTop: 20 }}>
          <div
            className="file-analysis-second-row-right-column"
            style={{ marginTop: 20 }}
          >
            <h3 className="file-analysis-second-row-right-column-title">
              <img
                src="/assets/file-unit-graph.svg"
                alt="file-unit-graph"
                style={{ marginRight: 15 }}
              />
              Files With Unit Prices
            </h3>
            <div className="tab-main-container">
              <div className="tab-container">
                <div
                  role="button"
                  tabIndex={0}
                  className={cn({
                    tab: true,
                    active: activeTab === "file-analysis",
                  })}
                  onClick={() => setActiveTab("file-analysis")}
                  onKeyPress={() => setActiveTab("file-analysis")}
                >
                  Workflow Status Analysis
                </div>
                <div
                  role="button"
                  tabIndex={0}
                  className={cn({ tab: true, active: activeTab === "files" })}
                  onClick={() => setActiveTab("files")}
                  onKeyPress={() => setActiveTab("files")}
                >
                  Files
                </div>
              </div>

              {activeTab === "files" && (
                <div className="file-analysis-second-row-grid file-analysis-container">
                  <LabelValue
                    value={errorData?.total_files_received || 0}
                    title="Received"
                    mainLoader={mainLoader}
                  />
                  <div className="divider-vertical" style={{ height: 50 }} />
                  <LabelValue
                    value={errorData?.total_ia_files_received || 0}
                    title="IA Generated"
                    mainLoader={mainLoader}
                  />
                  <div className="divider-vertical" style={{ height: 50 }} />
                  <LabelValue
                    value={
                      (errorData?.total_files_received || 0) +
                      (errorData?.total_ia_files_received || 0)
                    }
                    title="Total"
                    mainLoader={mainLoader}
                  />
                </div>
              )}

              {activeTab === "file-analysis" && (
                <div className="file-analysis-container">
                  <div className="file-analysis-second-row-grid">
                    <LabelValue
                      value={errorData?.total_published || 0}
                      title="Published"
                      mainLoader={mainLoader}
                    />
                    <div className="divider-vertical" style={{ height: 50 }} />
                    <LabelValue
                      value={errorData?.total_processed || 0}
                      title="Processing"
                      mainLoader={mainLoader}
                    />
                    <div className="divider-vertical" style={{ height: 50 }} />
                    <LabelValue
                      value={errorData?.total_resolved || 0}
                      title="Resolved"
                      mainLoader={mainLoader}
                    />
                  </div>

                  <div
                    className="file-analysis-second-row-grid"
                    style={{ marginTop: 16 }}
                  >
                    <LabelValue
                      value={errorData?.total_repair_queue || 0}
                      title="Repair Queue"
                      mainLoader={mainLoader}
                    />
                    <div className="divider-vertical" style={{ height: 50 }} />
                    <LabelValue
                      value={errorData?.total_quarantined || 0}
                      title="Quarantined"
                      mainLoader={mainLoader}
                    />
                    <div className="divider-vertical" style={{ height: 50 }} />
                    <LabelValue
                      value={errorData?.others_count || 0}
                      title="Others"
                      mainLoader={mainLoader}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FileAnalysis;

FileAnalysis.propTypes = {
  dateDetails: PropTypes.instanceOf(Object).isRequired,
  selectHourWindow: PropTypes.func.isRequired,
};
