import React, { useEffect } from "react";
import Chart from "chart.js";
import PropTypes from "prop-types";

Chart.defaults.global.defaultFontFamily = "'Montserrat', sans-serif";
Chart.defaults.global.legend.display = false;

function LineGraph({ dataSet, xAxisLabels }) {
  const chartRef = React.createRef();

  useEffect(() => {
    const myChartRef = chartRef.current.getContext("2d");
    if (window.myCharts !== undefined) {
      window.myCharts.destroy();
    }
    window.myCharts = new Chart(myChartRef, {
      type: "line",
      data: {
        // Bring in data
        labels: xAxisLabels,
        datasets: dataSet,
      },
      options: {
        responsive: true,
        point: {
          backgroundColor: "white",
        },
        layout: {
          padding: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    });
  }, [dataSet, xAxisLabels]);

  return (
    <div className="chart-style">
      <canvas id="myChart" ref={chartRef} />
      <div
        className="graph-items-group trend-graph-items"
        style={{ paddingLeft: 30, paddingRight: 30, marginTop: 10 }}
      >
        {dataSet.map((item) => (
          <div className="graph-legend" key={item.label}>
            <div
              style={{
                width: 10,
                height: 10,
                borderRadius: 5,
                backgroundColor: item.borderColor,
              }}
            />
            <p style={{ marginLeft: 5 }}>{item.label}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default LineGraph;

LineGraph.defaultProps = {
  dataSet: [],
  xAxisLabels: [],
};

LineGraph.propTypes = {
  dataSet: PropTypes.instanceOf(Array),
  xAxisLabels: PropTypes.instanceOf(Array),
};
