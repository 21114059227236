import React from "react";

function AttachmentSVG() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.25 3.37512V8.66512C8.25 9.71012 7.485 10.6401 6.445 10.7401C6.16717 10.7679 5.88661 10.7371 5.62144 10.6496C5.35626 10.5622 5.11237 10.4202 4.90552 10.2326C4.69866 10.0451 4.53345 9.81626 4.42053 9.5609C4.30762 9.30554 4.24953 9.02933 4.25 8.75012V2.57012C4.25 1.91512 4.72 1.32012 5.37 1.25512C5.54432 1.23689 5.72052 1.25553 5.88717 1.30981C6.05382 1.3641 6.20719 1.45282 6.33732 1.57023C6.46746 1.68763 6.57145 1.83109 6.64254 1.99129C6.71364 2.15149 6.75025 2.32485 6.75 2.50012V7.75012C6.75 8.02512 6.525 8.25012 6.25 8.25012C5.975 8.25012 5.75 8.02512 5.75 7.75012V3.37512C5.75 3.17012 5.58 3.00012 5.375 3.00012C5.17 3.00012 5 3.17012 5 3.37512V7.68012C5 8.33512 5.47 8.93012 6.12 8.99512C6.29432 9.01335 6.47052 8.99471 6.63717 8.94043C6.80382 8.88614 6.95719 8.79742 7.08732 8.68001C7.21746 8.56261 7.32145 8.41915 7.39254 8.25895C7.46364 8.09875 7.50025 7.92539 7.5 7.75012V2.58512C7.5 1.54012 6.735 0.61012 5.695 0.51012C5.41725 0.482756 5.13685 0.513855 4.87185 0.601416C4.60684 0.688977 4.36311 0.83106 4.15634 1.01852C3.94957 1.20598 3.78435 1.43465 3.67131 1.68983C3.55827 1.94501 3.49991 2.22103 3.5 2.50012V8.63512C3.5 10.0701 4.55 11.3551 5.98 11.4901C7.625 11.6401 9 10.3601 9 8.75012V3.37512C9 3.17012 8.83 3.00012 8.625 3.00012C8.42 3.00012 8.25 3.17012 8.25 3.37512Z"
        fill="#42505C"
      />
    </svg>
  );
}

export default AttachmentSVG;
