import axios from "../utils/axiosClient";
import endpoints from "./endpoints";

const {
  login,
  userInfo,
  uploadPic,
  logout,
  clientIP,
  resetPasswordMail,
  validateToken,
  changePassword,
  changeSettingsDetails,
  emailNotificationStatus,
  autoTaggingStatus,
  gmailURL,
} = endpoints;

const getLogin = async (payload) => {
  try {
    const response = await axios.post(login, payload);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getUserInfo = async () => {
  try {
    const response = await axios.get(userInfo);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const addClientIp = async (payload) => {
  try {
    const response = await axios.post(clientIP, payload);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const uploadPicture = async (payload) => {
  try {
    const response = await axios.post(uploadPic, payload);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const logOutUser = async () => {
  try {
    const response = await axios.post(logout);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const sendResetPasswordMail = async (payload) => {
  try {
    const response = await axios.post(resetPasswordMail, payload);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getValidateToken = async (uuid, token) => {
  try {
    const response = await axios.get(`${validateToken}${uuid}/${token}/`);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const changeLoginPassword = async (payload) => {
  try {
    const response = await axios.patch(changePassword, payload);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

// ------------------ Change Profile Details ------------------

const resetSettingsData = async (payload) => {
  try {
    const response = await axios.patch(changeSettingsDetails, payload);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

// ------------------ Profile Email Notification ------------------

const changeEmailStatus = async (payload) => {
  try {
    const response = await axios.patch(emailNotificationStatus, payload);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

// ------------------ Profile Auto Tagging ------------------

const enableAutoTagging = async (payload) => {
  try {
    const response = await axios.patch(autoTaggingStatus, payload);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const settingGmailURL = async (payload) => {
  try {
    const response = await axios.patch(gmailURL, payload);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export default {
  getLogin,
  uploadPicture,
  getUserInfo,
  logOutUser,
  addClientIp,
  sendResetPasswordMail,
  getValidateToken,
  changeLoginPassword,
  resetSettingsData,
  changeEmailStatus,
  enableAutoTagging,
  settingGmailURL,
};
