import React from "react";
import { toNumber, find } from "lodash";
import moment from "moment";
import userServices from "../api/userServices";

const roles = {
  USER: "user",
  BUSINESS_ADMIN: "business_admin",
  SUPER_ADMIN: "super_admin",
};

const appVersion = async () => {
  try {
    const res = await userServices.fetchAppVersion();
    return res.version_name;
  } catch (e) {
    return "";
  }
};

const getRoleLabel = (role) => {
  let label;
  switch (role) {
    case "user":
      label = "User";
      break;
    case "business_admin":
      label = "Business Admin";
      break;
    case "super_admin":
      label = "Super Admin";
      break;
    default:
      label = "Not Found";
  }
  return label;
};

const dateOptionsList = [
  { label: "D", value: "daily" },
  { label: "W", value: "weekly" },
  { label: "M", value: "monthly" },
];

const dateOptionsListExtended = [
  { label: "Daily", value: "daily" },
  { label: "Weekly", value: "weekly" },
  { label: "Monthly", value: "monthly" },
  { label: "Custom", value: "custom" },
];

const timeSince = (timeString) => {
  const newTimeString = toNumber(timeString) * 1000;
  let date = new Date(newTimeString);
  date = moment(date).fromNow();
  return date;
};

const getArrowColor = (value) =>
  value >= 0 ? "/assets/green-up2.png" : "/assets/red-down.png";

const getUnitDetails = (value) => {
  if (value === undefined || value === null) {
    return "0% Increase";
  }
  return value >= 0
    ? `${Math.abs(value)}% Increase`
    : `${Math.abs(value)}% Decrease`;
};

const getProfilePic = (picture) => picture || "/assets/avatar.png";

const disabledDate = (current, dates, durationType) => {
  let newDates = dates;
  if (!newDates || newDates.length === 0) {
    newDates = [Date.now(), undefined];
  }
  let tooLate;
  let tooEarly;
  if (durationType === "daily") {
    tooLate = newDates[0] && current.diff(newDates[0], "days") > 90;
    tooEarly = newDates[1] && newDates[1].diff(current, "days") > 90;
  } else if (durationType === "weekly") {
    tooLate = newDates[0] && current.diff(newDates[0], "months") > 3;
    tooEarly = newDates[1] && newDates[1].diff(current, "months") > 3;
  }
  if (durationType === "monthly") {
    tooLate = newDates[0] && current.diff(newDates[0], "months") > 11;
    tooEarly = newDates[1] && newDates[1].diff(current, "months") > 11;
  }
  const afterToday = current && current.valueOf() > Date.now();
  return tooEarly || tooLate || afterToday;
};

const tooLateValue = (durationType, newDates, current, hourWindow) => {
  let tooLate;
  if (durationType === "daily") {
    tooLate =
      newDates[0] &&
      current.diff(newDates[0], "days") >
        (hourWindow && hourWindow !== 24 ? hourWindow : 90);
  } else if (durationType === "weekly") {
    tooLate = newDates[0] && current.diff(newDates[0], "months") > 3;
  }
  if (durationType === "monthly") {
    tooLate = newDates[0] && current.diff(newDates[0], "months") > 11;
  }
  return tooLate;
};

const tooEarlyValue = (durationType, newDates, current, hourWindow) => {
  let tooEarly;
  if (durationType === "daily") {
    tooEarly =
      newDates[1] &&
      newDates[1].diff(current, "days") >
        (hourWindow && hourWindow !== 24 ? hourWindow : 90);
  } else if (durationType === "weekly") {
    tooEarly = newDates[1] && newDates[1].diff(current, "months") > 3;
  }
  if (durationType === "monthly") {
    tooEarly = newDates[1] && newDates[1].diff(current, "months") > 11;
  }
  return tooEarly;
};

const disabledDatesForHourlyOption = (
  current,
  dates,
  durationType,
  hourWindow
) => {
  let newDates = dates;
  if (!newDates || newDates.length === 0) {
    newDates = [Date.now(), undefined];
  }
  const tooLate = tooLateValue(durationType, newDates, current, hourWindow);
  const tooEarly = tooEarlyValue(durationType, newDates, current, hourWindow);

  const afterToday = current && current.valueOf() > Date.now();
  return tooEarly || tooLate || afterToday;
};

// quarantine and EWOA won't be having thread. It will be single record all the time
const checkHasThread = ({
  e,
  p,
  r,
  pro,
  hot,
  his,
  inactive,
  invalid,
  notAProduct,
  zipFile,
  ewka,
  iaProcessing,
}) =>
  e +
    p +
    r +
    pro +
    hot +
    his +
    inactive +
    invalid +
    notAProduct +
    zipFile +
    ewka >
  1;

// update in dashboard index also
const statusFilters = {
  all: "all",
  published: "pub",
  processing: "pro",
  error: "err",
  resolved: "res",
  startResolving: "start_res",
  processingResolved: "pro_res",
  quarantine: "quarantined",
  sendQuarantined: "req_qua_res",
  releaseQuarantined: "qua_release",
  StartValidation: "start_val",
  completedValidation: "cmp_val",
  processToUpload: "pro_upload",
  hotQueue: "hot_queue",
  NoAttachment: "no_attachment",
  largeFile: "large_file",
  historicalPrice: "his_error",
  inactive: "inactive_product",
  invalid: "invalid_product",
  notAProduct: "not_a_product",
  zipFile: "zip_file",
  ewka: "known_attachment",
  review: "review",
  fdPublished: "fd_pub",
};
const primaryIdentifierOptions = [
  {
    label: "APIR Code",
    value: "apir",
    apiKeyName: "APIR_code",
  },
  {
    label: "IRESS Code",
    value: "iress",
    apiKeyName: "iress_code",
  },
  {
    label: "CUSIP Code",
    value: "cusip",
    apiKeyName: "CUSIP_code",
  },
  {
    label: "Exchange Ticker Code",
    value: "exchange_ticker",
    apiKeyName: "exchange_ticker_code",
  },
  {
    label: "Bloomberg or FIGI Code",
    value: "figi",
    apiKeyName: "bloomberg_or_FIGI_code",
  },
  {
    label: "ISIN Code",
    value: "isin",
    apiKeyName: "ISIN_code",
  },
  {
    label: "Morningstar Code",
    value: "morningstar",
    apiKeyName: "morningstar_code",
  },
  {
    label: "Reuters Code",
    value: "reuters",
    apiKeyName: "reuters_code",
  },
  {
    label: "SEDOL Code",
    value: "sedol",
    apiKeyName: "SEDOL_code",
  },
  {
    label: "User Defined One",
    value: "user_defined_1",
    apiKeyName: "user_defined_field_one",
  },
  {
    label: "User Defined Two",
    value: "user_defined_2",
    apiKeyName: "user_defined_field_two",
  },
  {
    label: "ARSN",
    value: "arsn",
    apiKeyName: "arsn",
  },
];

const primaryIdentifierOptionsCompleteList = [
  {
    label: "APIR Code",
    value: "apir",
    apiKeyName: "APIR_code",
  },
  {
    label: "IRESS Code",
    value: "iress",
    apiKeyName: "iress_code",
  },
  {
    label: "CUSIP Code",
    value: "cusip",
    apiKeyName: "CUSIP_code",
  },
  {
    label: "Exchange Ticker Code",
    value: "exchange_ticker",
    apiKeyName: "exchange_ticker_code",
  },
  {
    label: "Bloomberg or FIGI Code",
    value: "figi",
    apiKeyName: "bloomberg_or_FIGI_code",
  },
  {
    label: "ISIN Code",
    value: "isin",
    apiKeyName: "ISIN_code",
  },
  {
    label: "Morningstar Code",
    value: "morningstar",
    apiKeyName: "morningstar_code",
  },
  {
    label: "Reuters Code",
    value: "reuters",
    apiKeyName: "reuters_code",
  },
  {
    label: "SEDOL Code",
    value: "sedol",
    apiKeyName: "SEDOL_code",
  },
  {
    label: "User Defined One",
    value: "user_defined_1",
    apiKeyName: "user_defined_field_one",
  },
  {
    label: "User Defined Two",
    value: "user_defined_2",
    apiKeyName: "user_defined_field_two",
  },
  {
    label: "ARSN",
    value: "arsn",
    apiKeyName: "arsn",
  },
];

const requiredFields = ["apir", "iress"];

const statusFilterOptions = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Published",
    value: "pub",
  },
  {
    label: "Processing",
    value: "pro",
  },
  {
    label: "Resolved",
    value: "res",
  },
  {
    label: "Error",
    value: "err",
  },
  {
    label: "Quarantine",
    value: "quarantined",
  },
  {
    label: "Hot Queue",
    value: "hot_queue",
  },
  {
    label: "EWOA",
    value: "no_attachment",
  },
  {
    label: "Historical Price",
    value: "his_error",
  },
  {
    label: "Inactive Product",
    value: "inactive_product",
  },
  {
    label: "Not In DB",
    value: "invalid_product",
  },
  {
    label: "Not a Product",
    value: "not_a_product",
  },
  {
    label: "Zip File",
    value: "zip_file",
  },
  {
    label: "EWKA",
    value: "known_attachment",
  },
  {
    label: "Review",
    value: "review",
  },
];

const otherStatusOptions = [
  {
    label: "Hot Queue",
    value: "hot_queue",
  },
  {
    label: "EWOA",
    value: "no_attachment",
  },
  {
    label: "Historical Price",
    value: "his_error",
  },
  {
    label: "Inactive",
    value: "inactive_product",
  },
  {
    label: "Not In DB",
    value: "invalid_product",
  },
  {
    label: "Not a Product",
    value: "not_a_product",
  },
  {
    label: "Zip File",
    value: "zip_file",
  },
  {
    label: "Quarantine",
    value: "quarantined",
  },
  {
    label: "EWKA",
    value: "known_attachment",
  },
  {
    label: "Review",
    value: "review",
  },
];

const resolveComments = {
  productCode: "Product Code Fixed",
  dateFormat: "Date Format Fixed",
  primaryCode: "Primary Code Missing",
  dataSequence: "Date Sequence Fixed",
  other: "Other",
};

const colorsList = [
  "#000000",
  "#434343",
  "#666666",
  "#999999",
  "#cccccc",
  "#efefef",
  "#f3f3f3",
  "#ffffff",
  "#fb4c2f",
  "#ffad47",
  "#fad165",
  "#16a766",
  "#43d692",
  "#4a86e8",
  "#a479e2",
  "#f691b3",
  "#f6c5be",
  "#ffe6c7",
  "#fef1d1",
  "#b9e4d0",
  "#c6f3de",
  "#c9daf8",
  "#e4d7f5",
  "#fcdee8",
  "#efa093",
  "#ffd6a2",
  "#fce8b3",
  "#89d3b2",
  "#a0eac9",
  "#a4c2f4",
  "#d0bcf1",
  "#fbc8d9",
  "#e66550",
  "#ffbc6b",
  "#fcda83",
  "#44b984",
  "#68dfa9",
  "#6d9eeb",
  "#b694e8",
  "#f7a7c0",
  "#cc3a21",
  "#eaa041",
  "#f2c960",
  "#149e60",
  "#3dc789",
  "#3c78d8",
  "#8e63ce",
  "#e07798",
  "#ac2b16",
  "#cf8933",
  "#d5ae49",
  "#0b804b",
  "#2a9c68",
  "#285bac",
  "#653e9b",
  "#b65775",
  "#822111",
  "#a46a21",
  "#aa8831",
  "#076239",
  "#1a764d",
  "#1c4587",
  "#41236d",
  "#83334c",
];

const productTypes = [
  {
    label: "Managed Fund",
    value: "Managed fund",
  },
  {
    label: "Insurance Bond",
    value: "Insurance bond",
  },
  {
    label: "Idps",
    value: "Idps",
  },
  {
    label: "Managed Account",
    value: "Managed account",
  },
  {
    label: "Credit",
    value: "Credit",
  },
  {
    label: "Insurance",
    value: "Insurance",
  },
  {
    label: "Etf",
    value: "Etf",
  },
  {
    label: "Superannuation",
    value: "Superannuation",
  },

  {
    label: "Derivatives",
    value: "Derivatives",
  },

  {
    label: "Other",
    value: "Other",
  },
];

const productSubTypes = [
  {
    label: "Wholesale",
    value: "Wholesale",
    type: ["Managed fund", "Superannuation"],
  },
  {
    label: "Retail",
    value: "Retail",
    type: ["Managed fund", "Superannuation"],
  },
  {
    label: "Annuity",
    value: "Annuity",
    type: ["Superannuation"],
  },
  {
    label: "Passive",
    value: "Passive",
    type: ["ETF"],
  },
  {
    label: "Active",
    value: "Active",
    type: ["ETF"],
  },
  {
    label: "Life",
    value: "1",
    type: ["Insurance"],
  },
  {
    label: "TPD",
    value: "2",
    type: ["Insurance"],
  },
  {
    label: "Trauma",
    value: "3",
    type: ["Insurance"],
  },
  {
    label: "Incope Protection",
    value: "4",
    type: ["Insurance"],
  },
  {
    label: "Other",
    value: "5",
    type: ["Insurance"],
  },
  {
    label: "Sma",
    value: "Sma",
    type: ["Sma"],
  },
];

const superannuationClassTypes = [
  {
    label: "Fund",
    value: "Fund",
  },
  {
    label: "Div",
    value: "Div",
  },
  {
    label: "Investment Option",
    value: "Investment Option",
  },
];
const formTypes = [
  {
    label: "Managed Investment Scheme",
    value: "Managed Investment Scheme",
  },
  {
    label: "AMIT",
    value: "AMIT",
  },
  {
    label: "UCIT",
    value: "UCIT",
  },
  {
    label: "CIV",
    value: "CIV",
  },
  {
    label: "OEIC",
    value: "OEIC",
  },
  {
    label: "CCIV",
    value: "CCIV",
  },
  {
    label: "VCLLP",
    value: "VCLLP",
  },
];

const statusTypes = [
  {
    label: "Open",
    value: "Open",
  },
  {
    label: "Closed",
    value: "Closed",
  },
  {
    label: "Terminated",
    value: "Terminated",
  },
  {
    label: "Suspended",
    value: "Suspended",
  },
  {
    label: "Not Yet Available",
    value: "Not yet available",
  },
  {
    label: "Not Available For Distribution",
    value: "Not available for distribution",
  },
];

const entityTypes = [
  {
    label: "Adviser",
    value: "Adviser",
  },
  {
    label: "Afsl",
    value: "Afsl",
  },
  {
    label: "Credit",
    value: "Credit",
  },
  {
    label: "Licensee",
    value: "Licensee",
  },
  {
    label: "Corporation",
    value: "Corporation",
  },
  {
    label: "Other",
    value: "Other",
  },
];

const issuerIdentifierTypes = [
  {
    label: "Afsl",
    value: "Afsl",
  },
  {
    label: "Acl",
    value: "Acl",
  },
  {
    label: "Abn",
    value: "Abn",
  },
  {
    label: "Far",
    value: "Far",
  },
  {
    label: "Other",
    value: "Other",
  },
];

const assetClassTypes = [
  {
    label: "Cash",
    value: "Cash",
  },
  {
    label: "Fixed Income",
    value: "Fixed Income",
  },
  {
    label: "Equity",
    value: "Equity",
  },
  {
    label: "Property",
    value: "Property",
  },
  {
    label: "Infrastructure",
    value: "Infrastructure",
  },
  {
    label: "Commodities",
    value: "Commodities",
  },
  {
    label: "Other",
    value: "Other",
  },
];

const assetClassSubCategory = [
  {
    label: "Australian",
    value: "Australian",
  },
  {
    label: "International",
    value: "International",
  },
];
const assetListingFixedIncomeType = [
  {
    label: "Government Debt",
    value: "Government debt",
  },
  {
    label: "Non Government Debt",
    value: "Non Government debt",
  },
  {
    label: "Mortgage Debt",
    value: "Mortgage debt",
  },
  {
    label: "Credit",
    value: "Credit",
  },
  {
    label: "Listed",
    value: "Listed",
  },
  {
    label: "Unlisted",
    value: "Unlisted",
  },
];
const pricingFrequencyTypes = [
  {
    label: "Daily",
    value: "Daily",
  },
  {
    label: "Weekly",
    value: "Weekly",
  },
  {
    label: "Monthly",
    value: "Monthly",
  },
  {
    label: "Quarterly",
    value: "Quarterly",
  },
  {
    label: "Semi Annual",
    value: "Semi Annual",
  },
  {
    label: "Annual",
    value: "Annual",
  },
];
const distributionFrequencyTypes = [
  {
    label: "Daily",
    value: "Daily",
  },
  {
    label: "Weekly",
    value: "Weekly",
  },
  {
    label: "Monthly",
    value: "Monthly",
  },
  {
    label: "Quarterly",
    value: "Quarterly",
  },
  {
    label: "Semi Annual",
    value: "Semi Annual",
  },
  {
    label: "Annual",
    value: "Annual",
  },
];

const domicile = [
  {
    label: "Australia",
    value: "AUS",
  },
  {
    label: "New Zealand",
    value: "NZ",
  },
];

const currency = [
  {
    label: "AUD",
    value: "AUD",
  },
  {
    label: "NZD",
    value: "NZD",
  },
];

// 3 patterns

// IS Reporting <IS_Reporting@challenger.com.au>
// <ssrs@mainstreamgroup.com>
// DL-FundDataOperations-AU@iress.com.au

const getName = (value) => {
  if (value.includes("<") && value.split("<")[0].trim() !== "")
    return value.split("<")[0].trim();
  if (value.includes("<"))
    return value.substring(value.lastIndexOf("<") + 1, value.lastIndexOf("@"));
  return value.split("@")[0];
};

const getEmail = (value) => {
  if (value.includes("<"))
    return value.substring(value.lastIndexOf("<") + 1, value.lastIndexOf(">"));
  return value;
};

const reportPages = {
  OVERVIEW: "overview",
  FILE_ANALYSIS: "fileAnalysis",
  ERROR_ANALYSIS: "errorAnalysis",
  EMAIL_ANALYSIS: "emailAnalysis",
  CUMLT_EMAIL_ANALYSIS: "cumulativeEmailAnalysis",
};

const isMailThreadEWOA = (mailThread = {}) => {
  const { status, codes, message } = mailThread;
  return (
    status === "no_attachment" ||
    (codes?.length && codes[0].length && codes[0][0] === "SE5004") ||
    message === "Email doesn't have any attachments"
  );
};

const isMailEOWA = async (mail = {}) => {
  const {
    processing_count: processingCount,
    total_attachment_count: totalAttachmentCount,
    resolved_count: resolvedCount,
    id,
  } = mail;
  if (
    (processingCount === 1 || resolvedCount === 1) &&
    totalAttachmentCount === 1
  ) {
    try {
      const mailThreads = await userServices.getMailAttachments(id);
      if (mailThreads?.length) {
        const [firstMailThread] = mailThreads;
        if (firstMailThread) {
          return isMailThreadEWOA(firstMailThread);
        }
      }
    } catch (error) {
      return false;
    }
  }
  return false;
};

const checkAndMapForEOWAInMail = async (mail) => {
  if (await isMailEOWA(mail)) {
    return {
      ...mail,
      is_ewoa: true,
    };
  }
  return mail;
};

const getEOWAMappedMails = async (mails) => {
  const promises = [];
  if (Array.isArray(mails) && mails.length) {
    mails.forEach((mail) => promises.push(checkAndMapForEOWAInMail(mail)));
  }
  const newMailData = await Promise.all(promises);
  return newMailData;
};

const getProductId = (productDetails) => {
  const productIdentifierType = productDetails.primary_identifier;
  const { apiKeyName } = find(primaryIdentifierOptionsCompleteList, [
    "value",
    productIdentifierType,
  ]);
  return productDetails[apiKeyName] || "";
};
const getProductType = (productDetails) => {
  const productIdentifierType = productDetails.primary_identifier;
  const { label } = find(primaryIdentifierOptionsCompleteList, [
    "value",
    productIdentifierType,
  ]);
  return label || "";
};

const formattedDateTime = (date) => {
  const today = new Date();
  if (today.toDateString() === date.toDateString()) {
    return moment(date).fromNow();
  }
  const diff = moment().diff(moment(date), "days");
  if (diff < 6) {
    return moment(date).calendar();
  }
  return moment(date).format("MMM D, YYYY [at] h:mm a");
};

const replaceURLs = (message) => {
  if (!message) return;
  const urlRegex = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#:.?+=&%!\-/]))?/;
  const words = message.split(/\n/g);
  const result = [];
  words.forEach((word) => {
    const internalWord = word.split(" ");

    internalWord.forEach((w) => {
      const hyperlink = w;
      result.push(
        w.match(urlRegex) ? (
          // eslint-disable-next-line react/jsx-filename-extension
          <>
            <a href={hyperlink} target="_blank" rel="noreferrer">
              {w}
            </a>{" "}
          </>
        ) : (
          `${w} `
        )
      );
    });
    result.push("\n");
  });

  // eslint-disable-next-line consistent-return
  return result.map((r) => r);
};

export default {
  roles,
  dateOptionsList,
  dateOptionsListExtended,
  timeSince,
  getArrowColor,
  getUnitDetails,
  getProfilePic,
  getRoleLabel,
  disabledDatesForHourlyOption,
  checkHasThread,
  statusFilters,
  resolveComments,
  primaryIdentifierOptions,
  requiredFields,
  statusFilterOptions,
  otherStatusOptions,
  getName,
  getEmail,
  reportPages,
  isMailThreadEWOA,
  isMailEOWA,
  getEOWAMappedMails,
  getProductId,
  getProductType,
  disabledDate,
  appVersion,
  formattedDateTime,
  colorsList,
  replaceURLs,
  productTypes,
  productSubTypes,
  superannuationClassTypes,
  statusTypes,
  issuerIdentifierTypes,
  entityTypes,
  formTypes,
  distributionFrequencyTypes,
  pricingFrequencyTypes,
  assetClassSubCategory,
  assetClassTypes,
  assetListingFixedIncomeType,
  domicile,
  currency,
};
