import React from "react";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";

import "./style.scss";
import PropTypes from "prop-types";

function SecondaryInput({ onChange, value }) {
  const onChangeHandle = (e) => {
    onChange(e.target.value);
  };

  const clearValue = () => {
    onChange("");
  };

  return (
    <div className="tab-search">
      <img src="/assets/loupe.svg" alt="search-lens" />
      <input
        placeholder="search for accounts, emails..."
        onChange={onChangeHandle}
        value={value}
      />
      {value && (
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label
          role="presentation"
          onKeyPress={clearValue}
          className="cancel-icon"
          onClick={clearValue}
        >
          <CancelRoundedIcon />
        </label>
      )}
    </div>
  );
}

export default SecondaryInput;

SecondaryInput.defaultProps = {
  onChange: () => {},
  value: "",
};

SecondaryInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};
