import React, { useContext, useState, useEffect } from "react";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import cn from "classnames";
import { Tooltip } from "@material-ui/core";
import styles from "./index.module.scss";
import appServices from "../../api/appServices";
import AuthContext from "../../context";
import RoleContext from "../../context/roleContext";
import helper from "../../utils/helper";
import ChangeLogPoup from "../ChangeLogPopup";

export default withRouter(({ history }) => {
  const { deleteAccessToken, iconSidebar, setIconSidebar } = useContext(
    AuthContext
  );
  const { userInfo } = useContext(RoleContext);
  const { userRoles, fullName } = userInfo;
  const { roles, appVersion } = helper;
  const location = useLocation();
  const currentLocation = location.pathname;

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [fullSidebar, setFullSidebar] = useState(false);
  const [changeLogPoup, setChangeLogPopup] = useState(false);
  const [versionName, setVersionName] = useState("");

  const logOutHandler = () => {
    appServices
      .logOutUser()
      .then(() => {
        deleteAccessToken();
        window.location.reload();
      })
      .catch(() => {});
  };

  const gotoDashBoard = () => {
    history.push("/dashboard/reports");
  };
  const gotoFAQ = () => {
    window.open(process.env.REACT_APP_FAQ_LINK, "_blank");
  };

  const openChangeLogPopup = () => {
    setChangeLogPopup(true);
  };

  const closeChangeLogPopup = () => {
    setChangeLogPopup(false);
  };

  const getVersionName = async () => {
    const version = await appVersion();
    setVersionName(version);
  };
  const fullSideBarHandler = (value) => {
    if (!iconSidebar && !isDropdownOpen) {
      setFullSidebar(value);
    }
  };

  useEffect(() => {
    getVersionName();
  }, []);

  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <header
      onMouseOver={() => fullSideBarHandler(true)}
      onMouseLeave={() => {
        fullSideBarHandler(false);
      }}
      className={cn({
        [styles.header]: true,
        [styles.fullWidth]: fullSidebar,
        [styles.smallWidth]: !fullSidebar,
      })}
    >
      <div className={styles.headerContent}>
        <div
          className={cn({
            [styles.headerIconContainer]: !fullSidebar,
          })}
        >
          <img
            className={styles.headerIcon}
            alt="logo"
            src="/assets/Logo.svg"
          />
        </div>
        <div className={styles.navLinks}>
          <div
            className={cn({
              [styles.navLinksItems]: true,
              [styles.navLinksItemsCenter]: !fullSidebar,
            })}
          >
            <NavLink
              activeClassName={styles.activeLink}
              className={cn({
                [styles.link]: true,
                [styles.linkWithIcon]: !fullSidebar,
              })}
              to="/dashboard"
            >
              <Tooltip title="Dashboard">
                <img
                  alt="user-dashbaord"
                  src={
                    currentLocation === "/dashboard"
                      ? "/assets/user-dashboard-select.svg"
                      : "/assets/user-dashboard.svg"
                  }
                  className={styles.iconImg}
                />
              </Tooltip>
              {fullSidebar && "Dashboard"}
            </NavLink>
            <NavLink
              activeClassName={styles.activeLink}
              className={cn({
                [styles.link]: true,
                [styles.linkWithIcon]: !fullSidebar,
              })}
              to="/product-lookup"
            >
              <Tooltip title="Product Lookup">
                <img
                  alt="search"
                  src={
                    currentLocation === "/product-lookup"
                      ? "/assets/product-lookup-select.svg"
                      : "/assets/product-lookup.svg"
                  }
                  style={{ marginRight: 5 }}
                />
              </Tooltip>
              {fullSidebar && "Product Lookup"}
            </NavLink>
            {/* <NavLink className={styles.link} to="/not-found">
              Settings
            </NavLink> */}

            <NavLink
              activeClassName={styles.activeLink}
              className={cn({
                [styles.link]: true,
                [styles.linkWithIcon]: !fullSidebar,
              })}
              to="/settings"
            >
              <Tooltip title={fullSidebar ? "" : "Settings"}>
                <img
                  alt="settings"
                  src={
                    currentLocation === "/settings"
                      ? "/assets/settings-select.svg"
                      : "/assets/settings.svg"
                  }
                  className={styles.iconImg}
                />
              </Tooltip>
              {fullSidebar && "Settings"}
            </NavLink>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            cursor: "pointer",
          }}
          role="button"
          tabIndex="0"
          onClick={() => {
            setDropdownOpen(!isDropdownOpen);
          }}
          onKeyPress={() => {
            setDropdownOpen(!isDropdownOpen);
          }}
        >
          <div className={styles.profileContainer}>
            <img alt="profile" src="/assets/profile-icon.svg" />
            {fullSidebar && (
              <div className={styles.profileName}>{fullName}</div>
            )}
            <div
              role="button"
              tabIndex="0"
              className={styles.dropdownContainer}
              onClick={() => {
                setDropdownOpen(!isDropdownOpen);
              }}
              onKeyPress={() => {
                setDropdownOpen(!isDropdownOpen);
              }}
            >
              {fullSidebar && (
                <img
                  alt="arrow"
                  src={
                    isDropdownOpen
                      ? "/assets/uparrow.svg"
                      : "/assets/downarrow.svg"
                  }
                  className={styles.dropdownIcon}
                />
              )}
              {isDropdownOpen && (
                <>
                  <div
                    aria-label="closeDropdown"
                    role="button"
                    tabIndex="0"
                    className={styles.scrim}
                    onClick={() => {
                      setDropdownOpen(false);
                    }}
                    onKeyPress={() => {
                      setDropdownOpen(false);
                    }}
                  />
                  <div
                    className={cn({
                      [styles.dropdown]: true,
                      [styles.fullDropdown]: fullSidebar,
                      [styles.smallDropdown]: !fullSidebar,
                    })}
                  >
                    <div className={styles.switchContainer}>
                      <div
                        className={cn({
                          [styles.switch]: true,
                          [styles.switchActive]: iconSidebar,
                        })}
                        role="button"
                        tabIndex="0"
                        onClick={() => {
                          setIconSidebar(true);
                          setFullSidebar(false);
                        }}
                        onKeyPress={() => {
                          setIconSidebar(true);
                          setFullSidebar(false);
                        }}
                      >
                        Icon View
                      </div>
                      <div
                        className={cn({
                          [styles.switch]: true,
                          [styles.switchActive]: !iconSidebar,
                        })}
                        role="button"
                        tabIndex="0"
                        onClick={() => {
                          setIconSidebar(false);
                        }}
                        onKeyPress={() => {
                          setIconSidebar(false);
                        }}
                      >
                        Full View
                      </div>
                    </div>
                    {!userRoles.includes(roles.USER) && (
                      <button
                        type="button"
                        className={styles.logoutButton}
                        onClick={gotoDashBoard}
                      >
                        <img
                          alt="user-dashboard"
                          src="/assets/admin-user-dash.svg"
                          className={styles.iconImg}
                        />{" "}
                        Admin Dashboard
                      </button>
                    )}
                    <button
                      type="button"
                      className={styles.logoutButton}
                      onClick={gotoFAQ}
                    >
                      <img
                        alt="faq"
                        src="/assets/faq.svg"
                        className={styles.iconImg}
                      />{" "}
                      FAQ
                    </button>

                    <button
                      type="button"
                      className={styles.logoutButton}
                      onClick={openChangeLogPopup}
                    >
                      <img
                        alt="logout"
                        src="/assets/change-log.svg"
                        className={styles.iconImg}
                      />
                      Change Log
                    </button>
                    <button
                      type="button"
                      className={styles.logoutButton}
                      onClick={logOutHandler}
                    >
                      <img
                        alt="logout"
                        src="/assets/logout.svg"
                        className={styles.iconImg}
                      />{" "}
                      Log Out
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
          {fullSidebar && (
            <div className={styles.versionContainer}>
              <p className={styles.versionNumber}>V{versionName}</p>
            </div>
          )}
        </div>
      </div>
      {changeLogPoup && <ChangeLogPoup closePopup={closeChangeLogPopup} />}
    </header>
  );
});
