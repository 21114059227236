import React, { useState, useEffect } from "react";
import { cloneDeep } from "lodash";
import PropTypes from "prop-types";

import Skeleton from "react-loading-skeleton";
import "./style.scss";
import showAlert from "../../../utils/showAlert";
import clientServices from "../../../api/clientServices";
import PaginationSkeleton from "../../../components/Skeletons/PaginationSkeleton";
import Pagination from "../../../components/Pagination";
import SelectBox from "../../../components/SelectBox";

function ProductsTable({ popUpData }) {
  const [alphabeticalOrder, setAlphabeticalOrder] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [filter, setFilter] = useState({
    currentPage: 1,
    searchKey: "",
    descendingSort: "false",
    fieldName: "sender_name",
    data_type: "all",
  });
  const [navigation, setNavigation] = useState({
    itemCount: undefined,
    totalCount: undefined,
    nextPageNum: undefined,
    prevPageNum: undefined,
    hasNextPage: undefined,
    hasPrevPage: undefined,
    dataPerPage: undefined,
  });

  // -------------------- Alphabetical Order --------------------
  const ascending = () => {
    const filterAscending = cloneDeep(filter);
    filterAscending.descendingSort = "false";
    filterAscending.currentPage = "1";
    filterAscending.searchKey = "";
    setFilter(filterAscending);
  };

  const descending = () => {
    const filterDescending = cloneDeep(filter);
    filterDescending.descendingSort = "true";
    filterDescending.currentPage = "1";
    filterDescending.searchKey = "";
    setFilter(filterDescending);
  };

  // --------------- Alphabetical Sorting ---------------
  const sortingOrder = () => {
    if (alphabeticalOrder) {
      ascending();
      setAlphabeticalOrder(false);
    } else {
      descending();
      setAlphabeticalOrder(true);
    }
  };

  const getSenderProductListing = () => {
    const filterText = [];
    if (filter.descendingSort)
      filterText.push(`descending_sort=${filter.descendingSort}`);
    if (filter.currentPage) filterText.push(`page=${filter.currentPage}`);
    filterText.push(`sender=${popUpData?.data?.id}`);
    filterText.push(`filter_by=${filter?.data_type}`);
    clientServices
      .getSenderProducts(filterText)
      .then((resp) => {
        setTableData(resp.results);
        const navigationCopy = cloneDeep(navigation);
        navigationCopy.itemCount = resp.results.length;
        navigationCopy.totalCount = resp.total_count;
        navigationCopy.nextPageNum = resp.next_page_num;
        navigationCopy.prevPageNum = resp.prev_page_num;
        navigationCopy.hasNextPage = resp.has_next_page;
        navigationCopy.hasPrevPage = resp.has_prev_page;
        navigationCopy.dataPerPage = resp.data_per_page;
        setNavigation(navigationCopy);
        setLoader(false);
      })
      .catch((e) => {
        setLoader(false);
        showAlert(e?.response?.data?.message, "error");
      });
  };
  useEffect(() => {
    setLoader(true);
    getSenderProductListing();
  }, [filter]);

  const tableSkeletonSection = () => (
    <>
      {Array.from({ length: 3 })
        .map((_, index) => index)
        .map((el) => (
          <div className="table-row table-row-skeleton" key={el}>
            <Skeleton width="99%" height={20} />
          </div>
        ))}
    </>
  );
  const tableDataSection = () => (
    <>
      {tableData.map((item) => (
        <div className="table-row" key={item}>
          <div className="table-col profile-col">
            <p data-testid="name">{item.product_name}</p>
          </div>
          <div className="table-col profile-col">
            <p data-testid="name">{item.primary_identifier}</p>
          </div>
          <div className="table-col">
            <p>{item.data_type ?? ""}</p>
          </div>
        </div>
      ))}
      {/* if there are no records  */}
      {tableData.length === 0 && (
        <div className="table-row table-row-no-data">No Record Found!</div>
      )}
    </>
  );

  const filterChangeHandler = (value) => {
    const temp = { ...filter };
    temp.data_type = value;
    setFilter(temp);
  };

  return (
    <div>
      <div className="filter-container">
        {loader ? (
          <PaginationSkeleton />
        ) : (
          <Pagination
            filter={filter}
            setFilter={setFilter}
            navigation={navigation}
          />
        )}
        <SelectBox
          listViewOption={[
            { label: "All", value: "all" },
            { label: "IMD02P001 & Non IMD Unit Prices", value: "unit_pricing" },
            { label: "IMD02A001 & MDU01G010 Fund Sizes", value: "fund_sizes" },
          ]}
          onChange={(e) => filterChangeHandler(e)}
          value={filter?.data_type}
        />
      </div>

      <div className="table">
        <div className="table-header">
          <p>
            Product Name
            <button type="button" onClick={sortingOrder}>
              {alphabeticalOrder ? (
                <img alt="sort" src="/assets/bx_sort-z-a.svg" />
              ) : (
                <img alt="sort" src="/assets/bx_sort-a-z.svg" />
              )}
            </button>
          </p>
          <p className="action-text">Identifier</p>
          <p>Data Type</p>
        </div>
        <div className="table-body">
          {loader ? tableSkeletonSection() : tableDataSection()}
        </div>
      </div>
    </div>
  );
}

export default ProductsTable;

ProductsTable.propTypes = {
  popUpData: PropTypes.instanceOf(Object).isRequired,
};
