import React, { useContext } from "react";
import PropTypes from "prop-types";
import ReactLoading from "react-loading";
import RefreshIcon from "@material-ui/icons/Refresh";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";
import { capitalize } from "lodash";
import helper from "../../utils/helper";
import AuthContext from "../../context";

import "./style.scss";

const contentSkeletonSection = () => (
  <>
    {Array.from({ length: 3 })
      .map((_, index) => index)
      .map((e) => (
        <div className="content-skeleton" key={e}>
          <Skeleton width="99%" height={20} />
        </div>
      ))}
  </>
);

function NotificationPopup({
  closePopup,
  clickHandler,
  loading,
  data,
  total,
  getData,
  loadMore,
}) {
  const { formattedDateTime } = helper;
  const { getUserDetailsFromDevice } = useContext(AuthContext);

  const onItemClick = (e, record) => {
    e.stopPropagation();
    clickHandler(record);
    closePopup();
  };

  const contentSection = () => (
    <div>
      <InfiniteScroll
        dataLength={data.length}
        next={loadMore}
        hasMore={total !== data.length}
        loader={
          <div className="infinite-scroll-loader">
            <ReactLoading type="bubbles" color="#1c57ad" />
          </div>
        }
        endMessage={
          total > 0 ? (
            <p className="infinite-scroll-end-messsage">
              <b>No more Tags.</b>
            </p>
          ) : null
        }
        scrollableTarget="infinte-scroll-table"
        className="infinite-scroll"
      >
        {data.map((e) => (
          <div
            className="content"
            onClick={(event) => onItemClick(event, e)}
            onKeyDown={(event) => onItemClick(event, e)}
            role="button"
            tabIndex={0}
            key={e.id}
          >
            <div className="title">
              <div>
                <div className="blue-round" />
                {e.subject}
              </div>
            </div>
            <div className="description">
              {capitalize(e?.starred_detail?.starred_by)} tagged you
              {e?.starred_detail?.tagged_user.length > 1
                ? `, ${e?.starred_detail?.tagged_user
                    .filter((x) => x.id !== getUserDetailsFromDevice()?.userId)
                    .map((m) => m.name)
                    .join(", ")}`
                : ""}{" "}
              in the mail. Reason being - {e?.starred_detail?.comment_text}
            </div>
            <div className="time">
              {formattedDateTime(
                new Date(e?.starred_detail?.tagged_created_at)
              )}
            </div>
          </div>
        ))}
      </InfiniteScroll>
      {data.length === 0 && (
        <div className="empty-notification">No New Tags!</div>
      )}
    </div>
  );

  const closePopupHandler = (e) => {
    e.stopPropagation();
    closePopup();
  };

  return (
    <div className="notification-popup">
      <div
        className="popup-overlay"
        onClick={(e) => closePopupHandler(e)}
        onKeyDown={(e) => closePopupHandler(e)}
        role="button"
        tabIndex={0}
        aria-label="close-popup"
      />
      <div className="popup-wrapper">
        <div className="popup-header">
          <h1>
            Tagged<div className="badge-container">{total}</div>
          </h1>
          <RefreshIcon onClick={getData} />
        </div>
        <div className="row-divider" />
        <div className="content-container" id="infinte-scroll-table">
          {loading ? contentSkeletonSection() : contentSection()}
        </div>
      </div>
    </div>
  );
}

export default NotificationPopup;

NotificationPopup.propTypes = {
  closePopup: PropTypes.func.isRequired,
  clickHandler: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
  total: PropTypes.number.isRequired,
  getData: PropTypes.func.isRequired,
  loadMore: PropTypes.func.isRequired,
};
