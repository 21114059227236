import React from "react";
import "./style.scss";
import PropTypes from "prop-types";

function Pagination({ filter, setFilter, navigation }) {
  const navPrev = () => {
    if (navigation.hasPrevPage) {
      setFilter({ ...filter, currentPage: navigation.prevPageNum });
    }
  };
  const navNext = () => {
    if (navigation.hasNextPage) {
      setFilter({ ...filter, currentPage: navigation.nextPageNum });
    }
  };
  return (
    <div className="pagination-wrapper">
      <p>
        {`${
          filter.currentPage * navigation.dataPerPage -
          navigation.dataPerPage +
          1
        } - ${
          filter.currentPage * navigation.dataPerPage -
          navigation.dataPerPage +
          navigation.itemCount
        } of ${navigation.totalCount}`}
      </p>
      <button
        type="button"
        onClick={navPrev}
        disabled={!navigation.hasPrevPage}
      >
        {" "}
        <img src="/assets/back.svg" alt="back" />
      </button>
      <button
        type="button"
        onClick={navNext}
        disabled={!navigation.hasNextPage}
      >
        {" "}
        <img src="/assets/next.svg" alt="next" />
      </button>
    </div>
  );
}

export default Pagination;

Pagination.propTypes = {
  filter: PropTypes.instanceOf(Object).isRequired,
  setFilter: PropTypes.func.isRequired,
  navigation: PropTypes.instanceOf(Object).isRequired,
};
