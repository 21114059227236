import React from "react";
import { ListItemText, makeStyles, MenuItem, Select } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  menuOption: {
    height: "37px",
    padding: "6px",
  },
  listItemRoot: {
    margin: "0px !important",
  },
  listItem: {
    fontSize: "13px",
  },

  menuFont: {
    fontSize: "13px !important",
  },
});

function SingleSelect({
  id,
  defaultValue,
  optionList,
  onChange,
  value,
  required,
  disabled
}) {
  const classes = useStyles();
  return (
    <Select
    disabled={disabled}
      id={id}
      fullWidth
      required={required}
      defaultValue={defaultValue || ""}
      onChange={onChange}
      value={value}
      classes={{
        select: classes.menuFont,
      }}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
        getContentAnchorEl: null,
      }}
    >
      {optionList.map((item) => (
        <MenuItem
          classes={{
            root: classes.menuOption,
          }}
          key={item.id || item.value}
          value={item.value}
          disabled={item.disabled || false}
        >
          <ListItemText
            classes={{
              root: classes.listItemRoot,
              primary: classes.listItem,
            }}
            primary={item.label || item.value}
          />
        </MenuItem>
      ))}
    </Select>
  );
}

export default SingleSelect;

SingleSelect.defaultProps = {
  defaultValue: "",
  required: false,
  disabled: false,
};

SingleSelect.propTypes = {
  id: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  optionList: PropTypes.instanceOf(Array).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool
};
