import React, { useState, useEffect, useContext } from "react";
import { Switch, Tooltip } from "antd";
import { Info, AttachMoney } from "@material-ui/icons";
import MainHeader from "../../components/MainHeader";
import Sidebar from "../../components/Sidebar";
import PasswordPopUp from "./ChangePassword";
import appServices from "../../api/appServices";
import userServices from "../../api/userServices";
import showAlert from "../../utils/showAlert";
import MultiSelectInfiniteScroll from "../../components/MultiSelectInfiniteScroll";
import RoleContext from "../../context/roleContext";
import helper from "../../utils/helper";
import "./style.scss";

function Settings() {
  const { userInfo } = useContext(RoleContext);
  const { userRoles } = userInfo;
  const { roles } = helper;

  // ------------------ State Values ------------------
  const [email, setEmail] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [passwordPopUp, setPasswordPopUp] = useState(false);
  const [emailStatus, setEmailStatus] = useState(false);
  const [usersList, setUserList] = useState([]);
  const [tagUsersList, setTagUserList] = useState([]);
  const [tagUsersListValue, setTagUserListValue] = useState([]);
  const [tagUsersIds, setTagUserIds] = useState([]);
  const [tagUsersTotal, setTagUsersTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [userListLoading, setUserListLoading] = useState(false);
  const [userListSearch, setUserListSearch] = useState("");
  const [priceLimit, setPriceLimit] = useState(0);
  const [spreadValue, setSpreadValue] = useState(0);
  const [autoTaggingStatus, setAutoTaggingStatus] = useState(false);
  const [gmailURL, setGmailURL] = useState("");

  // ------------------ Load Profile Details -------------

  const getUserDetails = () => {
    appServices.getUserInfo().then((data) => {
      setEmail(data.email);
      setFirstName(data.first_name);
      setLastName(data.last_name);
      setEmailStatus(data.email_notification);
      setAutoTaggingStatus(data.auto_tagging_status);
      setGmailURL(data?.gmail_url);
    });
  };

  const getTaggableUsers = () => {
    userServices.getTaggableUserList().then((resp) => {
      if (resp.is_tagged) {
        setTagUserList(resp.data.map((m) => m.uuid));
        setTagUserListValue(resp.data.map((m) => m.full_name));
        setTagUserIds(resp.data.map((m) => m.uuid));
      }
    });
  };

  const getPriceLimit = async () => {
    try {
      const res = await userServices.getCurrentPriceLimit();
      setPriceLimit(res.check_value);
    } catch (e) {
      // err
    }
  };

  const getSpreadValue = async () => {
    try {
      const res = await userServices.getCurrentSpreadValue();
      setSpreadValue(res.spread_value);
    } catch (e) {
      // err
    }
  };

  const getUserListDetails = (p) => {
    const filterText = [];
    if (p) {
      filterText.push(`page=${p}`);
    } else {
      filterText.push(`page=${page}`);
    }
    if (userListSearch) {
      filterText.push(`q=${userListSearch}`);
    }
    userServices.getUserListing(filterText).then((resp) => {
      setUserList(
        resp.results.map((item) => ({
          value: item.full_name,
          id: item.uuid,
          label: item.full_name,
        }))
      );
      setPage(resp?.next_page_num);
      setTagUsersTotal(resp?.total_count);
    });
  };

  useEffect(() => {
    let autoSaveTimer;
    autoSaveTimer = setTimeout(() => getUserListDetails(1), 1000);
    return () => {
      // cancel the request when component un mounts
      clearInterval(autoSaveTimer);
      autoSaveTimer = null;
    };
  }, [userListSearch]);

  const loadMoreUser = (e) => {
    if (page) {
      setUserListLoading(true);
      const filterText = [];
      filterText.push(`page=${page}`);
      if (userListSearch) {
        filterText.push(`q=${userListSearch}`);
      }
      userServices.getUserListing(filterText).then((resp) => {
        const list = resp.results.map((item) => ({
          value: item.full_name,
          id: item.uuid,
          label: item.full_name,
        }));
        setUserList([...usersList, ...list]);
        setPage(resp?.next_page_num);
        setTagUsersTotal(resp?.total_count);
        setUserListLoading(false);
      });
    }
  };

  useEffect(() => {
    getUserDetails();
    getTaggableUsers();
    getUserListDetails();
    getPriceLimit();
    getSpreadValue();
  }, []);

  const onChangeFirstName = (e) => {
    setFirstName(e.target.value);
  };

  const onChangeLastName = (e) => {
    setLastName(e.target.value);
  };

  const openPasswordPopUp = () => {
    setPasswordPopUp(true);
  };

  const closePasswordPopUp = () => {
    setPasswordPopUp(false);
  };

  // ------------------ Update Profile Details ------------------

  const updateUserName = () => {
    const data = {
      first_name: firstName,
      last_name: lastName,
    };
    appServices
      .resetSettingsData(data)
      .then((response) => {
        getUserDetails();
        showAlert(response.message, "success");
      })
      .catch((err) => {
        showAlert("Something went wrong!", "failure");
      });
  };

  const submitHandler = () => {
    if (!firstName) {
      showAlert("First Name cannot be empty", "failure");
    } else if (!lastName) {
      showAlert("Last name cannot be empty", "failure");
    } else {
      updateUserName();
    }
  };

  // ------------------ Email Notification Handler ------------------

  const emailStatusHandler = (e) => {
    const data = {
      email_notification: e,
    };
    setEmailStatus(e);
    appServices
      .changeEmailStatus(data)
      .then((response) => {
        showAlert(response.message, "success");
      })
      .catch((err) => {
        console.log(err);
        showAlert("Something went wrong", "failure");
      });
  };

  // ------------------ Auto Tagging Status Handler ------------------

  const autoTaggingHandler = (e) => {
    const data = {
      auto_tagging_status: e,
    };
    setAutoTaggingStatus(e);
    appServices
      .enableAutoTagging(data)
      .then((response) => {
        showAlert(response.message, "success");
      })
      .catch((err) => {
        console.log(err);
        showAlert("Something went wrong", "failure");
      });
  };

  const priceLimitHandler = async () => {
    if (priceLimit) {
      try {
        await userServices.updatePriceLimit(priceLimit);
        showAlert("Price limit updated", "success");
      } catch (e) {
        // err
        showAlert(e?.response?.data?.check_value[0], "error");
      }
    } else {
      showAlert("Please enter a price limit", "warning");
    }
  };

  const spreadValueHandler = async () => {
    try {
      await userServices.updateSpreadValue(spreadValue);
      showAlert("Spread Value updated", "success");
    } catch (e) {
      // err
      showAlert(e?.response?.data?.spread_value[0], "error");
    }
  };

  const taggableUserSubmitHandler = async () => {
    try {
      await userServices.updateTaggableUserList(tagUsersIds);
      showAlert("Taggable users updated", "success");
    } catch (e) {
      // error
    }
  };

  const handleTaggableUserList = (type, list) => {
    if (type === "delete") {
      setTagUserList(list.map((m) => m.id));
      setTagUserListValue(list.map((m) => m.value));
      setTagUserIds(list.map((m) => m.id));
    }
    if (type === "single") {
      if (list.check) {
        setTagUserList([...tagUsersList, list.id]);
        setTagUserListValue([...tagUsersListValue, list.value]);
        setTagUserIds([...tagUsersIds, list.id]);
      } else {
        const UL = [...tagUsersList];
        const ULel = UL.indexOf(list.id);
        UL.splice(ULel, 1);
        const ULV = [...tagUsersListValue];
        const ULVel = ULV.indexOf(list.value);
        ULV.splice(ULVel, 1);
        const ULId = [...tagUsersIds];
        const ULIdel = ULId.indexOf(list.id);
        ULId.splice(ULIdel, 1);

        setTagUserList(UL);
        setTagUserListValue(ULV);
        setTagUserIds(ULId);
      }
    }
  };

  const gmailURLSubmit = async () => {
    const regex = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#:.?+=&%!\-/]))?/;
    if (regex.test(gmailURL)) {
      try {
        let url = gmailURL;
        if (url[url.length - 1] === "/") url = url.substring(0, url.length - 1);
        const res = await appServices.settingGmailURL({ gmail_url: url });
        if (res?.success) {
          showAlert(res?.message, "success");
        }
      } catch (e) {
        // err
      }
    } else {
      showAlert("Must be a URL", "error");
    }
  };

  return (
    <div className="layout">
      <Sidebar />
      <div className="layout-container">
        <MainHeader title="Settings" />

        <div className="container settings-page">
          <div className="rowDivider" />
          <div className="mainHeading">Individual User Settings</div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div>
              <div className="name-text">
                <p>Personal Info</p>
                <Tooltip title="Update your personal details here">
                  <Info />
                </Tooltip>
              </div>
              <p className="flex-sub-text">Update your personal details here</p>
              <div className="form-box">
                <form>
                  {/* <div className="dotted-box">
              <div className="image">
                <img alt="human" className="human" src="/assets/human.svg" />{" "}
                <img
                  className="arrow"
                  alt="arrow"
                  src="/assets/arrow-img.svg"
                />{" "}
              </div>
              <div className="drag-text">
                Drag and drop your profile picture
              </div>
            </div> */}
                  &ensp;
                  <div className="settings-input-wrapper">
                    <div className="inputLabel">First Name</div>
                    <div className="settings-input">
                      <input
                        maxLength={100}
                        required="required"
                        type="text"
                        onChange={(e) => onChangeFirstName(e)}
                        value={firstName}
                      />
                    </div>
                    <div className="inputLabel">Last Name</div>
                    <div className="settings-input">
                      <input
                        maxLength={100}
                        required="required"
                        type="text"
                        onChange={(e) => onChangeLastName(e)}
                        value={lastName}
                      />
                    </div>
                    <div className="inputLabel">Email</div>
                    <div className="settings-input">
                      <input
                        maxLength={100}
                        required="required"
                        type="email"
                        disabled
                        value={email}
                      />
                    </div>

                    <div
                      className="change-password"
                      role="link"
                      tabIndex={0}
                      onKeyPress={openPasswordPopUp}
                      onClick={openPasswordPopUp}
                    >
                      <p>Change Password</p>
                    </div>
                    {passwordPopUp && (
                      <PasswordPopUp closePasswordPopUp={closePasswordPopUp} />
                    )}
                    <div className="popup-footer">
                      <button
                        type="button"
                        className="save-btn"
                        onClick={submitHandler}
                      >
                        Save Changes
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div style={{ marginLeft: 60 }}>
              <div className="notification">
                <div className="flex-text">
                  <p>Notifications</p>
                  <Tooltip title="Control your notifications and auto-follow settings">
                    <Info />
                  </Tooltip>
                </div>
                <div className="flex-sub-text">
                  <p>Control your notifications and auto-follow settings</p>
                </div>
                <div className="flex-box">
                  <div className="b1">
                    <div className="b2">
                      <div className="col-1">
                        <p className="email">Email Notification</p>
                        {/* <p className="commits">Commits data and history</p> */}
                      </div>
                      <Switch
                        className="sw"
                        checked={emailStatus}
                        onChange={(e) => emailStatusHandler(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="tagged-user-container">
                <div className="flex-text">
                  <p>Tag Users</p>
                  <Tooltip title="Select the users to be shown in tagged users list">
                    <Info />
                  </Tooltip>
                </div>
                <div className="flex-sub-text">
                  <p>Select the users to be shown in tagged users list</p>
                </div>
                <div className="tagged-flex-box">
                  <div className="b1">
                    <div className="b2">
                      <MultiSelectInfiniteScroll
                        id="tagged-user"
                        onChange={(type, data) => {
                          handleTaggableUserList(type, data);
                        }}
                        value={tagUsersList}
                        listOptions={usersList}
                        loadMore={loadMoreUser}
                        listLoading={userListLoading}
                        totalData={tagUsersTotal}
                        setSearch={setUserListSearch}
                        selectValue={tagUsersListValue}
                      />
                    </div>
                    <button
                      // disabled={tagUsersList.length === 0}
                      type="button"
                      className="save-btn"
                      onClick={taggableUserSubmitHandler}
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="manual-upload">
            <div className="b1">
              <div className="b2">
                <div className="col-1">
                  <div className="flex-text">
                    Auto Tagging Feature on IA Manual Upload
                  </div>
                </div>
                <Switch
                  className="sw"
                  checked={autoTaggingStatus}
                  onChange={(e) => autoTaggingHandler(e)}
                />
              </div>
            </div>
          </div>
          <div className="price-limit-container">
            <div className="flex-text">
              <p>Unique Gmail URL String</p>
              <Tooltip title="Copy your deligated iress mail url and paste here.">
                <Info />
              </Tooltip>
            </div>
            <div className="flex-sub-text">
              <p>Paste URL here.</p>
            </div>
            <div className="price-flex-box">
              <div className="b1">
                <div className="b2">
                  <div className="settings-input-wrapper">
                    <div className="inputLabel">URL</div>
                    <div className="settings-input price-limit-input">
                      <input
                        maxLength={90}
                        required="required"
                        type="text"
                        onChange={(e) => setGmailURL(e.target.value)}
                        value={gmailURL}
                      />
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  className="save-btn"
                  onClick={gmailURLSubmit}
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
          {userRoles.includes(roles.SUPER_ADMIN) && (
            <div className="systemSettingsContainer">
              <div className="mainHeading">System Wide Universal Settings</div>
              <div className="price-limit-container">
                <div className="flex-text">
                  <p>Price Limit</p>
                  <Tooltip title="Set the price comparison limit value for nav_a, entry_a, and exit_a here.">
                    <Info />
                  </Tooltip>
                </div>
                <div className="flex-sub-text">
                  <p>Set the price limit</p>
                </div>
                <div className="price-flex-box">
                  <div className="b1">
                    <div className="b2">
                      <div className="settings-input-wrapper">
                        <div className="inputLabel">Price Limit</div>
                        <div className="settings-input price-limit-input">
                          <span>
                            <AttachMoney />
                          </span>
                          <input
                            maxLength={90}
                            required="required"
                            type="number"
                            onChange={(e) => setPriceLimit(e.target.value)}
                            value={priceLimit}
                          />
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="save-btn"
                      onClick={priceLimitHandler}
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
              <div className="price-limit-container margin-top-10">
                <div className="flex-text">
                  <p>Spread Value</p>
                  <Tooltip title="Set the Spread comparison limit value for nav_a, entry_a, and exit_a here.">
                    <Info />
                  </Tooltip>
                </div>
                <div className="flex-sub-text">
                  <p>Set the spread value</p>
                </div>
                <div className="price-flex-box">
                  <div className="b1">
                    <div className="b2">
                      <div className="settings-input-wrapper">
                        <div className="inputLabel">Spread Value</div>
                        <div className="settings-input price-limit-input">
                          <input
                            maxLength={90}
                            required="required"
                            type="number"
                            onChange={(e) => setSpreadValue(e.target.value)}
                            value={spreadValue}
                            max={100}
                            min={0}
                          />
                          <span>%</span>
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="save-btn"
                      onClick={spreadValueHandler}
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Settings;
