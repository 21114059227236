import React from "react";
import ReactDateTime from "react-datetime";
import cn from "classnames";
import PropTypes from "prop-types";

import styles from "../../../resources/index.module.scss";

const datePattern = "\\d{1,2}-\\d{1,2}-\\d{4}";
const dateFormat = "DD-MM-YYYY";

function DateAndTime({
  id,
  name,
  placeholder,
  highZIndex,
  halfWidth,
  register,
  alignCenter,
  disabled,
  onChange,
}) {
  return (
    <ReactDateTime
      className={cn({
        [styles.datetimePickerContainer]: true,
        [styles.highZIndex]: highZIndex,
        [styles.halfWidth]: halfWidth,
        [styles.alignCenter]: alignCenter,
        [styles.greyBgDate]: disabled,
      })}
      closeOnSelect
      dateFormat={dateFormat}
      onChange={onChange}
      timeFormat={false}
      inputProps={{
        type: "text",
        autoComplete: "off",
        placeholder,
        pattern: datePattern,
        id,
        name,
        ref: register,
      }}
    />
  );
}

export default DateAndTime;

DateAndTime.defaultProps = {
  highZIndex: false,
  halfWidth: false,
  alignCenter: false,
  disabled: false,
  onChange: () => {},
};

DateAndTime.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  highZIndex: PropTypes.bool,
  halfWidth: PropTypes.bool,
  register: PropTypes.func.isRequired,
  alignCenter: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};
