import axios from "../utils/axiosClient";
import endpoints from "./endpoints";

const {
  clientReports,
  userReports,
  overView,
  userPerformance,
  clientPerformance,
  dashboardMetrics,
  trendAnalysis,
  fileAnalysis,
  errorAnalysis,
  emailAnalysis,
  senderReports,
  cumulativeEmailAnalysis,
} = endpoints;

const getClientReports = async (query) => {
  try {
    const response = await axios.get(`${clientReports}?${query.join("&")}`);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getUserReports = async (query) => {
  try {
    const response = await axios.get(`${userReports}?${query.join("&")}`);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getOverView = async (query) => {
  try {
    const response = await axios.get(`${overView}?${query.join("&")}`);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getUserPerformance = async () => {
  try {
    const response = await axios.get(userPerformance);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getClientPerformance = async () => {
  try {
    const response = await axios.get(clientPerformance);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getDashboardMetrics = async (query, type) => {
  try {
    const response = await axios.get(
      `${dashboardMetrics}?${query.join("&")}&type=${type}`
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getTrendAnalysis = async (query, type) => {
  try {
    const response = await axios.get(
      `${trendAnalysis}?${query.join("&")}&type=${type}`
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getFileAnalysis = async (query, type) => {
  try {
    const response = await axios.get(
      `${fileAnalysis}?${query.join("&")}&type=${type}`
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getErrorAnalysis = async (query) => {
  try {
    const response = await axios.get(`${errorAnalysis}?${query.join("&")}`);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getEmailAnalysis = async (query) => {
  try {
    const response = await axios.get(`${emailAnalysis}?${query?.join("&")}`);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getSenderReports = async (query) => {
  try {
    const response = await axios.get(`${senderReports}?${query.join("&")}`);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getCumulativeEmailAnalysis = async (query) => {
  try {
    const response = await axios.get(
      `${cumulativeEmailAnalysis}?${query?.join("&")}`
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export default {
  getClientReports,
  getUserReports,
  getOverView,
  getUserPerformance,
  getClientPerformance,
  getDashboardMetrics,
  getTrendAnalysis,
  getFileAnalysis,
  getErrorAnalysis,
  getEmailAnalysis,
  getSenderReports,
  getCumulativeEmailAnalysis,
};
