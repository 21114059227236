import React, { useState } from "react";
import PropTypes from "prop-types";
import { cloneDeep } from "lodash";
import productServices from "../../../api/productServices";
import showAlert from "../../../utils/showAlert";

function DeletePopup({
  item,
  setLoader,
  getProductListing,
  closeDeletePopup,
  filter,
  setFilter,
  navigation,
  count,
}) {
  const [deleting, setDeleting] = useState(false);

  // deletes product from database
  const deleteHandler = (e) => {
    e.preventDefault();
    setDeleting(true);
    setLoader(true);
    productServices
      .deleteProduct(item.id)
      .then(() => {
        showAlert("Product deleted!", "success");
        if (!navigation?.hasNextPage && count === 1) {
          const filterCopy = cloneDeep(filter);
          filterCopy.currentPage = navigation?.prevPageNum;
          setFilter(filterCopy);
        } else {
          getProductListing();
        }
        closeDeletePopup();
      })
      .catch(() => {
        setLoader(false);
        setDeleting(false);
      });
  };
  return (
    <div className="popup delete-product-popup">
      <div className="popup-overlay" />
      <div className="popup-wrapper">
        <div className="popup-header">
          <h1>Delete Product</h1>
          <p>Are you sure you want to delete the product?</p>
        </div>
        <form onSubmit={deleteHandler}>
          <div className="popup-footer">
            <button
              type="button"
              className="cancel-btn"
              onClick={closeDeletePopup}
            >
              Cancel
            </button>
            <button disabled={deleting} className="save-btn" type="submit">
              {deleting ? "Deleting..." : "Confirm"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default DeletePopup;

DeletePopup.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  setLoader: PropTypes.func.isRequired,
  getProductListing: PropTypes.func.isRequired,
  closeDeletePopup: PropTypes.func.isRequired,
  navigation: PropTypes.instanceOf(Object).isRequired,
  filter: PropTypes.instanceOf(Object).isRequired,
  setFilter: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
};
