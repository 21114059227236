import React, { useEffect, useRef, useState } from "react";
import { cloneDeep } from "lodash";
import useKeyboardShortcut from "use-keyboard-shortcut";

import MainHeader from "../../components/MainHeader";
import ClientManagementPopup from "./ClientManagementPopup";
import ClientManagementTable from "./ClientManagementTable";
import AddButton from "../../components/AddButton/index";
import Pagination from "../../components/Pagination/index";

import PaginationSkeleton from "../../components/Skeletons/PaginationSkeleton";
import clientServices from "../../api/clientServices";
import "./style.scss";
import MainLayout from "../../components/MainLayout";
import AdvanceSearchBox from "../../components/AdvanceSearchContainer";
import AdvanceSearchInput from "../../components/AdvanceSearchInput";
import HotQueuePopUp from "../HotQueue/HotQueuePopUp";
import hotQueueServices from "../../api/hotQueueServices";

function ClientManagement() {
  const findRef = useRef(null);

  const [loader, setLoader] = useState(false);
  const [popUpData, setPopUpData] = useState({
    isShowPopUp: false,
    isEditMode: false,
    data: {},
  });
  const [hotqueuePopUpData, setHotqueuePopUpData] = useState({
    isShowPopUp: false,
    isFromSender: true,
    data: {
      rule_name: "",
      active: false,
      rule_details: [
        {
          condition: "matches",
          condition_param: "mail_from",
          condition_data: "",
        },
      ],
    },
  });
  const [tableData, setTableData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [filter, setFilter] = useState({
    currentPage: 1,
    searchKey: "",
    descendingSort: "false",
    fieldName: "sender_name",
  });
  const [navigation, setNavigation] = useState({
    itemCount: undefined,
    totalCount: undefined,
    nextPageNum: undefined,
    prevPageNum: undefined,
    hasNextPage: undefined,
    hasPrevPage: undefined,
    dataPerPage: undefined,
  });
  const [advancePopup, setAdvancePopup] = useState(false);

  const openAdvancePopup = () => {
    setAdvancePopup(true);
  };

  const loadSenderListDetails = () => {
    const filterText = [];
    if (filter.descendingSort)
      filterText.push(`descending_sort=${filter.descendingSort}`);
    if (filter.fieldName) filterText.push(`field_name=${filter.fieldName}`);
    if (filter.currentPage) filterText.push(`page=${filter.currentPage}`);
    if (filter.searchKey.length !== 0) filterText.push(`q=${filter.searchKey}`);
    filterText.push(`page_size=${25}`);

    clientServices.getSenderListing(filterText).then((resp) => {
      setTableData([...tableData, ...resp.results]);
      setTotalData(resp.total_count);
      setFilter({ ...filter, currentPage: resp.next_page_num });
      const navigationCopy = cloneDeep(navigation);
      navigationCopy.itemCount = resp.results.length;
      navigationCopy.totalCount = resp.total_count;
      navigationCopy.nextPageNum = resp.next_page_num;
      navigationCopy.prevPageNum = resp.prev_page_num;
      navigationCopy.hasNextPage = resp.has_next_page;
      navigationCopy.hasPrevPage = resp.has_prev_page;
      navigationCopy.dataPerPage = resp.data_per_page;
      setNavigation(navigationCopy);
      setLoader(false);
    });
  };

  const getClientListDetails = () => {
    const filterText = [];
    if (filter.descendingSort)
      filterText.push(`descending_sort=${filter.descendingSort}`);
    if (filter.fieldName) filterText.push(`field_name=${filter.fieldName}`);
    if (filter.currentPage) filterText.push(`page=${filter.currentPage}`);

    if (filter.searchKey.length !== 0) filterText.push(`q=${filter.searchKey}`);
    filterText.push(`page_size=${25}`);
    clientServices.getSenderListing(filterText).then((resp) => {
      setTableData(resp.results);
      setTotalData(resp.total_count);

      const navigationCopy = cloneDeep(navigation);
      navigationCopy.itemCount = resp.results.length;
      navigationCopy.totalCount = resp.total_count;
      navigationCopy.nextPageNum = resp.next_page_num;
      navigationCopy.prevPageNum = resp.prev_page_num;
      navigationCopy.hasNextPage = resp.has_next_page;
      navigationCopy.hasPrevPage = resp.has_prev_page;
      navigationCopy.dataPerPage = resp.data_per_page;
      setNavigation(navigationCopy);
      setLoader(false);
    });
  };

  useEffect(() => {
    setLoader(true);
    let autoSaveTimer;
    autoSaveTimer = setTimeout(() => getClientListDetails(), 1000);
    return () => {
      // cancel the request when component un mounts
      clearInterval(autoSaveTimer);
      autoSaveTimer = null;
    };
  }, [filter]);

  useEffect(() => {
    document.title = "BCG IA | Client Management";
  }, []);

  const addButtonHandle = () => {
    const popUpDataCopy = cloneDeep(popUpData);
    popUpDataCopy.isShowPopUp = true;
    popUpDataCopy.isEditMode = false;
    popUpDataCopy.data = {};
    setPopUpData(popUpDataCopy);
  };

  const onChangeHandler = (key) => (value) => {
    const filterCopy = cloneDeep(filter);
    if (key === "searchKey") {
      filterCopy.searchKey = value;
      filterCopy.currentPage = 1;
      setFilter(filterCopy);
    }
  };

  const getHotQueueListing = async (searchKey) => {
    const filterText = [];
    filterText.push(`q=${searchKey}`);

    try {
      const resp = await hotQueueServices.getHotQueueList(filterText);
      return resp;
    } catch (err) {
      // error
      return [];
    }
  };

  // ----------------- Sort functions -----------------

  const ascendingName = (value) => {
    const filterAscendingName = cloneDeep(filter);
    filterAscendingName.currentPage = 1;
    filterAscendingName.descendingSort = "false";
    filterAscendingName.fieldName = value;
    setFilter(filterAscendingName);
  };
  const descendingName = (value) => {
    const filterDescendingName = cloneDeep(filter);
    filterDescendingName.currentPage = 1;
    filterDescendingName.descendingSort = "true";
    filterDescendingName.fieldName = value;
    setFilter(filterDescendingName);
  };

  const closePopup = () => {
    const popUpDataCopy = cloneDeep(popUpData);
    popUpDataCopy.isShowPopUp = false;
    popUpDataCopy.isEditMode = false;
    popUpDataCopy.data = {};
    setPopUpData(popUpDataCopy);
  };

  const closeHotqueuePopup = () => {
    const popUpDataCopy = cloneDeep(hotqueuePopUpData);
    popUpDataCopy.isShowPopUp = false;
    popUpDataCopy.isEditMode = false;
    popUpDataCopy.data = {};
    setHotqueuePopUpData(popUpDataCopy);
  };

  const openHotqueuePopup = async (item) => {
    const popUpDataCopy = cloneDeep(hotqueuePopUpData);
    const rules = await getHotQueueListing(item.email);
    popUpDataCopy.isShowPopUp = true;
    if (rules.length > 0) {
      let ruleDetails = [];
      rules.map((e) => {
        ruleDetails = [...ruleDetails, ...e.rule_details];
      });
      popUpDataCopy.isEditMode = true;
      popUpDataCopy.data = {
        id: rules[0].id,
        rule_name: rules[0].rule_name,
        active: false,
        rule_details: ruleDetails,
      };
    } else {
      popUpDataCopy.data = {
        rule_name: "",
        active: false,
        rule_details: [
          {
            condition: "matches",
            condition_param: "mail_from",
            condition_data: item.email,
          },
        ],
      };
    }
    setHotqueuePopUpData(popUpDataCopy);
  };

  // keyboard shortcuts
  useKeyboardShortcut(["Control", "a"], () => addButtonHandle(), {
    overrideSystem: true,
  });
  useKeyboardShortcut(["Escape"], () => closePopup());
  useKeyboardShortcut(["Control", "f"], () => findRef.current.focus(), {
    overrideSystem: true,
  });

  return (
    <MainLayout>
      <MainHeader title="Sender Management" />
      {/* <ManagementTabs totalCount={totalData} totalText="Senders" /> */}
      <div
        className="container client-management"
        data-testid="client-management"
      >
        <div className="page-header">
          <div className="page-header___right">
            <AdvanceSearchInput
              onChange={onChangeHandler("searchKey")}
              value={filter.searchKey}
              inputRef={findRef}
              placeholder="Search for Organizations, Emails ..."
              advancePopupOpen={openAdvancePopup}
            />
            <AddButton onClickHandle={addButtonHandle} />
          </div>
          {loader ? (
            <PaginationSkeleton />
          ) : (
            <Pagination
              filter={filter}
              setFilter={setFilter}
              navigation={navigation}
            />
          )}
        </div>
        {advancePopup && <AdvanceSearchBox />}
        <ClientManagementTable
          loader={loader}
          setLoader={setLoader}
          tableData={tableData}
          setTableData={setTableData}
          popUpData={popUpData}
          setPopUpData={setPopUpData}
          getClientListDetails={loadSenderListDetails}
          openHotqueuePopup={openHotqueuePopup}
          totalCount={totalData}
          ascendingName={ascendingName}
          descendingName={descendingName}
        />
        {popUpData.isShowPopUp && (
          <ClientManagementPopup
            setLoader={setLoader}
            popUpData={popUpData}
            getClientListDetails={getClientListDetails}
            closePopup={closePopup}
          />
        )}

        {hotqueuePopUpData.isShowPopUp && (
          <HotQueuePopUp
            setLoader={() => {}}
            popUpData={hotqueuePopUpData}
            closePopup={closeHotqueuePopup}
            getHotQueueListing={() => {}}
          />
        )}
      </div>
    </MainLayout>
  );
}

export default ClientManagement;
