import React, { memo, useEffect } from "react";
import Chart from "chart.js";
import PropTypes from "prop-types";

Chart.defaults.global.defaultFontFamily = "'Montserrat', sans-serif";

function BarGraph({ height, dataSets, xAxisLabels, type }) {
  const chartRef = React.createRef();

  useEffect(() => {
    const myChartRef = chartRef.current.getContext("2d");
    if (window.myCharts !== undefined) {
      window.myCharts.destroy();
    }
    window.myCharts = new Chart(myChartRef, {
      type: "bar",
      data: {
        labels: xAxisLabels,
        datasets: dataSets,
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },

        tooltips: {
          displayColors: type !== "email",
          callbacks: {
            beforeBody: (tooltipItem, data) =>
              data?.datasets[tooltipItem[0]?.datasetIndex]?.descriptions
                ? data?.datasets[tooltipItem[0]?.datasetIndex]?.descriptions[
                    tooltipItem[0].index
                  ]
                : "",

            label: (tooltipItem, data) => {
              if (type === "email") {
                const array = [];
                data?.datasets.forEach((d) =>
                  array.push(`${d?.label} : ${d?.data[tooltipItem?.index]}`)
                );
                return array;
              }

              return [
                `${data?.datasets[tooltipItem?.datasetIndex].label}:${
                  data?.datasets[tooltipItem?.datasetIndex]?.data[
                    tooltipItem?.index
                  ]
                }`,
              ];
            },
          },
        },
        hover: {
          mode: "x-axis",
          intersect: true,
          onHover(e, activeElements) {
            if (type !== "email") {
              const chartInstance = this;
              if (e.type === "mousemove") {
                const segment = chartInstance.getDatasetMeta(0).data[
                  activeElements[0]._index
                ];
                chartInstance.tooltip._active = [segment];
                chartInstance.tooltip.update();
              }
              if (e.type === "mouseout") {
                chartInstance.tooltip._active = [];
                chartInstance.tooltip.update();
              }
            }
          },
        },
      },
    });
  }, [dataSets, xAxisLabels]);

  return (
    <div className="chart-style">
      <canvas id="myChart" ref={chartRef} height={height} />
    </div>
  );
}

export default memo(BarGraph);

BarGraph.defaultProps = {
  dataSets: [],
  xAxisLabels: [],
  type: "",
};

BarGraph.propTypes = {
  height: PropTypes.number.isRequired,
  dataSets: PropTypes.shape(Array),
  xAxisLabels: PropTypes.shape(Array),
  type: PropTypes.string,
};
