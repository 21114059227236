const statusFilters = {
  all: "all",
  published: "pub",
  processing: "pro",
  error: "err",
  resolved: "res",
  startResolving: "start_res",
  processingResolved: "pro_res",
  quarantine: "quarantined",
  sendQuarantined: "req_qua_res",
  releaseQuarantined: "qua_release",
  hotQueue: "hot_queue",
  NoAttachment: "no_attachment",
  largeFile: "large_file",
  historicalPrice: "his_error",
  inactive: "inactive_product",
  invalid: "invalid_product",
  notAProduct: "not_a_product",
  zipFile: "zip_file",
  ewka: "known_attachment",
  startValidation: "start_val",
  completedValidation: "cmp_val",
  processToUpload: "pro_upload",
  pending: "pen",
  review: "review",
  fdPublished: "fd_pub",
};

export default statusFilters;
