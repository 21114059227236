import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import LockIcon from "@material-ui/icons/Lock";

import Layout from "../../components/Layout";
import FormInput from "../../components/FormInput/Index";
import appServices from "../../api/appServices";
import Spinner from "../../components/Spinner";
import styles from "./index.module.scss";

function ChangeSuccessSection() {
  return (
    <div className={styles.successSection}>
      <div className={styles.successImage}>
        <img src="/assets/tick.svg" alt="tick" />
      </div>
      <p className={styles.formDescription}>
        Your password has been created successfully.
      </p>
      <div className={styles.resetLink}>
        <Link to="/login">Back to Login Page →</Link>
      </div>
    </div>
  );
}

function ChangeSection({
  changing,
  email,
  register,
  getValues,
  errors,
  isUrlValid,
}) {
  return (
    <>
      <h2 className={styles.formTitle}>Set your password</h2>
      <p>Enter new password for your account</p>
      <p className={styles.email}>{email}</p>
      <FormInput
        disabled={changing}
        icon={LockIcon}
        label="New Password"
        placeholder="e.g.: X Æ A-12"
        name="newPassword"
        required
        register={register}
        type="password"
      />
      <FormInput
        disabled={changing}
        icon={LockIcon}
        label="Reenter Password"
        placeholder="e.g.: X Æ A-12"
        name="reenterPassword"
        required
        register={register({
          validate: (value) => value === getValues("newPassword"),
        })}
        type="password"
      />
      {errors.reenterPassword && (
        <div className={styles.invalidCredentials}>
          Please make sure your passwords match
        </div>
      )}
      {!isUrlValid && (
        <div className={styles.invalidCredentials}>
          Your password setting link is not valid. Please contact BCG admin.
        </div>
      )}
      <input
        disabled={changing || !isUrlValid}
        className={styles.formSubmitButton}
        type="submit"
        value={changing ? "Setting..." : "Set Password"}
      />

      <div className={styles.resetLink}>
        <Link to="/login">Back to Login Page →</Link>
      </div>
    </>
  );
}

ChangeSection.propTypes = {
  changing: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
  isUrlValid: PropTypes.bool.isRequired,
};

function SetPassword({ match }) {
  const { uuid, token } = match.params;
  const [email, setEmail] = useState("");
  const { register, handleSubmit, getValues, errors } = useForm();
  const [isUrlValid, setUrlValid] = useState(true);
  const [changing, setChanging] = useState(false);
  const [changed, setChanged] = useState(false);
  const [loader, setLoader] = useState(true);

  const resetPassword = (data) => {
    setChanging(false);
    if (isUrlValid && data.newPassword === data.reenterPassword) {
      const payload = {
        password: data.newPassword,
        uidb64: uuid,
        token,
      };
      appServices
        .changeLoginPassword(payload)
        .then(() => {
          setChanging(true);
          setChanged(true);
        })
        .catch(() => {
          setChanging(false);
          setChanged(false);
        });
    }
  };

  useEffect(() => {
    appServices
      .getValidateToken(uuid, token)
      .then((resp) => {
        if (resp.success) {
          setUrlValid(true);
          setEmail(resp.email);
          setLoader(false);
        } else setUrlValid(false);
      })
      .catch(() => {
        setUrlValid(false);
        setLoader(false);
      });
  }, []);

  return loader ? (
    <Spinner />
  ) : (
    <Layout>
      <form className={styles.form} onSubmit={handleSubmit(resetPassword)}>
        {changed ? (
          <ChangeSuccessSection />
        ) : (
          <ChangeSection
            errors={errors}
            register={register}
            changing={changing}
            email={email}
            getValues={getValues}
            isUrlValid={isUrlValid}
          />
        )}
      </form>
    </Layout>
  );
}

export default SetPassword;

SetPassword.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
};
