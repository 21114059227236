import React from "react";
import { cloneDeep, findIndex, startCase } from "lodash";
import PropTypes from "prop-types";
import { Switch } from "antd";
import { Divider } from "@material-ui/core";
import userServices from "../../../api/userServices";
import "./style.scss";
import TableSkeleton from "../../../components/Skeletons/TableSkeleton";
import showAlert from "../../../utils/showAlert";
import helper from "../../../utils/helper";
import appServices from "../../../api/appServices";

function UserManagementTable({
  loader,
  setLoader,
  tableData,
  setTableData,
  popUpData,
  setPopUpData,
}) {
  const { getRoleLabel } = helper;

  const editHandler = (item) => {
    const popUpDataCopy = cloneDeep(popUpData);
    popUpDataCopy.isShowPopUp = true;
    popUpDataCopy.isEditMode = true;
    popUpDataCopy.data = item;
    setPopUpData(popUpDataCopy);
  };

  const resetPassword = (item, newUser = false) => {
    // new user set to false - default case - while resetting pwd
    // newUser true if pending verification
    setLoader(true);
    const payload = {
      email: item.email,
      redirect_url: window.location.origin.concat(
        newUser ? "/set-password" : "/reset-password"
      ),
      new_user: newUser,
    };
    appServices
      .sendResetPasswordMail(payload)
      .then(() => {
        setLoader(false);
        showAlert("Password reset link is send!", "success");
      })
      .catch(() => {
        setLoader(false);
        showAlert(
          "User account is deactivated.Please activate first!",
          "warning"
        );
      });
  };

  const statusHandler = (item) => {
    const payload = {
      id: item.uuid,
    };

    if (item.active === true) {
      // deactivate user
      setLoader(true);
      userServices.deactivateUser(payload).then(() => {
        showAlert("User deactivated!", "success");
        const tableDataCopy = cloneDeep(tableData);
        const index = findIndex(
          tableDataCopy,
          (user) => user.uuid === item.uuid
        );
        tableDataCopy[index].active = false;
        setTableData(tableDataCopy);
        setLoader(false);
      });
    } else {
      // Activate  user
      setLoader(true);
      userServices.activateUser(payload).then(() => {
        showAlert("User activated!", "success");
        const tableDataCopy = cloneDeep(tableData);
        const index = findIndex(
          tableDataCopy,
          (user) => user.uuid === item.uuid
        );
        tableDataCopy[index].active = true;
        setTableData(tableDataCopy);
        setLoader(false);
      });
    }
  };

  const tableSkeletonSection = () => (
    <>
      {new Array(7).fill("").map((el, index) => (
        <TableSkeleton index={index} />
      ))}
    </>
  );

  const tableAccessColumn = (item) => {
    if (item.last_login === 0 && item.active === false)
      return <p className="yellow-col">Pending Verification</p>;
    return <p className="blue-col">{getRoleLabel(item.type_of_user)}</p>;
  };

  const tableActionColumn = (item) => {
    if (item.last_login === 0 && item.active === false)
      return (
        <div className="table-action___single">
          <button type="button" onClick={() => resetPassword(item, "true")}>
            <img src="/assets/reload.svg" alt="reload" />
            Resend Email
          </button>
        </div>
      );
    // means deactivated and new user
    return (
      <>
        <div className="table-action___single">
          <button type="button" onClick={() => editHandler(item)}>
            <img src="/assets/edit.svg" alt="edit" />
            Edit
          </button>
        </div>
        <Divider className="vertical-divider" orientation="vertical" />
        <div className="table-action___single ">
          <button type="button" onClick={() => resetPassword(item)}>
            <img src="/assets/reset-pwd.svg" alt="reset-password" />
            Reset
          </button>
        </div>
        <div className="table-action___single" style={{ display: "none" }}>
          <button type="button">
            <img src="/assets/download.svg" alt="download" />
            Logs
          </button>
        </div>
      </>
    );
  };

  const tableDataSection = () => (
    <>
      {tableData.map((item) => (
        <div key={item} className="table-row">
          <div className="table-col profile-col">
            <div className="profile-pic">
              <img
                src={`${
                  item.profile_pic ? item.profile_pic : "/assets/avatar.png"
                }`}
                alt="avatar"
              />
            </div>
            <div className="text-container">
              <p className="name-text">{startCase(item.full_name)}</p>
              <p className="email-text">{item.email}</p>
            </div>
          </div>
          <div className="table-col company-col">
            <p>{item.company_name}</p>
          </div>
          <div className="table-col access-col">{tableAccessColumn(item)}</div>
          <div className="table-col action-col">{tableActionColumn(item)}</div>
          <div className="table-col action-col">
            <Switch
              // data-testid="status-switch"
              checked={item.active}
              onChange={() => statusHandler(item)}
            />
          </div>
        </div>
      ))}
      {/* if there are no records  */}
      {tableData.length === 0 && (
        <div className="table-row table-row-no-data">No Record Found!</div>
      )}
    </>
  );

  return (
    <div className="table">
      <div className="table-header">
        <p>Login</p>
        <p>Organization</p>
        <p>Access</p>
        <p>Actions</p>
        <p>Account Status</p>
      </div>
      <div className="table-body">
        {loader ? tableSkeletonSection() : tableDataSection()}
      </div>
    </div>
  );
}

export default UserManagementTable;

UserManagementTable.defaultProps = {
  tableData: [],
};

UserManagementTable.propTypes = {
  loader: PropTypes.bool.isRequired,
  setLoader: PropTypes.func.isRequired,
  tableData: PropTypes.instanceOf(Array),
  setTableData: PropTypes.func.isRequired,
  popUpData: PropTypes.instanceOf(Object).isRequired,
  setPopUpData: PropTypes.func.isRequired,
};
