const REPAIR_QUEUE_CODES = [
  "E1003",
  "E1004",
  "E1005",
  "E1006",
  "E1007",
  "E2001",
  "E2002",
  "E3001",
  "E3002",
  "E3003",
  "E4001",
  "E4002",
  "E4003",
  "E4004",
  "E4005",
  "E5001",
  "W3005",
  "W3006",
  "W3007",
  "W6005",
  "500",
  "502",
  "504",
  "422",
];

const SYSTEM_GENERATED_CODES = [
  "SE5000",
  "SE5001",
  "SE5002",
  "SE5003",
  "SE5004",
  "SE5005",
  "SE5006",
  "SE5007",
  "SE5008",
  "SE5009",
  "SE5010",
  "SE5011",
  "SE5012",
  "SE5013",
  "SE5014",
  "SE5015",
  "SE5016",
  "SE5017",
  // "SE5018",
  // "SE5019",
  "SE5020",
  "SE5021",
  "SE5022",
  "SE5023",
  "SE5024",
  "SE5025",
  "SE5026",
  "SE5027",
  "SE5028",
  "SE5029",
  "SE5030",
  "SE5031",
];

const REPUBLISH_CODES = [
  "W2003",
  "W2004",
  "W2005",
  "W3004",
  "W6002",
  "W6001",
  "W6003",
  "W6004",
];

const BCG_ERRORS = [
  "E1003",
  "E1004",
  "E1005",
  "E1006",
  "E1007",
  "E2001",
  "E2002",
  "E3001",
  "E3002",
  "E3003",
  "E4001",
  "E4002",
  "E4003",
  "E4004",
  "E4005",
  "E5001",
];
const BCG_WARNINGS = [
  "W3005",
  "W3006",
  "W3007",
  "W6001",
  "W6003",
  "W6004",
  "W2003",
  "W2004",
  "W2005",
  "W3004",
  "W6002",
];

const CODE_LIST_FOR_FILTER = [
  { value: "E1003", title: "Not included in your 'Publishing list' " },
  { value: "E1004", title: "Primary code missing for some product(s)" },
  { value: "E1005", title: "No products found to publish" },
  { value: "E1006", title: "Products invalid or absent in CSV file" },
  {
    value: "E1007",
    title: "Product code cannot be found in the approved products",
  },
  {
    value: "E2001",
    title: "Field 'entry_a' has invalid data, should be positive",
  },
  { value: "E2002", title: "Missed required columns" },
  { value: "E3001", title: "No dates " },
  { value: "E3002", title: " Invalid dates format" },
  { value: "E3003", title: "Has invalid date sequence" },
  { value: "E4001", title: "Filename can’t be blank" },
  { value: "E4002", title: "Is not a file" },
  { value: "E4003", title: "File format not allowed to upload - csv" },
  {
    value: "E4004",
    title: "File format not allowed to upload - csv, xlsx, xls",
  },
  {
    value: "E4005",
    title: "Invalid file format, must contain the column headers",
  },
  { value: "E5001", title: "Attachment is potentially dangerous" },
  { value: "W3005", title: "Warning! Product already has published price " },
  { value: "W3006", title: "Dates can't be more than 5 days in the future" },
  {
    value: "W3007",
    title: "Warning! Dates can’t be more than 5 days in the future",
  },
  {
    value: "W6001",
    title: "Warning! Publishing historical prices for the product",
  },
  { value: "W6003", title: "Warning! Spread has been exceeded" },
  {
    value: "W6004",
    title: "Warning! Price for  product  same as last one published",
  },
  { value: "SE5000", title: "File have more than 1000 records" },
  { value: "SE5002", title: "Couldn't locate header row" },
  { value: "SE5003", title: "Not an IMD file" },
  { value: "SE5005", title: "Corrupted file" },
  { value: "SE5006", title: "Mime type not Acceptable " },
  { value: "SE5010", title: "Not a unit price file" },
  { value: "SE5011", title: "Primary code of the product is not found" },
  { value: "SE5012", title: "The product is inactive" },
  { value: "SE5013", title: "The product is not in DB" },
  { value: "SE5014", title: "No products found in the file" },
  { value: "SE5015", title: "Duplicate product name found" },
  { value: "SE5016", title: "Unhandled Zip File" },
  { value: "SE5017", title: "Email contains known attachment" },

  // { value: "SE5018", title: "Incorrect date format product found" },
  // { value: "SE5019", title: "Spread of the product has been exceeded 0.5%" },
  {
    value: "SE5020",
    title: "Mid price of the product is not between entry and exit price",
  },
  {
    value: "SE5021",
    title: "Spread of entry_a and nav_a has been exceeded 100%",
  },
  {
    value: "SE5022",
    title: "Spread of exit_a and nav_a has been exceeded 100%",
  },
  {
    value: "SE5023",
    title: "Spread of exit_a and entry_a has been exceeded 100%",
  },
  {
    value: "SE5024",
    title: "Predicted entry_a is not correct",
  },
  {
    value: "SE5025",
    title: "Predicted nav_a is not correct",
  },
  {
    value: "SE5026",
    title: "Predicted exit_a is not correct",
  },
  { value: "SE5027", title: "This document type is not handled by IA" },
  { value: "SE5028", title: "File contains future date products" },
  { value: "SE5029", title: "File contains date range greater than 35" },
  { value: "SE5030", title: "File contains invalid date field" },
  { value: "SE5031", title: "This document type is not send to BCG by IA" },

  { value: "SE5001", title: "Manager listed in HotRoute" },
  { value: "SE5004", title: "Email doesn't have any attachments" },
  { value: "SE5007", title: "Quarantined email" },
  { value: "SE5008", title: "APIR code is empty" },
  { value: "SE5009", title: "Already published product" },
  { value: "W2003", title: "Warning! Ignored column names" },
  { value: "W2004", title: "Warning! Field differs from database value" },
  { value: "W2005", title: "Warning! Field  will be changed" },
  { value: "W3004", title: "Warning! This product has missing date" },
  { value: "W6002", title: "Warning! Day to day price movement exceeds 2%" },
];

const ERROR_CODE_LIST = [
  { value: "E1003", title: "Not included in your 'Publishing list' " },
  { value: "E1004", title: "Primary code missing for some product(s)" },
  { value: "E1005", title: "No products found to publish" },
  { value: "E1006", title: "Products invalid or absent in CSV file" },
  {
    value: "E1007",
    title: "Product code cannot be found in the approved products",
  },
  {
    value: "E2001",
    title: "Field 'entry_a' has invalid data, should be positive",
  },
  { value: "E2002", title: "Missed required columns" },
  { value: "E3001", title: "No dates " },
  { value: "E3002", title: " Invalid dates format" },
  { value: "E3003", title: "Has invalid date sequence" },
  { value: "E4001", title: "Filename can’t be blank" },
  { value: "E4002", title: "Is not a file" },
  { value: "E4003", title: "File format not allowed to upload - csv" },
  {
    value: "E4004",
    title: "File format not allowed to upload - csv, xlsx, xls",
  },
  {
    value: "E4005",
    title: "Invalid file format, must contain the column headers",
  },
  { value: "E5001", title: "Attachment is potentially dangerous" },
  { value: "W3005", title: "Warning! Product already has published price " },
  { value: "W3006", title: "Dates can't be more than 5 days in the future" },
  {
    value: "W3007",
    title: "Warning! Dates can’t be more than 5 days in the future",
  },
  // {
  //   value: "W6001",
  //   title: "Warning! Publishing historical prices for the product",
  // },
  // { value: "W6003", title: "Warning! Spread has been exceeded" },
  // {
  //   value: "W6004",
  //   title: "Warning! Price for  product  same as last one published",
  // },
  // { value: "SE5000", title: "File have more than 1000 records" },
  { value: "SE5002", title: "Couldn't locate header row" },
  { value: "SE5003", title: "Not an IMD file" },
  { value: "SE5004", title: "Email doesn't have any attachments" },
  { value: "SE5005", title: "Corrupted file" },
  { value: "SE5006", title: "Mime type not Acceptable " },
  { value: "SE5007", title: "Quarantined email" },
  // { value: "SE5008", title: "APIR code is empty" },
  { value: "SE5009", title: "Already published product" },
  { value: "SE5010", title: "Not a unit price file" },
  // { value: "SE5011", title: "Primary code of the product is not found" },
  { value: "SE5012", title: "The product is inactive" },
  { value: "SE5013", title: "The product is not in DB" },
  { value: "SE5014", title: "No products found in the file" },
  { value: "SE5015", title: "Duplicate product name found" },
  { value: "SE5016", title: "Unhandled Zip File" },
  { value: "SE5017", title: "Email contains known attachment" },
  // { value: "SE5018", title: "Incorrect date format product found" },
  // { value: "SE5019", title: "Spread of the product has been exceeded 0.5%" },

  // no need
  // { value: "SE5001", title: "Manager listed in HotRoute" },

  // { value: "SE5007", title: "Quarantined email" },
  // { value: "W2003", title: "Warning! Ignored column names" },
  // { value: "W2004", title: "Warning! Field differs from database value" },
  // { value: "W2005", title: "Warning! Field  will be changed" },
  // { value: "W3004", title: "Warning! This product has missing date" },
  // { value: "W6002", title: "Warning! Day to day price movement exceeds 2%" },

  {
    value: "SE5020",
    title: "Mid price of the product is not between entry and exit price",
  },
  {
    value: "SE5021",
    title: "Spread of entry_a and nav_a has been exceeded 100%",
  },
  {
    value: "SE5022",
    title: "Spread of exit_a and nav_a has been exceeded 100%",
  },
  {
    value: "SE5023",
    title: "Spread of exit_a and entry_a has been exceeded 100%",
  },
  {
    value: "SE5024",
    title: "Predicted entry_a is not correct",
  },
  {
    value: "SE5025",
    title: "Predicted nav_a is not correct",
  },
  {
    value: "SE5026",
    title: "Predicted exit_a is not correct",
  },
  { value: "SE5027", title: "This document type is not handled by IA" },
  { value: "SE5028", title: "File contains future date products" },
  { value: "SE5029", title: "File contains date range greater than 35" },
  { value: "SE5030", title: "File contains invalid date field" },
  { value: "SE5031", title: "This document type is not send to BCG by IA" },
  {
    value: "500",
    title: "Internal Server Error",
  },
  {
    value: "502",
    title: "Bad Gateway",
  },
  {
    value: "504",
    title: "Gateway Timeout",
  },
  {
    value: "422",
    title: "Unprocessable Entity",
  },
];

export {
  REPAIR_QUEUE_CODES,
  SYSTEM_GENERATED_CODES,
  REPUBLISH_CODES,
  ERROR_CODE_LIST,
  CODE_LIST_FOR_FILTER,
  BCG_ERRORS,
  BCG_WARNINGS,
};
