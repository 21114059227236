import React, { useEffect, useState } from "react";
import { Switch, DatePicker, TimePicker } from "antd";
import cn from "classnames";
import moment from "moment";
import { cloneDeep, find } from "lodash";
import PropTypes from "prop-types";
import { Checkbox } from "@material-ui/core";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import Input from "../../../components/Input/index";
import MultipleValue from "../../../components/MultipleValue";
import productServices from "../../../api/productServices";
import showAlert from "../../../utils/showAlert";
import SingleSelect from "../../../components/SingleSelect";
import helper from "../../../utils/helper";

const {
  primaryIdentifierOptions,
  requiredFields,
  domicile,
  currency,
  superannuationClassTypes,
  statusTypes,
  issuerIdentifierTypes,
  entityTypes,
  formTypes,
  distributionFrequencyTypes,
  pricingFrequencyTypes,
  getProductId,
} = helper;

function ProductManagementPopup({
  setLoader,
  popUpData,
  closePopup,
  clientList,
  getProductListing,
}) {
  const [saving, setSaving] = useState(false);
  const [formDetails, setFormDetails] = useState({
    status: true,
    productName: "",
    iressCode: "",
    apirCode: "",
    userDefinedFieldOne: "",
    userDefinedFieldTwo: "",
    fundDataProductName: "",
    primaryIdentifier: "",
    arsn: "",
    // has relation with primary  identifier
    relatedField: "",
    // new fields
    shortName: "",
    abbrName: "",
    fundManager: "",
    domicile: "",
    custodian: "",
    transferAgent: "",
    arbnNumber: "",
    exchange: "",
    trustyEntity: "",
    australianNumber: "",
    rseNumber: "",
    superannuationClass: "",
    form: "",
    shareClass: "",
    productStatus: "",
    issuerName: "",
    icr: "",
    openDate: "",
    currency: "",
    assetClass: [],
    assetClassSub: [],
    assetClassFixedIncome: [],
    hedgeStrategy: "",
    benchmark: "",
    pricingFrequency: "",
    publicationTime: "",
    distributionFreq: "",
    mer: "",
    issuerEntityId: "",
    issuerEntityIdType: "",
    issuerEntityType: "",
    productType: "",
    productSubType: [],
    private: "",
    selfPublished: false,
  });

  const [additionalIdentifier, setAdditionalIdentifier] = useState([]);

  // has relation with primary  identifier
  const [relatedFieldDetails, setRelatedFieldDetails] = useState({
    label: "",
    apiKeyName: "",
    show: false,
  });

  const [owner, setOwner] = useState([]);
  const [publisher, setPublisher] = useState([]);
  const [subscriber, setSubscriber] = useState([]);
  const [privateType, setPrivateType] = useState(false);

  const [activeTab, setActiveTab] = useState("update");

  const showError = (error) => {
    if (error?.product_name) showAlert(error.product_name[0], "error");
    else if (error?.iress_code?.iress_code)
      showAlert(error.iress_code?.iress_code, "error");
    else if (error?.APIR_code?.APIR_code)
      showAlert(error?.APIR_code?.APIR_code, "error");
    else if (error?.user_defined_field_one?.user_defined_field_one)
      showAlert(error?.user_defined_field_one?.user_defined_field_one, "error");
    else if (error?.CUSIP_code?.CUSIP_code)
      showAlert(error?.CUSIP_code?.CUSIP_code, "error");
    else if (error?.exchange_ticker_code?.exchange_ticker_code)
      showAlert(error?.exchange_ticker_code?.exchange_ticker_code, "error");
    else if (error?.bloomberg_or_FIGI_code?.bloomberg_or_FIGI_code)
      showAlert(error?.bloomberg_or_FIGI_code?.bloomberg_or_FIGI_code, "error");
    else if (error?.ISIN_code?.ISIN_code)
      showAlert(error?.ISIN_code?.ISIN_code, "error");
    else if (error?.morningstar_code?.morningstar_code)
      showAlert(error?.morningstar_code?.morningstar_code, "error");
    else if (error?.reuters_code?.reuters_code)
      showAlert(error?.reuters_code?.reuters_code, "error");
    else if (error?.SEDOL_code?.SEDOL_code)
      showAlert(error?.SEDOL_code?.SEDOL_code, "error");
    else if (error?.arsn?.arsn) showAlert(error?.arsn?.arsn, "error");
    else showAlert("Something went wrong!", "error");
  };

  const submitData = () => {
    setSaving(true);
    const ownerId = owner.map((el) => el.id);
    const publisherId = publisher.map((el) => el.id);
    const subscriberId = subscriber.map((el) => el.id);

    const payload = {
      status: formDetails.status,
      product_name: formDetails.productName,
      iress_code: formDetails.iressCode,
      APIR_code: formDetails.apirCode,
      owner: ownerId,
      publisher: publisherId,
      subscriber: subscriberId,
      user_defined_field_one: formDetails.userDefinedFieldOne,
      user_defined_field_two: formDetails.userDefinedFieldTwo,
      fund_data_product_name: formDetails.fundDataProductName,
      primary_identifier: formDetails.primaryIdentifier,
      // new fields
      self_published: formDetails.selfPublished,
      private: privateType,
    };
    if (relatedFieldDetails.show)
      payload[relatedFieldDetails.apiKeyName] = formDetails.relatedField;

    if (additionalIdentifier.length > 0) {
      additionalIdentifier.forEach((identifier) => {
        if (identifier.show) {
          payload[identifier.apiKeyName] = identifier.value ?? "";
        }
      });
    }
    if (popUpData.isEditMode) {
      // editing product code
      productServices
        .editProductDetails(popUpData.data.id, payload)
        .then(() => {
          setLoader(true);
          closePopup();
          showAlert("Product updated successfully!", "success");
          getProductListing();
        })
        .catch((error) => {
          setSaving(false);
          showError(error?.response?.data);
        });
    } else {
      // adding new product code
      productServices
        .addNewProduct(payload)
        .then(() => {
          setLoader(true);
          closePopup();
          showAlert("Product added successfully!", "success");
          getProductListing();
        })
        .catch((error) => {
          setSaving(false);
          showError(error?.response?.data);
        });
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (formDetails.productName !== "" && formDetails.productName.trim() === "")
      showAlert("Please enter product code!", "warning");
    else if (
      formDetails.primaryIdentifier === "iress" &&
      formDetails.iressCode.trim() === ""
    )
      showAlert("Please enter IRESS code!", "warning");
    else submitData();
  };

  const onChangeHandler = (key) => (value) => {
    const formDetailsCopy = cloneDeep(formDetails);
    formDetailsCopy[key] = value;
    if (key === "primaryIdentifier") {
      formDetailsCopy.relatedField = "";
      if (!requiredFields.includes(value)) {
        const { label, apiKeyName } = find(primaryIdentifierOptions, [
          "value",
          value,
        ]);
        setRelatedFieldDetails({
          label,
          apiKeyName,
          show: true,
        });
      } else
        setRelatedFieldDetails({
          label: "",
          apiKeyName: "",
          show: false,
        });
    }
    setFormDetails(formDetailsCopy);
  };

  const onChangeAdditionalIdentifierHandler = (key, index) => (inputValue) => {
    if (key === "type") {
      if (!requiredFields.includes(inputValue)) {
        const { label, apiKeyName, value } = find(primaryIdentifierOptions, [
          "value",
          inputValue,
        ]);
        const newAdditionalIdentifiers = [...additionalIdentifier];
        newAdditionalIdentifiers[index] = {
          label,
          apiKeyName,
          show: true,
          key: value,
        };
        setAdditionalIdentifier(newAdditionalIdentifiers);
      } else {
        const newAdditionalIdentifiers = [...additionalIdentifier];
        newAdditionalIdentifiers[index] = {
          label: "",
          apiKeyName: "",
          show: false,
          key: "",
        };
        setAdditionalIdentifier(newAdditionalIdentifiers);
      }
    }
    if (key === "value") {
      const newAdditionalIdentifiers = [...additionalIdentifier];
      newAdditionalIdentifiers[index] = {
        ...newAdditionalIdentifiers[index],
        value: inputValue,
      };
      setAdditionalIdentifier(newAdditionalIdentifiers);
    }
  };

  const removeAdditionalIdentifierHandler = (index) => {
    const newList = [...additionalIdentifier];
    newList[index] = { ...newList[index], cancelled: true, value: "" };

    setAdditionalIdentifier(newList);
  };

  useEffect(() => {
    if (popUpData.isEditMode) {
      const {
        status,
        product_name: productName,
        iress_code: iressCode,
        APIR_code: APIRCode,
        owner: ownerId,
        owners,
        publisher: publisherId,
        publishers,
        subscriber: subscriberId,
        subscribers,
        user_defined_field_one: userDefinedFieldOne,
        user_defined_field_two: userDefinedFieldTwo,
        fund_data_product_name: fundDataProductName,
        primary_identifier: primaryIdentifier,
        // new fields
        product_details: productDetails,
        self_published: selfPublished,
        private_status: privateStatus,
      } = popUpData.data;
      let optionalField;

      const ownerCopy = owners.map((el, index) => ({
        email: el,
        id: ownerId[index],
      }));
      const publisherCopy = publishers.map((el, index) => ({
        email: el,
        id: publisherId[index],
      }));
      const subscriberCopy = subscribers.map((el, index) => ({
        email: el,
        id: subscriberId[index],
      }));

      if (!requiredFields.includes(primaryIdentifier)) {
        const { label, apiKeyName } = find(primaryIdentifierOptions, [
          "value",
          primaryIdentifier,
        ]);
        optionalField = popUpData.data[apiKeyName] || "";
        setRelatedFieldDetails({
          label,
          apiKeyName,
          show: true,
        });
      }
      const additionalList = [];
      primaryIdentifierOptions
        .filter((x) => x.value !== primaryIdentifier)
        .forEach((m) => {
          if (popUpData.data[m.apiKeyName]) {
            if (!requiredFields.includes(m.value)) {
              const {
                label,
                apiKeyName,
                value,
              } = find(primaryIdentifierOptions, ["value", m.value]);
              const newValue = popUpData.data[apiKeyName] || "";
              additionalList.push({
                label,
                apiKeyName,
                show: true,
                key: value,
                value: newValue,
              });
            }
          }
        });
      setAdditionalIdentifier(additionalList);
      setFormDetails({
        status,
        productName,
        iressCode,
        apirCode: APIRCode,
        userDefinedFieldOne,
        userDefinedFieldTwo,
        fundDataProductName,
        primaryIdentifier,
        relatedField: optionalField,

        // new fields
        private: productDetails[0]?.private ?? "",
        selfPublished,
      });

      setOwner(ownerCopy);
      setPublisher(publisherCopy);
      setSubscriber(subscriberCopy);
      setPrivateType(privateStatus);
    }
  }, []);

  const filterAdditionalIdentifierList = () => {
    const filter = ["apir", "iress"];
    const additonal = additionalIdentifier.map((m) => !m.cancelled && m.key);
    if (!additionalIdentifier.includes(formDetails.primaryIdentifier)) {
      filter.push(formDetails.primaryIdentifier);
    }
    filter.push(...additonal);

    return primaryIdentifierOptions.map((x) => {
      if (filter.includes(x.value)) return { ...x, disabled: true };
      return x;
    });
  };
  const tabHandler = (value) => {
    setActiveTab(value);
  };

  return (
    <div className="popup product-management-popup">
      <div className="popup-overlay" />
      <div className="popup-wrapper">
        {/* <div className="popup-tabs">
          <div
            role="button"
            tabIndex={0}
            onClick={() => tabHandler("update")}
            onKeyPress={() => tabHandler("update")}
            className={cn({ tab: true, active: activeTab === "update" })}
          >
            {popUpData.isEditMode ? "Edit Product" : "New Product"}
          </div>
        </div> */}
        {activeTab === "update" && (
          <>
            <div className="popup-header">
              <h1>
                {popUpData.isEditMode ? "View/Edit Product" : "New Product"}
              </h1>
              <div className="sub-heading">
                <p>
                  {popUpData.isEditMode
                    ? "Use this page to update your Product information."
                    : "Use this page to add a new Product"}
                </p>
                <p>
                  Activate Product
                  <Switch
                    className="switch-product"
                    size="small"
                    onChange={onChangeHandler("status")}
                    checked={formDetails.status}
                  />
                </p>
              </div>
            </div>
            <form onSubmit={submitHandler}>
              <div className="popup-body product-management-form">
                <div>
                  <div className="product-type">
                    <h2>Product Type</h2>
                    <div className="product-checkbox">
                      <Checkbox
                        id="checkSelf"
                        name="unread"
                        color="primary"
                        size="small"
                        onChange={(e) =>
                          onChangeHandler("selfPublished")(e.target.checked)
                        }
                        checked={formDetails.selfPublished}
                        title="Self Publisher"
                      />
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label htmlFor="checkSelf">Self Publisher</label>
                      <Checkbox
                        id="checkPrivate"
                        name="unread"
                        color="primary"
                        size="small"
                        onChange={(e) => setPrivateType(e.target.checked)}
                        checked={privateType}
                      />
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label htmlFor="checkPrivate">Private</label>
                    </div>
                  </div>
                  <h2>BCG: Reference Data</h2>
                  <div>
                    <Input
                      required={formDetails?.fundDataProductName === ""}
                      placeholder="Product Name"
                      onChange={onChangeHandler("productName")}
                      value={formDetails.productName}
                      maxLength={90}
                      minLength={3}
                    />
                  </div>
                </div>

                <div className="popup-row">
                  <Input
                    required={formDetails?.primaryIdentifier === "iress"}
                    placeholder="Iress Code"
                    onChange={onChangeHandler("iressCode")}
                    value={formDetails.iressCode}
                    maxLength={90}
                  />
                  <Input
                    required={formDetails.primaryIdentifier === "apir"}
                    placeholder="Apir Code"
                    onChange={onChangeHandler("apirCode")}
                    value={formDetails.apirCode}
                    minLength={3}
                    maxLength={90}
                  />
                  <div className="product-select-box">
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label htmlFor="primary-identifier">
                      Primary Identifier
                    </label>
                    <SingleSelect
                      required
                      id="primary-identifier"
                      optionList={primaryIdentifierOptions}
                      onChange={(e) => {
                        onChangeHandler("primaryIdentifier")(e.target.value);
                      }}
                      value={formDetails.primaryIdentifier}
                      keyName="value"
                    />
                  </div>
                </div>
                <div className="popup-row">
                  {relatedFieldDetails.show &&
                    !additionalIdentifier
                      .map((x) => !x.cancelled && x.key)
                      .includes(formDetails.primaryIdentifier) && (
                      <Input
                        required
                        placeholder={relatedFieldDetails.label}
                        onChange={onChangeHandler("relatedField")}
                        value={formDetails.relatedField}
                        maxLength={90}
                      />
                    )}
                </div>

                {additionalIdentifier.length > 0 &&
                  additionalIdentifier.map(
                    (identifier, index) =>
                      !identifier.cancelled && (
                        <div key={identifier} className="popup-row">
                          <div className="product-select-box">
                            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                            <label htmlFor="primary-identifier">
                              #{index + 1} ADDITIONAL IDENTIFIER TYPE
                            </label>
                            <SingleSelect
                              id="extra-identifier"
                              required
                              optionList={filterAdditionalIdentifierList()}
                              onChange={(e) => {
                                onChangeAdditionalIdentifierHandler(
                                  "type",
                                  index
                                )(e.target.value);
                              }}
                              value={identifier.key}
                            />
                          </div>
                          <div className="close-input-container">
                            {identifier.show && (
                              <Input
                                required="true"
                                placeholder={identifier.label}
                                onChange={(e) => {
                                  onChangeAdditionalIdentifierHandler(
                                    "value",
                                    index
                                  )(e);
                                }}
                                value={identifier.value}
                              />
                            )}
                            <CancelRoundedIcon
                              className="close-btn"
                              onClick={() =>
                                removeAdditionalIdentifierHandler(index)
                              }
                            />
                          </div>
                        </div>
                      )
                  )}

                {filterAdditionalIdentifierList().filter((x) => !x.disabled)
                  .length > 0 && (
                  <div className="popup-row">
                    <button
                      disabled={
                        filterAdditionalIdentifierList().filter(
                          (x) => !x.disabled
                        ).length === 0
                      }
                      type="button"
                      className="add-more-btn"
                      onClick={() =>
                        setAdditionalIdentifier((identifiers) => [
                          ...identifiers,
                          {
                            show: false,
                          },
                        ])
                      }
                    >
                      <img
                        src="/assets/add-hot-queue.svg"
                        alt="add-identifier"
                      />{" "}
                      Add More Identifier
                    </button>
                  </div>
                )}

                <div>
                  <h2>Sender : Reference Data</h2>
                  <div>
                    <Input
                      required={formDetails.productName === ""}
                      placeholder="Sender Product Name"
                      onChange={onChangeHandler("fundDataProductName")}
                      value={formDetails.fundDataProductName}
                      maxLength={160}
                    />
                  </div>
                </div>
                <div className="select-group-row">
                  <Input
                    required={
                      formDetails.primaryIdentifier === "user_defined_1"
                    }
                    placeholder="Sender code #1"
                    onChange={onChangeHandler("userDefinedFieldOne")}
                    value={formDetails.userDefinedFieldOne}
                    maxLength={90}
                  />
                  <Input
                    placeholder="Sender code #2"
                    onChange={onChangeHandler("userDefinedFieldTwo")}
                    value={formDetails.userDefinedFieldTwo}
                    maxLength={90}
                  />
                </div>

                <div className="select-group-row">
                  <div className="product-select-box">
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label htmlFor="owners">Owners</label>
                    <MultipleValue
                      setValue={setOwner}
                      optionList={clientList}
                      value={owner}
                      keyName="email"
                      width="100%"
                      limitTags={2}
                      placeHolder=""
                    />
                  </div>
                  <div className="product-select-box">
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label>Publishers</label>
                    <MultipleValue
                      setValue={setPublisher}
                      optionList={clientList}
                      value={publisher}
                      keyName="email"
                      width="100%"
                      limitTags={2}
                      placeHolder=""
                    />
                  </div>
                </div>

                <div className="select-group-row">
                  <div className="product-select-box">
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label>Subscribers</label>
                    <MultipleValue
                      setValue={setSubscriber}
                      optionList={clientList}
                      value={subscriber}
                      keyName="email"
                      width="100%"
                      limitTags={2}
                      placeHolder=""
                    />
                  </div>
                </div>
              </div>
              <div className="popup-footer">
                <button
                  type="button"
                  className="cancel-btn"
                  onClick={closePopup}
                >
                  Cancel
                </button>
                <button type="submit" disabled={saving} className="save-btn">
                  {saving && "Saving..."}
                  {!saving && popUpData.isEditMode && "Save Changes"}
                  {!saving && !popUpData.isEditMode && "Add New Product"}
                </button>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );
}

export default ProductManagementPopup;

ProductManagementPopup.propTypes = {
  setLoader: PropTypes.func.isRequired,
  popUpData: PropTypes.instanceOf(Object).isRequired,
  closePopup: PropTypes.func.isRequired,
  clientList: PropTypes.instanceOf(Array).isRequired,
  getProductListing: PropTypes.func.isRequired,
};
