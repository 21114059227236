import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";
import cn from "classnames";
import Input from "../../../components/Input/index";
import showAlert from "../../../utils/showAlert";
import SingleSelect from "../../../components/SingleSelect";
import "./style.scss";
import hotQueueServices from "../../../api/hotQueueServices";

function HotQueuePopUp({
  setLoader,
  popUpData,
  closePopup,
  getHotQueueListing,
}) {
  const [saving, setSaving] = useState(false);
  const [formValues, setFormValues] = useState([]);
  const [ruleName, setRuleName] = useState([]);
  const [sheetPassword, setSheetPassword] = useState("");
  const [ruleNotes, setRuleNotes] = useState("");
  const [ruleInstructions, setRuleInstructions] = useState("");
  const [activeTab, setActiveTab] = useState("rules");

  const ruleFilter = (values) => {
    const rule = [];
    values.forEach((el) => {
      // if (el.condition === "matches" && el.condition_data.length === 0)
      //   showAlert("Missing field required!", "warning");
      // if (el.condition === "contains" || el.condition === "does_not_contain")
      if (
        el.condition_data === "" ||
        (el.condition_data !== "" && el.condition_data.trim() === "")
      )
        showAlert("Missing field required!", "warning");
      rule.push({
        condition: el.condition,
        condition_param: el.condition_param,
        condition_data: el.condition_data,
        rule_note: el.rule_note,
      });
    });
    return rule;
  };

  const submitData = async () => {
    if (formValues.length < 1) showAlert("Add Rules", "warning");
    else {
      setSaving(true);
      const rule = ruleFilter(formValues);

      const payload = {
        rule_name: ruleName,
        active: popUpData.isEditMode ? popUpData.data.active : true,
        rule_details: rule,
        instruction: ruleInstructions ?? "",
        hotqueue_notes: ruleNotes ?? "",
        sheet_password: sheetPassword,
      };
      try {
        // eslint-disable-next-line no-unused-expressions
        popUpData.isEditMode
          ? await hotQueueServices.editHotQueue(popUpData.data.id, payload)
          : await hotQueueServices.addNewHotQueue(payload);
        setLoader(true);
        closePopup();
        getHotQueueListing();
        showAlert(
          `Hot Queue ${
            popUpData.isEditMode ? "updated" : "added"
          } successfully!`,
          "success"
        );
      } catch (err) {
        setSaving(false);
      }
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (ruleName !== "" && ruleName.trim() === "")
      showAlert("Please enter rule name!", "warning");
    else submitData();
  };

  const onChangeHandler = (name) => (value) => {
    if (name === "ruleName") {
      setRuleName(value);
    } else if (name === "ruleNotes") {
      setRuleNotes(value.target.value);
    } else if (name === "ruleInstructions") {
      setRuleInstructions(value.target.value);
    } else if (name === "sheetPassword") {
      setSheetPassword(value);
    }
  };

  useEffect(() => {
    // isFromSender is because same popup is reused in sender page
    if (popUpData.isEditMode || popUpData.isFromSender) {
      // eslint-disable-next-line camelcase
      const {
        rule_name: name,
        rule_details: details,
        instruction,
        hotqueue_notes: note,
        sheet_password: password,
      } = popUpData.data;
      setRuleName(name);
      setSheetPassword(password);
      setRuleNotes(note ?? "");
      setRuleInstructions(instruction ?? "");
      const ruleDetails = [];
      details.forEach((el, i) => {
        ruleDetails.push({
          condition: el.condition,
          condition_param: el.condition_param,
          condition_data: el.condition_data,
          showView: i === 0,
          rule_note: el.rule_note,
        });
      });
      setFormValues(ruleDetails);
    }
  }, []);

  const addRule = () => {
    setFormValues([
      ...formValues,
      {
        condition_param: "mail_from",
        condition: "matches",
        condition_data: "",
        showView: true,
        rule_note: "",
      },
    ]);
  };

  const removeRule = (i) => {
    const newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  const handleChange = (i, e, name) => {
    const newFormValues = [...formValues];
    if (name === "condition_param") {
      if (e.target.value === "full_body")
        newFormValues[i].condition = "contains";
    }

    if (name === "showView") newFormValues[i][name] = !formValues[i].showView;
    else if (name === "condition_data") newFormValues[i][name] = e.target.value;
    else if (name === "rule_note") newFormValues[i][name] = e.target.value;
    else newFormValues[i][name] = e.target.value;
    setFormValues(newFormValues);
  };

  const listData = [
    {
      label: "Email",
      value: "mail_from",
    },
    {
      label: "Subject",
      value: "subject",
    },
    {
      label: "Body",
      value: "full_body",
    },
  ];

  const matchData = [
    {
      label: "Contains",
      value: "contains",
    },
    {
      label: "Does not Contain",
      value: "does_not_contain",
    },
    {
      label: "Matches",
      value: "matches",
    },
  ];
  const matchData1 = [
    {
      label: "Contains",
      value: "contains",
    },
    {
      label: "Does not Contain",
      value: "does_not_contain",
    },
  ];

  const tabHandler = (value) => {
    setActiveTab(value);
  };

  return (
    <div className="popup">
      <div className="popup-overlay" />
      <div className="popup-wrapper hot-queue-popup">
        <div className="popup-header">
          <h1>
            {popUpData.isEditMode ? "Update Hot Queue" : "Add To Hot Queue"}
          </h1>
          <div className="sub-heading">
            <p>
              {popUpData.isEditMode
                ? "Edit settings from Hot Queue"
                : "Add to Hot Queue if all of the conditions are met"}
            </p>
          </div>
        </div>
        <form onSubmit={submitHandler} className="hotqueue-form">
          <div className="popup-body product-management-form">
            <div className="popup-row1">
              <Input
                required="required"
                placeholder="Hot Queue Description"
                onChange={onChangeHandler("ruleName")}
                value={ruleName}
                maxLength={90}
              />
            </div>
            <div className="popup-row1">
              <Input
                placeholder="Sheet Password"
                onChange={onChangeHandler("sheetPassword")}
                value={sheetPassword}
                maxLength={90}
              />
            </div>
          </div>
          <div className="rowDivider" />

          <div className="popup-tabs">
            <div
              role="button"
              tabIndex={0}
              onClick={() => tabHandler("rules")}
              onKeyDown={() => tabHandler("rules")}
              className={cn({ tab: true, active: activeTab === "rules" })}
            >
              Rules
            </div>
            <div
              role="button"
              tabIndex={0}
              onClick={() => tabHandler("notes")}
              onKeyDown={() => tabHandler("notes")}
              className={cn({ tab: true, active: activeTab === "notes" })}
            >
              Notes
            </div>

            <div
              role="button"
              tabIndex={0}
              onClick={() => tabHandler("instructions")}
              onKeyDown={() => tabHandler("instructions")}
              className={cn({
                tab: true,
                active: activeTab === "instructions",
              })}
            >
              Instructions
            </div>
          </div>
          {activeTab === "rules" && (
            <>
              <div className="rules-container">
                {formValues.map((el, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={el} className="list-container">
                    <div className="add-rule-head">
                      <button
                        type="button"
                        className="drop-btn"
                        onClick={(e) => handleChange(index, e, "showView")}
                      >
                        <img
                          src={
                            !el.showView
                              ? "/assets/hot-queue-dropright.svg"
                              : "/assets/hot-queue-dropdown.svg"
                          }
                          alt="dropdown"
                          className="icon-img"
                        />
                        <div>Rule {index + 1}</div>
                      </button>
                      <button
                        type="button"
                        className="drop-btn"
                        onClick={() => removeRule(index)}
                      >
                        <img
                          src="/assets/delete-hot-queue.svg"
                          alt="delete"
                          className="icon-img"
                        />
                      </button>
                    </div>
                    {el.showView && (
                      <div className="add-hot-queue-list">
                        <div className="inner-list">
                          <div>Add to Hot queue if</div>
                          <div className="add-hot-queue-popup">
                            <div className="hot-queue-select-box1">
                              <SingleSelect
                                id="primary-identifier"
                                required
                                optionList={listData}
                                onChange={(e) =>
                                  handleChange(index, e, "condition_param")
                                }
                                value={el.condition_param}
                              />
                            </div>
                            <div className="hot-queue-select-box2">
                              <SingleSelect
                                id="primary-identifier"
                                required
                                optionList={
                                  el.condition_param === "full_body"
                                    ? matchData1
                                    : matchData
                                }
                                onChange={(e) =>
                                  handleChange(index, e, "condition")
                                }
                                value={el.condition}
                              />
                            </div>

                            <div className="hot-queue-select-box">
                              <div className="popup-row2">
                                <TextField
                                  id="outlined-multiline-flexible"
                                  label="type here"
                                  multiline
                                  maxRows={2}
                                  onChange={(e) =>
                                    handleChange(index, e, "condition_data")
                                  }
                                  value={el.condition_data}
                                  inputProps={{ maxLength: 100 }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="bottom-text">
                            Maximum 100 character
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>

              <div>
                <button
                  type="button"
                  className="cancel-btn add-rule-btn"
                  onClick={() => addRule()}
                >
                  <img
                    src="/assets/add-hot-queue.svg"
                    alt="add"
                    className="icon-img"
                  />
                  Add Rules
                </button>
              </div>
            </>
          )}

          {activeTab === "notes" && (
            <div className="rules-container">
              <textarea
                className="textarea-resize"
                placeholder="Enter note here.."
                rows="12"
                maxLength="2000"
                onChange={onChangeHandler("ruleNotes")}
                value={ruleNotes}
              />
              <div className="bottom-message">
                You have {2000 - ruleNotes.length} of 2000 character(s)
                remaining
              </div>
            </div>
          )}
          {activeTab === "instructions" && (
            <div className="rules-container">
              <textarea
                className="textarea-resize"
                placeholder="Enter instructions here.."
                rows="12"
                maxLength="2000"
                onChange={onChangeHandler("ruleInstructions")}
                value={ruleInstructions}
              />
              <div className="bottom-message">
                You have {2000 - ruleInstructions.length} of 2000 character(s)
                remaining
              </div>
            </div>
          )}

          <div className="popup-footer">
            <button type="button" className="cancel-btn" onClick={closePopup}>
              Cancel
            </button>
            <button
              type="submit"
              disabled={saving}
              className="save-btn-hot-queue"
            >
              {saving ? (
                "Saving..."
              ) : (
                <>
                  <img
                    src="/assets/add-to-queue.svg"
                    alt="add"
                    className="icon-img"
                  />
                  {popUpData.isEditMode ? "Save Changes" : "Add to hot queue"}
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default HotQueuePopUp;

HotQueuePopUp.propTypes = {
  setLoader: PropTypes.func.isRequired,
  popUpData: PropTypes.instanceOf(Object).isRequired,
  closePopup: PropTypes.func.isRequired,
  clientList: PropTypes.instanceOf(Array).isRequired,
  getHotQueueListing: PropTypes.func.isRequired,
};
