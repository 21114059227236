import axios from "../utils/axiosClient";
import endpoints from "./endpoints";

const {
  clientListing,
  createClient,
  updateClient,
  clientActivate,
  clientDeactivate,
  hotRoute,
  senderListing,
  senderCreate,
  senderOrganizationListing,
  senderProductsListing,
} = endpoints;

const getClientListing = async (query) => {
  try {
    const response = await axios.get(`${clientListing}?${query.join("&")}`);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const addNewClient = async (payload) => {
  try {
    const response = await axios.post(createClient, payload);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateClientData = async (payload) => {
  try {
    const response = await axios.put(`${updateClient}${payload.id}/`, payload);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const activateClient = async (payload) => {
  try {
    const response = await axios.post(clientActivate, payload);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const deactivateClient = async (payload) => {
  try {
    const response = await axios.delete(`${clientDeactivate}${payload.id}/`);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const hotQueueStatus = async (payload) => {
  try {
    const response = await axios.post(hotRoute, payload);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getSenderListing = async (query) => {
  try {
    const response = await axios.get(`${senderListing}?${query.join("&")}`);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const addNewSender = async (payload) => {
  try {
    const response = await axios.post(senderCreate, payload);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateSenderData = async (payload) => {
  try {
    const response = await axios.patch(
      `${senderCreate}${payload.id}/`,
      payload
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateSenderStatus = async (data) => {
  try {
    const response = await axios.patch(`${senderCreate}${data.id}/`, data);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getSenderOrganization = async (orgName) => {
  try {
    const response = await axios.get(
      `${senderOrganizationListing}?org_name=${orgName}`
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
const getSenderProducts = async (query) => {
  try {
    const response = await axios.get(
      `${senderProductsListing}?${query.join("&")}`
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export default {
  getClientListing,
  addNewClient,
  updateClientData,
  activateClient,
  deactivateClient,
  hotQueueStatus,
  getSenderListing,
  addNewSender,
  getSenderOrganization,
  updateSenderData,
  updateSenderStatus,
  getSenderProducts,
};
