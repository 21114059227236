import React, { useState, useRef } from "react";
import moment from "moment";
import { cloneDeep, startCase } from "lodash";
import { DatePicker } from "antd";
import PropTypes from "prop-types";

import SearchInput from "../../../../components/SearchInput";
import helper from "../../../../utils/helper";
import "./style.scss";

function OverviewTabPopup({
  tableData,
  showPopup,
  handlePopup,
  endDate,
  startDate,
}) {
  // converting to ISO_8601
  const newStartDate = startDate.split("-").reverse().join("-");
  const parsedStartDate = moment(newStartDate);
  const newEndDate = endDate.split("-").reverse().join("-");
  const parsedEndDate = moment(newEndDate);
  const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];

  const inputRef = useRef();

  const { getProfilePic, getUnitDetails } = helper;
  const [filter, setFilter] = useState({
    currentPage: 1,
    searchKey: "",
  });

  const onChangeHandler = (key) => (value) => {
    const filterCopy = cloneDeep(filter);
    if (key === "searchKey") {
      filterCopy.searchKey = value;
      filterCopy.currentPage = 1;
      setFilter(filterCopy);
    }
  };

  const getRateComponent = (value) =>
    showPopup.type === "user" ? (
      <p>{`${Math.abs(value || 0)}${
        value >= 0 ? "% more resolved" : "% less resolved"
      }`}</p>
    ) : (
      <p>{getUnitDetails(value)}</p>
    );

  const tableDataSection = () => (
    <>
      {tableData
        .filter(
          (data) =>
            data.name.toLowerCase().includes(filter.searchKey.toLowerCase()) ||
            data.email.toLowerCase().includes(filter.searchKey.toLowerCase())
        )
        .map((item) => (
          <div className="table-row" key={item}>
            <div className="table-col profile-col">
              <div className="profile-pic">
                <img src={getProfilePic(item.photo)} alt="avatar" />
              </div>
              <p>{item.name}</p>
            </div>
            <div className="table-col rate-col">
              <div className="rate-pic">
                <img
                  className="sidebar-list-single___pic"
                  src={`${
                    item.percentage >= 0
                      ? "/assets/green-up3.png"
                      : "/assets/red-down2.png"
                  }`}
                  alt="arrow"
                />
              </div>
              {getRateComponent(item.percentage)}
            </div>
            <div className="table-col email-col">
              <p>{item.email}</p>
            </div>
          </div>
        ))}
      {/* if there are no records  */}
      {tableData.length === 0 && (
        <div className="table-row table-row-no-data">No Record Found!</div>
      )}
    </>
  );

  return (
    <div className="popup">
      <div className="popup-overlay" />
      <div className="pf-popup-wrapper">
        <div className="pf-popup-header">
          <h1 className="box-header">{showPopup.type} Performance</h1>
          <p role="presentation" onClick={handlePopup("")}>
            Close
          </p>
        </div>
        <div>
          <DatePicker
            allowClear={false}
            value={parsedStartDate}
            format={dateFormatList}
            disabled
          />
          <DatePicker
            allowClear={false}
            value={parsedEndDate}
            format={dateFormatList}
            disabled
          />
        </div>

        <div className="search-section">
          <SearchInput
            onChange={onChangeHandler("searchKey")}
            value={filter.searchKey}
            placeholder="Search for accounts, emails ..."
            inputRef={inputRef}
          />
        </div>
        <div className="table">
          <div className="table-header">
            <p>{startCase(showPopup.type)}</p>
            <p>
              {showPopup.type === "user" ? "Resolved Rate" : "Published Rate"}
            </p>
            <p>Email</p>
          </div>
          <div className="table-body">{tableDataSection()}</div>
        </div>
      </div>
    </div>
  );
}

export default OverviewTabPopup;

OverviewTabPopup.defaultProps = {
  tableData: [],
};

OverviewTabPopup.propTypes = {
  tableData: PropTypes.instanceOf(Array),
  showPopup: PropTypes.instanceOf(Object).isRequired,
  handlePopup: PropTypes.func.isRequired,
  endDate: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
};
