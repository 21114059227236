import React, { useContext, useEffect, useState } from "react";
import { cloneDeep } from "lodash";
import { Dots } from "react-activity";
import PropTypes from "prop-types";
import { Switch } from "antd";
import RoleContext from "../../../context/roleContext";
import FileInput from "../../../components/FileInput";
import Input from "../../../components/Input/index";
import userServices from "../../../api/userServices";
import SelectBox from "../../../components/SelectBox";
import showAlert from "../../../utils/showAlert";
import helper from "../../../utils/helper";
import "react-activity/dist/Dots.css";

function UserManagementPopup({
  setLoader,
  popUpData,
  gerUserListDetails,
  closePopup,
}) {
  const [formDetails, setFormDetails] = useState({
    email: "",
    firstName: "",
    lastName: "",
    companyName: "",
    password: "",
    confirmPassword: "",
    userId: "",
    userType: "user", // default
    photo: "",
    // s3 bucket url
    image_name: "",
    // simply an image name
  });
  const [passwordError, updatePasswordError] = useState("");
  const { userInfo } = useContext(RoleContext);
  const { userRoles } = userInfo;
  const { roles } = helper;
  const [isLoading, setLoading] = useState(false);

  const userListOptions = [
    { value: "user", label: "User" },

    {
      value: "business_admin",
      label: "Business Admin",
    },
  ];

  if (userRoles.includes(roles.SUPER_ADMIN)) {
    userListOptions.push({
      value: "super_admin",
      label: "Super Admin",
    });
  }

  useEffect(() => {
    if (popUpData.isEditMode) {
      const formDetailsCopy = cloneDeep(formDetails);
      formDetailsCopy.email = popUpData.data.email;
      formDetailsCopy.firstName = popUpData.data.first_name;
      formDetailsCopy.lastName = popUpData.data.last_name;
      formDetailsCopy.companyName = popUpData.data.company_name;
      formDetailsCopy.userType = popUpData.data.type_of_user;
      formDetailsCopy.photo = popUpData.data.profile_pic;
      formDetailsCopy.image_name = popUpData.data.photo;
      formDetailsCopy.email_notification = popUpData.data.email_notification;
      setFormDetails(formDetailsCopy);
    }
  }, []);

  const onChangeHandler = (key) => (value1, value2) => {
    const formDetailsCopy = cloneDeep(formDetails);
    formDetailsCopy[key] = value1;
    if (key === "photo") formDetailsCopy.image_name = value2;
    setFormDetails(formDetailsCopy);
    if (key === "password" || key === "confirmPassword") {
      updatePasswordError("");
    }
  };

  const emailStatusHandler = (value) => {
    const formDetailsCopy = cloneDeep(formDetails);
    formDetailsCopy.email_notification = value;
    setFormDetails(formDetailsCopy);
  };

  const submitData = async () => {
    let payload = {
      email: formDetails.email,
      first_name: formDetails.firstName,
      last_name: formDetails.lastName,
      company_name: formDetails.companyName,
      photo: formDetails.image_name,
      user_type: formDetails.userType,
    };
    try {
      setLoader(true);
      if (popUpData.isEditMode) {
        // editing existing user
        payload = {
          ...payload,
          id: popUpData.data.uuid,
          email_notification: formDetails.email_notification,
        };
        if (formDetails.password) {
          payload.password = formDetails.password;
        }
        await userServices.updateUserData(payload);
      } else {
        await userServices.addNewUser(payload);
      }
      closePopup();
      showAlert(
        `User ${popUpData.isEditMode ? "updated" : "added"} successfully!`,
        "success"
      );
      setLoader(true);
      gerUserListDetails();
      setLoading(false);
    } catch (error) {
      const message = error.response?.data?.message
        ? error.response.data.message
        : error.response.data[0] || "";
      if (message) showAlert(message, "error");
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  };

  const submitHandler = (event) => {
    event.preventDefault();
    if (formDetails.password !== formDetails.confirmPassword) {
      updatePasswordError("Password and confirm password should be same");
    } else {
      submitData();
    }
  };

  return (
    <div className="popup">
      <div className="popup-overlay" />
      <div className="popup-wrapper">
        <div className="popup-header">
          <h1>{popUpData.isEditMode ? "Edit User" : "New USER"}</h1>
          <p>
            {popUpData.isEditMode
              ? "Use this page to update your contact information and change your password."
              : "Use this page to add a new User"}
          </p>
        </div>
        <form onSubmit={submitHandler}>
          <div className="popup-body">
            <div className="popup-row">
              <Input
                maxLength={60}
                required="required"
                type="email"
                placeholder="Email address"
                onChange={onChangeHandler("email")}
                value={formDetails.email}
              />
              <div />
              <FileInput
                onChange={onChangeHandler("photo")}
                imageUrl={formDetails.photo}
              />
            </div>
            <div className="popup-row">
              <Input
                required="required"
                placeholder="First Name"
                onChange={onChangeHandler("firstName")}
                value={formDetails.firstName}
              />
              <Input
                required="required"
                placeholder="Last Name"
                onChange={onChangeHandler("lastName")}
                value={formDetails.lastName}
              />
              <Input
                required="required"
                placeholder="Company Name"
                onChange={onChangeHandler("companyName")}
                value={formDetails.companyName}
              />
            </div>
            <div className="popup-row">
              {popUpData.isEditMode && (
                <>
                  <Input
                    placeholder="New password"
                    type="password"
                    onChange={onChangeHandler("password")}
                    value={formDetails.password}
                  />

                  <Input
                    required={formDetails.password ? "required" : ""}
                    placeholder="Confirm"
                    type="password"
                    onChange={onChangeHandler("confirmPassword")}
                    value={formDetails.confirmPassword}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      alignItems: "flex-end",
                      height: "100%",
                    }}
                  >
                    <p>Email Notification</p>
                    <Switch
                      className="sw"
                      checked={formDetails.email_notification}
                      onChange={(e) => emailStatusHandler(e)}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="invalidCredentials">{passwordError}</div>
          <div className="user-popup-footer">
            <SelectBox
              listViewOption={userListOptions}
              onChange={onChangeHandler("userType")}
              value={formDetails.userType}
            />
            <div>
              <button type="button" className="cancel-btn" onClick={closePopup}>
                Cancel
              </button>
              <button type="submit" className="save-btn" disabled={isLoading}>
                {isLoading ? (
                  <div style={{ textAlign: "center" }}>
                    <Dots />
                  </div>
                ) : (
                  (popUpData.isEditMode && "Save Changes") || "Add New Account"
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default UserManagementPopup;

UserManagementPopup.propTypes = {
  setLoader: PropTypes.func.isRequired,
  popUpData: PropTypes.instanceOf(Object).isRequired,
  gerUserListDetails: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
};
