import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { cloneDeep } from "lodash";
import "./styles.scss";
import showAlert from "../../../utils/showAlert";
import UploadInput from "../../../components/UploadInput";
import productServices from "../../../api/productServices";

function BulkUplaodPopup({ closePopup }) {
  const [saving, setSaving] = useState(false);
  const [formDetails, setFormDetails] = useState({
    file: "",
  });

  const submitData = () => {
    setSaving(true);

    const payload = new FormData();
    payload.append("product_file", formDetails.file);

    productServices
      .fileUpload(payload)
      .then((response) => {
        showAlert(response?.message, "success");
        closePopup();
      })
      .catch((error) => {
        setSaving(false);
        showAlert(error?.response?.data?.message, "error");
      });
  };

  const submitHandler = () => {
    if (!formDetails.file && formDetails.file === "")
      showAlert("Please select a file!", "warning");
    else submitData();
  };

  const onChangeHandler = (key) => (value) => {
    const formDetailsCopy = cloneDeep(formDetails);
    formDetailsCopy[key] = value;
    setFormDetails(formDetailsCopy);
  };

  const downloadTemplateHandler = () => {
    productServices.getUploadTemplate().then((resp) => {
      window.location.href = resp?.base_file_url;
    });
  };

  return (
    <div className="popup">
      <div className="popup-overlay" />
      <div className="popup-wrapper">
        <div className="popup-header">
          <h1>Bulk Upload</h1>
        </div>
        <p className="upload-subheading">
          Upload same file format as of template file
        </p>

        <div className="popup-body">
          <div className="upload-popup-row">
            <button
              type="button"
              className="template_btn"
              onClick={downloadTemplateHandler}
            >
              Download Template
            </button>
          </div>

          <div className="upload-popup-row">
            <UploadInput onChange={onChangeHandler("file")} accept=".xlsx" />
          </div>
        </div>

        <div className="popup-footer">
          <button type="button" className="cancel-btn" onClick={closePopup}>
            Cancel
          </button>
          <button
            type="button"
            onClick={submitHandler}
            disabled={saving}
            className="save-btn"
          >
            {saving ? "Uploading..." : " Upload File"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default memo(BulkUplaodPopup);

BulkUplaodPopup.propTypes = {
  closePopup: PropTypes.func.isRequired,
};
