import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import PropTypes from "prop-types";

function MultipleValue({
  setValue = () => {},
  optionList = [],
  value,
  keyName = "full_name",
  placeHolder = "",
  width = 425,
  limitTags = 2,
  disabled,
}) {
  const handleMultipleValue = (_, newValue) => {
    setValue(newValue);
  };
  return (
    <Autocomplete
      multiple
      id="multiple-value"
      style={{ width }}
      options={optionList}
      onChange={handleMultipleValue}
      value={value}
      getOptionSelected={(option, currentValue) =>
        option[keyName] === currentValue[keyName]
      }
      getOptionLabel={(option) => option[keyName]}
      limitTags={limitTags}
      filterSelectedOptions
      disabled={disabled}
      renderInput={(params) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <TextField {...params} variant="standard" label={placeHolder} />
      )}
    />
  );
}

export default MultipleValue;

MultipleValue.defaultProps = {
  setValue: () => {},
  optionList: [],
  keyName: "full_name",
  placeHolder: "",
  width: 425,
  limitTags: 2,
  disabled: false,
};

MultipleValue.propTypes = {
  setValue: PropTypes.func,
  optionList: PropTypes.instanceOf(Array),
  keyName: PropTypes.string,
  placeHolder: PropTypes.string,
  width: PropTypes.number,
  limitTags: PropTypes.number,
  value: PropTypes.instanceOf(Array).isRequired,
  disabled: PropTypes.bool,
};
