import React from "react";
import Skeleton from "react-loading-skeleton";
import PropTypes from "prop-types";

function TableSkeleton({ index = 0 }) {
  return (
    <div className="table-row table-row-skeleton" key={index}>
      <Skeleton circle height={30} width={30} />
      <Skeleton width="98%" height={20} />
    </div>
  );
}

export default TableSkeleton;

TableSkeleton.propTypes = {
  index: PropTypes.number.isRequired,
};
