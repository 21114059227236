import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";

import Cookies from "js-cookie";
import AuthContext from "../../context/index";

const setAccessToken = (AccessToken) => {
  Cookies.set("AccessToken", AccessToken, { expires: 7 });
};
const deleteAccessToken = () => {
  Cookies.set("AccessToken", "--", { expires: -1 });
};
const getAccessToken = () => Cookies.get("AccessToken");
const setUserDetailsOnDevice = (userDetails) => {
  localStorage.setItem("UserDetails", JSON.stringify(userDetails));
};
const getUserDetailsFromDevice = () => {
  const userDetails = localStorage.getItem("UserDetails");
  return userDetails ? JSON.parse(userDetails) : {};
};

export default function AuthProvider({ children }) {
  const [isSignedIn, setSignIn] = useState(false);
  const [iconSidebar, setIconSidebar] = useState(true);
  const [userDetails, setUserDetails] = useState({
    name: "",
  });

  const contextValue = useMemo(
    () => ({
      isSignedIn,
      setSignIn,
      userDetails,
      setUserDetailsOnDevice,
      getUserDetailsFromDevice,
      setUserDetails,
      deleteAccessToken,
      getAccessToken,
      setAccessToken,
      iconSidebar,
      setIconSidebar,
    }),
    [userDetails, isSignedIn, iconSidebar]
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
