import React, { memo, useEffect, useState, useRef } from "react";
import cn from "classnames";
import moment from "moment";
import { Switch } from "antd";
import PropTypes from "prop-types";
import Tippy from "@tippyjs/react/headless";
import userServices from "../../api/userServices";
import showAlert from "../../utils/showAlert";
import styles from "../../pages/dashboard/resources/index.module.scss";
import MailExtension from "../../pages/dashboard/modules/containers/MailExtension";
import StarMailPopup from "../../pages/dashboard/modules/containers/StarMailPopup";
import helper from "../../utils/helper";

const { checkHasThread, getName, getEmail } = helper;

function MailItem({
  selectedRecord,
  selectRecord,
  setHasThread,
  starringReasons,
  users,
  onUpdate,
  setShowAll,
  setSelectedThreadMail,
  setSelectedExtension,
  selectedThreadMail,
  selectedExtension,
  hasThread: hasThreadFromDashboard,
  mail,
  hasMailListChanged,
  setHasMailListChanged,
  currentDataList,
  setPreviewLoading,
  tableRef,
  hotQueueClickHandler,
  setHotQueueRulesData,
  openHotQueueModal,
  getMailLabel,
  setLabel,
}) {
  const [isRead, setRead] = useState(mail.is_read);
  const [isStarred, setStarred] = useState(mail?.starred_detail?.starred);
  const [isStarPopupOpen, setStarPopupOpen] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const [popUp, setPopUp] = useState(false);
  const [isViewAllLoading, setViewAllLoading] = useState(false);
  const [selectedRecordMailThread, setSelectedRecordMailThread] = useState([]);

  const [rightBoxSize, setRightBoxSize] = useState(
    tableRef.current ? tableRef.current.clientWidth : 0
  );
  const observer = useRef(null);

  useEffect(() => {
    observer.current = new ResizeObserver(() => {
      setRightBoxSize(tableRef.current ? tableRef.current.clientWidth : 0);
    });

    observer.current.observe(tableRef.current);

    return () =>
      tableRef.current ? observer.current.unobserve(tableRef.current) : null;
  }, []);

  const selectMail = (record) => {
    setShowAll(false);
    const hasThread = checkHasThread({
      e: record.error_count,
      p: record.published_count,
      r: record.resolved_count,
      pro: record.processing_count,
      hot: record.hot_queue_count,
      his: record.historical_price_error_count,
      inactive: record.inactive_error_count,
      invalid: record.invalid_error_count,
      notAProduct: record.not_a_product_error_count,
      zipFile: record.zip_file_error_count,
      ewak: record.known_attachment_count,
      iaProcessing: record.ia_processing_count,
    });
    setHasThread(hasThread);
    selectRecord(record);
  };

  useEffect(() => {
    if (rightBoxSize <= 780) {
      setPopUp(false);
    }
  }, [rightBoxSize]);

  const calculateTotalMail = (record) => {
    const total =
      record.error_count +
      record.published_count +
      record.resolved_count +
      record.processing_count +
      record.hot_queue_count +
      record.historical_price_error_count +
      record.inactive_error_count +
      record.invalid_error_count +
      record.not_a_product_error_count +
      record.zip_file_error_count +
      record.known_attachment_count +
      record.ia_processing_count;

    return total > 1 ? `(${total})` : "";
  };

  const calculateTotalCount = (record, type) => {
    const total =
      record.error_count +
      record.resolved_count +
      record.published_count +
      record.processing_count +
      record.hot_queue_count +
      record.historical_price_error_count +
      record.inactive_error_count +
      record.invalid_error_count +
      record.not_a_product_error_count +
      record.zip_file_error_count +
      record.known_attachment_count +
      record.ia_processing_count +
      record.review_count;
    if (type === "hot_queue_count") {
      return record[type];
    }
    return total > 1 ? record[type] : "";
  };

  const fetchMailAttachments = async (newSelectedRecord) => {
    if (newSelectedRecord?.id) {
      setViewAllLoading(true);
      try {
        const resp = await userServices.getMailAttachments(
          newSelectedRecord.id
        );
        if (resp.length) {
          setSelectedRecordMailThread(resp);
          setViewAllLoading(false);
          return resp;
        }
      } catch (error) {
        //  error occurred while fetching mail attachments
      }
      setViewAllLoading(false);
    }
    return null;
  };

  const show = () => setStarPopupOpen(true);
  const hide = () => setStarPopupOpen(false);

  const onSwitchChange = (checked) => {
    setRead(checked);
    userServices
      .markMailRead({
        is_read: checked,
        mail: mail.id,
        message_id: mail.message_id,
      })
      .then((resp) => {
        setRead(resp.is_read);
        getMailLabel(mail?.message_id);
      })
      .catch(() => {
        setRead(mail.is_read);
      });
  };

  const onSubmit = (data) => {
    setSubmitting(true);
    if (data.is_update) {
      userServices
        .editMailAsImportant({
          starred: true,
          mail_detail: mail.id,
          tagged_user: data.tagged_users,
          comment: data.comment,
          other_comment: data.other_comment,
        })
        .then(() => {
          setStarred(true);
          setSubmitting(false);
          setStarPopupOpen(false);
          showAlert("Updated successfully!", "success");
          onUpdate();
        })
        .catch(() => {
          setSubmitting(false);
          showAlert("Something went wrong!", "failure");
        });
      return;
    }
    userServices
      .markMailAsImportant({
        starred: true,
        mail_detail: mail.id,
        tagged_user: data.tagged_users,
        comment: data.comment,
        other_comment: data.other_comment,
      })
      .then(() => {
        setStarred(true);
        setSubmitting(false);
        setStarPopupOpen(false);
        showAlert("Email starred successfully!", "success");
        onUpdate();
      })
      .catch(() => {
        setSubmitting(false);
        showAlert("Something went wrong!", "failure");
      });
  };

  const removeStar = () => {
    setStarred(false);
    userServices
      .markMailAsNotImportant(mail.id)
      .then(() => {
        setStarred(false);
        setStarPopupOpen(false);
        showAlert("Star removed successfully!", "success");
        onUpdate();
      })
      .catch(() => {
        setStarred(true);
        showAlert("Something went wrong!", "failure");
      });
  };

  const onclickViewAll = async () => {
    const newPopUp = !popUp;
    setPopUp(newPopUp);
    selectMail(mail);
    setHasThread(true);
    setSelectedThreadMail(null);
    setSelectedExtension(null);
    if (newPopUp) {
      await fetchMailAttachments(mail);
    }
  };

  const isQuarantined = !(
    mail.req_quarantined_count === 0 &&
    mail.quarantined_count === 0 &&
    mail.qua_release_count === 0
  );

  const hasSingleAttachment =
    mail.total_attachment_count === 1 ||
    mail.total_attachment_count === 0 ||
    // no_attachment_count means 'EOWA'
    mail.no_attachment_count !== 0 ||
    mail.is_ewoa;

  // effect to fetch mail threads whenever any of the mail status changes
  useEffect(() => {
    (async () => {
      if (hasMailListChanged && selectedRecord?.id === mail?.id) {
        const selectedMailData = await fetchMailAttachments(mail);
        // refresh the mail thread data if the mail is quarantined or EOWA
        if (hasSingleAttachment && selectedMailData) {
          setSelectedThreadMail(selectedMailData?.[0]);
        }
        setHasMailListChanged(false);
      }
    })();
  }, [hasMailListChanged]);

  const selectMailItem = async () => {
    setHotQueueRulesData({});

    selectMail(mail);
    if (hasSingleAttachment) {
      const selectedMailData = await fetchMailAttachments(mail);
      if (selectedMailData) {
        setSelectedThreadMail(selectedMailData?.[0]);
        setHasThread(null);
      } else {
        setHasThread(true);
        selectedRecordMailThread(null);
      }
    } else {
      setHasThread(true);
      setSelectedThreadMail(null);
    }
    hotQueueClickHandler(mail.hot_queue);
    setSelectedExtension(null);
  };

  // effect to select the first mail item after the list of mail data changes
  // and checks for EQWA or quarantined mail data to open mail detailed preview
  // sidebar
  useEffect(() => {
    (async () => {
      if (
        currentDataList.length &&
        mail?.id === selectedRecord?.id &&
        currentDataList[0].id === selectedRecord.id &&
        hasSingleAttachment
      ) {
        setPreviewLoading(true);
        await selectMailItem();
        setPreviewLoading(false);
      }
    })();
  }, [currentDataList]);

  const getFileCount = () =>
    (mail.total_attachment_count === 1 && isQuarantined) ||
    mail.no_attachment_count !== 0 ||
    mail.is_ewoa
      ? 0
      : mail.total_attachment_count;

  const onHotQueueStatusClick = async (event) => {
    event.stopPropagation();
    await selectMailItem();
    hotQueueClickHandler(mail.hot_queue);
    openHotQueueModal();
  };

  const onHotQueueStatusClickInMailExtension = () => {
    hotQueueClickHandler(mail.hot_queue);
    openHotQueueModal();
  };

  return (
    <div
      style={{
        position: "relative",
        // paddingTop: 16,
        // paddingBlockEnd: 20,
        // borderBottom: popUp ? "1px solid #f2f2f2" : "none",
      }}
      className={cn({
        [styles.selectedRecord]: mail.id === selectedRecord?.id,
        [styles.pb1]: mail.id === selectedRecord?.id,
        [styles.btb]: mail.id === selectedRecord?.id,
      })}
    >
      <div
        role="button"
        tabIndex="0"
        onKeyDown={selectMailItem}
        onClick={selectMailItem}
      >
        <div
          className={cn({
            [styles.record]: true,
            [styles.selectedRecord]: mail.id === selectedRecord?.id,
            [styles.recordWithDate]: true,
          })}
        >
          <Tippy
            zIndex={100}
            visible={isStarPopupOpen}
            render={() => (
              <StarMailPopup
                users={users}
                starringReasons={starringReasons}
                isStarPopupOpen={isStarPopupOpen}
                setStarPopupOpen={setStarPopupOpen}
                setStarred={setStarred}
                isStarred={isStarred}
                onSubmit={onSubmit}
                starDetails={mail?.starred_detail}
                removeStar={removeStar}
                isSubmitting={isSubmitting}
              />
            )}
            allowHTML
            interactive
            placement="bottom-start"
            popperOptions={{ strategy: "fixed" }}
          >
            <img
              role="presentation"
              alt="star"
              className={cn({
                [styles.star]: true,
                [styles.popupOpen]: isStarPopupOpen,
              })}
              onKeyDown={isStarPopupOpen ? hide : show}
              onClick={isStarPopupOpen ? hide : show}
              src={
                isStarred
                  ? "/assets/star_selected.svg"
                  : "/assets/star_unselected.svg"
              }
            />
          </Tippy>
          <div style={{ display: "flex" }}>
            <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              <div className={styles.fromName}>
                {mail?.manual_upload
                  ? mail?.sender_details?.sender_name
                  : getName(mail.mail_from)}
                &ensp;
                <span>{calculateTotalMail(mail)}</span>
              </div>
              <div data-testid="email" className={styles.fromEmail}>
                {mail?.manual_upload
                  ? mail?.sender_details?.sender_email
                  : getEmail(mail.mail_from)}
              </div>
              {mail.tag === "ia" && (
                <div className={styles.generateIA}>IA Generated</div>
              )}
            </div>
          </div>
          {rightBoxSize > 780 && (
            <div>
              <div className={styles.emailSubject}>{mail.subject}</div>
              <div className={styles.emailDateTime}>
                {moment.unix(mail.date).format("dddd MMMM D,  YYYY  HH:mm:ss")}
              </div>
            </div>
          )}
          <div
            className={cn({
              [styles.readBtnContainer]: true,
              [styles.readBtnContainerLarge]: rightBoxSize <= 780,
            })}
          >
            {/* <div style={{ marginRight: "10%" }}>
              <div className={styles.emailDateTime}>
                {moment.unix(mail.date).format("MMM DD, HH:mm")}
              </div>
            </div> */}

            <div className={styles.readUnreadContainer}>
              <div
                className={styles.readUnread}
                style={{ marginLeft: isRead ? "10px" : 0 }}
              >
                {isRead ? "Read" : "Unread"}
              </div>
              <Switch
                style={{ marginLeft: 5 }}
                size="small"
                onChange={onSwitchChange}
                checked={isRead}
              />
            </div>
          </div>
        </div>
        <div
          className={cn({
            [styles.viewAllContainer]: true,
            [styles.record]: true,
            [styles.recordSmall]: rightBoxSize <= 780, // condition when expanding
          })}
        >
          {/* <div className={styles.divider} /> */}
          <div />
          {rightBoxSize <= 780 && (
            <div>
              <div className={styles.emailSubject}>{mail.subject}</div>
            </div>
          )}
          <div className={styles.totalFilesTitle}>
            Total Files: {getFileCount()}
          </div>

          <div
            className={cn({
              [styles.statusCol]: true,
              [styles.statusColSmall]: rightBoxSize <= 780, // condition when expanding
            })}
          >
            <div
              className={cn({
                [styles.recordStatus]: true,
                [styles.red]: true,
                [styles.singleRecord]:
                  calculateTotalCount(mail, "error_count") === "",
                [styles.hide]: mail.error_count === 0,
              })}
            >
              {calculateTotalCount(mail, "error_count")} Error
            </div>
            <div
              className={cn({
                [styles.recordStatus]: true,
                [styles.reviewYellow]: true,
                [styles.singleRecord]:
                  calculateTotalCount(mail, "review_count") === "",
                [styles.hide]: mail.review_count === 0,
              })}
            >
              {calculateTotalCount(mail, "review_count")} Review
            </div>
            <div
              className={cn({
                [styles.recordStatus]: true,
                [styles.yellow]: true,
                [styles.singleRecord]:
                  calculateTotalCount(mail, "processing_count") === "",
                [styles.hide]: mail.processing_count === 0,
              })}
            >
              {calculateTotalCount(mail, "processing_count")} Processing
            </div>
            <div
              className={cn({
                [styles.recordStatus]: true,
                [styles.yellow]: true,
                [styles.singleRecord]:
                  calculateTotalCount(mail, "ia_processing_count") === "",
                [styles.hide]: mail.ia_processing_count === 0,
              })}
            >
              {calculateTotalCount(mail, "ia_processing_count")} IA Processing
            </div>
            <div
              className={cn({
                [styles.recordStatus]: true,
                [styles.blue]: true,
                [styles.singleRecord]:
                  calculateTotalCount(mail, "resolved_count") === "",
                [styles.hide]: mail.resolved_count === 0,
              })}
            >
              {calculateTotalCount(mail, "resolved_count")} Resolved
            </div>
            <div
              className={cn({
                [styles.recordStatus]: true,
                [styles.green]: true,
                [styles.singleRecord]:
                  calculateTotalCount(mail, "published_count") === "",
                [styles.hide]: mail.published_count === 0,
              })}
            >
              {calculateTotalCount(mail, "published_count")} Published
            </div>
            <div
              className={cn({
                [styles.recordStatus]: true,
                [styles.lightYellow]: true,
                [styles.hide]: !isQuarantined,
              })}
            >
              QUARANTINE
            </div>

            <div
              className={cn({
                [styles.recordStatus]: true,
                [styles.orange]: true,
                [styles.singleRecord]:
                  calculateTotalCount(mail, "hot_queue_count") === "",
                [styles.hide]: mail.hot_queue_count === 0,
              })}
              onClick={(e) => onHotQueueStatusClick(e)}
              onKeyDown={(e) => onHotQueueStatusClick(e)}
              role="button"
              tabIndex={0}
            >
              {calculateTotalCount(mail, "hot_queue_count")}{" "}
              {mail.hot_queue_count > 1
                ? "Files Hot Queued"
                : "File Hot Queued"}
            </div>

            <div
              className={cn({
                [styles.recordStatus]: true,
                [styles.lightBlue]: true,
                [styles.singleRecord]:
                  calculateTotalCount(mail, "no_attachment_count") === "",
                [styles.hide]: mail.no_attachment_count === 0,
              })}
            >
              {calculateTotalCount(mail, "no_attachment_count")} EWOA
            </div>

            <div
              className={cn({
                [styles.recordStatus]: true,
                [styles.violet]: true,
                [styles.singleRecord]:
                  calculateTotalCount(mail, "historical_price_error_count") ===
                  "",
                [styles.hide]: mail.historical_price_error_count === 0,
              })}
            >
              {calculateTotalCount(mail, "historical_price_error_count")} HP
            </div>

            <div
              className={cn({
                [styles.recordStatus]: true,
                [styles.red]: true,
                [styles.singleRecord]:
                  calculateTotalCount(mail, "inactive_error_count") === "",
                [styles.hide]: mail.inactive_error_count === 0,
              })}
            >
              {calculateTotalCount(mail, "inactive_error_count")} Inactive
            </div>

            <div
              className={cn({
                [styles.recordStatus]: true,
                [styles.red]: true,
                [styles.singleRecord]:
                  calculateTotalCount(mail, "invalid_error_count") === "",
                [styles.hide]: mail.invalid_error_count === 0,
              })}
            >
              {calculateTotalCount(mail, "invalid_error_count")} Not In DB
            </div>

            <div
              className={cn({
                [styles.recordStatus]: true,
                [styles.red]: true,
                [styles.singleRecord]:
                  calculateTotalCount(mail, "not_a_product_error_count") === "",
                [styles.hide]: mail.not_a_product_error_count === 0,
              })}
            >
              {calculateTotalCount(mail, "not_a_product_error_count")} Not A
              Product
            </div>

            <div
              className={cn({
                [styles.recordStatus]: true,
                [styles.red]: true,
                [styles.singleRecord]:
                  calculateTotalCount(mail, "zip_file_error_count") === "",
                [styles.hide]: mail.zip_file_error_count === 0,
              })}
            >
              {calculateTotalCount(mail, "zip_file_error_count")} Zip File
            </div>

            <div
              className={cn({
                [styles.recordStatus]: true,
                [styles.lightBlue]: true,
                [styles.singleRecord]:
                  calculateTotalCount(mail, "known_attachment_count") === "",
                [styles.hide]: mail.known_attachment_count === 0,
              })}
            >
              {calculateTotalCount(mail, "known_attachment_count")} EWKA
            </div>
          </div>

          {rightBoxSize > 780 && !hasSingleAttachment && (
            <div
              className={cn({
                [styles.totalDisplay]: true,
                [styles.expandIcon]: true,
              })}
            >
              <div
                role="button"
                tabIndex="0"
                className={cn({
                  [styles.previewFileDownloadLink]: true,
                })}
                onKeyDown={async () => onclickViewAll()}
                onClick={async () => onclickViewAll()}
                style={{ textAlign: "right" }}
              >
                <img
                  alt="arrow"
                  className={cn({
                    [styles.star]: true,
                  })}
                  src={
                    popUp
                      ? "/assets/mail-expand-arrow-up.svg"
                      : "/assets/mail-expand-arrow-down.svg"
                  }
                />
              </div>
            </div>
          )}
        </div>
        {mail.id !== selectedRecord?.id && (
          <div className={styles.rowDivider} />
        )}
      </div>

      {popUp && mail.total_attachment_count > 0 && (
        <div style={{ flexBasis: "100%" }}>
          <MailExtension
            mail={mail}
            setHasThread={setHasThread}
            extensionData={selectedRecordMailThread}
            isViewAllLoading={isViewAllLoading}
            setPopUp={() => {
              selectMail(mail);
              setHasThread(true);
              setSelectedThreadMail(null);
              setSelectedExtension(null);
              setPopUp(!popUp);
            }}
            popUp={popUp}
            setSelectedThreadMail={setSelectedThreadMail}
            setSelectedExtension={setSelectedExtension}
            selectedThreadMail={selectedThreadMail}
            selectedExtension={selectedExtension}
            hasThread={hasThreadFromDashboard}
            isMailSelected={mail.id === selectedRecord?.id}
            selectRecord={selectRecord}
            hotQueueClickHandler={onHotQueueStatusClickInMailExtension}
            setHotQueueRulesData={setHotQueueRulesData}
            hotQueueRulefetcher={() => hotQueueClickHandler(mail.hot_queue)}
          />
        </div>
      )}
    </div>
  );
}

export default memo(MailItem);

MailItem.defaultProps = {
  selectedRecord: {},
  selectedExtension: null,
  selectedThreadMail: null,
  mail: {},
  currentDataList: [],
};

MailItem.propTypes = {
  mail: PropTypes.instanceOf(Object),
  selectedRecord: PropTypes.instanceOf(Object),
  selectRecord: PropTypes.func.isRequired,
  setHasThread: PropTypes.func.isRequired,
  starringReasons: PropTypes.instanceOf(Array).isRequired,
  users: PropTypes.instanceOf(Array).isRequired,
  onUpdate: PropTypes.func.isRequired,
  setShowAll: PropTypes.func.isRequired,
  setSelectedThreadMail: PropTypes.func.isRequired,
  setSelectedExtension: PropTypes.func.isRequired,
  selectedThreadMail: PropTypes.instanceOf(Object),
  selectedExtension: PropTypes.instanceOf(Object),
  hasThread: PropTypes.bool.isRequired,
  hasMailListChanged: PropTypes.bool.isRequired,
  setHasMailListChanged: PropTypes.func.isRequired,
  currentDataList: PropTypes.instanceOf(Array),
  setPreviewLoading: PropTypes.func.isRequired,
  tableRef: PropTypes.instanceOf(Object).isRequired,
  hotQueueClickHandler: PropTypes.func.isRequired,
  setHotQueueRulesData: PropTypes.func.isRequired,
  openHotQueueModal: PropTypes.func.isRequired,
  getMailLabel: PropTypes.func.isRequired,
  setLabel: PropTypes.func.isRequired,
};
