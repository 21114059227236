/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { Controller } from "react-hook-form";
import PropTypes from "prop-types";
import { Checkbox } from "@material-ui/core";
import MultiSelect from "../../../../../components/MultiSelect";
import SingleSelect from "../../../../../components/SingleSelect";
import styles from "../../../resources/index.module.scss";
import { ERROR_CODE_LIST } from "../../../../../utils/constantData";

function FilterForm({
  setValue,
  setPopupOpen,
  handleSearchSubmit,
  isPopupOpen,
  selectRecord,
  setFilter,
  filter,
  registerSearch,
  DateField,
  controlSearch,
  fundTagOptions,
  shouldRenderCalendar,
  defaultFilterFromDate,
  statusFilters,
  statusFilterOptions,
  defaultFilterToDate,
  starringReasons,
  taggedUsers,
  starredUsers,
  openKeyModal,
  resetSearchForm,
  clearFilter,
  initialFilter,
  onSelectedFilter,
  loadingData,
  previewLoading,
  setSelectedFilter,
  setResetMainFilter,
}) {
  return (
    <form
      style={{ ...(!isPopupOpen && { display: "none" }) }}
      className={styles.listViewPrefSearchPopup}
      onSubmit={handleSearchSubmit((data) => {
        setResetMainFilter(false);
        setSelectedFilter((prevState) => ({
          ...prevState,
          assignedTo: [],
        }));
        setPopupOpen(false);
        selectRecord({});
        setFilter((prevFilter) => ({
          ...prevFilter,
          ...data,
          currentPage: 1,
          pageSize: filter.pageSize,
        }));
      })}
      onReset={() => {
        setValue("errorCodesList", []);
        setValue("fundTagList", []);
        setValue("editedFundTagList", []);
        setValue("starringReasons", []);
        setValue("starredBy", []);
        setValue("taggedUsers", []);
        setPopupOpen(false);
      }}
    >
      <div style={{ display: "flex" }}>
        <div className={styles.filterCheckBox}>
          <Checkbox
            name="all"
            color="primary"
            size="small"
            onChange={onSelectedFilter}
            checked={filter?.all}
            disabled={loadingData || previewLoading}
          />
          <div>All</div>
        </div>
        <div className={styles.filterCheckBox}>
          <Checkbox
            name="read"
            color="primary"
            size="small"
            onChange={onSelectedFilter}
            checked={filter?.read}
            disabled={loadingData || previewLoading}
          />
          <div>Read</div>
        </div>
        <div className={styles.filterCheckBox}>
          <Checkbox
            name="unread"
            color="primary"
            size="small"
            onChange={onSelectedFilter}
            checked={filter?.unread}
            disabled={loadingData || previewLoading}
          />
          <div>Unread</div>
        </div>
      </div>
      <table>
        <tbody>
          <tr className={styles.listViewPrefSearchPopupRow}>
            <td>
              <label htmlFor="search-from">From</label>
            </td>
            <td>
              <input id="search-from" name="from" ref={registerSearch} />
            </td>
          </tr>
          <tr className={styles.listViewPrefSearchPopupRow}>
            <td>
              <label htmlFor="search-subject">Subject</label>
            </td>
            <td>
              <input id="search-subject" name="subject" ref={registerSearch} />
            </td>
          </tr>
          <tr className={styles.listViewPrefSearchPopupRow}>
            <td>
              <label htmlFor="search-reference">Reference ID</label>
            </td>
            <td>
              <input
                id="search-reference"
                name="reference_id"
                ref={registerSearch}
              />
            </td>
          </tr>
          <tr className={styles.listViewPrefSearchPopupRow}>
            <td>
              <label htmlFor="fund-tag">Fund Tag</label>
            </td>
            <td>
              <Controller
                control={controlSearch}
                name="fundTagList"
                defaultValue={[]}
                render={({ onChange, value }) => (
                  <MultiSelect
                    id="fund-tag"
                    onChange={onChange}
                    value={value}
                    listOptions={fundTagOptions}
                  />
                )}
              />
            </td>
          </tr>
          <tr className={styles.listViewPrefSearchPopupRow}>
            <td>
              <label htmlFor="edited-fund-tag">Edited Fund Tag</label>
            </td>
            <td>
              <Controller
                control={controlSearch}
                name="editedFundTagList"
                defaultValue={[]}
                render={({ onChange, value }) => (
                  <MultiSelect
                    id="edit-fund-tag"
                    onChange={onChange}
                    value={value}
                    listOptions={fundTagOptions}
                  />
                )}
              />
            </td>
          </tr>
          <tr className={styles.listViewPrefSearchPopupRow}>
            <td>
              <label htmlFor="search-status">Status</label>
            </td>
            <td>
              <Controller
                control={controlSearch}
                name="status"
                render={({ onChange, value }) => (
                  <SingleSelect
                    id="search-status"
                    defaultValue={statusFilters.all}
                    optionList={statusFilterOptions}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </td>
          </tr>
          <tr className={styles.listViewPrefSearchPopupRow}>
            <td>
              <label htmlFor="search-manager">manager</label>
            </td>
            <td>
              <input
                id="search-manager"
                name="manager_name"
                ref={registerSearch}
              />
            </td>
          </tr>
          <tr className={styles.listViewPrefSearchPopupRow}>
            <td>
              <label htmlFor="search-prepared-by">prepared by</label>
            </td>
            <td>
              <input
                id="search-prepared-by"
                name="prepared_by"
                ref={registerSearch}
              />
            </td>
          </tr>
          <tr className={styles.listViewPrefSearchPopupRow}>
            <td>
              <label htmlFor="search-date">date</label>
            </td>
            <td>
              {shouldRenderCalendar && (
                <div className={styles.listViewPrefSearchPopupInputFlex}>
                  <DateField
                    id="search-date1"
                    name="date_from"
                    disabled={filter?.all}
                    halfWidth
                    onChange={() => {
                      setResetMainFilter(true);
                      setFilter((prevFilter) => ({
                        ...prevFilter,
                        all: false,
                      }));
                    }}
                    placeholder={defaultFilterFromDate}
                    register={registerSearch}
                  />
                  <DateField
                    id="search-date2"
                    name="date_to"
                    disabled={filter?.all}
                    halfWidth
                    onChange={() => {
                      setResetMainFilter(true);
                      setFilter((prevFilter) => ({
                        ...prevFilter,
                        all: false,
                      }));
                    }}
                    placeholder={defaultFilterToDate}
                    register={registerSearch}
                  />
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.16671 3.33341H5.83337V2.50008C5.83337 2.27907 5.92117 2.06711 6.07745 1.91083C6.23373 1.75455 6.44569 1.66675 6.66671 1.66675C6.88772 1.66675 7.09968 1.75455 7.25596 1.91083C7.41224 2.06711 7.50004 2.27907 7.50004 2.50008V3.33341H12.5V2.50008C12.5 2.27907 12.5878 2.06711 12.7441 1.91083C12.9004 1.75455 13.1124 1.66675 13.3334 1.66675C13.5544 1.66675 13.7663 1.75455 13.9226 1.91083C14.0789 2.06711 14.1667 2.27907 14.1667 2.50008V3.33341H15.8334C16.4964 3.33341 17.1323 3.59681 17.6011 4.06565C18.07 4.53449 18.3334 5.17037 18.3334 5.83342V15.8334C18.3334 16.4965 18.07 17.1323 17.6011 17.6012C17.1323 18.07 16.4964 18.3334 15.8334 18.3334H4.16671C3.50366 18.3334 2.86778 18.07 2.39894 17.6012C1.9301 17.1323 1.66671 16.4965 1.66671 15.8334V5.83342C1.66671 5.17037 1.9301 4.53449 2.39894 4.06565C2.86778 3.59681 3.50366 3.33341 4.16671 3.33341ZM3.33337 15.8334C3.33337 16.0544 3.42117 16.2664 3.57745 16.4227C3.73373 16.579 3.94569 16.6667 4.16671 16.6667H15.8334C16.0544 16.6667 16.2663 16.579 16.4226 16.4227C16.5789 16.2664 16.6667 16.0544 16.6667 15.8334V10.0001H3.33337V15.8334ZM3.33337 8.33342H16.6667V5.83342C16.6667 5.6124 16.5789 5.40044 16.4226 5.24416C16.2663 5.08788 16.0544 5.00008 15.8334 5.00008H14.1667V5.83342C14.1667 6.05443 14.0789 6.26639 13.9226 6.42267C13.7663 6.57895 13.5544 6.66675 13.3334 6.66675C13.1124 6.66675 12.9004 6.57895 12.7441 6.42267C12.5878 6.26639 12.5 6.05443 12.5 5.83342V5.00008H7.50004V5.83342C7.50004 6.05443 7.41224 6.26639 7.25596 6.42267C7.09968 6.57895 6.88772 6.66675 6.66671 6.66675C6.44569 6.66675 6.23373 6.57895 6.07745 6.42267C5.92117 6.26639 5.83337 6.05443 5.83337 5.83342V5.00008H4.16671C3.94569 5.00008 3.73373 5.08788 3.57745 5.24416C3.42117 5.40044 3.33337 5.6124 3.33337 5.83342V8.33342Z"
                      fill="#979797"
                    />
                  </svg>
                </div>
              )}
            </td>
          </tr>
          <tr className={styles.listViewPrefSearchPopupRow}>
            <td>
              <label htmlFor="search-dop" title="Date of Preparation">
                D.O.P
              </label>
            </td>
            <td>
              {shouldRenderCalendar && (
                <DateField
                  alignCenter
                  id="search-dop"
                  name="dop"
                  placeholder={defaultFilterFromDate}
                  register={registerSearch}
                />
              )}
            </td>
          </tr>
          <tr className={styles.listViewPrefSearchPopupRow}>
            <td>
              <label htmlFor="error-codes">Error Codes</label>
            </td>
            <td>
              <Controller
                control={controlSearch}
                name="errorCodesList"
                defaultValue={[]}
                render={({ onChange, value }) => (
                  <MultiSelect
                    id="error-code"
                    onChange={onChange}
                    value={value}
                    listOptions={ERROR_CODE_LIST}
                  />
                )}
              />
            </td>
          </tr>
          <tr className={styles.listViewPrefSearchPopupRow}>
            <td>
              <label htmlFor="error-codes">Star Reason</label>
            </td>
            <td>
              <Controller
                control={controlSearch}
                name="starringReasons"
                defaultValue={[]}
                render={({ onChange, value }) => (
                  <MultiSelect
                    id="error-code"
                    onChange={onChange}
                    value={value}
                    listOptions={[...starringReasons, { value: "Others" }]}
                  />
                )}
              />
            </td>
          </tr>
          <tr className={styles.listViewPrefSearchPopupRow}>
            <td>
              <label htmlFor="error-codes">Starred By</label>
            </td>
            <td>
              <Controller
                control={controlSearch}
                name="starredBy"
                defaultValue={[]}
                render={({ onChange, value }) => (
                  <MultiSelect
                    id="error-code"
                    onChange={onChange}
                    value={value}
                    listOptions={starredUsers}
                  />
                )}
              />
            </td>
          </tr>
          <tr className={styles.listViewPrefSearchPopupRow}>
            <td>
              <label htmlFor="error-codes">Tagged Users</label>
            </td>
            <td>
              <Controller
                control={controlSearch}
                name="taggedUsers"
                defaultValue={[]}
                render={({ onChange, value }) => (
                  <MultiSelect
                    id="error-code"
                    onChange={onChange}
                    value={value}
                    listOptions={taggedUsers}
                  />
                )}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div className={styles.listViewPrefSearchPopupButtons}>
        <input
          className={styles.listViewPrefSearchPopupClear}
          onClick={() => {
            setResetMainFilter(false);
            resetSearchForm();
            clearFilter();
            selectRecord({});
            setFilter({
              ...initialFilter,
              fundTagList: [],
            });
          }}
          type="reset"
          value="Clear"
        />
        <input
          className={styles.listViewPrefSearchPopupSubmit}
          type="button"
          value="Code Generator Demo"
          onClick={openKeyModal}
        />
        <input
          className={styles.listViewPrefSearchPopupSubmit}
          type="submit"
          value="Search"
        />
      </div>
    </form>
  );
}

export default FilterForm;

FilterForm.propTypes = {
  setValue: PropTypes.func.isRequired,
  setPopupOpen: PropTypes.func.isRequired,
  handleSearchSubmit: PropTypes.func.isRequired,
  isPopupOpen: PropTypes.bool.isRequired,
  selectRecord: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
  filter: PropTypes.instanceOf(Object).isRequired,
  registerSearch: PropTypes.func.isRequired,
  DateField: PropTypes.node.isRequired,
  controlSearch: PropTypes.instanceOf(Object).isRequired,
  fundTagOptions: PropTypes.instanceOf(Array).isRequired,
  shouldRenderCalendar: PropTypes.bool.isRequired,
  defaultFilterFromDate: PropTypes.string.isRequired,
  statusFilters: PropTypes.instanceOf(Object).isRequired,
  statusFilterOptions: PropTypes.instanceOf(Array).isRequired,
  defaultFilterToDate: PropTypes.string.isRequired,
  starringReasons: PropTypes.instanceOf(Array).isRequired,
  taggedUsers: PropTypes.instanceOf(Array).isRequired,
  starredUsers: PropTypes.instanceOf(Array).isRequired,
  openKeyModal: PropTypes.func.isRequired,
  resetSearchForm: PropTypes.func.isRequired,
  clearFilter: PropTypes.func.isRequired,
  initialFilter: PropTypes.instanceOf(Object).isRequired,
  onSelectedFilter: PropTypes.func.isRequired,
  loadingData: PropTypes.bool.isRequired,
  previewLoading: PropTypes.bool.isRequired,
  setSelectedFilter: PropTypes.func.isRequired,
  setResetMainFilter: PropTypes.func.isRequired,
};
