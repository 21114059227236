import React from "react";
import Skeleton from "react-loading-skeleton";
import "./style.scss";

function PaginationSkeleton() {
  return (
    <div className="pagination-wrapper">
      <Skeleton width={70} height={20} />
      &ensp;
      <Skeleton circle height={20} width={20} />
      &nbsp;
      <Skeleton circle height={20} width={20} />
    </div>
  );
}

export default PaginationSkeleton;
