import React, { memo, useState, useRef } from "react";

import { Select } from "antd";
import PropTypes from "prop-types";
import { cloneDeep } from "lodash";

import "./styles.scss";
import helper from "../../../../../utils/helper";
import showAlert from "../../../../../utils/showAlert";
import userServices from "../../../../../api/userServices";
import Input from "../../../../../components/Input";

function LabelModal({ closePopup, loadlabelList }) {
  const [saving, setSaving] = useState(false);
  const [formDetails, setFormDetails] = useState({
    name: "",
    backgroundColor: "",
    textColor: "",
  });

  const selectRef = useRef();
  const { Option } = Select;
  const { colorsList } = helper;

  const submitData = async () => {
    setSaving(true);

    const payload = {
      label_name: formDetails.name,
    };
    if (formDetails.backgroundColor)
      payload.background_color = formDetails.backgroundColor;
    if (formDetails.textColor) payload.text_color = formDetails.textColor;

    try {
      await userServices.updateMailLabels(payload);
      closePopup();
      showAlert("New Label created", "success");
      loadlabelList();
    } catch (error) {
      setSaving(false);
      showAlert(error?.response?.data?.message, "error");
    }
  };

  const submitHandler = async () => {
    if (!formDetails.name && formDetails.name === "")
      showAlert("Please Enter Label Name!", "warning");
    else await submitData();
  };

  const onChangeHandler = (key) => (value) => {
    const formDetailsCopy = cloneDeep(formDetails);
    formDetailsCopy[key] = value;
    setFormDetails(formDetailsCopy);
  };

  return (
    <div className="popup label-modal" data-testid="client-management-popup">
      <div className="popup-overlay" />
      <div className="popup-wrapper">
        <div className="popup-header">
          <h1>New Label</h1>
        </div>

        <div className="popup-body">
          <div className="label-popup-row">
            <div>
              <Input
                placeholder="Label Name"
                onChange={onChangeHandler("name")}
                value={formDetails.name}
                maxLength={225}
                minLength={3}
              />
            </div>
          </div>

          <div className="label-popup-row" id="background-select-box">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <div className="select-label">BACKGROUND COLOR</div>
            <Select
              ref={selectRef}
              placeholder="Please select"
              size="large"
              showArrow
              onChange={onChangeHandler("backgroundColor")}
              bordered={false}
              getPopupContainer={() =>
                document.getElementById("background-select-box")
              }
              style={{
                width: "100%",
                maxWidth: 860,
                height: 42,
                paddingRight: 10,
                background: "rgba(224, 231, 255, 0.2)",
                border: "1px solid #e0e7ff",
                borderRadius: 5,
                zIndex: 99,
              }}
              dropdownStyle={{
                marginBottom: 50,
                zIndex: 100,
                maxHeight: 200,
              }}
            >
              {colorsList.map((c) => (
                <Option key={c} value={c}>
                  <div className="option-container">
                    <div
                      style={{
                        background: c,
                      }}
                      className="color-box"
                    />
                    {c}
                  </div>
                </Option>
              ))}
            </Select>
          </div>
          <div className="label-popup-row" id="text-select-box">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <div className="select-label">TEXT COLOR</div>
            <Select
              ref={selectRef}
              placeholder="Please select"
              size="large"
              showArrow
              onChange={onChangeHandler("textColor")}
              bordered={false}
              getPopupContainer={() =>
                document.getElementById("text-select-box")
              }
              style={{
                width: "100%",
                maxWidth: 860,
                height: 42,
                paddingRight: 10,
                background: "rgba(224, 231, 255, 0.2)",
                border: "1px solid #e0e7ff",
                borderRadius: 5,
                zIndex: 99,
              }}
              dropdownStyle={{
                marginBottom: 50,
                zIndex: 100,
                maxHeight: 200,
              }}
            >
              <Option value="#ffffff">
                <div className="option-container">
                  <div
                    style={{
                      background: "#ffffff",
                    }}
                    className="color-box"
                  />
                  White
                </div>
              </Option>
              <Option value="#000000">
                <div className="option-container">
                  <div
                    style={{
                      background: "#000000",
                    }}
                    className="color-box"
                  />
                  Black
                </div>
              </Option>
            </Select>
          </div>
        </div>

        <div className="popup-footer">
          <button type="button" className="cancel-btn" onClick={closePopup}>
            Cancel
          </button>
          <button
            type="button"
            onClick={submitHandler}
            disabled={saving}
            className="save-btn"
          >
            {saving ? "Creating..." : " Create"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default memo(LabelModal);

LabelModal.propTypes = {
  closePopup: PropTypes.func.isRequired,
  loadlabelList: PropTypes.func.isRequired,
};
