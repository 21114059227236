import React from "react";
import PropTypes from "prop-types";
import AdminSidebar from "../AdminSidebar";

function MainLayout({ children }) {
  return (
    <div className="layout">
      <AdminSidebar />
      <div className="layout-container">{children}</div>
    </div>
  );
}

export default MainLayout;

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
