import React, { useState } from "react";
import PropTypes from "prop-types";

import styles from "../../../resources/index.module.scss";

function TextWrap({ text }) {
  const textLen = text?.length;
  const maxTextLen = 300;

  const [shouldWrap, setWrap] = useState(true);

  return (
    <span className={styles.completeEmailMessage}>
      {textLen > maxTextLen ? (
        <>
          {shouldWrap ? <>{text?.substring(0, maxTextLen)}...</> : text}&nbsp;
          <span
            role="button"
            tabIndex="0"
            onKeyPress={() => {
              setWrap(!shouldWrap);
            }}
            onClick={() => {
              setWrap(!shouldWrap);
            }}
            className={styles.emailMessageWrapAnchor}
          >
            {shouldWrap ? "more" : "less"}
          </span>
        </>
      ) : (
        text
      )}
    </span>
  );
}

export default TextWrap;

TextWrap.propTypes = {
  text: PropTypes.string.isRequired,
};
