import React, { useEffect, useRef, useState } from "react";
import { cloneDeep } from "lodash";
import useKeyboardShortcut from "use-keyboard-shortcut";
import AddButton from "../../components/AddButton/index";
import Pagination from "../../components/Pagination/index";
import SearchInput from "../../components/SearchInput";
import MainHeader from "../../components/MainHeader";
import UserManagementPopup from "./UserManagementPopup";
import UserManagementTable from "./UserManagementTable";
import PaginationSkeleton from "../../components/Skeletons/PaginationSkeleton";
import userServices from "../../api/userServices";
import MainLayout from "../../components/MainLayout";

import "./style.scss";

function UserManagement() {
  const findRef = useRef(null);
  const [loader, setLoader] = useState(false);
  const [popUpData, setPopUpData] = useState({
    isShowPopUp: false,
    isEditMode: false,
    data: {},
  });
  const [tableData, setTableData] = useState([]);
  const [filter, setFilter] = useState({
    currentPage: 1,
    searchKey: "",
  });
  const [navigation, setNavigation] = useState({
    itemCount: undefined,
    totalCount: undefined,
    nextPageNum: undefined,
    prevPageNum: undefined,
    hasNextPage: undefined,
    hasPrevPage: undefined,
    dataPerPage: undefined,
  });

  const addButtonHandle = () => {
    const popUpDataCopy = cloneDeep(popUpData);
    popUpDataCopy.isShowPopUp = true;
    popUpDataCopy.isEditMode = false;
    popUpDataCopy.data = {};
    setPopUpData(popUpDataCopy);
  };

  const gerUserListDetails = () => {
    const filterText = [];

    if (filter.currentPage) filterText.push(`page=${filter.currentPage}`);
    if (filter.searchKey.length !== 0) filterText.push(`q=${filter.searchKey}`);

    userServices.getUserListing(filterText).then((resp) => {
      setTableData(resp.results);

      const navigationCopy = cloneDeep(navigation);
      navigationCopy.itemCount = resp?.results?.length;
      navigationCopy.totalCount = resp.total_count;
      navigationCopy.nextPageNum = resp.next_page_num;
      navigationCopy.prevPageNum = resp.prev_page_num;
      navigationCopy.hasNextPage = resp.has_next_page;
      navigationCopy.hasPrevPage = resp.has_prev_page;
      navigationCopy.dataPerPage = resp.data_per_page;
      setNavigation(navigationCopy);
      setLoader(false);
    });
  };

  useEffect(() => {
    setLoader(true);
    let autoSaveTimer;
    autoSaveTimer = setTimeout(() => gerUserListDetails(), 1000);
    return () => {
      // cancel the request when component un mounts
      clearInterval(autoSaveTimer);
      autoSaveTimer = null;
    };
  }, [filter]);

  useEffect(() => {
    document.title = "BCG IA | User Management";
  }, []);

  const onChangeHandler = (key) => (value) => {
    const filterCopy = cloneDeep(filter);
    if (key === "searchKey") {
      filterCopy.searchKey = value;
      filterCopy.currentPage = 1;
      setFilter(filterCopy);
    }
  };

  const closePopup = () => {
    const popUpDataCopy = cloneDeep(popUpData);
    popUpDataCopy.isShowPopUp = false;
    popUpDataCopy.isEditMode = false;
    popUpDataCopy.data = {};
    setPopUpData(popUpDataCopy);
  };

  // keyboard shortcuts
  useKeyboardShortcut(["Control", "a"], () => addButtonHandle(), {
    overrideSystem: true,
  });
  useKeyboardShortcut(["Escape"], () => closePopup());
  useKeyboardShortcut(["Control", "f"], () => findRef.current.focus(), {
    overrideSystem: true,
  });

  return (
    <MainLayout>
      <MainHeader title="User Management" />
      {/* <ManagementTabs totalCount={navigation.totalCount} totalText="Users" /> */}
      <div className="container usermanagment">
        <div className="page-header">
          <div className="page-header___right">
            <SearchInput
              onChange={onChangeHandler("searchKey")}
              value={filter.searchKey}
              inputRef={findRef}
              placeholder="Search for accounts, emails ..."
            />
            <AddButton onClickHandle={addButtonHandle} />
          </div>
          {loader ? (
            <PaginationSkeleton />
          ) : (
            <Pagination
              filter={filter}
              setFilter={setFilter}
              navigation={navigation}
            />
          )}
        </div>
        <UserManagementTable
          loader={loader}
          setLoader={setLoader}
          tableData={tableData}
          setTableData={setTableData}
          popUpData={popUpData}
          setPopUpData={setPopUpData}
          gerUserListDetails={gerUserListDetails}
        />
        {popUpData.isShowPopUp && (
          <UserManagementPopup
            setLoader={setLoader}
            popUpData={popUpData}
            gerUserListDetails={gerUserListDetails}
            closePopup={closePopup}
          />
        )}
      </div>
    </MainLayout>
  );
}

export default UserManagement;
