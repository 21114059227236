import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";
import { isEmpty } from "lodash";
import gitbookServices from "../../api/gitbookServices";
import "./style.scss";

function ChangeLogPoup({ closePopup }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const gotoChangeLog = () => {
    window.open(process.env.REACT_APP_CHANGE_LOG_LINK, "_blank");
  };

  const getChangeLogData = async () => {
    try {
      setLoading(true);
      const getUrl = await gitbookServices.getSpaceContent();
      const url = getUrl?.pages[0]?.path;
      const res = await gitbookServices.getPageContent(url);
      setData(res);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const filterData = () => {
    let header = 0;
    const result = [];
    for (let i = 0; i < data?.document?.nodes.length; i++) {
      if (data?.document?.nodes[i].type.includes("heading") && header > 0) {
        break;
      }
      if (data?.document?.nodes[i].type.includes("heading")) {
        header += 1;
      }
      result.push({ ...data?.document?.nodes[i], id: i + 1 });
    }

    return result;
  };

  useEffect(() => {
    getChangeLogData();
  }, []);

  return (
    <div className="popup change-log-update-popup">
      <div className="popup-overlay" />
      <div className="popup-wrapper">
        <div className="popup-header">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {loading ? (
              <Skeleton width={350} height={20} />
            ) : (
              <h1>{data?.title}</h1>
            )}
            <div
              role="button"
              tabIndex="0"
              className="close-btn"
              onKeyPress={closePopup}
              onClick={closePopup}
            >
              Close
            </div>
          </div>
        </div>
        <div className="change_list">
          {isEmpty(data?.document?.nodes) ? (
            <Skeleton height={60} count={5} />
          ) : (
            filterData().map((e) => (
              <div key={e.id}>
                {e.type === "heading-3" && (
                  <div className="heading">{e?.nodes[0]?.leaves[0]?.text}</div>
                )}
                {e.type === "expandable" && (
                  <div
                    className="data"
                    role="button"
                    tabIndex="0"
                    onKeyDown={gotoChangeLog}
                    onClick={gotoChangeLog}
                  >
                    <div className="single__content">
                      <div className="single___data">
                        <p>
                          {e?.fragments[1]?.nodes[0]?.nodes[0]?.object ===
                            "text" &&
                            e?.fragments[1]?.nodes[0]?.nodes[0]?.leaves[0]
                              ?.text}
                        </p>
                        <p>
                          {e?.fragments[0]?.nodes[0]?.nodes[0]?.leaves[0]?.text}
                        </p>
                      </div>
                    </div>
                    <div className="view-details">View Details</div>
                  </div>
                )}
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}

export default ChangeLogPoup;

ChangeLogPoup.propTypes = {
  closePopup: PropTypes.func.isRequired,
};
