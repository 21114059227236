import React, { useEffect, useState } from "react";
import { cloneDeep } from "lodash";

import MainHeader from "../../components/MainHeader";

import AddButton from "../../components/AddButton";
import PaginationSkeleton from "../../components/Skeletons/PaginationSkeleton/index";
import Pagination from "../../components/Pagination/index";
import "./style.scss";
import HotQueueTable from "./HotQueueTable";
import HotQueuePopUp from "./HotQueuePopUp";
import hotQueueServices from "../../api/hotQueueServices";
import DeletePopup from "./DeletePopup";
import MainLayout from "../../components/MainLayout";

function HotQueue() {
  const [loader, setLoader] = useState(false);
  const [popUpData, setPopUpData] = useState({
    isShowPopUp: false,
    isEditMode: false,
    data: {},
  });
  const [showDeletePopUp, setDeletePopUp] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [filter, setFilter] = useState({
    currentPage: 1,
    searchKey: "",
    descendingSort: "",
  });
  const [navigation, setNavigation] = useState({
    itemCount: undefined,
    totalCount: undefined,
    nextPageNum: undefined,
    prevPageNum: undefined,
    hasNextPage: undefined,
    hasPrevPage: undefined,
    dataPerPage: undefined,
  });
  const addButtonHandle = () => {
    const popUpDataCopy = cloneDeep(popUpData);
    popUpDataCopy.isShowPopUp = true;
    popUpDataCopy.isEditMode = false;
    popUpDataCopy.data = {};
    setPopUpData(popUpDataCopy);
  };

  const closePopup = () => {
    const popUpDataCopy = cloneDeep(popUpData);
    popUpDataCopy.isShowPopUp = false;
    popUpDataCopy.isEditMode = false;
    popUpDataCopy.data = {};
    setPopUpData(popUpDataCopy);
  };

  const openDeletePopup = () => {
    setDeletePopUp(true);
  };

  const closeDeletePopup = () => {
    setDeletePopUp(false);
  };

  const getHotQueueListing = async () => {
    const filterText = [];
    if (filter.currentPage) filterText.push(`page=${filter.currentPage}`);
    if (filter.searchKey.length !== 0) filterText.push(`q=${filter.searchKey}`);
    if (filter.descendingSort)
      filterText.push(`descending_sort=${filter.descendingSort}`);

    try {
      const resp = await hotQueueServices.getHotQueueList(filterText);
      setTableData(resp.results);
      const navigationCopy = cloneDeep(navigation);
      navigationCopy.itemCount = resp.results.length;
      navigationCopy.totalCount = resp.total_count;
      navigationCopy.nextPageNum = resp.next_page_num;
      navigationCopy.prevPageNum = resp.prev_page_num;
      navigationCopy.hasNextPage = resp.has_next_page;
      navigationCopy.hasPrevPage = resp.has_prev_page;
      navigationCopy.dataPerPage = resp.data_per_page;
      setNavigation(navigationCopy);
      setLoader(false);
    } catch (err) {
      // error
    }
  };
  useEffect(() => {
    setLoader(true);
    let autoSaveTimer;
    autoSaveTimer = setTimeout(() => getHotQueueListing(), 1000);
    return () => {
      // cancel the request when component un mounts
      clearInterval(autoSaveTimer);
      autoSaveTimer = null;
    };
  }, [filter]);

  useEffect(() => {
    document.title = "BCG IA | Hot Queue";
  }, []);

  // -------------------- Alphabetical Order --------------------
  const ascending = () => {
    const filterAscending = cloneDeep(filter);
    filterAscending.descendingSort = "false";
    filterAscending.currentPage = "1";
    filterAscending.searchKey = "";
    setFilter(filterAscending);
  };

  const descending = () => {
    const filterDescending = cloneDeep(filter);
    filterDescending.descendingSort = "true";
    filterDescending.currentPage = "1";
    filterDescending.searchKey = "";
    setFilter(filterDescending);
  };

  return (
    <MainLayout>
      <MainHeader title="Hot Queue" />
      <div className="container hotqueue-management">
        <div className="page-header">
          <div className="page-header___right_hotqueue">
            <AddButton onClickHandle={addButtonHandle} />
            <div className="count_div">{tableData.length} Items</div>
          </div>
          {loader ? (
            <PaginationSkeleton />
          ) : (
            <Pagination
              filter={filter}
              setFilter={setFilter}
              navigation={navigation}
            />
          )}
        </div>

        <HotQueueTable
          loader={loader}
          tableData={tableData}
          popUpData={popUpData}
          setPopUpData={setPopUpData}
          setLoader={setLoader}
          setTableData={setTableData}
          ascending={ascending}
          descending={descending}
          getHotQueueListing={getHotQueueListing}
          openDeletePopup={openDeletePopup}
        />

        {popUpData.isShowPopUp && (
          <HotQueuePopUp
            setLoader={setLoader}
            popUpData={popUpData}
            closePopup={closePopup}
            getHotQueueListing={getHotQueueListing}
          />
        )}

        {showDeletePopUp && (
          <DeletePopup
            item={popUpData.data}
            setLoader={setLoader}
            getHotQueueListing={getHotQueueListing}
            closeDeletePopup={closeDeletePopup}
          />
        )}
      </div>
    </MainLayout>
  );
}

export default HotQueue;
