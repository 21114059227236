import axios from "axios";
import endpoints from "../api/endpoints";

const gitbookAxios = axios.create({ baseURL: endpoints.gitBookURL });
gitbookAxios.interceptors.request.use(
  (config) => {
    const token = process.env.REACT_APP_GITBOOK_TOKEN;
    const newConfig = config;
    if (token) newConfig.headers.Authorization = `Bearer ${token}`;

    return newConfig;
  },
  (error) => Promise.reject(error)
);
gitbookAxios.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export default gitbookAxios;
