import React, { useEffect } from "react";
import cn from "classnames";

import PropTypes from "prop-types";

import styles from "../../pages/dashboard/resources/index.module.scss";
import statusFilters from "../../pages/dashboard/modules/constants/statusFilter";
import AttachmentSVG from "../AttachmentSVG";
import CustomBullet from "../CustomBullet";

function MailExtensionItem({
  mailAttachment,
  tag,
  status,
  date,
  isSubChild,
  onClick,
  selected,
  hotQueueClickHandler,
}) {
  const {
    published,
    error,
    resolved,
    hotQueue,
    largeFile,
    historicalPrice,
    inactive,
    invalid,
    notAProduct,
    zipFile,
    ewka,
    quarantine,
    releaseQuarantined,
    sendQuarantined,
    NoAttachment,
    processing,
    startResolving,
    processingResolved,
    startValidation,
    completedValidation,
    processToUpload,
    pending,
    review,
    fdPublished,
  } = statusFilters;

  const IAProcessingStatus = ![
    published,
    error,
    resolved,
    hotQueue,
    largeFile,
    historicalPrice,
    inactive,
    invalid,
    startResolving,
    processingResolved,
    notAProduct,
    zipFile,
    ewka,
    quarantine,
    releaseQuarantined,
    sendQuarantined,
    NoAttachment,
    review,
    fdPublished,
  ].includes(status);

  const processingStatus = ![
    published,
    error,
    resolved,
    hotQueue,
    largeFile,
    historicalPrice,
    inactive,
    invalid,
    notAProduct,
    zipFile,
    ewka,
    quarantine,
    processing,
    releaseQuarantined,
    sendQuarantined,
    NoAttachment,
    startValidation,
    completedValidation,
    processToUpload,
    pending,
    review,
    fdPublished,
  ].includes(status);

  // effect to reload extension details in mail detail preview after status
  // change
  useEffect(() => {
    if (selected) {
      onClick();
    }
  }, [selected]);

  return (
    <div
      role="button"
      tabIndex="0"
      className={cn({
        [styles.mailExtensionItemRoot]: true,
        [styles.mailExtensionItemCircle]: tag !== "ia",
        [styles.gridCol2]: !date,
        [styles.gridCol3]: date,
        // [styles.pt0]: isSubChild,
        [styles.mailExtensionSelected]: selected,
      })}
      onKeyPress={onClick}
      onClick={onClick}
    >
      {mailAttachment && (
        <div style={{ textAlign: "left" }}>
          <div
            className={cn({
              [styles.previewFileDownload]: true,
              [styles.previewFileDownloadForExtension]: true,
            })}
          >
            {isSubChild && <CustomBullet />}
            {!isSubChild && tag === "ia" && (
              <div
                className={styles.generateIA}
                style={{ marginTop: 0, marginRight: "1rem" }}
              >
                IA Generated
              </div>
            )}
            {!!date && <AttachmentSVG />}
            <div className={styles.trimText}>
              <a
                className={styles.previewFileDownloadLink}
                download
                target="_blank"
                rel="noopener noreferrer"
                href={mailAttachment}
              >
                {mailAttachment.split("?")[0].split("/").pop()}
              </a>
            </div>
          </div>
        </div>
      )}

      <div>
        {(status === published || status === fdPublished) && (
          <div
            className={cn({
              [styles.recordStatus]: true,
              [styles.green]: true,
              [styles.threadRecord]: true,
            })}
          >
            Published
          </div>
        )}
        {status === error && (
          <div
            className={cn({
              [styles.recordStatus]: true,
              [styles.red]: true,
              [styles.threadRecord]: true,
            })}
          >
            Error
          </div>
        )}
        {status === review && (
          <div
            className={cn({
              [styles.recordStatus]: true,
              [styles.reviewYellow]: true,
              [styles.threadRecord]: true,
            })}
          >
            Review
          </div>
        )}
        {status === resolved && (
          <div
            className={cn({
              [styles.recordStatus]: true,
              [styles.blue]: true,
              [styles.threadRecord]: true,
            })}
          >
            Resolved
          </div>
        )}
        {(status === hotQueue || status === largeFile) && (
          <div
            className={cn({
              [styles.recordStatus]: true,
              [styles.orange]: true,
              [styles.threadRecord]: true,
            })}
            onClick={hotQueueClickHandler}
            onKeyPress={hotQueueClickHandler}
            tabIndex={0}
            role="button"
          >
            Hot Queue
          </div>
        )}
        {status === historicalPrice && (
          <div
            className={cn({
              [styles.recordStatus]: true,
              [styles.violet]: true,
              [styles.threadRecord]: true,
            })}
          >
            HP
          </div>
        )}
        {status === inactive && (
          <div
            className={cn({
              [styles.recordStatus]: true,
              [styles.red]: true,
              [styles.threadRecord]: true,
            })}
          >
            Inactive
          </div>
        )}
        {status === invalid && (
          <div
            className={cn({
              [styles.recordStatus]: true,
              [styles.red]: true,
              [styles.threadRecord]: true,
            })}
          >
            Not In DB
          </div>
        )}
        {status === notAProduct && (
          <div
            className={cn({
              [styles.recordStatus]: true,
              [styles.red]: true,
              [styles.threadRecord]: true,
            })}
          >
            Not A Product
          </div>
        )}
        {status === zipFile && (
          <div
            className={cn({
              [styles.recordStatus]: true,
              [styles.red]: true,
              [styles.threadRecord]: true,
            })}
          >
            Zip File
          </div>
        )}
        {status === ewka && (
          <div
            className={cn({
              [styles.recordStatus]: true,
              [styles.lightBlue]: true,
              [styles.threadRecord]: true,
            })}
          >
            EWKA
          </div>
        )}
        {status === NoAttachment && (
          <div
            className={cn({
              [styles.recordStatus]: true,
              [styles.lightBlue]: true,
              [styles.threadRecord]: true,
            })}
          >
            EWOA
          </div>
        )}
        {[quarantine, releaseQuarantined, sendQuarantined].includes(status) && (
          <div
            className={cn({
              [styles.recordStatus]: true,
              [styles.lightYellow]: true,
              [styles.threadRecord]: true,
            })}
          >
            QUARANTINE
          </div>
        )}
        {processingStatus && (
          <div
            className={cn({
              [styles.recordStatus]: true,
              [styles.yellow]: true,
              [styles.threadRecord]: true,
            })}
          >
            Processing
          </div>
        )}
        {IAProcessingStatus && (
          <div
            className={cn({
              [styles.recordStatus]: true,
              [styles.yellow]: true,
              [styles.threadRecord]: true,
            })}
          >
            IA Processing
          </div>
        )}
      </div>
    </div>
  );
}

export default MailExtensionItem;

MailExtensionItem.defaultProps = {
  mailAttachment: "",
  date: 0,
  isSubChild: false,
  onClick: () => {},
  selected: false,
};

MailExtensionItem.propTypes = {
  mailAttachment: PropTypes.string,
  tag: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  date: PropTypes.number,
  isSubChild: PropTypes.bool,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  hotQueueClickHandler: PropTypes.func.isRequired,
};
