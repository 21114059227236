import React, { useEffect, useState } from "react";
import { cloneDeep, findIndex, startCase, toNumber } from "lodash";
import { DatePicker, Radio } from "antd";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import PropTypes from "prop-types";

import TabHeader from "../TabHeader";
import MainHeader from "../../../components/MainHeader";
import AreaGraph from "../../../components/AreaChart";
import SidebarList from "../../../components/SidebarList";
import helper from "../../../utils/helper";
import reportServices from "../../../api/reportServices";
import userServices from "../../../api/userServices";
import CardSkeleton from "../../../components/Skeletons/CardSkeleton";
import BoxSkeleton from "../../../components/Skeletons/BoxSkeleton";
import "antd/dist/antd.css";
import "./style.scss";
import MainLayout from "../../../components/MainLayout";

const { RangePicker } = DatePicker;
const { dateOptionsList, timeSince, getProfilePic, disabledDate } = helper;

function FooterSection({ user, downloadPDF, lastLoginTime, userReports }) {
  return (
    <>
      <div className="sidebar-list-single__content">
        <img
          className="sidebar-list-single___pic"
          src={getProfilePic(user.profile_pic)}
          alt="profile-pic"
        />
        <div className="sidebar-list-single___data">
          <p>{startCase(user?.full_name)}</p>
          <span>{`Last Login: ${lastLoginTime(userReports?.last_login)}`}</span>
          <span>IP Address: {userReports?.ip_address || "Not Found"}</span>
        </div>
      </div>
      <button
        type="button"
        className="area-graph-footer-action"
        onClick={downloadPDF}
      >
        <img src="/assets/download.svg" alt="download" />
        <p>Download user report</p>
      </button>
    </>
  );
}

FooterSection.defaultProps = {
  user: null,
  userReports: null,
};

FooterSection.propTypes = {
  user: PropTypes.instanceOf(Object),
  downloadPDF: PropTypes.func.isRequired,
  lastLoginTime: PropTypes.func.isRequired,
  userReports: PropTypes.instanceOf(Object),
};

function RecentActivitySection({ mainLoader, userReports }) {
  if (mainLoader) {
    return <BoxSkeleton />;
  }
  if (userReports?.recent_activity?.length) {
    return userReports?.recent_activity.map((item) => (
      <div key={item} className="recent-activity-single">
        <div className="recent-activity-single___img-box">
          {/* <img src="/assets/code.png" alt="code" /> */}
          {/* <img src={getProfilePic(item.photo)} alt="profile-pic" /> */}
          <img src="/assets/avatar.png" alt="profile-pic" />
        </div>
        <div className="recent-activity-single___data-box">
          <div className="recent-activity-single___top-data">
            <h1>{startCase(item.full_name)}</h1>
            <span>{timeSince(item.created_at)}</span>
          </div>
          <p>{item.log}</p>
        </div>
      </div>
    ));
  }
  return null;
}

RecentActivitySection.defaultProps = {
  userReports: null,
};

RecentActivitySection.propTypes = {
  mainLoader: PropTypes.bool.isRequired,
  userReports: PropTypes.instanceOf(Object),
};

function UserReportTab() {
  const [sideLoader, setSideLoader] = useState(false);
  const [mainLoader, setMainLoader] = useState(false);
  const [dateDetails, setDateDetails] = useState({
    durationType: "daily", // monthly or weekly or daily,
    fromDate: moment().startOf("month"),
    toDate: moment(),
  });
  // for setting date offset
  const [hackValue, setHackValue] = useState();
  const [dates, setDates] = useState([]);

  const [filter, setFilter] = useState({
    currentPage: 1,
    searchKey: "",
  });
  const [navigation, setNavigation] = useState({
    itemCount: undefined,
    totalCount: undefined,
    nextPageNum: undefined,
    prevPageNum: undefined,
    hasNextPage: undefined,
    hasPrevPage: undefined,
    dataPerPage: 25,
  });

  const [userList, setUserList] = useState([]);
  const [userReports, setUserReports] = useState({});
  const [user, setUser] = useState({});

  const getUserReports = () => {
    setMainLoader(true);
    const filterText = [];

    const fromDate = moment(dateDetails.fromDate).format("YYYY-MM-DD");
    const toDate = moment(dateDetails.toDate).format("YYYY-MM-DD");

    if (user.uuid) filterText.push(`id=${user.uuid}`);
    if (dateDetails.durationType)
      filterText.push(`duration_type=${dateDetails.durationType}`);
    if (dateDetails.fromDate) filterText.push(`start_duration=${fromDate}`);
    if (dateDetails.toDate) filterText.push(`end_duration=${toDate}`);
    reportServices.getUserReports(filterText).then((resp) => {
      setUserReports(resp);
      setMainLoader(false);
    });
  };

  const getUserListDetails = () => {
    const filterText = [];

    if (filter.currentPage) filterText.push(`page=${filter.currentPage}`);
    if (filter.searchKey.length !== 0) filterText.push(`q=${filter.searchKey}`);
    userServices.getUserListing(filterText).then((resp) => {
      const navigationCopy = cloneDeep(navigation);
      navigationCopy.itemCount = resp.results.length;
      navigationCopy.totalCount = resp.total_count;
      navigationCopy.nextPageNum = resp.next_page_num;
      navigationCopy.prevPageNum = resp.prev_page_num;
      navigationCopy.hasNextPage = resp.has_next_page;
      navigationCopy.hasPrevPage = resp.has_prev_page;
      navigationCopy.dataPerPage = resp.data_per_page;
      setNavigation(navigationCopy);

      const newResponse = resp;
      // preselecting the first client
      if (newResponse.results.length !== 0) {
        newResponse.results[0].isActive = true;
        setUser(newResponse.results[0]);
      } else {
        setMainLoader(false);
      }
      setUserList(newResponse.results);
      setSideLoader(false);
    });
  };

  // component did update for user
  useEffect(() => {
    if (user.uuid) {
      getUserReports();
    }
  }, [user, dateDetails]);

  // component did mount and did update for filter
  useEffect(() => {
    setSideLoader(true);
    setMainLoader(true);
    let autoSaveTimer;
    autoSaveTimer = setTimeout(() => getUserListDetails(), 1000);
    return () => {
      // cancel the request when component un mounts
      clearInterval(autoSaveTimer);
      autoSaveTimer = null;
    };
  }, [filter]);

  const handleRadioButton = (e) => {
    const dateDetailsCopy = cloneDeep(dateDetails);
    dateDetailsCopy.durationType = e.target.value;
    // setting default values
    if (e.target.value === "monthly")
      dateDetailsCopy.fromDate = moment().startOf("year");
    else if (e.target.value === "weekly" || e.target.value === "daily")
      dateDetailsCopy.fromDate = moment().startOf("month");
    setDateDetails(dateDetailsCopy);
  };

  const handleUser = (item) => {
    setUser(item);
    const userListCopy = cloneDeep(userList);

    const oldIndex = findIndex(
      userListCopy,
      (currentUser) => currentUser.isActive
    );
    const newIndex = findIndex(
      userListCopy,
      (currentUser) => currentUser.uuid === item.uuid
    );

    userListCopy[oldIndex].isActive = false;
    userListCopy[newIndex].isActive = true;

    setUserList(userListCopy);
  };

  const handleDate = ([fromDate, endDate]) => {
    const dateDetailsCopy = cloneDeep(dateDetails);
    dateDetailsCopy.fromDate = fromDate;
    dateDetailsCopy.toDate = endDate;

    setDateDetails(dateDetailsCopy);
  };
  const searchHandler = (value) => {
    const filterCopy = cloneDeep(filter);
    filterCopy.searchKey = value;
    filterCopy.currentPage = 1;
    setFilter(filterCopy);
  };

  const onOpenChange = (open) => {
    if (open) {
      setHackValue([]);
      setDates([]);
    } else {
      setHackValue(undefined);
    }
  };

  const downloadPDF = () => {
    window.print();
  };

  const lastLoginTime = (timeString) => {
    if (timeString === 0 || undefined || null) {
      return "Not Found";
    }
    const newTimeString = toNumber(timeString) * 1000;
    const time = new Date(newTimeString);
    return moment(time).format("D MMM HH mm");
  };

  return (
    <MainLayout>
      <MainHeader title="Overview" />
      <div className="tab-content-wrapper">
        <TabHeader onChange={searchHandler} value={filter.searchKey} />
        <div className="tab-content user-report-tab">
          <div className="user-reports-sidebar">
            <SidebarList
              loader={sideLoader}
              dataSet={userList}
              title="Users"
              handleUser={handleUser}
              filter={filter}
              setFilter={setFilter}
              navigation={navigation}
            />
            {/* <div className="tab-box-wrapper">
              <div className="recent-activity-header">
                <h1 className="box-header">RECENT ACTIVITY</h1>
              </div>
              <RecentActivitySection
                mainLoader={mainLoader}
                userReports={userReports}
              />
            </div> */}
          </div>

          <div className="client-report-data-col">
            <div className="tab-box-wrapper user-tab-container">
              <div className="graph-wrapper">
                <div className="graph-header">
                  <div className="graph-header-left">
                    <h1>Resolved graph</h1>
                    <Radio.Group
                      options={dateOptionsList}
                      onChange={handleRadioButton}
                      value={dateDetails.durationType}
                      buttonStyle="solid"
                      optionType="button"
                      className="graph-header-radio-btn"
                    />
                  </div>
                  <div className="graph-header-right">
                    <RangePicker
                      format="DD-MM-YYYY"
                      value={
                        hackValue || [dateDetails.fromDate, dateDetails.toDate]
                      }
                      onChange={handleDate}
                      allowClear={false}
                      disabledDate={(current) =>
                        disabledDate(current, dates, dateDetails.durationType)
                      }
                      onCalendarChange={(val) => setDates(val)}
                      onOpenChange={onOpenChange}
                    />
                  </div>
                </div>
                <div className="user-report-2col-container">
                  {mainLoader ? (
                    <Skeleton width="100%" height={440} />
                  ) : (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <AreaGraph
                        resolved={userReports?.resolved}
                        xAxisLabels={userReports?.label}
                      />
                    </div>
                  )}

                  <div>
                    <div className="sidebar-list-single__content">
                      <div className="sidebar-list-single___data">
                        <p>{startCase(user?.full_name)}</p>
                        <span>{`Last Login: ${lastLoginTime(
                          userReports?.last_login
                        )}`}</span>
                        {/* <span>
                          IP Address: {userReports?.ip_address || "Not Found"}
                        </span> */}
                      </div>
                    </div>
                    <div className="user-report-data-top-row">
                      <div className="tab-box-wrapper overview-stats-count">
                        <p>Total Files Resolved </p>
                        {/* <span>80% Increase</span> */}
                        <div className="inline-img">
                          <div className="green-round">
                            <img src="/assets/green-up.png" alt="green-arrow" />
                          </div>
                          <h1>
                            {mainLoader ? (
                              <CardSkeleton />
                            ) : (
                              userReports?.total_files_resolved || 0
                            )}
                          </h1>
                        </div>
                      </div>
                      <div className="tab-box-wrapper overview-stats-count">
                        <p>Resolved Weekly</p>
                        {/* <span>80% Increase</span> */}
                        <div className="inline-img">
                          <div className="green-round">
                            <img src="/assets/green-up.png" alt="green-arrow" />
                          </div>
                          <h1>
                            {mainLoader ? (
                              <CardSkeleton />
                            ) : (
                              userReports?.resolved_weekly || 0
                            )}
                          </h1>
                        </div>
                      </div>
                      <div className="tab-box-wrapper overview-stats-count">
                        <p>Resolved Monthly</p>
                        {/* <span>80% Increase</span> */}
                        <div className="inline-img">
                          <div className="green-round">
                            <img src="/assets/green-up.png" alt="green-arrow" />
                          </div>
                          <h1>
                            {mainLoader ? (
                              <CardSkeleton />
                            ) : (
                              userReports?.total_files_resolved || 0
                            )}
                          </h1>
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="area-graph-footer-action"
                      onClick={downloadPDF}
                    >
                      <img src="/assets/download.svg" alt="download" />
                      <p>Download user report</p>
                    </button>
                  </div>
                </div>
                <div className="area-graph-footer">
                  <div className="recent-activity-header">
                    <h1 className="box-header">Recent Activity</h1>
                  </div>
                  <div className="recent-activity-list">
                    <RecentActivitySection
                      mainLoader={mainLoader}
                      userReports={userReports}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default UserReportTab;
