import React from "react";
import { Pagination } from "antd";
import PropTypes from "prop-types";

import styles from "../../../resources/index.module.scss";
import "antd/dist/antd.css";

function BottomPagination({
  pageSize,
  currentPage,
  totalCount,
  onChange,
  disabled,
}) {
  return (
    <Pagination
      className={styles.bottomPagination}
      pageSizeOptions={[25, 50, 100]}
      total={totalCount}
      pageSize={pageSize}
      current={currentPage}
      showSizeChanger
      showQuickJumper={{ goButton: "page" }}
      onChange={onChange}
      position="both"
      showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
      disabled={disabled}
    />
  );
}

export default BottomPagination;

BottomPagination.defaultProps = {
  pageSize: 25,
  currentPage: 1,
  disabled: false,
};

BottomPagination.propTypes = {
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  totalCount: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
