import React, { useState } from "react";
import { cloneDeep, findIndex } from "lodash";
import PropTypes from "prop-types";
import { Switch } from "antd";
import Skeleton from "react-loading-skeleton";
import "./style.scss";
import showAlert from "../../../utils/showAlert";
import hotQueueServices from "../../../api/hotQueueServices";

function HotQueueTable({
  loader,
  setLoader,
  tableData,
  setTableData,
  popUpData,
  setPopUpData,
  openDeletePopup,
  ascending,
  descending,
}) {
  const [alphabeticalOrder, setAlphabeticalOrder] = useState(true);

  const editHandler = (item) => {
    const popUpDataCopy = cloneDeep(popUpData);
    popUpDataCopy.isShowPopUp = true;
    popUpDataCopy.isEditMode = true;
    popUpDataCopy.data = item;
    setPopUpData(popUpDataCopy);
  };

  const deleteHandler = (item) => {
    // saving the item that needs to be deleted in state variable
    const popUpDataCopy = cloneDeep(popUpData);
    popUpDataCopy.isShowPopUp = false;
    popUpDataCopy.isEditMode = false;
    popUpDataCopy.data = item;
    setPopUpData(popUpDataCopy);

    // shows the delete popup
    openDeletePopup();
  };

  const statusHandler = async (item) => {
    try {
      const payload = {
        ...item,
        active: !item.active,
        instruction: item?.instruction ?? "",
        hotqueue_notes: item?.hotqueue_notes ?? "",
      };

      await hotQueueServices.editHotQueue(item.id, payload);

      // deactivate client
      setLoader(true);
      showAlert(
        `Hot Queue ${item.active ? "deactivated!" : "activated!"}`,
        "success"
      );
      const tableDataCopy = cloneDeep(tableData);
      const index = findIndex(tableDataCopy, (user) => user.id === item.id);
      tableDataCopy[index].active = !item.active;
      setTableData(tableDataCopy);
      setLoader(false);
    } catch (err) {
      // error
    }
  };

  // --------------- Alphabetical Sorting ---------------
  const sortingOrder = () => {
    if (alphabeticalOrder) {
      ascending();
      setAlphabeticalOrder(false);
    } else {
      descending();
      setAlphabeticalOrder(true);
    }
  };

  const tableSkeletonSection = () => (
    <>
      {Array.from({ length: 7 })
        .map((_, index) => index)
        .map((el) => (
          <div className="table-row table-row-skeleton" key={el}>
            <Skeleton width="99%" height={20} />
          </div>
        ))}
    </>
  );
  const tableDataSection = () => (
    <>
      {tableData.map((item) => (
        <div className="table-row" key={item}>
          <div className="table-col profile-col">
            <p data-testid="name">{item.rule_name}</p>
          </div>
          <div className="table-col action-col">
            <div className="table-action__double_hotqueue">
              <div className="table-action___single">
                <button
                  type="button"
                  onClick={() => editHandler(item)}
                  data-testid="edit-btn"
                >
                  <img src="/assets/edit.svg" alt="edit" />
                  Edit / Show Rule
                </button>
              </div>
            </div>
            <div className="table-action__double_hotqueue">
              <div className="table-action___single">
                <button type="button" onClick={() => deleteHandler(item)}>
                  <img src="/assets/remove-btn.svg" alt="remove" />
                  Remove
                </button>
              </div>
            </div>
          </div>

          <div className="table-action___single">
            <Switch
              data-testid="status-switch"
              checked={item.active}
              onChange={() => statusHandler(item)}
            />
          </div>
        </div>
      ))}
      {/* if there are no records  */}
      {tableData.length === 0 && (
        <div className="table-row table-row-no-data">No Record Found!</div>
      )}
    </>
  );

  return (
    <div className="table" data-testid="hot-queue">
      <div className="table-header">
        <p>
          Hot Queue{" "}
          <button type="button" onClick={sortingOrder}>
            {" "}
            {alphabeticalOrder ? (
              <img alt="sort" src="/assets/bx_sort-z-a.svg" />
            ) : (
              <img alt="sort" src="/assets/bx_sort-a-z.svg" />
            )}
          </button>
        </p>

        <p className="action-text">Actions</p>
        <p className="action-text">Status</p>
      </div>
      <div className="table-body">
        {loader ? tableSkeletonSection() : tableDataSection()}
      </div>
    </div>
  );
}

export default HotQueueTable;

HotQueueTable.propTypes = {
  loader: PropTypes.bool.isRequired,
  setLoader: PropTypes.func.isRequired,
  tableData: PropTypes.instanceOf(Array).isRequired,
  setTableData: PropTypes.func.isRequired,
  popUpData: PropTypes.instanceOf(Object).isRequired,
  setPopUpData: PropTypes.func.isRequired,
  openDeletePopup: PropTypes.func.isRequired,
  ascending: PropTypes.func.isRequired,
  descending: PropTypes.func.isRequired,
};
