import React, { useEffect, useState } from "react";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import PropTypes from "prop-types";
import { Tooltip } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import reportServices from "../../../../../api/reportServices";
import BulletChart from "../../../../../components/BulletChart";
import { ReactComponent as FileAnalysisIcon } from "../../../../../assets/fileAnalysis.svg";
import { ReactComponent as FilesIcon } from "../../../../../assets/files.svg";

function LabelValue({
  isLeftAligned,
  divider,
  mainLoader,
  value,
  title,
  tooltip,
}) {
  let rootClassName;
  if (isLeftAligned) {
    if (divider) {
      rootClassName = "label-value";
    } else {
      rootClassName = "label-value-last";
    }
  } else if (divider) {
    rootClassName = "label-value-center-aligned";
  } else {
    rootClassName = "label-value-center-aligned-last";
  }
  return (
    <div className={rootClassName}>
      <Tooltip title={tooltip}>
        <div
          className={
            isLeftAligned ? "" : "label-value-center-aligned-inner-div"
          }
        >
          {mainLoader ? (
            <Skeleton width={50} height={24} />
          ) : (
            <p className="label-value-value">{value || 0}</p>
          )}
          <p className="label-value-title">{title}</p>
        </div>
      </Tooltip>
    </div>
  );
}

LabelValue.defaultProps = {
  isLeftAligned: false,
  divider: true,
  value: 0,
  tooltip: "",
};

LabelValue.propTypes = {
  isLeftAligned: PropTypes.bool,
  divider: PropTypes.bool,
  mainLoader: PropTypes.bool.isRequired,
  value: PropTypes.number,
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
};

function GridItemAnalysis({
  header,
  total,
  published,
  processing,
  resolved,
  repair_queue: repairQueue,
  quarentined,
  others,
  mainLoader,
  viewType,
  data,
  tooltip,
}) {
  return (
    <div className="metrics-grid-item">
      <Tooltip title={tooltip}>
        <h3 className="grid-item-heading">
          <FileAnalysisIcon style={{ marginRight: 10 }} />
          {header}
        </h3>
      </Tooltip>
      {viewType === "list" ? (
        <div className="metrics-grid-item-summary">
          <LabelValue
            title="Total"
            value={total}
            // isLeftAligned
            mainLoader={mainLoader}
            tooltip="Total is the number of files processed by IA. Total does not usually include Quarantine and EWOA as there are usually no files processed by IA for those"
          />
          <LabelValue
            title="Published"
            value={published}
            mainLoader={mainLoader}
          />
          <LabelValue
            title="Processing"
            value={processing}
            mainLoader={mainLoader}
          />
          <LabelValue
            title="Resolved"
            value={resolved}
            mainLoader={mainLoader}
          />
          <LabelValue
            title="Repair Queue"
            value={repairQueue}
            mainLoader={mainLoader}
          />
          <LabelValue
            title="Quarantined"
            value={quarentined}
            mainLoader={mainLoader}
          />
          <LabelValue
            title="Others"
            value={others}
            divider={false}
            mainLoader={mainLoader}
          />
        </div>
      ) : (
        <BulletChart
          data={data}
          secondaryDataFields={[
            "Processing",
            "Quarantine",
            "Resolved",
            "Repair Queue",
          ]}
          primaryDataFields={["Published"]}
          title="File Analysis"
        />
      )}
    </div>
  );
}

GridItemAnalysis.defaultProps = {
  total: 0,
  published: 0,
  processing: 0,
  resolved: 0,
  repair_queue: 0,
  quarentined: 0,
  others: 0,
  data: [],
  tooltip: "",
};

GridItemAnalysis.propTypes = {
  header: PropTypes.string.isRequired,
  total: PropTypes.number,
  published: PropTypes.number,
  processing: PropTypes.number,
  resolved: PropTypes.number,
  repair_queue: PropTypes.number,
  quarentined: PropTypes.number,
  others: PropTypes.number,
  mainLoader: PropTypes.bool.isRequired,
  viewType: PropTypes.string.isRequired,
  data: PropTypes.shape(Array),
  tooltip: PropTypes.string,
};

function ProductAnalysis({
  style,
  header,
  published,
  processing,
  resolved,
  repair_queue: repairQueue,
  others,
  mainLoader,
  tooltip,
}) {
  return (
    <div style={style} className="metrics-grid-item">
      <Tooltip title={tooltip}>
        <h3 className="grid-item-heading">
          <img
            src="/assets/product-analysis.svg"
            alt="product-analysis"
            style={{ marginRight: 5 }}
          />
          {header}
        </h3>
      </Tooltip>
      <div className="metrics-grid-item-summary">
        <LabelValue
          title="Total"
          value={published + processing + repairQueue + resolved + others}
          // isLeftAligned
          mainLoader={mainLoader}
        />
        <LabelValue
          title="Published"
          value={published}
          mainLoader={mainLoader}
        />
        <LabelValue
          title="Processing"
          value={processing}
          mainLoader={mainLoader}
        />
        <LabelValue
          title="Repair Queue"
          value={repairQueue}
          mainLoader={mainLoader}
        />
        <LabelValue title="Resolved" value={resolved} mainLoader={mainLoader} />
        <LabelValue
          title="Others"
          value={others}
          divider={false}
          mainLoader={mainLoader}
        />
      </div>
    </div>
  );
}

ProductAnalysis.defaultProps = {
  published: 0,
  processing: 0,
  resolved: 0,
  repair_queue: 0,
  others: 0,
  tooltip: "",
};

ProductAnalysis.propTypes = {
  style: PropTypes.instanceOf(Object).isRequired,
  header: PropTypes.string.isRequired,
  published: PropTypes.number,
  processing: PropTypes.number,
  resolved: PropTypes.number,
  repair_queue: PropTypes.number,
  others: PropTypes.number,
  mainLoader: PropTypes.bool.isRequired,
  tooltip: PropTypes.string,
};

function GridItemTotal({ header, hint, value, mainLoader, tooltip }) {
  return (
    <div className="metrics-grid-item-total">
      <div className="metrics-grid-item-total-inner-div">
        <div className="grid-main-heading">
          <Tooltip title={tooltip}>
            <h3 className="grid-item-heading" style={{ alignSelf: "center" }}>
              <img
                src="/assets/email-analysis.svg"
                alt="email-analysis"
                style={{ marginRight: 5 }}
              />
              {header}
            </h3>
          </Tooltip>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="itemTotalHint" title={hint}>
            <InfoOutlinedIcon
              id="itemTotalHint"
              fontSize="small"
              color="primary"
            />
          </label>
        </div>
        <div className="value-div">
          <LabelValue
            title="Total"
            value={value}
            divider={false}
            // isLeftAligned
            mainLoader={mainLoader}
          />
        </div>
      </div>
    </div>
  );
}

GridItemTotal.defaultProps = {
  value: 0,
  tooltip: "",
};

GridItemTotal.propTypes = {
  header: PropTypes.string.isRequired,
  mainLoader: PropTypes.bool.isRequired,
  hint: PropTypes.string.isRequired,
  value: PropTypes.number,
  tooltip: PropTypes.string,
};

function GridItemFiles({ data, mainLoader }) {
  return (
    <div className="metrics-grid-item-files-content">
      <Tooltip title="Processed files by IA predicted by IA with unit price fundtag">
        <h3 className="grid-item-heading">
          <FilesIcon style={{ marginRight: 10 }} />
          Files
        </h3>
      </Tooltip>
      <div className="metrics-grid-item-files-summary">
        <LabelValue
          title="Received"
          value={data?.received_count ? data?.received_count : 0}
          // isLeftAligned
          mainLoader={mainLoader}
        />
        <LabelValue
          title="IA Generated"
          value={data?.ia_generated_count ? data?.ia_generated_count : 0}
          mainLoader={mainLoader}
        />

        <LabelValue
          title="Total"
          value={
            data?.total_attachment_count ? data?.total_attachment_count : 0
          }
          divider={false}
          mainLoader={mainLoader}
          tooltip="Total is the number of files processed by IA. Total does not usually include Quarantine and EWOA as there are usually no files processed by IA for those"
        />
      </div>
    </div>
  );
}

GridItemFiles.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  mainLoader: PropTypes.bool.isRequired,
};

function Metrics({ dateDetails, viewType }) {
  const [mainLoader, setMainLoader] = useState(false);
  const [emailData, setEmailData] = useState();
  const [attachmentData, setAttachmentData] = useState();
  const [productData, setProductData] = useState();

  function getMetricsData() {
    setMainLoader(true);
    const filterText = [];
    const fromDate = moment(dateDetails.fromDate).format("YYYY-MM-DD");
    const toDate = moment(dateDetails.toDate).format("YYYY-MM-DD");
    if (dateDetails.fromDate) filterText.push(`date_from=${fromDate}`);
    if (dateDetails.toDate) filterText.push(`date_to=${toDate}`);
    reportServices.getDashboardMetrics(filterText, "email").then((resp) => {
      setEmailData(resp);
    });
    reportServices
      .getDashboardMetrics(filterText, "attachment")
      .then((resp) => {
        setAttachmentData(resp);
      });
    reportServices.getDashboardMetrics(filterText, "products").then((resp) => {
      setProductData(resp);
      setMainLoader(false);
    });
  }

  useEffect(() => {
    if (dateDetails) getMetricsData();
  }, [dateDetails]);

  return (
    <>
      <div
        className="metrics-grid-1"
        style={{ marginTop: viewType === "list" ? 16 : -15 }}
      >
        <div>
          {viewType === "list" ? (
            <GridItemAnalysis
              header="Workflow Status Analysis"
              others={attachmentData?.others_count}
              quarentined={attachmentData?.quarantined_count}
              repair_queue={attachmentData?.repair_queued_count}
              resolved={attachmentData?.resolved_count}
              processing={attachmentData?.processing_count}
              published={attachmentData?.published_count}
              total={attachmentData?.total_attachment_count}
              mainLoader={mainLoader}
              viewType={viewType}
              data={attachmentData}
              tooltip="Analysis of Workflow statuses predicted with a unit price fundtag"
            />
          ) : (
            <BulletChart
              mainLoader={mainLoader}
              data={attachmentData}
              secondaryDataFields={[
                "Processing",
                "Quarantine",
                "Resolved",
                "Repair Queue",
                "Others",
              ]}
              primaryDataFields={["Published"]}
              title="Workflow Status Analysis"
              totalValue={attachmentData?.total_attachment_count}
              tooltip="Analysis of Workflow statuses predicted with a unit price fundtag"
            />
          )}

          {viewType === "list" ? (
            <ProductAnalysis
              header="Unit Price Records Analysis"
              others={productData?.other_products_count}
              repair_queue={productData?.repair_queued_products_count}
              resolved={productData?.resolved_products_count}
              processing={productData?.processing_products_count}
              published={productData?.published_products_count}
              style={{ marginTop: 16 }}
              mainLoader={mainLoader}
              tooltip="Number of individual records of products with unit price information and dates"
            />
          ) : (
            <BulletChart
              mainLoader={mainLoader}
              data={productData}
              secondaryDataFields={[
                "Processing",
                "Quarantine",
                "Repair Queue",
                "Resolved",
                "Others",
              ]}
              primaryDataFields={["Published"]}
              title="Unit Price Records Analysis"
              totalValue={
                productData.published_products_count +
                productData.processing_products_count +
                productData.repair_queued_products_count +
                productData.resolved_products_count +
                productData.other_products_count
              }
              tooltip="Number of individual records of products with unit price information and dates"
            />
          )}
        </div>
        {viewType === "list" && (
          <div>
            <GridItemTotal
              header="Emails"
              hint="Total Emails"
              value={emailData?.email_count}
              mainLoader={mainLoader}
              tooltip="Total number of emails that have an IA unit price fundtag prediction"
            />
          </div>
        )}
      </div>

      {viewType === "list" ? (
        <>
          {/* <div className="divider" style={{ width: "75%", marginTop: 16 }} /> */}
          <div className="metrics-grid-item-files" style={{ marginTop: 16 }}>
            <GridItemFiles data={attachmentData} mainLoader={mainLoader} />
            <GridItemTotal
              style={{ marginLeft: 16 }}
              header="Emails"
              hint="Emails with attachments"
              value={emailData?.email_with_files}
              mainLoader={mainLoader}
              tooltip="Total number of emails that have an IA unit price tag prediction but excluding emails that do not have any processed files such as Quarantine and EWOA"
            />
          </div>
        </>
      ) : (
        <BulletChart
          mainLoader={mainLoader}
          data={attachmentData}
          secondaryDataFields={["Recieved", "Total"]}
          primaryDataFields={["IA Generated"]}
          title="Files"
          totalValue={emailData?.email_with_files}
          tooltip="Processed files by IA predicted by IA with unit price fundtag"
        />
      )}
    </>
  );
}

export default Metrics;

Metrics.propTypes = {
  dateDetails: PropTypes.instanceOf(Object).isRequired,
  viewType: PropTypes.string.isRequired,
};
